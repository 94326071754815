import * as S from './MappedEvent.styles'
import { Button, Paragraph } from 'applaus-ui-kit'
import { MappedEvent as MappedEventComponent } from '../../Desktop/components/MappedEvent'
import { Dispatch, SetStateAction } from 'react'
import { StepState } from '../Event'
import produce from 'immer'

type Props = {
  selectedEvent: any
  setStep: Dispatch<SetStateAction<StepState>>
}

export const MappedEvent = ({ selectedEvent, setStep }: Props) => {
  return (
    <S.Container>
      <div>
        <S.BackIcon
          onClick={() =>
            setStep(
              produce((draft) => {
                draft.show = 'chooseEventItem'
              })
            )
          }
        />
        <Paragraph variant="large" type="bold">
          Ingresso
        </Paragraph>
      </div>
      {selectedEvent ? (
        <MappedEventComponent eventItem={selectedEvent} />
      ) : undefined}
      {selectedEvent.tickets && selectedEvent.tickets?.length > 0 && (
        <>
          <div style={{ height: '74px' }} />
          <S.TicketBox>
            <S.TicketWrapper>
              <S.TicketAmount>
                <Paragraph variant="small" type="semiBold">
                  {0}
                </Paragraph>
              </S.TicketAmount>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="button"
                size="small"
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => {}}>
                Comprar agora
              </Button>
            </S.TicketWrapper>
          </S.TicketBox>
        </>
      )}
    </S.Container>
  )
}
