import { AxiosResponse } from 'axios'

import { api } from '../config'

export type GetOneTicketResponse = {
  id: string
  name: string
  description: string
  priceCents: number
  discountCents: number
  type: string
  rule: string
  category: string
  createdAt: string
  updatedAt: string | null
  customField: string | null
  eventItems: {
    eventItem: {
      id: string
    }
    ticketQuantity: number
  }[]
}

export const getOneTicketService = async (ticketId: string) => {
  const { data }: AxiosResponse<GetOneTicketResponse> = await api.get(
    `api/events/eventItems/tickets/${ticketId}`
  )

  return data
}
