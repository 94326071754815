import styled, { css } from 'styled-components'

import { ReactComponent as SearchFile } from '../../assets/icons/search.svg'
import { ReactComponent as EditFile } from '../../assets/icons/edit.svg'
import { ReactComponent as ExcludeFile } from '../../assets/icons/trash-alt.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    max-width: 1366px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl}
      ${theme.spacing.stack.xxs};

    h5 {
      font-weight: ${theme.font.weight.semiBold};
      margin-bottom: ${theme.spacing.stack.sm};
    }

    .label {
      color: #39383a;
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.regular};
      line-height: 21px;
    }

    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs};
    }
  `}
`

export const CreatePartnerBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing.stack.md};
    display: flex;
    justify-content: space-between;
    > button {
      white-space: nowrap;
      margin-right: auto;
    }
    @media (max-width: 1170px) {
      > button {
        margin: ${theme.spacing.stack.xxxs} auto ${theme.spacing.stack.xxxs} 0;
      }
      flex-direction: column;
      margin-top: ${theme.spacing.stack.xxs};
    }
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    margin-right: ${theme.spacing.stack.xxxs};
  `}
`

export const EditPartnerCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacing.stack.small};
    width: 100%;
    .titleBox {
      width: 80%;
      display: flex;
      flex-direction: column;
    }
    .paragraph {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .actions {
      display: flex;
    }
  `}
`

export const Table = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing.stack.xs} 0;
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.spacing.stack.xxl};
  `}
`

export const TableHead = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    margin-bottom: ${theme.spacing.stack.xs};
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    border-radius: ${theme.border.radius.medium};
    > p {
      color: #808080;
    }
  `}
`

export const TableBody = styled.div`
  ${({ theme }) => css`
    > div {
      margin-bottom: ${theme.spacing.stack.xxs};
    }
  `}
`

export const TableData = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    padding: ${theme.spacing.stack.xxs};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.border.radius.medium};

    p:first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: ${theme.spacing.stack.xxxs};
    }

    .WAITING {
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      font-weight: ${theme.font.weight.semiBold};
      color: ${theme.color.alert.warning.nth1};
    }

    .ACCEPTED {
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      font-weight: ${theme.font.weight.semiBold};
      color: ${theme.color.alert.success.nth2};
    }

    .edit {
      display: flex;
      justify-content: flex-end;
    }
  `}
`

export const SearchIcon = styled(SearchFile)`
  position: absolute;
  fill: #39383a;
  top: 50%;
  transform: translateY(-50%);
  left: 3rem;
`

export const ExcludeIcon = styled(ExcludeFile)`
  fill: #808080;
  margin-left: 1rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
    fill: black;
  }
  transition: 0.3s ease;
`
export const EditIcon = styled(EditFile)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.inline.xxxs};
    cursor: pointer;
    fill: #b88bcf;
    transition: 0.3s ease;
    &:hover {
      transform: scale(1.2);
      fill: #8601c9;
    }
  `}
`

export const Input = styled.input`
  ${({ theme }) => css`
    background: #f4f4f4;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxs}
      ${theme.spacing.stack.xxxs} 72px;
    width: 100%;
    font-size: ${theme.font.size.sm};
    border-radius: ${theme.border.radius.medium};
    outline: 0;
    border: 0;

    &::placeholder {
      font-size: ${theme.font.size.sm};
    }
  `}
`

export const PartnerData = styled.div`
  ${({ theme }) => css`
    max-width: 640px;
    @media (min-width: 768px) {
      margin-bottom: ${theme.spacing.stack.xxs};
    }
  `}
`

export const PartnerAddress = styled.div`
  ${({ theme }) =>
    css`
      max-width: 640px;
      @media (min-width: 768px) {
        margin-bottom: ${theme.spacing.stack.xxs};
      }
    `}
`

export const Error = styled.p`
  ${({ theme }) => css`
    color: #fc3e3e;
    font-size: 12px;
    min-height: 24px;
  `}
`

export const PartnerBankData = styled.div`
  ${({ theme }) => css`
    max-width: 640px;
  `}
`

export const ButtonBox = styled.div`
  ${({ theme }) => css`
    max-width: 640px;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      color: ${theme.color.alert.error.nth1};
    }

    button:nth-child(2) {
      margin-left: ${theme.spacing.stack.small};
      background: ${theme.color.base.nth1};
    }
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    marginTop: '4px',
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}
