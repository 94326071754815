const ErrorIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6595 3.53863L22.7095 17.5386C22.9809 17.9963 23.1257 18.5179 23.129 19.05C23.1324 19.582 22.9941 20.1054 22.7285 20.5665C22.4629 21.0275 22.0794 21.4096 21.6174 21.6735C21.1554 21.9375 20.6316 22.0739 20.0995 22.0686H3.97952C3.4517 22.065 2.93418 21.9222 2.4792 21.6547C2.02422 21.3871 1.64789 21.0042 1.3882 20.5447C1.12852 20.0852 0.994665 19.5652 1.00016 19.0374C1.00566 18.5096 1.15031 17.9926 1.41952 17.5386L9.41952 3.53863C9.67971 3.07215 10.0597 2.68359 10.5203 2.4131C10.9809 2.14261 11.5054 2 12.0395 2C12.5737 2 13.0981 2.14261 13.5587 2.4131C14.0193 2.68359 14.3993 3.07215 14.6595 3.53863Z"
        fill="current"
      />
      <path
        d="M12 16C11.8022 16 11.6089 16.0586 11.4444 16.1685C11.28 16.2784 11.1518 16.4346 11.0761 16.6173C11.0004 16.8 10.9806 17.0011 11.0192 17.1951C11.0578 17.3891 11.153 17.5673 11.2929 17.7071C11.4327 17.847 11.6109 17.9422 11.8049 17.9808C11.9989 18.0194 12.2 17.9996 12.3827 17.9239C12.5654 17.8482 12.7216 17.72 12.8315 17.5556C12.9414 17.3911 13 17.1978 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16ZM12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z"
        fill="white"
      />
    </svg>
  )
}

export { ErrorIcon }
