import * as S from './Tickets.styles'
import { useState } from 'react'
import { AlertModal, Button, Heading, Paragraph } from 'applaus-ui-kit'
import { CreateTickets } from './CreateTickets/CreateTickets'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/rootReducer'
import {
  deleteTicket,
  deleteTicketRequest,
  nextStep,
  stepBack
} from '../../../../redux/Events/actions'
import { TicketTable } from './components/ticketsTable/TicketsTable'
import { ModalProps } from 'applaus-ui-kit/dist/components/alertModal/AlertModal'

import { Tickets as TicketsType } from '../../../../redux/Events/types'
import { EditTickets } from './EditTickets/EditTickets'

export const Tickets = () => {
  const [createTicket, setCreateTicket] = useState(false)

  const [editTicket, setEditTicket] = useState<{
    open: boolean
    ticket?: TicketsType
  }>({
    open: false
  })

  const { manageEvent } = useSelector((state: ReduxState) => state.EventReducer)
  const [alertModalState, setAlertModalState] = useState<ModalProps>({
    open: false,
    click: () => {},
    buttonText: '',
    message: '',
    variant: 'error'
  })
  const dispatch = useDispatch()

  const closeModal = () => {
    setAlertModalState({
      open: false,
      click: () => {},
      buttonText: '',
      message: '',
      variant: 'error'
    })
  }

  const handleRemoveTicket = (ticketId: string, ticket: TicketsType) => {
    setAlertModalState({
      buttonText: 'Sim',
      cancelClick: () => closeModal(),
      message: 'Deseja deletar o ingresso',
      click: () => {
        dispatch(deleteTicket(ticket))
        dispatch(deleteTicketRequest(ticketId))
        closeModal()
      },
      open: true,
      variant: 'error'
    })
  }

  const handleEditTicket = (ticket: TicketsType) => {
    setEditTicket({
      open: true,
      ticket: ticket
    })
  }

  const tickets = manageEvent?.eventItems?.flatMap((eventItem) =>
    eventItem.tickets.filter((ticket) => ticket)
  )

  return (
    <>
      <S.Container>
        <S.Wrapper>
          {createTicket ? (
            <CreateTickets setCreateTicket={() => setCreateTicket(false)} />
          ) : editTicket.open ? (
            <EditTickets
              editTicket={editTicket}
              setEditTicket={setEditTicket}
            />
          ) : (
            <>
              <S.TitleBox>
                <S.BackIcon onClick={() => dispatch(stepBack())} />
                <Heading variant="h3">Ingressos</Heading>
              </S.TitleBox>
              <Paragraph variant="large">
                Configure os tipos de ingressos do seu evento
              </Paragraph>
              <S.CreateTicketBox>
                <Paragraph variant="large" type="bold">
                  Ingressos
                </Paragraph>
                <Button
                  onClick={() => setCreateTicket(true)}
                  type="button"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  fullWidth={false}>
                  + Adicionar Ingresso
                </Button>
              </S.CreateTicketBox>
              <TicketTable
                eventItems={manageEvent?.eventItems ?? []}
                handleRemoveTicket={handleRemoveTicket}
                handleEditTicket={handleEditTicket}
              />
              <Button
                className="continueButton"
                onClick={() => {
                  tickets && tickets?.length > 0 && dispatch(nextStep())
                }}
                disabled={tickets?.length === 0}
                type="button"
                variant="contained"
                color="primary"
                size="medium"
                fullWidth={false}>
                Continuar
              </Button>
            </>
          )}
        </S.Wrapper>
      </S.Container>
      <AlertModal {...alertModalState} />
    </>
  )
}
