import { action } from 'typesafe-actions'
import {
  CreatorPaymentSetting,
  UserActions,
  UserInfo,
  UserState
} from './types'

export const setUserAction = (data: UserState) =>
  action(UserActions.setUser, data)

export const refreshTokenAction = (data: {
  refreshToken: string
  accessToken: string
}) => action(UserActions.refreshToken, data)

export const logoutAction = () => action(UserActions.logout)

export const getUserInfoRequest = () => action(UserActions.getUserInfoRequest)

export const getUserInfoSuccess = (data: UserInfo) =>
  action(UserActions.getUserInfo, data)

export type EditUser = {
  firstName: string
  lastName: string
  email: string
  address?: {
    city: string
    complement: string
    country: string
    number: string
    state: string
    street: string
    zipCode: string
    district: string
  }
  corporateName?: string
  document: string
  phone: string
}

export const editUserRequest = (data: EditUser) =>
  action(UserActions.editUserRequest, data)

export const editCreatorPaymentSettingSuccess = (data: CreatorPaymentSetting) =>
  action(UserActions.editCreatorPaymentSettingSuccess, data)
