import produce from 'immer'
import { Reducer } from 'redux'
import { CourseActions, CourseState } from './types'

const INITIAL_STATE: CourseState = {
  data: []
}

export const CourseReducer: Reducer<CourseState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case CourseActions.LOAD_COURSES_SUCCESS:
      return produce(state, (draft) => {
        draft.data = action.payload
      })
    case CourseActions.ADD_COURSE_SUCCESS:
      return produce(state, (draft) => {
        draft.data.push(action.payload)
      })
    case CourseActions.REMOVE_COURSE:
      return produce(state, (draft) => {
        const findCourseId = draft.data.findIndex(
          ({ id }) => id === action.payload
        )

        if (findCourseId !== -1) {
          draft.data.splice(findCourseId, 1)
        }
      })

    case CourseActions.EDIT_COURSE_SUCCESS:
      return produce(state, (draft) => {
        const findCourseId = draft.data.findIndex(
          ({ id }) => id === action.payload.id
        )

        if (findCourseId !== -1) {
          draft.data[findCourseId].description = action.payload.description
          draft.data[findCourseId].name = action.payload.name
          draft.data[findCourseId].coverUrl = action.payload.coverUrl
          draft.data[findCourseId].priceCents = action.payload.priceCents
          draft.data[findCourseId].discountCents = action.payload.discountCents
        }
      })

    default:
      return state
  }
}
