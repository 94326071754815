import * as S from './EventAddress.styles'
import { Button, Heading, Paragraph, Input } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { addressSchema } from './validationSchema'
import { viaCepService } from '../../../../api/viaCep/viaCepService'
import { useDispatch, useSelector } from 'react-redux'
import { addLocation, stepBack } from '../../../../redux/Events/actions'
import { clearLoading, setLoading } from '../../../../redux/Loading/actions'
import { ReduxState } from '../../../../redux/rootReducer'
import ReactSelect from 'react-select'
import { useEffect, useState } from 'react'
import jsonStates from '../../../../utils/estados-cidades.json'

export const EventAddress = () => {
  const { manageEvent } = useSelector((state: ReduxState) => state.EventReducer)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      zipCode: manageEvent?.address?.zipCode ?? '',
      street: manageEvent?.address?.street ?? '',
      number: manageEvent?.address?.number ?? '',
      complement: manageEvent?.address?.complement ?? '',
      city: manageEvent?.address?.city ?? '',
      state: manageEvent?.address?.state ?? '',
      country: 'BR',
      name: manageEvent?.address?.name ?? ''
    },
    validationSchema: addressSchema,
    onSubmit: async (values) => {
      dispatch(
        addLocation({
          ...values
        })
      )
    }
  })

  const [state] = useState(
    jsonStates.estados.map((state) => ({
      value: state.sigla,
      label: state.nome
    }))
  )

  const [cities, setCities] = useState<{ value: string; label: string }[]>(
    jsonStates.estados
      .find(({ sigla }) => sigla === formik.values.state)
      ?.cidades.map((city) => ({ value: city, label: city })) ?? []
  )

  useEffect(() => {
    if (formik.values.state !== '') {
      const stateIndex = jsonStates.estados.findIndex(
        (state) => state.sigla === formik.values.state
      )
      if (stateIndex !== -1) {
        setCities(
          jsonStates.estados[stateIndex].cidades.map((city) => ({
            value: city,
            label: city
          }))
        )
      }
    }
  }, [formik.values.state])

  const handleCep = async (value: string) => {
    const cepFormated = value.replaceAll(/[^\d]/g, '').trim()

    if (cepFormated.length === 8) {
      try {
        dispatch(setLoading())
        const data = await viaCepService(cepFormated)
        if (data.erro !== true) {
          formik.setFieldValue('zipCode', cepFormated)
          formik.setFieldValue('street', data.logradouro)
          formik.setFieldValue('state', data.uf)
          formik.setFieldValue('city', data.localidade)
        }
        dispatch(clearLoading())
      } catch (err) {
        dispatch(clearLoading())
      }
    }
  }

  return (
    <S.Container>
      <S.Wrapper onSubmit={formik.handleSubmit}>
        <S.TitleBox>
          <S.BackIcon onClick={() => dispatch(stepBack())} />
          <Heading variant="h3">Localização</Heading>
        </S.TitleBox>

        <Paragraph variant="large">Onde o seu evento vai acontecer?</Paragraph>
        <div className="twoInputs">
          <Input
            name="zipCode"
            label="*CEP"
            onChange={(e) => {
              formik.handleChange(e)
              handleCep(e.target.value)
            }}
            value={formik.values.zipCode ?? ''}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            texterror={formik.errors.zipCode}
            fullWidth
          />
          <Input
            name="name"
            label="*Nome do local"
            onChange={formik.handleChange}
            value={formik.values.name ?? ''}
            error={formik.touched.name && Boolean(formik.errors.name)}
            texterror={formik.errors.name}
            fullWidth
          />
          <Input
            name="street"
            label="*Endereço"
            onChange={formik.handleChange}
            value={formik.values.street ?? ''}
            error={formik.touched.street && Boolean(formik.errors.street)}
            texterror={formik.errors.street}
            fullWidth
          />
        </div>
        <div className="splitInput">
          <div>
            <S.Label>*Estado</S.Label>
            <ReactSelect
              onChange={(selectValue) => {
                if (selectValue && selectValue.value) {
                  formik.setFieldValue('state', selectValue.value)
                  formik.setFieldValue('city', '')
                }
              }}
              value={
                state.find(
                  (option) => option.value === formik.values.state
                ) ?? {
                  value: '',
                  label: ''
                }
              }
              placeholder="Estado"
              styles={S.customStyles}
              options={state}
              noOptionsMessage={() => 'Cidade não encontrada'}
            />
            <S.Error>
              {formik.touched.state && Boolean(formik.errors.state)
                ? formik.errors.state
                : null}
            </S.Error>
          </div>
          <div>
            <S.Label>*Cidade</S.Label>
            <ReactSelect
              value={
                cities.find(
                  (option) => option.value === formik.values.city
                ) ?? {
                  value: '',
                  label: ''
                }
              }
              onChange={(selectValue) => {
                if (selectValue && selectValue.value) {
                  formik.setFieldValue('city', selectValue.value)
                }
              }}
              placeholder="Cidade"
              styles={S.customStyles}
              options={cities}
              noOptionsMessage={() => 'Cidade não encontrada'}
            />
            <S.Error>
              {formik.touched.city && Boolean(formik.errors.city)
                ? formik.errors.city
                : null}
            </S.Error>
          </div>
        </div>
        <div className="twoInputs">
          <Input
            name="number"
            label="*Número"
            onChange={formik.handleChange}
            value={formik.values.number ?? ''}
            error={formik.touched.number && Boolean(formik.errors.number)}
            texterror={formik.errors.number}
            fullWidth
          />
          <Input
            name="complement"
            label="Complemento"
            onChange={formik.handleChange}
            value={formik.values.complement ?? ''}
            error={
              formik.touched.complement && Boolean(formik.errors.complement)
            }
            texterror={formik.errors.complement}
            fullWidth
          />
        </div>
        <Button
          variant="contained"
          fullWidth={false}
          type="submit"
          size="medium"
          color="primary">
          Continuar
        </Button>
      </S.Wrapper>
    </S.Container>
  )
}
