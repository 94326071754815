import { action } from 'typesafe-actions'
import { Address, EditEvent, EventActions } from './types'

export type EditBasicInformationRequest = {
  name?: string
  category?: string
  ageGroup?: string
  cashBackPercent?: number | null
  banners?: {
    desktopUrl?: string | File
    tabletUrl?: string | File
    mobileUrl?: string | File
    cardUrl?: string | File
  }[]
  seatMapId?: string
}

export type EditBasicInformationSuccess = {
  name?: string
  category?: string
  ageGroup?: string
  cashBackPercent?: number
  banners?: {
    id: string
    desktopUrl: string
    tabletUrl: string
    mobileUrl: string
    cardUrl: string
  }[]
  seatMapId?: string
}

export const editNextStep = () => action(EventActions.editNextStep)

export const editStepBack = () => action(EventActions.editStepBack)

export const loadEventSuccesstAction = (data: EditEvent) =>
  action(EventActions.loadEventSuccess, data)

export const editBasicInformationRequestAction = (
  data: EditBasicInformationRequest
) => action(EventActions.editBasicInformationRequest, data)

export const editBasicInformationSuccessAction = (
  data: EditBasicInformationSuccess
) => action(EventActions.editBasicInformationSuccess, data)

export const editDescriptionRequestAction = (data: string) =>
  action(EventActions.editDescriptionRequest, data)

export const editDescriptionSuccessAction = (data: string) =>
  action(EventActions.editDescriptionSuccess, data)

export const editAddressRequestAction = (data: Address) =>
  action(EventActions.editAddressRequest, data)

export const editAddressSuccessAction = (data: Address) =>
  action(EventActions.editAddressSuccess, data)

export const editEventItemRequestAction = (data: any) =>
  action(EventActions.editEventItemRequest, data)

export const editEventItemSuccessAction = (data: any) =>
  action(EventActions.editEventItemSuccess, data)

export const editTicketSuccessAction = (data: any) =>
  action(EventActions.editTicketSuccess, data)

export const goToStepAction = (step: number) =>
  action(EventActions.goToStep, step)
