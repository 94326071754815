import * as Yup from 'yup'
import { cpfValidate } from '../../utils/cpfValidate'
import { messageHelper } from '../../utils/messageHelper'
import { phoneValidate } from '../../utils/phoneValidate'
import { cnpjValidate } from '../../utils/cnpjValidate'

export const validationSchemaPersonal = Yup.object({
  firstName: Yup.string().required(
    messageHelper.yup.creator.error.nameIsMandatory
  ),
  lastName: Yup.string().required(
    messageHelper.yup.creator.error.lastNameIsMandatory
  ),
  document: Yup.string()
    .required(messageHelper.yup.creator.error.documentIsMandatory)
    .test(
      'document validation',
      messageHelper.yup.creator.error.documentIsInvalid,
      (value) => {
        if (value) {
          return cpfValidate(value)
        } else return false
      }
    ),
  email: Yup.string()
    .email(messageHelper.yup.creator.error.emailIsInvalid)
    .required(messageHelper.yup.creator.error.emailIsMandatory),
  phone: Yup.string()
    .required(messageHelper.yup.creator.error.phoneIsMandatory)
    .test(
      'phone validation',
      messageHelper.yup.creator.error.phoneIsInvalid,
      (value) => {
        if (value) {
          return phoneValidate(value)
        } else return false
      }
    ),
  address: Yup.object({
    city: Yup.string().required(
      messageHelper.yup.creator.error.cityIsMandatory
    ),
    state: Yup.string().required(
      messageHelper.yup.creator.error.stateIsMandatory
    ),
    zipCode: Yup.string()
      .nullable()
      .test(
        'validateMinStreet',
        messageHelper.yup.creator.error.cepIsInvalid,
        (value) => {
          if (value && value.replace(/[_/'']/g, '').length < 8) {
            return false
          } else return true
        }
      ),
    street: Yup.string()
      .nullable()
      .test(
        'validateMinStreet',
        messageHelper.yup.creator.error.shortAddress,
        (value) => {
          if (value && value.length < 5) {
            return false
          } else return true
        }
      )
      .test(
        'validateMaxStreet',
        messageHelper.yup.creator.error.longAddress,
        (value) => {
          if (value && value.length > 255) {
            return false
          } else return true
        }
      ),
    complement: Yup.string()
      .nullable()
      .test(
        'validateMinComplement',
        messageHelper.yup.creator.error.shortComplement,
        (value) => {
          if (value && value.length < 5) {
            return false
          } else return true
        }
      )
      .test(
        'validateMaxComplement',
        messageHelper.yup.creator.error.longComplement,
        (value) => {
          if (value && value.length > 255) {
            return false
          } else return true
        }
      ),
    number: Yup.string()
      .nullable()
      .test('validateNumber', 'Número inválido', (value) => {
        if (value && value === '0') {
          return false
        } else return true
      })
  })
})

export const validationSchemaCompany = Yup.object({
  firstName: Yup.string().required(
    messageHelper.yup.creator.error.nameIsMandatory
  ),
  lastName: Yup.string().required(
    messageHelper.yup.creator.error.lastNameIsMandatory
  ),
  corporateName: Yup.string().required(
    messageHelper.yup.creator.error.corporateNameIsMandatory
  ),
  document: Yup.string()
    .required(messageHelper.yup.creator.error.cnpjIsMandatory)
    .test(
      'document validation',
      messageHelper.yup.creator.error.cnpjIsInvalid,
      (value) => {
        if (value) {
          return cnpjValidate(value.replace(/[^0-9]/g, '').trim())
        } else return false
      }
    ),
  email: Yup.string()
    .email(messageHelper.yup.creator.error.emailIsInvalid)
    .required(messageHelper.yup.creator.error.emailIsMandatory),
  phone: Yup.string()
    .required(messageHelper.yup.creator.error.phoneIsMandatory)
    .test(
      'phone validation',
      messageHelper.yup.creator.error.phoneIsInvalid,
      (value) => {
        if (value) {
          return phoneValidate(value)
        } else return false
      }
    )
})
