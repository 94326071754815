import styled, { css } from 'styled-components'

import { ReactComponent as SearchFile } from '../../assets/icons/search.svg'
import { ReactComponent as EditFile } from '../../assets/icons/edit.svg'
import { ReactComponent as ExcludeFile } from '../../assets/icons/trash-alt.svg'

export const ExcludeIcon = styled(ExcludeFile)`
  fill: #808080;
  margin-left: 1rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
    fill: black;
  }
  transition: 0.3s ease;
`
export const EditIcon = styled(EditFile)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.inline.xxxs};
    cursor: pointer;
    fill: #b88bcf;
    transition: 0.3s ease;
    &:hover {
      transform: scale(1.2);
      fill: #8601c9;
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    overflow-y: auto;
    overflow-y: auto;
    padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl} 0;
    @media (max-width: 1170px) {
      padding: 3rem;
    }

    .paragraph {
      margin-right: ${theme.spacing.stack.xxs};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
  `}
`

export const CreateEventBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing.stack.md};
    display: flex;
    justify-content: space-between;
    > button {
      white-space: nowrap;
      margin-right: auto;
    }
    @media (max-width: 1170px) {
      > button {
        margin: ${theme.spacing.stack.xxxs} auto ${theme.spacing.stack.xxxs} 0;
      }
      flex-direction: column;
      margin-top: ${theme.spacing.stack.xxs};
    }
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-right: ${theme.spacing.stack.xxxs};
    width: 100%;
  `}
`

export const SearchIcon = styled(SearchFile)`
  position: absolute;
  fill: #39383a;
  top: 50%;
  transform: translateY(-50%);
  left: 3rem;
`

export const Input = styled.input`
  ${({ theme }) => css`
    background: #f4f4f4;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxs}
      ${theme.spacing.stack.xxxs} 72px;
    width: 100%;
    font-size: ${theme.font.size.sm};
    border-radius: ${theme.border.radius.medium};
    outline: 0;
    border: 0;

    &::placeholder {
      font-size: ${theme.font.size.sm};
    }
  `}
`

export const Table = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing.stack.xs} 0;
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.spacing.stack.xxl};
  `}
`

export const TableHead = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    margin-bottom: ${theme.spacing.stack.xs};
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    border-radius: ${theme.border.radius.medium};
    > p {
      color: #808080;
    }
  `}
`

export const TableBody = styled.div`
  ${({ theme }) => css`
    > div {
      margin-bottom: ${theme.spacing.stack.xxs};
    }
    div:last-child {
      margin-bottom: 0;
    }
    .public-status {
      color: ${theme.color.alert.success.nth2};
      position: relative;
    }

    .closed-status {
      color: ${theme.color.alert.error.nth1};
      position: relative;
    }
    .without-tickets-paragraph {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`

export const TableData = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxs};
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.border.radius.medium};
    > p {
      align-self: center;
    }
    > progress {
      background: red;
    }
    p:last-child {
      margin-left: auto;
    }

    .courtesy {
      margin-right: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > button {
      background: ${theme.color.base.nth1};
    }
  `}
`

export const EditEventCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacing.stack.small};
    width: 100%;
    .titleBox {
      width: 80%;
      display: flex;
      flex-direction: column;
    }
    .paragraph {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .actions {
      display: flex;
    }
  `}
`
