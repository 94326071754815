import * as S from './EditTickets.styles'
import { Heading } from 'applaus-ui-kit'

import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'

import { TabulatedTicket } from './TabulatedTicket/TabulatedTicket'
import { MappedTicket } from './MappedTicket/MappedTicket'
import { Dispatch, SetStateAction } from 'react'

import { Tickets } from '../../../../../redux/Events/types'

type Props = {
  editTicket: {
    open: boolean
    ticket?: Tickets
  }
  setEditTicket: Dispatch<
    SetStateAction<{
      open: boolean
      ticket?: Tickets | undefined
    }>
  >
}

export const EditTickets = ({ editTicket, setEditTicket }: Props) => {
  const { manageEvent } = useSelector((state: ReduxState) => state.EventReducer)

  return (
    <S.Wrapper>
      <S.TitleBox>
        <S.BackIcon onClick={() => setEditTicket({ open: false })} />
        <Heading variant="h3">Editar ingresso</Heading>
      </S.TitleBox>
      {manageEvent?.type === 'MAPPED' ? (
        <MappedTicket
          editTicket={editTicket.ticket!}
          setEditTicket={setEditTicket}
        />
      ) : (
        <TabulatedTicket
          editTicket={editTicket.ticket!}
          setEditTicket={setEditTicket}
        />
      )}
    </S.Wrapper>
  )
}
