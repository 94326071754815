import { AlertModal, AlertModalActions } from './types'
import { Reducer } from 'redux'
import produce from 'immer'

const INITIAL_STATE: AlertModal = {
  open: false,
  buttonText: '',
  title: '',
  variant: 'error',
  click: () => {}
}

export const AlertModalReducer: Reducer<AlertModal> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case AlertModalActions.SET_ALERT_MODAL:
      return produce(state, (draft) => {
        draft.open = action.payload.open
        draft.title = action.payload.title
        draft.description = action.payload.description
        draft.variant = action.payload.variant
        draft.buttonText = action.payload.buttonText
        draft.click = action.payload.click
      })
    case AlertModalActions.CLEAR_MODAL:
      return INITIAL_STATE
    default:
      return state
  }
}
