import { Dispatch, SetStateAction, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select, { components, GroupBase, SingleValueProps } from 'react-select'
// @ts-ignore
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import { ReduxState } from '../../../../../../redux/rootReducer'
import * as S from './MappedTicket.styles'
import { Tickets } from '../../../../../../redux/Events/types'
import * as _ from 'lodash'
import { useFormik } from 'formik'
import { Button, Input, Paragraph, RadioButton } from 'applaus-ui-kit'
import { CurrencyInput } from '../../../../../../components/CurrencyInput/CurrencyInput'
import { updateTicketService } from '../../../../../../api/tickets/updateTicketService'
import axios from 'axios'
import * as Yup from 'yup'
import { addTicketEventItem } from '../../../../../../redux/Events/actions'

type Props = {
  editTicket: Tickets
  setEditTicket: Dispatch<
    SetStateAction<{
      open: boolean
      ticket?: Tickets | undefined
    }>
  >
}

type ChartCategories = {
  label: string
}

type OptionType = {
  value: string
  label: string
}

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line no-unused-vars
    Group extends GroupBase<Option>
  > {}
}

function SingleValue({
  children,
  ...props
}: SingleValueProps<OptionType, false>) {
  return <components.SingleValue {...props}>{children}</components.SingleValue>
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Nome do ingresso é obrigátorio.'),
  priceCents: Yup.string().required('Preço do ingresso é obrigátorio.'),
  discountCents: Yup.string().required('Preço final é obrigátorio.'),
  rule: Yup.string()
})

export const MappedTicket = ({ editTicket, setEditTicket }: Props) => {
  const dispatch = useDispatch()
  const { manageEvent } = useSelector((state: ReduxState) => state.EventReducer)
  const [options, setOptions] = useState<OptionType[]>([])

  const [typeTicket, setTypeTicket] = useState(editTicket.type)
  const [isFree, setIsFree] = useState(editTicket.description === 'Gratuito')

  const formik = useFormik({
    initialValues: {
      name: editTicket.name,
      category: editTicket.category,
      priceCents: String((editTicket.priceCents / 100).toFixed(2)),
      discountCents: '0',
      type: editTicket.type,
      rule: editTicket.rule ?? ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const data = await updateTicketService(editTicket.id, {
          category: values.category,
          description: !isFree ? 'Pago' : 'Gratuito',
          type:
            editTicket.type !== typeTicket
              ? typeTicket === 'Outros'
                ? values.type
                : typeTicket
              : undefined,
          name: values.name,
          ticketPrice: {
            discountCents: 0,
            priceCents:
              editTicket.priceCents.toString() !== values.priceCents
                ? !isFree
                  ? values.priceCents.includes(',')
                    ? values.priceCents.includes('.')
                      ? Number(
                          values.priceCents
                            .replace(/[^0-9]/g, '')
                            .replace(',', '')
                        ) * 100
                      : Number(
                          values.priceCents
                            .replace(/[^0-9]/g, '')
                            .replace(',', '')
                        )
                    : Number(values.priceCents.replace(/[^0-9]/g, '')) * 100
                  : undefined
                : undefined,
            isFree: isFree
          }
        })

        data &&
          dispatch(
            addTicketEventItem(data, 'edit', editTicket.eventItems[0].id!)
          )

        if (data !== undefined && !axios.isAxiosError(data)) {
          setEditTicket({
            open: false
          })
        }
      } catch (error) {}
    }
  })

  if (!manageEvent?.eventItems) {
    setEditTicket({
      open: false
    })
  }

  const optionsType = [
    {
      value: 'Inteira',
      label: 'Inteira'
    },
    {
      value: 'Meia',
      label: 'Meia'
    },
    {
      value: 'Outros',
      label: 'Outros'
    }
  ]

  return (
    <>
      <SeatsioSeatingChart
        mode="static"
        workspaceKey={process.env.REACT_APP_PUBLIC_KEY_WORKSPACE}
        event={manageEvent?.eventItems![0].eventKey ?? ''}
        onChartRendered={async (createdChart: any) => {
          const chartCategories: ChartCategories[] =
            await createdChart.listCategories()
          setOptions(
            chartCategories.map(({ label }) => ({ value: label, label }))
          )
        }}
        onChartRenderingFailed={() => setEditTicket({ open: false })}
        region="sa"
        language="pt"
        multiSelectEnabled={true}
        showSectionPricingOverlay={true}
      />
      <S.Form onSubmit={formik.handleSubmit}>
        <Select<OptionType>
          value={_.find(options, function (option) {
            return option.value === formik.values.category
          })}
          onChange={(selectValue) => {
            if (selectValue && selectValue.value) {
              formik.setFieldValue('category', selectValue.value)
            }
          }}
          placeholder="Selecione a categoria"
          styles={S.customStyles}
          options={options}
          components={{ SingleValue }}
          noOptionsMessage={() => 'Categoria não encontrada'}
        />
        <div className="radioButtonBox">
          <div className="radioButton">
            <Paragraph variant="large">Pago</Paragraph>
            <RadioButton
              checked={!isFree}
              readOnly
              onClick={() => {
                formik.setFieldValue('priceCents', '')
                formik.setFieldValue('discountCents', '')
                setIsFree(false)
              }}
            />
          </div>
          <div>
            <Paragraph variant="large">Gratuito</Paragraph>
            <RadioButton
              checked={isFree}
              readOnly
              onClick={() => {
                formik.setFieldValue('priceCents', '100')
                formik.setFieldValue('discountCents', '100')
                setIsFree(true)
              }}
            />
          </div>
        </div>
        <div className="inputBox">
          <Input
            label="*Nome"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            texterror={formik.errors.name}
          />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap'
            }}>
            <div
              style={{
                width: '244px',
                marginRight: '16px',
                marginBottom: '24px'
              }}>
              <S.Label>Tipo do ingresso</S.Label>
              <Select
                placeholder="Tipo do ingresso"
                options={optionsType}
                onChange={(e: any) => {
                  if (e && e?.value !== 'Outros') {
                    formik.setFieldValue('type', e?.value)
                    setTypeTicket(e?.value)
                  } else if (e && e?.value === 'Outros') {
                    setTypeTicket(e?.value)
                    formik.setFieldValue('type', '')
                  }
                }}
                value={
                  typeTicket !== 'Inteira' && typeTicket !== 'Meia'
                    ? [
                        {
                          label: 'Outros',
                          value: 'Outros'
                        }
                      ]
                    : formik.values.type
                    ? [
                        {
                          label: formik.values.type,
                          value: formik.values.type
                        }
                      ]
                    : null
                }
                styles={S.customStylesType}
              />
            </div>
            {typeTicket !== 'Inteira' && typeTicket !== 'Meia' ? (
              <div className="inputType">
                <Input
                  name="type"
                  label="Tipo do ingresso"
                  value={
                    typeTicket !== 'Inteira' && typeTicket !== 'Meia'
                      ? formik.values.type
                      : ''
                  }
                  onChange={formik.handleChange}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                />
                {formik.touched.type && Boolean(formik.errors.type) ? (
                  <S.Error>{formik.errors.type}</S.Error>
                ) : (
                  <S.Error></S.Error>
                )}
              </div>
            ) : null}
          </div>
        </div>
        {!isFree ? (
          <div className="priceBox">
            <CurrencyInput
              label="*Preço"
              name="priceCents"
              onValueChange={(value) => {
                formik.setFieldValue('priceCents', value)
              }}
              value={formik.values.priceCents}
              intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
              min="1"
              allowNegativeValue={false}
              error={
                formik.touched.priceCents && Boolean(formik.errors.priceCents)
              }
              texterror={formik.errors.priceCents}
            />
            {/* <CurrencyInput
              label="*Preço com desconto"
              name="discountCents"
              onValueChange={(value) => {
                formik.setFieldValue('discountCents', value)
              }}
              value={formik.values.discountCents}
              intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
              min="1"
              allowNegativeValue={false}
              error={
                formik.touched.discountCents &&
                Boolean(formik.errors.discountCents)
              }
              texterror={formik.errors.discountCents}
            /> */}
          </div>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          fullWidth={false}
          type="submit"
          size="medium">
          Editar Ingresso
        </Button>
      </S.Form>
    </>
  )
}
