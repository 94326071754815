import { all } from 'redux-saga/effects'
import { EventSaga } from './Events/saga'

import { CourseSaga } from './Courses/saga'
import { UserSaga } from './User/sagas'
import { ManageEvent } from './ManageEvent/sagas'

export default function* rootSaga(): any {
  return yield all([CourseSaga, EventSaga, UserSaga, ManageEvent])
}
