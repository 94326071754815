import { combineReducers } from 'redux'
import { CourseReducer } from './Courses/reducer'
import { UserReducer } from './User/reducer'
import { AlertModalReducer } from './AlertModal/reducer'
import { EventReducer } from './Events/reducer'
import { LoadingReducer } from './Loading/reducer'
import { ModalReducer } from './Modal/reducer'
import { ManageEventReducer } from './ManageEvent/reducer'

const rootReducer = combineReducers({
  CourseReducer,
  UserReducer,
  AlertModalReducer,
  EventReducer,
  LoadingReducer,
  ModalReducer,
  ManageEventReducer
})

export type ReduxState = ReturnType<typeof rootReducer>

export default rootReducer
