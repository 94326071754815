import * as S from './EventDescription.styles'
import { Heading, Paragraph, Button } from 'applaus-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAlertModalAction } from '../../../../redux/AlertModal/actions'
import { ReduxState } from '../../../../redux/rootReducer'
import { RichText } from '../../../../components/RichText/RichText'
import {
  editDescriptionRequestAction,
  editStepBack
} from '../../../../redux/Events/editActions'
import { clearLoading, setLoading } from '../../../../redux/Loading/actions'

export const EventDescription = () => {
  const dispatch = useDispatch()
  const { editEvent } = useSelector((state: ReduxState) => state.EventReducer)
  const [description, setDescription] = useState(editEvent?.description)

  const onSubmit = () => {
    const characterCount = description?.length ? description?.length : 0
    if (characterCount > 0 && characterCount < 3) {
      dispatch(
        setAlertModalAction({
          title: 'Descrição deve ter pelo menos 3 caracteres',
          variant: 'error',
          open: true,
          buttonText: 'Fechar'
        })
      )
    } else if (characterCount > 0 && characterCount > 255) {
      dispatch(
        setAlertModalAction({
          title: 'Descrição deve ter no máximo 255 caracteres',
          variant: 'error',
          open: true,
          buttonText: 'Fechar'
        })
      )
    } else {
      console.log()
      dispatch(editDescriptionRequestAction(description ?? ''))
    }
  }

  const onLoadRichText = () => {
    dispatch(clearLoading())
  }

  useEffect(() => {
    dispatch(setLoading())
  }, [])

  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleBox>
          <S.BackIcon onClick={() => dispatch(editStepBack())} />
          <Heading variant="h3">Descrição do evento</Heading>
        </S.TitleBox>
        <Paragraph variant="large">
          Chegou o momento de falar sobre seu evento. Conte todos os <br />
          detalhes, como a programação, regras e classificação.
        </Paragraph>

        <Paragraph variant="large">Máximo de caracteres: 250</Paragraph>

        <RichText
          data={editEvent?.description ?? ''}
          onReady={onLoadRichText}
          onChange={(value) => setDescription(value)}
        />
        <Button
          onClick={onSubmit}
          variant="contained"
          fullWidth={false}
          type="button"
          size="medium"
          color="primary">
          Continuar
        </Button>
      </S.Wrapper>
    </S.Container>
  )
}
