import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from 'assets/icons/backIcon.svg'

import { ReactComponent as EditFile } from 'assets/icons/edit.svg'
import { ReactComponent as TrashFile } from 'assets/icons/trash-alt.svg'

export const EditIcon = styled(EditFile)`
  ${({ theme }) => css`
    height: 24px;
    cursor: pointer;
    fill: ${theme.color.neutral.nth3};
    margin-right: ${theme.spacing.stack.xxxs};
    :hover {
      fill: ${theme.color.brand.primary.nth1};
    }

    @media (max-width: 767px) {
      height: 16px;
      width: 16px;
      margin-right: ${theme.spacing.stack.small};
    }
  `}
`
export const TrashIcon = styled(TrashFile)`
  ${({ theme }) => css`
    cursor: pointer;
    fill: ${theme.color.neutral.nth3};
    :hover {
      fill: ${theme.color.alert.error.nth1};
    }
    @media (max-width: 767px) {
      height: 16px;
      width: 16px;
    }
  `}
`

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`

export const Container = styled.div`
  ${({ theme }) => css`
    > button {
      margin-left: auto;
    }
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs};
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.xs};
    }
    @media (min-width: 1171px) {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
      width: 100%;
      > button {
        margin-bottom: ${theme.spacing.stack.xxxs};
      }
    }
  `}
`

export const Subtitle = styled.p`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      margin-top: ${theme.spacing.stack.small};
      margin-bottom: ${theme.spacing.stack.xxxs};
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: ${theme.spacing.stack.xxxs};
      margin-left: 56px;
    }
  `}
`

export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
  `}
`

export const TableHeader = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    background: ${theme.color.base.nth4};
    padding: ${theme.spacing.stack.xxxs};
    border-radius: 6px;
    margin-bottom: ${theme.spacing.stack.small};
    @media (max-width: 767px) {
      margin-top: ${theme.spacing.stack.xxxs};
      grid-template-columns: 1fr 2fr 1fr 1fr;
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.small};
      gap: ${theme.spacing.inline.nano};
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      margin-top: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const TableTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.base.nth2};
    @media (min-width: 1171px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
  `}
`

export const TableData = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    background: ${theme.color.neutral.nth4};
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.xxxs};
    align-items: center;
    div:last-of-type {
      margin-left: auto;
    }
    @media (max-width: 767px) {
      .data-info {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
      }
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.small};
      gap: ${theme.spacing.inline.small};
      grid-template-columns: 1fr 2fr 1fr 1fr;
    }
  `}
`
