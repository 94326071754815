import { Paragraph } from 'applaus-ui-kit'
import { AnimatePresence } from 'framer-motion'
import { ReactNode, useState } from 'react'
import * as S from './Accordion.styles'

type Props = {
  title: string
  children: ReactNode
}

export const CustomAccordion = ({ title, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <S.Container>
      <S.Header>
        <S.IconBox
          animate={{ rotate: isOpen ? 0 : -90 }}
          transition={{ duration: 0.3 }}
          onClick={() => setIsOpen((prev) => !prev)}>
          <S.ChevronIcon />
        </S.IconBox>
        <Paragraph variant="regular" type="semiBold" className="module-title">
          {title}
        </Paragraph>
      </S.Header>
      <AnimatePresence>
        {isOpen ? (
          <S.AccordionInfo
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            exit={{ opacity: 0, height: 0 }}>
            {children}
          </S.AccordionInfo>
        ) : undefined}
      </AnimatePresence>
    </S.Container>
  )
}
