import { AxiosResponse } from 'axios'
import { api } from '../config'

export const sendBordero = async (eventId: string) => {
  const { status }: AxiosResponse = await api.post(
    `/api/creators/events/${eventId}/bordero`
  )

  return status
}
