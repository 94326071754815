import { Paragraph } from 'applaus-ui-kit'
import { EventItems, Tickets } from '../../../../../../../redux/Events/types'
import { formatPrice } from '../../../../../../../utils/formatPrice'
import * as S from './DesktopTicketTable.styles'

type Props = {
  eventItems: EventItems[]
  handleRemoveTicket: (ticketId: string, ticket: Tickets) => void
  handleEditTicket: (ticket: Tickets) => void
}

export const DesktopTicketTable = ({
  eventItems,
  handleRemoveTicket,
  handleEditTicket
}: Props) => {
  return (
    <S.Table>
      <S.TableHead>
        <Paragraph variant="regular" type="semiBold">
          Nome
        </Paragraph>
        <Paragraph variant="regular" type="semiBold">
          Setor
        </Paragraph>
        <Paragraph variant="regular" type="semiBold">
          Valor
        </Paragraph>
        <div />
      </S.TableHead>

      <S.TableBody>
        {eventItems
          .filter((eventItem) => eventItem.tickets.length > 0)
          .map((eventItem) =>
            eventItem.tickets.map((ticket) => (
              <S.TableData key={ticket.id}>
                <Paragraph variant="regular" type="semiBold">
                  {ticket.name}
                </Paragraph>
                <Paragraph variant="regular" type="semiBold">
                  {ticket.eventItems[0].title}
                </Paragraph>
                <Paragraph variant="regular" type="semiBold">
                  {ticket.isFree
                    ? 'Gratuito'
                    : formatPrice(ticket.priceCents / 100)}
                </Paragraph>

                <S.Actions>
                  <S.EditIcon onClick={() => handleEditTicket(ticket)} />
                  <S.TrashIcon
                    onClick={() => handleRemoveTicket(ticket.id, ticket)}
                  />
                </S.Actions>
              </S.TableData>
            ))
          )}
      </S.TableBody>
    </S.Table>
  )
}
