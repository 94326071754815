import QrReader from 'react-qr-reader'
import styled, { css } from 'styled-components'

import { ReactComponent as BackFile } from '../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)``

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
    overflow: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100vh;
    .scan-text {
      margin: ${theme.spacing.stack.xs} 0;
      font-size: 27px;
      font-style: normal;
      font-weight: 600;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
    }

    @media (max-width: 767px) {
      padding: 0;
      overflow: hidden !important;
      .inputBox {
        padding: ${theme.spacing.stack.xxs};
      }

      .backButton {
        padding: ${theme.spacing.stack.xxs};
      }
    }
  `}
`

export const QrReaderStyled = styled(QrReader)`
  ${({ theme }) => css`
    width: 520px !important;
    height: 350px !important;
    div {
      width: 520px !important;
      height: 350px !important;
      box-shadow: none !important;
      border: 1px solid ${theme.color.brand.primary.nth1} !important;
      border-radius: ${theme.border.radius.medium} !important;
    }

    video {
      border: 1px solid ${theme.color.brand.primary.nth1} !important;
      border-radius: ${theme.border.radius.medium} !important;
      width: 520px !important;
      height: 350px !important;
    }

    @media (max-width: 767px) {
      width: 100% !important;
      height: 100% !important;
      div {
        width: 100% !important;
        height: 100% !important;
        box-shadow: none !important;
        border: 0 !important;
      }

      video {
        border: 0 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
  `}
`

export const BackButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;

    .back-text {
      margin-left: ${theme.spacing.inline.small};
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
    }
  `}
`

export const ContentScan = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xs};
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  `}
`

export const ButtonBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
  `}
`
