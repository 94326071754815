import { CustomerTicket } from '../../../../redux/Events/types'
import { formatPrice } from '../../../../utils/formatPrice'
import { TicketCard } from '../TicketCard/TicketCard'
import * as S from './PurchaseSummary.styles'

type PurchaseSummaryProps = {
  titleTicket: string
  eventDate: string
  eventAddress: string
  tickets: CustomerTicket[]
}

export const PurchaseSummary = ({
  titleTicket,
  eventAddress,
  eventDate,
  tickets
}: PurchaseSummaryProps) => {
  return (
    <S.Container>
      <S.TitleSummary>RESUMO DA COMPRA</S.TitleSummary>

      <S.ContentInfo>
        <S.TitleInfo>{titleTicket}</S.TitleInfo>
        <S.DataBox>
          <S.IconData />
          <S.TitleData>{eventDate}</S.TitleData>
        </S.DataBox>
        <S.LocationBox>
          <S.IconLocation />
          <S.TitleLocation>{eventAddress}</S.TitleLocation>
        </S.LocationBox>
      </S.ContentInfo>

      <S.TicketsBox>
        {tickets.map((ticket, index) => (
          <TicketCard key={ticket.id + index} {...ticket} />
        ))}
      </S.TicketsBox>

      <S.Total>
        <S.TextTotal>
          Total: {''}
          {formatPrice(tickets.reduce((acc, val) => acc + val.totalPrice!, 0))}
        </S.TextTotal>
      </S.Total>
    </S.Container>
  )
}
