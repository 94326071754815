import styled, { css } from 'styled-components'
import MaskedInput from 'react-text-mask'

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.color.base.nth1};
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 21px;
    margin-bottom: 4px;
  `}
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

export const Input = styled(MaskedInput)<{ error?: boolean }>`
  ${({ theme, error }) => css`
    width: 100%;
    padding: 8px ${theme.spacing.stack.xxs};
    color: ${theme.color.base.nth1};
    background: ${error ? theme.color.alert.error.nth4 : theme.color.base.nth4};
    border: ${error
      ? `1px solid ${theme.color.alert.error.nth1}`
      : '1px solid transparent'};
    border-radius: 6px;
    font-size: ${theme.font.size.sm};
    padding-left: ${theme.spacing.stack.xxxs};
    &:focus {
      ${!error
        ? css`
            border: ${theme.border.width.line} solid
              ${theme.color.brand.primary.nth2};
          `
        : null}
      background: ${theme.color.neutral.nth4};
    }
    &::placeholder {
      color: ${theme.color.base.nth1};
    }
    @media (max-width: 767px) {
      padding: 8px ${theme.spacing.stack.xxs};
    }
  `}
`

export const Error = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.alert.error.nth1};
    font-size: ${theme.font.size.xxs};
    min-height: ${theme.spacing.stack.xxs};
  `}
`

export const IconSvg = styled.svg<{ error?: boolean }>`
  ${({ theme, error }) =>
    css`
      fill: ${error ? theme.color.alert.error.nth1 : theme.color.base.nth1};
      height: 24px;
      width: 24px;
      position: absolute;
      top: 50%;
      right: ${theme.spacing.inline.xxxs};
      transform: translateY(-50%);
    `}
`
