import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from '../../../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`
export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }

    @media (min-width: 1171px) {
      margin-bottom: ${theme.spacing.stack.xs};
    }
  `}
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Wrapper = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    max-width: 1366px;
    width: 100%;
    align-self: center;

    > button {
      align-self: end;
    }
    .splitInput {
      display: flex;
      column-gap: 2rem;
      > div {
        width: 100%;
      }
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    .twoInputs {
      display: flex;
      column-gap: 2rem;
      > div:last-child {
        width: 100%;
      }
      @media (max-width: 767px) {
        > div {
          width: 100%;
        }
        flex-direction: column;
      }
    }
    .complement {
      width: 100%;
    }
    .city {
      width: 100%;
    }

    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.xxxl};

      .split-inputs {
        display: flex;
        div:last-child {
          margin-left: 2rem;
        }
      }
    }
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Label = styled.p`
  ${({ theme }) => css`
    color: #39383a;
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 24px;
    margin-bottom: 2px;
  `}
`

export const Error = styled.p`
  ${({ theme }) => css`
    color: #fc3e3e;
    font-size: ${theme.font.size.xxs};
    min-height: ${theme.spacing.stack.xxs};
  `}
`
