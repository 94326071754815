import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from '../../../../assets/icons/backIcon.svg'
import { ReactComponent as LoginFile } from '../../../../assets/icons/log-in.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`
export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
    margin-bottom: ${theme.spacing.stack.xxxs};
    @media (min-width: 1171px) {
      margin-bottom: ${theme.spacing.stack.xs};
    }
  `}
`

export const LoginIcon = styled(LoginFile)``

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: ${theme.spacing.stack.xxs};
    > button {
      margin-top: ${theme.spacing.stack.xxs};
      align-self: end;
    }
    @media (min-width: 1171px) {
      height: 100%;
      max-width: 1366px;
      margin: 0 auto;
      padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
    }
    .show-event {
      margin-right: auto;
      padding-left: 0;
      display: flex;
      align-items: center;
    }
  `}
`

export const PrivacySelection = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing.stack.small};
    margin-bottom: ${theme.spacing.stack.xxs};
    .radioButtonBox {
      display: flex;
    }
    .radioButton {
      margin-right: 1rem;
    }
    @media (min-width: 1171px) {
      flex-direction: row;
      > div:first-of-type {
        margin-right: 3rem;
      }
    }
  `}
`

export const ChooseEventStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    .radioButtonWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }
    .options-text {
      margin-left: 2rem;
    }
  `}
`

export const TermsSection = styled.section`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing.stack.small};
    > p {
      text-align: justify;
    }
  `}
`
