import { action } from 'typesafe-actions'
import { CourseCreationRequest } from '../../api/courses/createCourseService'
import { Course, CourseActions } from './types'

export const loadCoursesRequestAction = () =>
  action(CourseActions.LOAD_COURSES_REQUEST)

export const loadCoursesSuccessAction = (data: Course[]) =>
  action(CourseActions.LOAD_COURSES_SUCCESS, data)

export const addCourseRequestAction = (data: CourseCreationRequest) =>
  action(CourseActions.ADD_COURSE_REQUEST, data)

export const addCourseSuccessAction = (data: Course) =>
  action(CourseActions.ADD_COURSE_SUCCESS, data)

export const removeCourseAction = (id: string) =>
  action(CourseActions.REMOVE_COURSE, id)

export const editCourseRequestAction = (data: {
  id: string
  name?: string | undefined
  description?: string | undefined
  priceCents?: number | undefined
  discountCents?: number | undefined
  coverUrl?: File | string | undefined
}) => action(CourseActions.EDIT_COURSE_REQUEST, data)

export const editCourseSuccessAction = (data: Course) =>
  action(CourseActions.EDIT_COURSE_SUCCESS, data)
