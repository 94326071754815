import axios, { AxiosResponse } from 'axios'

type Request = {
  email: string
  password: string
}

type Response = {
  accessToken: string
  refreshToken: string
  expiresIn: number
  refreshExpiresIn: number
}

export const authService = async (request: Request) => {
  const body = {
    email: request.email,
    password: request.password,
    clientId: process.env.REACT_APP_CLIENT_ID
  }

  const { data }: AxiosResponse<Response> = await axios.post(
    process.env.REACT_APP_AUTH_API + '/api/v1/auth/users/login',
    body
  )
  return data
}
