import * as Yup from 'yup'
import { messageHelper } from '../../../../../utils/messageHelper'

export const createTabulatedEventSchema = Yup.object({
  name: Yup.string()
    .min(2, messageHelper.yup.creator.error.courseShortName)
    .max(128, messageHelper.yup.creator.error.courseLongName)
    .required(messageHelper.yup.creator.error.nameIsMandatory)
    .test(
      'nameVerification',
      messageHelper.yup.creator.error.courseShortName,
      (value) => {
        if (value && value?.trim().length < 3) {
          return false
        } else return true
      }
    ),
  maxCustomerTickets: Yup.string().test(
    'maxTickets',
    messageHelper.yup.creator.error.maxTicketsNoZero,
    (value) => (Number(value) ? Number(value) > 0 : false)
  )
})

export const createMappedEventSchema = Yup.object({
  name: Yup.string()
    .min(2, messageHelper.yup.creator.error.courseShortName)
    .max(128, messageHelper.yup.creator.error.courseLongName)
    .required(messageHelper.yup.creator.error.nameIsMandatory)
    .test(
      'nameVerification',
      messageHelper.yup.creator.error.courseShortName,
      (value) => {
        if (value && value?.trim().length < 3) {
          return false
        } else return true
      }
    ),
  salesFrom: Yup.string().required('Início das vendas é obrigatório.'),
  finalSales: Yup.string().required('Início das vendas é obrigatório.')
})
