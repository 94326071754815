import { api } from '../config'
import { AxiosResponse } from 'axios'
import { CreatorPaymentSetting } from '../../redux/User/types'

export type BankAccount = {
  bank: string
  bankAccountType: string
  bankAg: string
  bankCc: string
}

export const bankAccountVerificationService = async (request: BankAccount) => {
  const { data }: AxiosResponse<CreatorPaymentSetting> = await api.post(
    'api/creators/accounts/verification',
    request
  )

  return data
}
