import { Button, Input, Paragraph, RadioButton } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createTicketService } from '../../../../../../../../../api/tickets/createTicketService'
import { CurrencyInput } from '../../../../../../../../../components/CurrencyInput/CurrencyInput'
import {
  clearLoading,
  setLoading
} from '../../../../../../../../../redux/Loading/actions'
import { ReduxState } from '../../../../../../../../../redux/rootReducer'
import * as S from './TicketAccordion.styles'
import { Tickets } from '../../../../../../../../../redux/Events/types'
import { updateTicketService } from '../../../../../../../../../api/tickets/updateTicketService'
import { setAlertModalAction } from '../../../../../../../../../redux/AlertModal/actions'
import ReactSelect from 'react-select'
import { createTicketValidation } from './validationSchema'
import { addTicketEventItem } from '../../../../../../../../../redux/Events/actions'
import { useWindowSize } from '../../../../../../../../../hooks/useWindowSize'

type Props = {
  category: string
  eventItems: string[]
}

type ManageForm = {
  open: boolean
  type?: 'CREATE' | 'EDIT'
  ticket?: Tickets
}

export const TicketAccordion = ({ category, eventItems }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const media = useWindowSize()
  const { manageEvent, editEvent } = useSelector(
    (state: ReduxState) => state.EventReducer
  )
  const [manageForm, setManageForm] = useState<ManageForm>({
    open: false,
    type: 'CREATE'
  })

  const [typeTicket, setTypeTicket] = useState('')
  const [isFree, setIsFree] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      rule: '',
      priceCents: '',
      discountCents: '0'
    },
    validationSchema: createTicketValidation,
    onSubmit: async (values) => {
      try {
        dispatch(setLoading())

        if (
          !isFree &&
          Number(values.priceCents.replace(/[^0-9]/g, '').trim())
        ) {
          if (manageForm.type === 'CREATE') {
            const dataTickets = []

            const data = await createTicketService({
              category: category,
              description: !isFree ? 'Pago' : 'Gratuito',
              type: typeTicket === 'Outros' ? values.type : typeTicket,
              name: values.name,
              rule: values.rule ? values.rule : undefined,
              discountCents: isFree ? undefined : 0,
              priceCents: !isFree
                ? values.priceCents.includes(',')
                  ? values.priceCents.includes('.')
                    ? Number(
                        values.priceCents
                          .replace(/[^0-9]/g, '')
                          .replace(',', '')
                      )
                    : Number(
                        values.priceCents
                          .replace(/[^0-9]/g, '')
                          .replace(',', '')
                      )
                  : Number(values.priceCents.replace(/[^0-9]/g, '')) * 100
                : undefined,
              isFree: isFree,

              eventItemsTicket: eventItems.map((eventItemId) => ({
                eventItemId: eventItemId,
                ticketQuantity: 0
              }))
            })
            data && dispatch(addTicketEventItem(data, 'create'))
            dataTickets.push(data)

            if (
              dataTickets &&
              (typeTicket === 'Inteira' || typeTicket === 'Meia')
            ) {
              for (let i = 0; i < dataTickets.length; i++) {
                const data = await createTicketService({
                  category: category,
                  description: !isFree ? 'Pago' : 'Gratuito',
                  type: typeTicket === 'Inteira' ? 'Meia' : 'Inteira',
                  name: typeTicket === 'Inteira' ? 'Meia' : 'Inteira',
                  rule:
                    typeTicket === 'Inteira'
                      ? 'Para ter direito a meia entrada, é necessário ter a carteira estudantil.'
                      : '',
                  discountCents: 0,
                  priceCents:
                    !isFree && typeTicket === 'Meia'
                      ? values.priceCents.includes(',')
                        ? values.priceCents.includes('.')
                          ? Number(
                              values.priceCents
                                .replace(/[^0-9]/g, '')
                                .replace(',', '')
                            ) *
                            100 *
                            2
                          : Number(
                              values.priceCents
                                .replace(/[^0-9]/g, '')
                                .replace(',', '')
                            ) * 2
                        : Number(values.priceCents.replace(/[^0-9]/g, '')) *
                          100 *
                          2
                      : !isFree && typeTicket === 'Inteira'
                      ? values.priceCents.includes(',')
                        ? values.priceCents.includes('.')
                          ? (Number(
                              values.priceCents
                                .replace(/[^0-9]/g, '')
                                .replace(',', '')
                            ) *
                              100) /
                            2
                          : Number(
                              values.priceCents
                                .replace(/[^0-9]/g, '')
                                .replace(',', '')
                            ) / 2
                        : (Number(values.priceCents.replace(/[^0-9]/g, '')) *
                            100) /
                          2
                      : undefined,
                  isFree: isFree,
                  eventItemsTicket: [
                    {
                      eventItemId: eventItems[i],
                      ticketQuantity: 0
                    }
                  ]
                })
                data && dispatch(addTicketEventItem(data, 'create'))
              }
            }

            if (dataTickets) {
              clearForm()
            }
          } else {
            const data = await updateTicketService(manageForm?.ticket!.id, {
              category: category,
              description: !isFree ? 'Pago' : 'Gratuito',
              name: values.name,
              type: typeTicket,
              rule: values.rule ? values.rule : undefined,
              ticketPrice: {
                isFree: isFree,
                priceCents: !isFree
                  ? values.priceCents.includes(',')
                    ? values.priceCents.includes('.')
                      ? Number(
                          values.priceCents
                            .replace(/[^0-9]/g, '')
                            .replace(',', '')
                        ) * 100
                      : Number(
                          values.priceCents
                            .replace(/[^0-9]/g, '')
                            .replace(',', '')
                        )
                    : Number(values.priceCents.replace(/[^0-9]/g, '')) * 100
                  : undefined,
                discountCents: 0
              }
            })

            if (data) {
              dispatch(
                addTicketEventItem(
                  data,
                  'edit',
                  manageForm.ticket?.eventItems[0].id
                )
              )
              clearForm()
            }
          }
        }

        dispatch(clearLoading())
      } catch (error) {
        dispatch(clearLoading())
        dispatch(
          setAlertModalAction({
            title: 'Ocorreu um erro ao criar o ingresso.',
            open: true,
            variant: 'error',
            description: 'Tente novamente.',
            buttonText: 'Ok'
          })
        )
      }
    }
  })

  const clearForm = () => {
    setManageForm({
      open: false
    })
    formik.resetForm()
  }

  const editTicket = (ticket: Tickets) => {
    setManageForm({
      open: true,
      ticket: ticket,
      type: 'EDIT'
    })
    formik.setFieldValue(
      'priceCents',
      String((ticket.priceCents / 100).toFixed(2))
    )
    formik.setFieldValue(
      'discountCents',
      String(((ticket.priceCents - ticket.discountCents) / 100).toFixed(2))
    )
    formik.setFieldValue('name', ticket.name)
    formik.setFieldValue('rule', ticket.rule ?? '')
    formik.setFieldValue('type', ticket.type ?? '')
    setIsFree(ticket.type === 'Gratuito')
    setTypeTicket(ticket.type)
  }

  const addTicket = () => {
    setManageForm({
      open: true,
      type: 'CREATE'
    })
    formik.resetForm()
  }

  const options = [
    {
      value: 'Inteira',
      label: 'Inteira'
    },
    {
      value: 'Meia',
      label: 'Meia'
    },
    {
      value: 'Outros',
      label: 'Outros'
    }
  ]

  const renderTickets = useMemo(() => {
    return manageEvent
      ? eventItems.flatMap((id) =>
          manageEvent?.eventItems?.filter((eventItem) => eventItem.id === id)
        )
      : eventItems.flatMap((id) =>
          editEvent?.eventItems?.filter((eventItem) => eventItem.id === id)
        )
  }, [eventItems, manageEvent, editEvent])

  return (
    <S.Container>
      <S.Header>
        <Paragraph variant="regular" type="semiBold">
          {category}
        </Paragraph>
        <S.IconBox
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          onClick={() => {
            setIsOpen((prev) => !prev)
            clearForm()
          }}>
          <S.ChevronIcon />
        </S.IconBox>
      </S.Header>
      <AnimatePresence initial={true}>
        {isOpen ? (
          <S.TicketsBox
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}>
            {renderTickets.map((eventItem) => {
              if (eventItem?.tickets) {
                return eventItem?.tickets
                  .filter((ticket) => ticket.category === category && ticket)
                  .map((ticket) => (
                    <S.TicketCard key={ticket.id}>
                      <Paragraph variant="large" type="normal">
                        {ticket.name + ' - ' + eventItem.title}
                      </Paragraph>
                      <S.EditIcon onClick={() => editTicket(ticket)} />
                    </S.TicketCard>
                  ))
              } else return null
            })}
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth={false}
              onClick={addTicket}
              type="button">
              Adicionar ingresso
            </Button>
            <AnimatePresence initial={true}>
              {manageForm.open && (
                <S.AccordionInfo
                  onSubmit={formik.handleSubmit}
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: 'auto' },
                    collapsed: { opacity: 0, height: 0 }
                  }}
                  transition={{
                    duration: 0.8,
                    ease: [0.04, 0.62, 0.23, 0.98]
                  }}>
                  <div className="radioButtonBox">
                    <div className="radioButton">
                      <Paragraph variant="large">Pago</Paragraph>
                      <RadioButton
                        checked={!isFree}
                        readOnly
                        onClick={() => {
                          formik.setFieldValue('priceCents', '')
                          formik.setFieldValue('discountCents', '')
                          setIsFree(false)
                        }}
                      />
                    </div>
                    <div>
                      <Paragraph variant="large">Gratuito</Paragraph>
                      <RadioButton
                        checked={isFree}
                        readOnly
                        onClick={() => {
                          formik.setFieldValue('priceCents', '100')
                          formik.setFieldValue('discountCents', '100')
                          setIsFree(true)
                        }}
                      />
                    </div>
                  </div>
                  <div className="inputBox">
                    <Input
                      label="*Nome"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      texterror={formik.errors.name}
                      fullWidth={media.isMobile}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: media.isMobile ? '100%' : 'auto'
                      }}>
                      <div
                        style={{
                          width: media.isMobile ? '100%' : '244px',
                          marginRight: '16px',
                          marginBottom: '24px'
                        }}>
                        <S.Label>Tipo do ingresso</S.Label>
                        <ReactSelect
                          placeholder="Tipo do ingresso"
                          options={options}
                          onChange={(e: any) => {
                            if (e && e?.value !== 'Outros') {
                              formik.setFieldValue('type', e?.value)
                              setTypeTicket(e?.value)
                            } else if (e && e?.value === 'Outros') {
                              setTypeTicket(e?.value)
                              formik.setFieldValue('type', '')
                            }
                          }}
                          value={
                            typeTicket === 'Outros'
                              ? [
                                  {
                                    label: 'Outros',
                                    value: 'Outros'
                                  }
                                ]
                              : formik.values.type
                              ? [
                                  {
                                    label: formik.values.type,
                                    value: formik.values.type
                                  }
                                ]
                              : null
                          }
                          styles={S.customStyles}
                        />
                      </div>
                      {typeTicket === 'Outros' ? (
                        <div className="inputType">
                          <Input
                            name="type"
                            label="Tipo do ingresso"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.type && Boolean(formik.errors.type)
                            }
                            fullWidth={media.isMobile}
                          />
                          {formik.touched.type &&
                          Boolean(formik.errors.type) ? (
                            <S.Error>{formik.errors.type}</S.Error>
                          ) : undefined}
                        </div>
                      ) : undefined}
                    </div>
                  </div>

                  {!isFree ? (
                    <div className="priceBox">
                      <CurrencyInput
                        className="currencyInput"
                        label="Preço"
                        name="priceCents"
                        onValueChange={(value) => {
                          formik.setFieldValue('priceCents', value)
                        }}
                        error={
                          formik.touched.priceCents &&
                          Boolean(formik.errors.priceCents)
                        }
                        texterror={formik.errors.priceCents}
                        value={formik.values.priceCents}
                        intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
                        min="1"
                        allowNegativeValue={false}
                      />
                    </div>
                  ) : null}
                  <Input
                    label="Regra"
                    name="rule"
                    value={formik.values.rule}
                    onChange={formik.handleChange}
                    error={formik.touched.rule && Boolean(formik.errors.rule)}
                    texterror={formik.errors.rule}
                    fullWidth={media.isMobile}
                  />
                  <S.Actions>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      fullWidth={false}
                      type="submit">
                      {manageForm.ticket ? 'Editar Ingresso' : 'Criar Ingresso'}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      fullWidth={false}
                      onClick={() => setManageForm({ open: false })}
                      type="button">
                      Cancelar
                    </Button>
                  </S.Actions>
                </S.AccordionInfo>
              )}
            </AnimatePresence>
          </S.TicketsBox>
        ) : undefined}
      </AnimatePresence>
    </S.Container>
  )
}
