import * as S from './CreateTickets.styles'
import { Button, Heading } from 'applaus-ui-kit'

import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../../redux/rootReducer'

import { TabulatedTicket } from './components/TabulatedTicket/TabulatedTicket'
import { MappedTicket } from './components/MappedTicket/MappedTicket'

type Props = {
  setCreateTicket: () => void
}

export const CreateTickets = ({ setCreateTicket }: Props) => {
  const { manageEvent } = useSelector((state: ReduxState) => state.EventReducer)

  return (
    <S.Wrapper>
      <S.TitleBox>
        <S.BackIcon onClick={setCreateTicket} />
        <Heading variant="h3">Adicionar ingresso</Heading>
      </S.TitleBox>
      {manageEvent?.type === 'MAPPED' ? (
        <MappedTicket setCreateTicket={setCreateTicket} />
      ) : (
        <TabulatedTicket setCreateTicket={setCreateTicket} />
      )}

      {manageEvent?.type === 'MAPPED' ? (
        <Button
          color="primary"
          variant="contained"
          fullWidth
          size="large"
          onClick={setCreateTicket}>
          Continuar
        </Button>
      ) : null}
    </S.Wrapper>
  )
}
