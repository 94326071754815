import * as S from './DateInfo.styles'
import { Heading, Paragraph } from 'applaus-ui-kit'
import moment from 'moment'
import { useWindowSize } from '../../../../hooks/useWindowSize'

type Props = {
  dates: string[]
}

export const DateInfo = ({ dates }: Props) => {
  const { isMobile } = useWindowSize()
  const desktopDates = dates.map((date, index) => {
    return moment(date).format(
      `DD/MM [ás] HH[h]mm ${
        index === dates.length - 2
          ? '[ e ]'
          : index !== dates.length - 1
          ? '[ , ]'
          : ''
      }`
    )
  })

  const mobileDates = dates.map((date, index) => {
    return moment(date).format(
      `DD/MM ${
        index === dates.length - 2
          ? '[ e ]'
          : index !== dates.length - 1
          ? '[ , ]'
          : ''
      }`
    )
  })

  return (
    <S.Container>
      <S.ClockIcon />
      {dates.length === 0 ? (
        <S.SingleDateBox>
          <Heading
            variant="h5"
            className="textInfo"
            style={{ textTransform: 'capitalize' }}>
            {moment(dates[0]).locale('pt-br').format('dddd [ás] HH[h]mm')}
          </Heading>
          <Paragraph variant="small" type="normal" className="textInfo">
            {moment(dates[0]).locale('pt-br').format('ll')}
          </Paragraph>
        </S.SingleDateBox>
      ) : (
        <Paragraph
          variant="large"
          className="textInfo"
          style={{ textTransform: 'capitalize' }}>
          {isMobile ? mobileDates : desktopDates}
        </Paragraph>
      )}
    </S.Container>
  )
}
