import * as S from './DesktopAppBar.styles'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import { useRef, useState } from 'react'
import logo from '../../../assets/images/logo.png'
import { logoutAction } from '../../../redux/User/actions'
import { MenuItem } from '../../menu/MenuItem/MenuItem'
import { Menu } from '../../menu/Menu/Menu'
import { Paragraph } from 'applaus-ui-kit'
import { history } from '../../../routes/services/history'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'

export const DesktopAppBar = () => {
  const { isDesktop } = useWindowSize()
  const { userInfo } = useSelector((state: ReduxState) => state.UserReducer)
  const [showMenu, setShowMenu] = useState(false)
  const dispatch = useDispatch()

  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => setShowMenu(false))

  return (
    <S.Content>
      <img src={logo} alt="applaus" onClick={() => history.push('/')} />
      {isDesktop ? (
        <S.DropDown
          ref={wrapperRef}
          onClick={() => setShowMenu((prev) => !prev)}>
          <Paragraph
            variant="large"
            type="semiBold"
            className="user-name"
            style={{ color: '#8601c9' }}>
            {userInfo?.firstName} {userInfo?.lastName}
          </Paragraph>
          <S.ChevronDownIcon />

          <Menu id="menu" open={showMenu}>
            <MenuItem
              onClick={() => dispatch(logoutAction())}
              Icon={S.LogoutIcon}>
              Sair
            </MenuItem>
          </Menu>
        </S.DropDown>
      ) : undefined}
    </S.Content>
  )
}
