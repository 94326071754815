import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from '../../../../assets/icons/backIcon.svg'
import { ReactComponent as RemoveFile } from '../../../../assets/icons/trash-alt.svg'
import { ReactComponent as EditFile } from '../../../../assets/icons/edit.svg'

export const TrashIcon = styled(RemoveFile)`
  cursor: pointer;
  fill: #808080;
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.2);
    fill: #39383a;
  }
`
export const EditIcon = styled(EditFile)`
  ${({ theme }) => css`
    margin-left: auto;
    margin-right: ${theme.spacing.inline.xxxs};
    cursor: pointer;
    fill: #b88bcf;
    transition: 0.3s ease;
    &:hover {
      transform: scale(1.2);
      fill: #8601c9;
    }
  `}
`
export const BackIcon = styled(BackFile)`
  cursor: pointer;
`
export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .inputHeight {
    height: 43px;
  }
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    max-width: 1366px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    .add-section button {
      margin-top: ${theme.spacing.stack.xxxs};
      align-self: end;
    }
    .continue-button {
      margin-top: auto;
      align-self: flex-end;
    }
    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
    }
  `}
`

export const CreateSessionBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${theme.spacing.stack.xxxs} 0;
  `}
`
export const EventItemsBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const EventItemsHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
  `}
`

export const EventItemsContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-template-rows: 1fr;
    width: 100%;
    background: #fff;
    padding: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    margin: 8px 0;
  `}
`
