import { Paragraph } from 'applaus-ui-kit'
import produce from 'immer'
import moment from 'moment'
import { Dispatch, SetStateAction } from 'react'
import { StepState } from '../Event'

import * as S from './ChooseEventItem.styles'

type Props = {
  data: any
  setStep: Dispatch<SetStateAction<StepState>>
}

export const ChooseEventItem = ({ data, setStep }: Props) => {
  const handleClick = (e: any) => {
    setStep(
      produce((draft) => {
        draft.show = 'chooseTicket'
        draft.selectedEventItem = e
      })
    )
  }

  return (
    <S.Container>
      <div>
        <S.BackIcon
          onClick={() =>
            setStep(
              produce((draft) => {
                draft.show = 'showEvent'
              })
            )
          }
        />
        <Paragraph variant="large" type="bold">
          Horario dos eventos
        </Paragraph>
      </div>
      {data!.eventItems.map(
        (eventItem: { id: any; dates: any[] }, index: any) => {
          return (
            <S.EventItems
              key={index + eventItem.id}
              onClick={() => handleClick(eventItem)}>
              <Paragraph variant="regular" type="semiBold">
                {moment(eventItem.dates[0]).format('ll')}
              </Paragraph>
              <S.Dates>
                {eventItem.dates.map((date: any, index: any) => (
                  <S.Paragraph key={index + date} onClick={() => {}}>
                    {moment(date).format('LT')}
                  </S.Paragraph>
                ))}
              </S.Dates>
            </S.EventItems>
          )
        }
      )}
    </S.Container>
  )
}
