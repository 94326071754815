import { AxiosResponse } from 'axios'
import { api } from '../config'

type Request = {
  eventId: string
  body: {
    name?: string
    banners?: {
      desktopUrl?: string
      tabletUrl?: string
      mobileUrl?: string
      cardUrl?: string
    }[]
    seatMapId?: string
    description?: string
  }
}

type Response = {
  createdAt: string
  creatorId: string
  description: string
  id: string
  name: string
  banners: {
    id: string
    desktopUrl: string
    tabletUrl: string
    mobileUrl: string
    cardUrl: string
  }[]
  seatMapId: string | null
  type: 'MAPPED' | 'TABULATED'
}

export const updateEventService = async ({ body, eventId }: Request) => {
  const { data }: AxiosResponse<Response> = await api.put(
    `/api/events/${eventId}`,
    body
  )
  return data
}
