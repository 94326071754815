import { api } from '../config'
import { Coupon } from '../../redux/Events/types'
import { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'

export const editCouponService = async (
  couponId: string,
  originCoupon: Coupon,
  requestBody: Coupon
) => {
  const changes: any = {}
  for (const prop in originCoupon) {
    const propName = prop as
      | 'name'
      | 'description'
      | 'amountOfUse'
      | 'value'
      | 'valueType'
      | 'quantity'
      | 'startValidateDate'
      | 'finalValidateDate'
    if (
      propName.indexOf('$') !== 0 &&
      originCoupon[propName] !== requestBody[propName]
    ) {
      changes[propName] = requestBody[propName]
    }
  }

  if (!isEmpty(changes)) {
    const { data }: AxiosResponse<Coupon> = await api.put(
      `api/coupons/${couponId}`,
      changes
    )
    return data
  }
}
