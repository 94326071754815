import * as S from './Events.style'
import { history } from '../../routes/services/history'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'
import { Heading, Button, Paragraph } from 'applaus-ui-kit'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearStateAction,
  listEventsRequestAction
} from '../../redux/Events/actions'
import { ReduxState } from '../../redux/rootReducer'
import { useWindowSize } from '../../hooks/useWindowSize'
import moment from 'moment'
import 'moment/locale/pt-br'
import { removeEventService } from '../../api/events/removeEventService'
import _ from 'lodash'

export const Events = () => {
  const [searchInput, setSearchInput] = useState('')
  const dispatch = useDispatch()
  const { data } = useSelector((state: ReduxState) => state.EventReducer)
  const windowSize = useWindowSize()

  useEffect(() => {
    dispatch(clearStateAction())
    dispatch(listEventsRequestAction())
  }, [])

  const handleDeleteEvent = async (eventId: string) => {
    await removeEventService(eventId)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const renderEvents = useMemo(() => {
    return _.filter(data, function (event) {
      return event.name.toLowerCase().includes(searchInput.toLocaleLowerCase())
    })
  }, [searchInput, data])

  return (
    <AuthenticatedTemplate isActive={'events'}>
      <S.Container>
        <S.Wrapper>
          <Heading variant="h3">Meus eventos</Heading>
          <S.CreateEventBox>
            <S.InputWrapper>
              <S.SearchIcon />
              <S.Input
                placeholder="Buscar pelo nome do evento"
                onChange={(e) => handleInputChange(e)}
              />
            </S.InputWrapper>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth={false}
              onClick={() => history.push('/createEvent')}>
              Criar Evento
            </Button>
          </S.CreateEventBox>
          {windowSize.isMobile ? (
            renderEvents.map((event) => (
              <S.EditEventCard
                key={event.id}
                onClick={() => history.push(`/manageEvent/${event.id}`)}>
                <div className="titleBox">
                  <Paragraph
                    variant="large"
                    type="semiBold"
                    className="paragraph">
                    {event.name}
                  </Paragraph>

                  <Paragraph variant="regular" className="paragraph">
                    {event.eventItems && event.eventItems?.length > 0
                      ? moment(event.eventItems[0].dates[0]).format('lll')
                      : 'Não possui data definida'}
                  </Paragraph>
                </div>
                <div className="actions">
                  <S.EditIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      history.push(`/editEvent/${event.id!}`)
                    }}
                  />
                  <S.ExcludeIcon onClick={() => handleDeleteEvent(event.id!)} />
                </div>
              </S.EditEventCard>
            ))
          ) : (
            <S.Table>
              <S.TableHead>
                <Paragraph variant="regular" type="semiBold">
                  Status
                </Paragraph>

                <Paragraph variant="regular" type="semiBold">
                  Evento
                </Paragraph>

                <Paragraph variant="regular" type="semiBold">
                  ingressos
                </Paragraph>
                <div />
              </S.TableHead>

              <S.TableBody>
                {renderEvents.map((event) => (
                  <S.TableData key={event.id}>
                    <Paragraph
                      variant="regular"
                      type="semiBold"
                      className={
                        event.status === 'OPEN'
                          ? 'public-status'
                          : event.status === 'CANCELED'
                          ? 'closed-status'
                          : 'others-status'
                      }>
                      {event.status === 'OPEN'
                        ? 'Publicado'
                        : event.status === 'SOLD_OUT'
                        ? 'Esgotado'
                        : event.status === 'CANCELED'
                        ? 'Cancelado'
                        : event.status === 'CLOSED'
                        ? 'Fechado'
                        : event.status === 'DRAFT'
                        ? 'Rascunho'
                        : null}
                    </Paragraph>
                    <Paragraph
                      variant="regular"
                      type="semiBold"
                      className="paragraph">
                      {event.name}
                    </Paragraph>

                    {event.tickets && event.tickets?.length > 0 ? (
                      <div>
                        {event.type === 'MAPPED' ? (
                          <>
                            <Paragraph variant="regular" type="semiBold">
                              {`${event.eventItems?.reduce(
                                (acc, { ticketsSold }) =>
                                  acc + (ticketsSold || 0),
                                0
                              )}`}
                              {`/${event.eventItems?.reduce(
                                (acc, { ticketQuantity }) =>
                                  acc + (ticketQuantity || 0),
                                0
                              )}`}
                            </Paragraph>
                            <progress
                              value={event.eventItems?.reduce(
                                (acc, { ticketsSold }) =>
                                  acc + (ticketsSold || 0),
                                0
                              )}
                              max={event.eventItems?.reduce(
                                (acc, { ticketQuantity }) =>
                                  acc + (ticketQuantity || 0),
                                0
                              )}
                            />
                          </>
                        ) : (
                          <>
                            <Paragraph variant="regular" type="semiBold">
                              {`${event.tickets?.reduce(
                                (acc, { ticketsSold }) =>
                                  acc + (ticketsSold || 0),
                                0
                              )}`}
                              {`/${event.tickets?.reduce(
                                (acc, { ticketQuantity }) =>
                                  acc + (ticketQuantity || 0),
                                0
                              )}`}
                            </Paragraph>
                            <progress
                              value={event.tickets?.reduce(
                                (acc, { ticketsSold }) =>
                                  acc + (ticketsSold || 0),
                                0
                              )}
                              max={event.tickets?.reduce(
                                (acc, { ticketQuantity }) =>
                                  acc + (ticketQuantity || 0),
                                0
                              )}
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <Paragraph
                        variant="regular"
                        className="without-tickets-paragraph">
                        Sem ingressos
                      </Paragraph>
                    )}

                    <S.Actions>
                      <Button
                        color="primary"
                        size="medium"
                        variant="contained"
                        fullWidth={false}
                        onClick={() =>
                          history.push(`/manageEvent/${event.id}`)
                        }>
                        Gerenciar
                      </Button>
                      {/* <S.EditIcon */}
                      {/*  onClick={() => history.push(`/editEvent/${event.id!}`)} */}
                      {/* /> */}
                      <S.ExcludeIcon
                        onClick={() => handleDeleteEvent(event.id!)}
                      />
                    </S.Actions>
                  </S.TableData>
                ))}
              </S.TableBody>
            </S.Table>
          )}
        </S.Wrapper>
      </S.Container>
    </AuthenticatedTemplate>
  )
}
