import axios from 'axios'
import { setAlertModalAction } from '../../redux/AlertModal/actions'
import { removeEventSuccessAction } from '../../redux/Events/actions'
import { clearLoading, setLoading } from '../../redux/Loading/actions'
import store from '../../redux/store'
import { api } from '../config'

export const removeEventService = async (eventId: string) => {
  const { dispatch } = store
  try {
    dispatch(setLoading())
    await api
      .delete(`/api/events/${eventId}`)
      .then(() => dispatch(removeEventSuccessAction(eventId)))
    dispatch(clearLoading())
  } catch (e) {
    dispatch(clearLoading())
    if (axios.isAxiosError(e)) {
      switch (e.response?.status) {
        case 500:
          dispatch(
            setAlertModalAction({
              open: true,
              buttonText: 'Tentar novamente',
              title: 'Erro interno do servidor',
              variant: 'error'
            })
          )
          break
        case 404:
          dispatch(
            setAlertModalAction({
              open: true,
              buttonText: 'Fechar',
              title: 'Evento não encontrado',
              variant: 'error'
            })
          )
          break
        default:
          break
      }
    } else {
      dispatch(
        setAlertModalAction({
          open: true,
          buttonText: 'Tentar novamente',
          title: 'Erro interno do servidor',
          variant: 'error'
        })
      )
    }
  }
}
