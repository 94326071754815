import { api } from '../config'
import { Address } from '../../redux/Events/types'
import store from '../../redux/store'
import { addLocationSuccess } from '../../redux/Events/actions'
import { clearLoading, setLoading } from '../../redux/Loading/actions'

export const updateEventAddress = async (request: Address, eventId: string) => {
  const { dispatch } = store
  try {
    dispatch(setLoading())
    await api
      .put(`/api/events/${eventId}`, {
        address: { ...request, number: Number(request.number) }
      })
      .then(() =>
        dispatch(
          addLocationSuccess({ ...request, number: Number(request.number) })
        )
      )
    dispatch(clearLoading())
  } catch (err) {
    dispatch(clearLoading())
  }
}
