import * as Yup from 'yup'

export const createPartnerSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório!'),
  typeDocument: Yup.string().required('Tipo é obrigatório!'),
  document: Yup.string().required('Documento é obrigatório!'),
  phone: Yup.string()
    .required('Telefone é obrigatório!')
    .test('phoneValidity', 'Telefone inválido', (value) => {
      if (value && value.replace(/[^0-9]/g, '').trim().length === 11) {
        return true
      } else {
        return false
      }
    }),
  email: Yup.string().email('Email inválido.').required('Email é obrigatório!'),
  zipCode: Yup.string()
    .required('CEP é obrigatório!')
    .test('zipCodeValidity', 'CEP inválido.', (value) => {
      if (value && value.replace(/[^0-9]/g, '').trim().length === 8) {
        return true
      } else return false
    }),
  street: Yup.string().required('Endereço é obrigatório!'),
  state: Yup.string().required('Estado é obrigatório!'),
  city: Yup.string().required('Cidade é obrigatória!'),
  number: Yup.string().required('Número é obrigatório!'),
  complement: Yup.string().test(
    'complementValidity',
    'Minimo de 3 caracteres',
    (value) => {
      if (value && value.length > 2) {
        return true
      } else if (!value) {
        return true
      } else return false
    }
  ),
  agency: Yup.string().required('Agência é obrigatória!'),
  bank: Yup.string().required('Banco é obrigatório!'),
  account: Yup.string().required('Conta é obrigatória!'),
  typeAccount: Yup.string().required('Tipo da conta é obrigatório!')
})

export const editPartnerSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório!'),
  typeDocument: Yup.string().required('Tipo é obrigatório!'),
  document: Yup.string().required('Documento é obrigatório!'),
  phone: Yup.string().required('Telefone é obrigatório!'),
  email: Yup.string().email('Email inválido.').required('Email é obrigatório!'),
  zipCode: Yup.string().required('CEP é obrigatório!'),
  street: Yup.string().required('Endereço é obrigatório!'),
  state: Yup.string().required('Estado é obrigatório!'),
  city: Yup.string().required('Cidade é obrigatória!'),
  number: Yup.string().required('Número é obrigatório!'),
  complement: Yup.string().test(
    'complementValidity',
    'Minimo de 3 caracteres',
    (value) => {
      if (value && value.length > 2) {
        return true
      } else if (!value) {
        return true
      } else return false
    }
  ),
  agency: Yup.string().required('Agência é obrigatória!'),
  bank: Yup.string().required('Banco é obrigatório!')
})
