import { ReactNode } from 'react'
import { history } from '../../../routes/services/history'
import * as S from './AuthenticatedTemplate.styles'

import logo from '../../../assets/images/logo.png'
import { Paragraph } from 'applaus-ui-kit'
import { useDispatch } from 'react-redux'
import { logoutAction } from '../../../redux/User/actions'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { AppBar } from '../../AppBar/AppBar'

type Props = {
  isActive:
    | 'courses'
    | 'events'
    | 'profile'
    | 'scan'
    | 'home'
    | 'bankData'
    | 'courtesy'
    | 'partner'
  children: ReactNode
}

export const AuthenticatedTemplate = ({ isActive, children }: Props) => {
  const { isMobile, isTablet } = useWindowSize()
  const dispatch = useDispatch()
  return (
    <S.Container>
      {isMobile || isTablet ? (
        <AppBar />
      ) : (
        <S.Menu>
          <img src={logo} alt="applaus" />
          <div>
            <S.Item
              isActive={isActive === 'home'}
              onClick={() => history.push('/')}>
              <S.HomeIcon />
              <Paragraph variant="large">Home</Paragraph>
            </S.Item>

            <S.Item
              isActive={isActive === 'events'}
              onClick={() => history.push('/events')}>
              <S.CouponIcon />
              <Paragraph variant="large">Meus eventos</Paragraph>
            </S.Item>
            <S.Item
              isActive={isActive === 'profile'}
              onClick={() => history.push('/profile')}>
              <S.UserIcon />
              <Paragraph variant="large">Meus dados</Paragraph>
            </S.Item>
            <S.Item
              isActive={isActive === 'bankData'}
              onClick={() => history.push('/bankData')}>
              <S.CreditCardIcon />
              <Paragraph variant="large">Dados Bancários</Paragraph>
            </S.Item>
            <S.Item
              isActive={isActive === 'partner'}
              onClick={() => history.push('/partners')}>
              <S.PartnerIcon />
              <Paragraph variant="large">Parceiros</Paragraph>
            </S.Item>
            <S.Item isActive={false} onClick={() => dispatch(logoutAction())}>
              <S.LogoutIcon />
              <Paragraph variant="large">Sair</Paragraph>
            </S.Item>
          </div>
        </S.Menu>
      )}

      {children}
    </S.Container>
  )
}
