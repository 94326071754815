import { api } from '../config'
import { AxiosResponse } from 'axios'
import store from '../../redux/store'
import { createEventItemSuccess } from '../../redux/Events/actions'
import { Tickets } from '../../redux/Events/types'
import { setAlertModalAction } from '../../redux/AlertModal/actions'

export type CreateEventItemRequest = {
  eventId: string
  title: string
  description: string
  dates: string[]
  maxCustomerTickets: number
  salesFrom?: string
  finalSales?: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
}

export type CreateEventItemResponse = {
  id: string
  title: string
  description: string
  dates: string[]
  maxCustomerTickets: string
  salesFrom?: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
  tickets: Tickets[]
}

export const createEventItemService = async (
  request: CreateEventItemRequest
) => {
  const { dispatch } = store
  let error = false
  try {
    await api
      .post(`/api/events/${request.eventId}/eventItems`, {
        eventId: request.eventId,
        title: request.title,
        description: request.description,
        dates: request.dates,
        maxCustomerTickets: request.maxCustomerTickets,
        salesFrom: request.salesFrom,
        finalSales: request.finalSales,
        ticketInfo: request.ticketInfo
      })
      .then(({ data }: AxiosResponse<CreateEventItemResponse>) => {
        dispatch(createEventItemSuccess(data))
        dispatch(
          setAlertModalAction({
            open: true,
            variant: 'success',
            buttonText: 'Fechar',
            title: 'Sessão criada com sucesso'
          })
        )
      })
  } catch (err) {
    error = true
    dispatch(
      setAlertModalAction({
        open: true,
        variant: 'error',
        buttonText: 'Fechar',
        title: 'Data de início das vendas expirada.'
      })
    )
  }
  return error
}
