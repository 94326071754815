/* eslint-disable no-unused-vars */
import { Button, Heading, Input, InputMask, Paragraph } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import ReactSelect from 'react-select'
import { Column, Row } from '../../components/grid/GridSystem'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'
import * as S from './Partner.styles'
import { createPartnerSchema } from './validationSchema'
import jsonStates from '../../utils/estados-cidades.json'
import { BankOptions } from '../BankData/banks'
import { useWindowSize } from '../../hooks/useWindowSize'
import _ from 'lodash'
import {
  listPartners,
  ListPartnersResponse
} from '../../api/partner/listPartners'
import { clearLoading, setLoading } from '../../redux/Loading/actions'
import { useDispatch } from 'react-redux'
import { createPartnerService } from '../../api/partner/createPartnerService'
import {
  clearModalAction,
  setAlertModalAction
} from '../../redux/AlertModal/actions'
import { viaCepService } from '../../api/viaCep/viaCepService'
import { editPartnertService } from '../../api/partner/editPartnertService'
import { deletePartnerService } from '../../api/partner/deletePartnerService'

export const Partner = () => {
  const dispatch = useDispatch()
  const { isMobile } = useWindowSize()
  const [createPartner, setCreatePartner] = useState(false)
  const [editPartner, setEditPartner] =
    useState<{ partner: ListPartnersResponse; action: 'edit' | 'create' }>()
  const [data, setData] = useState<ListPartnersResponse[]>()
  const [searchInput, setSearchInput] = useState('')
  const optionsStates = jsonStates.estados.map((state) => ({
    label: state.nome,
    value: state.sigla
  }))
  const [cities, setCities] = useState<{ label: string; value: string }[]>()
  const mappedBanks = Object.entries(BankOptions).map((values) => ({
    value: values[0],
    label: values[1]
  }))
  const [selectedState, setSelectedState] = useState('')
  const [deletePartnerSuccess, setDeletePartnerSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: editPartner?.partner.name ?? '',
      typeDocument: editPartner?.partner.documentType ?? 'CPF',
      document: editPartner?.partner.document ?? '',
      phone: editPartner?.partner.phoneNumber ?? '',
      email: editPartner?.partner.email ?? '',
      zipCode: editPartner?.partner.address?.zipCode ?? '',
      street: editPartner?.partner.address?.street ?? '',
      state: editPartner?.partner.address?.state ?? '',
      city: editPartner?.partner.address?.city ?? '',
      number: editPartner?.partner.address?.number ?? '',
      complement: editPartner?.partner.address?.complement ?? '',
      agency: editPartner?.partner.bankAg ?? '',
      bank: editPartner?.partner.bank ?? '',
      account: editPartner?.partner.bankCc ?? '',
      typeAccount: editPartner?.partner.bankAccountType ?? ''
    },
    validationSchema: createPartnerSchema,
    onSubmit: async (values) => {
      dispatch(setLoading())
      if (editPartner?.action === 'edit') {
        try {
          const editAddress = {
            country: editPartner.partner.address.country,
            district: editPartner.partner.address.district,
            zipCode: editPartner.partner.address.zipCode,
            street: editPartner.partner.address.street,
            state: editPartner.partner.address.state,
            city: editPartner.partner.address.city,
            number: editPartner.partner.address.number,
            complement:
              editPartner.partner.address.complement === null
                ? undefined
                : editPartner.partner.address.complement
          }

          const currentAddress = {
            country: editPartner.partner.address.country,
            district: editPartner.partner.address.district,
            zipCode: values.zipCode,
            street: values.street,
            state: values.state,
            city: values.city,
            number: Number(values.number),
            complement: values.complement === '' ? undefined : values.complement
          }

          const editBankInfo = {
            bankAg: editPartner?.partner.bankAg,
            bank: editPartner?.partner.bank,
            bankCc: editPartner?.partner.bankCc,
            bankAccountType: editPartner?.partner.bankAccountType
          }
          const currentBankInfo = {
            bankAg: formik.values.agency,
            bank: formik.values.bank,
            bankCc: formik.values.account,
            bankAccountType: formik.values.typeAccount
          }

          const response = await editPartnertService(
            {
              name:
                values.name !== editPartner.partner.name
                  ? values.name
                  : undefined,
              document:
                values.document.replace(/[^0-9]/g, '').trim() !==
                editPartner.partner.document
                  ? values.document.replace(/[^0-9]/g, '').trim()
                  : undefined,
              documentType:
                values.typeDocument !== editPartner.partner.documentType
                  ? values.typeDocument
                  : undefined,
              phoneNumber:
                values.phone.replace(/[^0-9]/g, '').trim() !==
                editPartner.partner.phoneNumber
                  ? values.phone.replace(/[^0-9]/g, '').trim()
                  : undefined,
              email:
                values.email !== editPartner.partner.email
                  ? values.email
                  : undefined,
              address: _.isEqual(currentAddress, editAddress)
                ? undefined
                : currentAddress,
              bankInfo:
                editPartner.partner.status !== 'ACCEPTED'
                  ? undefined
                  : _.isEqual(currentBankInfo, editBankInfo)
                  ? undefined
                  : currentBankInfo
            },
            editPartner.partner.id
          )
          dispatch(clearLoading())
          if (response === 'noResponse') {
            setCreatePartner(false)
            setEditPartner((prev) => ({
              partner: prev!.partner,
              action: 'create'
            }))
          } else {
            dispatch(
              setAlertModalAction({
                buttonText: 'Ok',
                open: true,
                title: 'Parceiro alterado com sucesso',
                variant: 'success',
                click: () => {
                  setCreatePartner(false)
                  dispatch(clearModalAction())
                  setEditPartner((prev) => ({
                    partner: prev!.partner,
                    action: 'create'
                  }))
                }
              })
            )
          }
        } catch (e) {}
      } else {
        try {
          const response = await createPartnerService(
            {
              name: values.name,
              document: values.document.replace(/[^0-9]/g, '').trim(),
              documentType: values.typeDocument,
              email: values.email,
              phoneNumber: values.phone.replace(/[^0-9]/g, '').trim(),
              address: {
                zipCode: values.zipCode.replace(/[^0-9]/g, '').trim(),
                city: values.city,
                country: 'BR',
                district: 'San martin',
                number: Number(values.number),
                state: values.state,
                street: values.street
              }
            },
            {
              bank: values.bank,
              bankAccountType: values.typeAccount,
              bankAg: values.agency,
              bankCc: values.account
            }
          )

          dispatch(clearLoading())
          if (response) {
            dispatch(
              setAlertModalAction({
                title: 'Parceiro criado com sucesso',
                open: true,
                variant: 'success',
                buttonText: 'Ok',
                click: () => {
                  dispatch(clearModalAction())
                  setCreatePartner(false)
                  formik.resetForm()
                }
              })
            )
          }
        } catch (e) {
          dispatch(clearLoading())
          dispatch(
            setAlertModalAction({
              title: 'Ocorreu um erro na criação do parceiro.',
              description: 'Tente novamente.',
              open: true,
              variant: 'error',
              buttonText: 'Ok',
              click: () => {
                dispatch(clearModalAction())
                formik.resetForm()
              }
            })
          )
        }
      }
    }
  })

  useEffect(() => {
    if (editPartner && editPartner.action === 'edit') {
      formik.setValues({
        name: editPartner?.partner.name ?? '',
        typeDocument: editPartner?.partner.documentType ?? '',
        document: editPartner?.partner.document ?? '',
        phone: editPartner?.partner.phoneNumber ?? '',
        email: editPartner?.partner.email ?? '',
        zipCode: editPartner?.partner.address?.zipCode ?? '',
        street: editPartner?.partner.address?.street ?? '',
        state: editPartner?.partner.address?.state ?? '',
        city: editPartner?.partner.address?.city ?? '',
        number: editPartner?.partner.address?.number ?? '',
        complement: editPartner?.partner.address?.complement ?? '',
        agency: editPartner?.partner.bankAg ?? '',
        bank: editPartner?.partner.bank ?? '',
        account: editPartner?.partner.bankCc ?? '',
        typeAccount: editPartner?.partner.bankAccountType ?? ''
      })
    }
  }, [editPartner])

  useEffect(() => {
    setCities(
      jsonStates.estados
        .filter(
          (state) =>
            state.nome === selectedState || state.sigla === selectedState
        )
        .flatMap((state) =>
          state.cidades.flatMap((city) => ({ label: city, value: city }))
        )
    )
  }, [selectedState])

  const options = [
    { label: 'CPF', value: 'CPF' },
    { label: 'CNPJ', value: 'CNPJ' }
  ]

  const typesAccount = [
    { label: 'Conta Poupança', value: 'Poupança' },
    { label: 'Conta Corrente', value: 'Corrente' }
  ]

  useEffect(() => {
    const getPartners = async () => {
      dispatch(setLoading())
      const response: ListPartnersResponse[] = await listPartners()
      setData(response)
      dispatch(clearLoading())
    }
    getPartners()

    if (createPartner || deletePartnerSuccess) {
      getPartners()
    }
  }, [createPartner, deletePartnerSuccess])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const renderPartners = useMemo(() => {
    return _.filter(data, function (partner) {
      return partner.name
        .toLowerCase()
        .includes(searchInput.toLocaleLowerCase())
    })
  }, [searchInput, data])

  const handleCEP = async (value: string) => {
    if (value.replace(/[^0-9]/g, '').trim().length === 8) {
      const response = await viaCepService(value.replace(/[^0-9]/g, '').trim())

      if (response) {
        setSelectedState(response.uf)
        formik.setFieldValue('state', response.uf)
        formik.setFieldValue('street', response.logradouro)
        formik.setFieldValue('city', response.localidade)
      }
    }
  }

  const deletePartner = async (partnerId: string) => {
    dispatch(setLoading())
    try {
      const status = await deletePartnerService(partnerId)
      dispatch(clearLoading())
      if (status === 204) {
        dispatch(
          setAlertModalAction({
            title: 'Parceiro deletado com sucesso.',
            open: true,
            variant: 'success',
            buttonText: 'Ok',
            click: () => {
              dispatch(clearModalAction())
              setDeletePartnerSuccess(true)
            }
          })
        )
        setDeletePartnerSuccess(false)
      }
    } catch (e) {
      dispatch(clearLoading())
      dispatch(
        setAlertModalAction({
          title: 'Ocorreu um erro na deleção do parceiro.',
          description: 'Tente novamente.',
          open: true,
          variant: 'error',
          buttonText: 'Ok',
          click: () => {
            dispatch(clearModalAction())
          }
        })
      )
    }
  }

  return (
    <AuthenticatedTemplate isActive="partner">
      <S.Container>
        {!createPartner ? (
          <>
            <Heading variant="h5">Parceiros</Heading>
            <S.CreatePartnerBox>
              <S.InputWrapper>
                <S.SearchIcon />
                <S.Input
                  placeholder="Buscar pelo nome do evento"
                  onChange={(e) => handleInputChange(e)}
                />
              </S.InputWrapper>
              <Button
                color="primary"
                size="large"
                variant="contained"
                fullWidth={false}
                onClick={() => setCreatePartner(true)}>
                Adicionar novo
              </Button>
            </S.CreatePartnerBox>

            {isMobile ? (
              renderPartners.map((partner) => (
                <S.EditPartnerCard key={partner.id} onClick={() => {}}>
                  <div className="titleBox">
                    <Paragraph
                      variant="large"
                      type="semiBold"
                      className="paragraph">
                      {partner.name}
                    </Paragraph>
                  </div>
                  <div className="actions">
                    <S.EditIcon
                      onClick={() => {
                        setEditPartner({ partner: partner, action: 'edit' })
                        setCreatePartner(true)
                      }}
                    />
                    <S.ExcludeIcon
                      onClick={() => {
                        deletePartner(partner.id)
                      }}
                    />
                  </div>
                </S.EditPartnerCard>
              ))
            ) : (
              <S.Table>
                <S.TableHead>
                  <Paragraph variant="regular" type="semiBold">
                    Nome
                  </Paragraph>

                  <Paragraph variant="regular" type="semiBold">
                    Email
                  </Paragraph>

                  <Paragraph variant="regular" type="semiBold">
                    Status
                  </Paragraph>
                  <div />
                </S.TableHead>

                <S.TableBody>
                  {renderPartners.map((partner) => (
                    <S.TableData key={partner.id}>
                      <Paragraph variant="regular" type="semiBold">
                        {partner.name}
                      </Paragraph>
                      <Paragraph variant="regular" type="semiBold">
                        {partner.email}
                      </Paragraph>
                      <p className={partner.status}>
                        {partner.status === 'WAITING'
                          ? 'Pendente'
                          : partner.status === 'ACCEPTED'
                          ? 'Regularizado'
                          : 'Negado'}
                      </p>
                      <div className="edit">
                        <S.EditIcon
                          onClick={() => {
                            setEditPartner({ partner: partner, action: 'edit' })
                            setCreatePartner(true)
                          }}
                        />
                        <S.ExcludeIcon
                          onClick={() => {
                            deletePartner(partner.id)
                            setCreatePartner(false)
                          }}
                        />
                      </div>
                    </S.TableData>
                  ))}
                </S.TableBody>
              </S.Table>
            )}
          </>
        ) : (
          <>
            <S.PartnerData>
              <Heading variant="h5">Dados do parceiro</Heading>
              <Input
                name="name"
                label="*Nome"
                placeholder="Digite o nome"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                texterror={formik.errors.name}
                fullWidth
              />
              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={3} desktop={4}>
                  <label className="label">*Tipo de documento</label>
                  <ReactSelect
                    name="typeDocument"
                    placeholder="Selecione"
                    options={options}
                    value={options.filter(
                      (option) => option.label === formik.values.typeDocument
                    )}
                    onChange={(e: any) => {
                      formik.setFieldValue('typeDocument', e.value)
                      formik.setFieldValue('document', '')
                    }}
                    noOptionsMessage={() => 'Tipo não encontrado'}
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.typeDocument &&
                    Boolean(formik.errors.typeDocument)
                      ? formik.errors.typeDocument
                      : ''}
                  </S.Error>
                </Column>
                <Column mobile={4} tablet={5} desktop={8}>
                  <InputMask
                    mask={
                      formik.values.typeDocument === 'CPF'
                        ? '999.999.999-99'
                        : '99.999.999/9999-99'
                    }
                    name="document"
                    label="*Documento"
                    value={formik.values.document}
                    placeholder="Digite o documento"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.document && Boolean(formik.errors.document)
                    }
                    texterror={formik.errors.document}
                    fullWidth
                  />
                </Column>
              </Row>
              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={3} desktop={4}>
                  <InputMask
                    mask={'(99) 99999-9999'}
                    name="phone"
                    value={formik.values.phone}
                    label="*Telefone"
                    placeholder="(00) 00000-0000"
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    texterror={formik.errors.phone}
                    fullWidth
                  />
                </Column>
                <Column mobile={4} tablet={5} desktop={8}>
                  <Input
                    name="email"
                    label="*Email"
                    value={formik.values.email}
                    placeholder="Digite o email"
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    texterror={formik.errors.email}
                    fullWidth
                  />
                </Column>
              </Row>
            </S.PartnerData>

            <S.PartnerAddress>
              <Heading variant="h5">Endereço</Heading>
              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={3} desktop={4}>
                  <InputMask
                    mask="99999-999"
                    name="zipCode"
                    label="*CEP"
                    value={formik.values.zipCode}
                    placeholder="00000-000"
                    onChange={(e) => {
                      formik.handleChange(e)
                      handleCEP(e.target.value)
                    }}
                    error={
                      formik.touched.zipCode && Boolean(formik.errors.zipCode)
                    }
                    texterror={formik.errors.zipCode}
                    fullWidth
                  />
                </Column>
                <Column mobile={4} tablet={5} desktop={8}>
                  <Input
                    name="street"
                    label="*Endereço"
                    value={formik.values.street}
                    placeholder="Digite o endereço"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.street && Boolean(formik.errors.street)
                    }
                    texterror={formik.errors.street}
                    fullWidth
                  />
                </Column>
              </Row>
              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={4} desktop={6}>
                  <label className="label">*Estado</label>
                  <ReactSelect
                    placeholder="Selecione"
                    options={optionsStates}
                    value={
                      optionsStates.filter(
                        (state) => state.value === formik.values.state
                      )[0]
                    }
                    onChange={(e: any) => {
                      setSelectedState(e.label)
                      formik.setFieldValue('state', e.label)
                    }}
                    noOptionsMessage={() => 'Estado não encontrado'}
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.state && Boolean(formik.errors.state)
                      ? formik.errors.state
                      : ''}
                  </S.Error>
                </Column>
                <Column mobile={4} tablet={4} desktop={6}>
                  <label className="label">*Cidade</label>
                  <ReactSelect
                    placeholder="Selecione"
                    options={cities}
                    value={
                      cities?.filter(
                        (city) => city.label === formik.values.city
                      )[0]
                    }
                    onChange={(e: any) => formik.setFieldValue('city', e.label)}
                    noOptionsMessage={() =>
                      selectedState
                        ? 'Cidade não encontrada.'
                        : 'Escolha um estado.'
                    }
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.city && Boolean(formik.errors.city)
                      ? formik.errors.city
                      : ''}
                  </S.Error>
                </Column>
              </Row>

              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={3} desktop={4}>
                  <Input
                    name="number"
                    label="*Número"
                    value={formik.values.number}
                    placeholder="Digite o número"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.number && Boolean(formik.errors.number)
                    }
                    texterror={formik.errors.number}
                    fullWidth
                  />
                </Column>
                <Column mobile={4} tablet={5} desktop={8}>
                  <Input
                    name="complement"
                    label="Complemento"
                    value={formik.values.complement}
                    placeholder="Digite o complemento"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.complement &&
                      Boolean(formik.errors.complement)
                    }
                    texterror={formik.errors.complement}
                    fullWidth
                  />
                </Column>
              </Row>
            </S.PartnerAddress>

            <S.PartnerBankData>
              <Heading variant="h5">Dados bancários</Heading>
              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={3} desktop={5}>
                  <Input
                    name="agency"
                    label="*Agência"
                    disabled={
                      editPartner && editPartner?.partner.status !== 'ACCEPTED'
                    }
                    value={formik.values.agency}
                    placeholder="0000"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.agency && Boolean(formik.errors.agency)
                    }
                    texterror={formik.errors.agency}
                    fullWidth
                  />
                </Column>
                <Column mobile={4} tablet={5} desktop={7}>
                  <label className="label">*Banco</label>
                  <ReactSelect
                    placeholder="Banco"
                    options={mappedBanks}
                    value={
                      mappedBanks.filter(
                        (type) => type.label === formik.values.bank
                      )[0]
                    }
                    isDisabled={
                      editPartner && editPartner?.partner.status !== 'ACCEPTED'
                    }
                    onChange={(e: any) => formik.setFieldValue('bank', e.value)}
                    noOptionsMessage={() => 'Banco não encontrado'}
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.bank && Boolean(formik.errors.bank)
                      ? formik.errors.bank
                      : ''}
                  </S.Error>
                </Column>
              </Row>
              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={3} desktop={5}>
                  <Input
                    name="account"
                    label="*Conta"
                    value={formik.values.account}
                    placeholder="0000"
                    disabled={
                      editPartner && editPartner?.partner.status !== 'ACCEPTED'
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.account && Boolean(formik.errors.account)
                    }
                    texterror={formik.errors.account}
                    fullWidth
                  />
                </Column>
                <Column mobile={4} tablet={5} desktop={7}>
                  <label className="label">*Tipo de conta</label>
                  <ReactSelect
                    placeholder="Tipo de conta"
                    options={typesAccount}
                    value={
                      typesAccount.filter(
                        (type) => type.value === formik.values.typeAccount
                      )[0]
                    }
                    isDisabled={
                      editPartner && editPartner?.partner.status !== 'ACCEPTED'
                    }
                    onChange={(e: any) =>
                      formik.setFieldValue('typeAccount', e.value)
                    }
                    noOptionsMessage={() => 'Tipo de conta não encontrada'}
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.typeAccount &&
                    Boolean(formik.errors.typeAccount)
                      ? formik.errors.typeAccount
                      : ''}
                  </S.Error>
                </Column>
              </Row>
            </S.PartnerBankData>

            <S.ButtonBox>
              <Button
                color="primary"
                size="large"
                variant="text"
                fullWidth={false}
                onClick={() => setCreatePartner(false)}>
                Cancelar
              </Button>
              <Button
                color="primary"
                size="large"
                variant="contained"
                fullWidth={false}
                onClick={formik.submitForm}>
                {editPartner?.action === 'edit' ? 'Editar' : 'Adicionar'}
              </Button>
            </S.ButtonBox>
          </>
        )}
      </S.Container>
    </AuthenticatedTemplate>
  )
}
