/* eslint-disable no-use-before-define */

/* eslint-disable no-unused-vars */
import { SocialLinks } from '../../api/events/updateSocialLinks'

export enum EventActions {
  registerEventRequest = '@event/REGISTER_EVENT_REQUEST',
  registerEventSuccess = '@event/REGISTER_EVENT_SUCCESS',
  addDescription = '@event/ADD_DESCRIPTION',
  addDescriptionSuccess = '@event/ADD_DESCRIPTION_SUCCESS',
  addLocation = '@event/ADD_LOCATION',
  addLocationSuccess = '@event/ADD_LOCATION_SUCCESS',
  createEventSuccess = '@event/CREATE_EVENT_SUCCESS',
  removeEventSuccess = '@event/REMOVE_EVENT_SUCCESS',
  listEventsRequest = '@event/LIST_EVENTS_REQUEST',
  listEventsSuccess = '@event/LIST_EVENTS_SUCCESS',
  createEventItemRequest = '@event/CREATE_EVENT_ITEM_REQUEST',
  createEventItemSuccess = '@event/CREATE_EVENT_ITEM_SUCCESS',
  loadEventItemsRequest = '@event/LOAD_EVENT_ITEM_REQUEST',
  loadEventItemsSuccess = '@event/LOAD_EVENT_ITEM_SUCCESS',
  addTicketSuccess = '@event/ADD_TICKET_SUCCESS',
  addTicket = '@event/ADD_TICKET',
  deleteTicket = '@event/DELETE_TICKET',
  deleteTicketRequest = '@event/DELETE_TICKET_REQUEST',
  deleteTicketSuccess = '@event/DELETE_TICKET_SUCCESS',
  removeEventItemRequest = '@event/REMOVE_EVENT_ITEM_REQUEST',
  removeEventItemSuccess = '@event/REMOVE_EVENT_ITEM_SUCCESS',
  publishEvent = '@event/PUBLISH_EVENT',
  nextStep = '@event/NEXT_STEP',
  stepBack = '@event/STEP_BACK',
  loadEventRequest = '@event/LOAD_EVENT_REQUEST',
  loadEventSuccess = '@event/LOAD_EVENT_SUCCESS',
  editNextStep = '@event/EDIT_NEXT_STEP',
  editStepBack = '@event/EDIT_STEP_BACK',
  editBasicInformationRequest = '@event/EDIT_BASIC_INFORMATION_REQUEST',
  editBasicInformationSuccess = '@event/EDIT_BASIC_INFORMATION_SUCCESS',
  editDescriptionRequest = '@event/EDIT_DESCRIPTION_REQUEST',
  editDescriptionSuccess = '@event/EDIT_DESCRIPTION_SUCCESS',
  editAddressRequest = '@event/EDIT_ADDRESS_REQUEST',
  editAddressSuccess = '@event/EDIT_ADDRESS_SUCCESS',
  editEventItemRequest = '@event/EDIT_EVENT_ITEM_REQUEST',
  editEventItemSuccess = '@event/EDIT_EVENT_ITEM_SUCCESS',
  editPixelsRequest = '@event/EDIT_PIXELS_REQUEST',
  editPixelsSuccess = '@event/EDIT_PIXELS_SUCCESS',
  editSocialLinksSuccess = '@event/SOCIAL_LINKS_SUCCESS',
  editTicketSuccess = '@event/EDIT_TICKET_SUCCESS',
  goToStep = '@event/GO_TO_STEP',
  clearState = '@event/CLEAR_STATE',
  createCouponRequest = '@event/CREATE_COUPON_REQUEST',
  createCouponSuccess = '@event/CREATE_COUPON_SUCCESS',
  editCouponRequest = '@event/EDIT_COUPON_REQUEST',
  editCouponSuccess = '@event/EDIT_COUPON_SUCCESS',
  removeCouponRequest = '@event/REMOVE_COUPON_REQUEST',
  removeCouponSuccess = '@event/REMOVE_COUPON_SUCCESS'
}

export type Coupon = {
  id: string
  name: string
  description: string
  amountOfUse: number
  value: number
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
}

type Pixels = {
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export type Address = {
  name?: string
  country?: string
  state?: string
  city?: string
  street?: string
  number?: string | number
  zipCode?: string
  complement?: string | null
}

export type EventItems = {
  id?: string
  title: string
  description: string
  dates: string[]
  maxCustomerTickets: string
  salesFrom?: string
  finalSales?: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
  tickets: Tickets[]
  eventKey?: string
  ticketQuantity?: number
  ticketsSold?: number
}

export type Tickets = {
  id: string
  name: string
  description: string
  totalPrice?: number
  priceCents: number
  isFree?: boolean
  discountCents: number
  maxCustomerTickets: number
  type: string
  category?: string
  rule: string
  eventItems: EventItems[]
  createdAt: string
}

export type CustomerTicket = {
  id: string
  name: string
  totalPrice: number
  eventItemId: string
  category: string
  labels?: {
    own: string
    parent: string
    section: string
    displayedLabel: string
  }
  seatKey?: string
  ticketInfo?: {
    name: string
    value: string
    isRequired: boolean
  }[]
}

export type CreateEvent = {
  id: string
  name: string
  ageGroup: string
  category: string
  cashBackPercent?: number
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  dates?: string[]
  description: string
  banners: {
    desktopUrl: string | File
    tabletUrl: string | File
    mobileUrl: string | File
    cardUrl: string | File
  }[]
  status?: 'OPEN' | 'CLOSED' | 'DRAFT' | 'CANCELED' | 'SOLD_OUT'
  pixels: null | Pixels
  address?: Address
  createdAt?: string
  updatedAt?: string
  creatorId?: string
  eventItems?: EventItems[]
  tickets?: Tickets[]
  coupons: Coupon[]
  socialLinks: null | SocialLinks
}

export type EditEvent = {
  isPublic: boolean
  id: string
  ageGroup: string
  name?: string
  category?: string
  cashBackPercent?: number
  status: 'OPEN' | 'CLOSED' | 'DRAFT' | 'CANCELED' | 'SOLD_OUT'
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  dates?: string[]
  description: string
  banners?: {
    id?: string
    desktopUrl?: string
    tabletUrl?: string
    mobileUrl?: string
    cardUrl?: string
  }[]
  pixels: null | Pixels
  address: Address
  eventItems?: EventItems[]
  tickets: Tickets[]
  coupons: Coupon[]
  socialLinks: null | SocialLinks
}

export type Event = {
  id?: string
  name: string
  category?: string
  type: 'MAPPED' | 'TABULATED'
  status?: 'OPEN' | 'CLOSED' | 'DRAFT' | 'CANCELED' | 'SOLD_OUT'
  socialLinks: null | SocialLinks
  seatMapId?: string
  dates?: string[]
  description?: string
  banners?: {
    desktopUrl: string | File
    tabletUrl: string | File
    mobileUrl: string | File
    cardUrl: string | File
  }[]
  address?: Address
  createdAt?: string
  updatedAt?: string
  creatorId?: string
  eventItems?: EventItems[]
  tickets?: {
    eventItemId?: string
    ticketId?: string
    ticketQuantity?: number
    ticketsSold?: number
  }[]
  coupon?: Coupon[]
}

export type Steps = {
  name: string
  position: number
  completed: Boolean
  isActive: boolean
}

export type EventState = {
  steps: Steps[]
  manageEvent?: CreateEvent
  editEvent?: EditEvent
  editSteps: Steps[]
  data: Event[]
}
