import styled, { css } from 'styled-components'

// @ts-ignore
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'

export const SeatsioChart = styled(SeatsioSeatingChart)`
  ${({ theme }) => css`
    width: 700px;
    height: 500px;
    margin: ${theme.spacing.stack.xxs} 0;

    @media (max-width: 1170px) {
      width: 100%;
      height: 30%;
    }
  `}
`
