import { useWindowSize } from '../../hooks/useWindowSize'
import { DesktopAppBar } from './Desktop/DesktopAppBar'
import { MobileAppBar } from './Mobile/MobileAppBar'

export const AppBar = () => {
  const windowSize = useWindowSize()

  return windowSize.isTablet || windowSize.isMobile ? (
    <MobileAppBar />
  ) : (
    <DesktopAppBar />
  )
}
