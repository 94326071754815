import * as S from './Unauthenticated.styles'

import MediaMatch from '../mediaMatch/MediaMatch'
import { ReactNode } from 'react'

type UnauthenticatedTemplateProps = {
  children: ReactNode
  image: string
}

export default function UnauthenticatedTemplate({
  children,
  image
}: UnauthenticatedTemplateProps) {
  return (
    <S.Container>
      <MediaMatch greaterThan="large">
        <S.TemplateImage>
          <S.Image src={image} />
        </S.TemplateImage>
      </MediaMatch>
      <S.TemplatePage>{children}</S.TemplatePage>
    </S.Container>
  )
}
