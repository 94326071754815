import { api } from '../config'
import axios, { AxiosResponse } from 'axios'
import { Address, EventItems } from '../../redux/Events/types'
import store from '../../redux/store'
import { clearLoading, setLoading } from '../../redux/Loading/actions'
import { createEventSuccessAction } from '../../redux/Events/actions'
import { setAlertModalAction } from '../../redux/AlertModal/actions'
import { SocialLinks } from './updateSocialLinks'

export type CreateEventRequest = {
  name: string
  description?: string
  category?: string
  cashBackPercent?: number
  ageGroup?: string
  banners?: {
    desktopUrl: string | File
    tabletUrl: string | File
    mobileUrl: string | File
    cardUrl: string | File
  }[]
  type: 'MAPPED' | 'TABULATED'
  seatMapId: string | undefined
}

export type CreateEventResponse = {
  id: string
  name: string
  category?: string
  cashBackPercent?: number
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  dates?: string[]
  description?: string
  banners?: {
    desktopUrl: string | File
    tabletUrl: string | File
    mobileUrl: string | File
    cardUrl: string | File
  }[]
  address?: Address
  createdAt?: string
  updatedAt?: string
  creatorId?: string
  eventItems?: EventItems[]
  socialLinks: SocialLinks | null
}

export const createEventService = async (request: CreateEventRequest) => {
  const { dispatch } = store

  try {
    dispatch(setLoading())
    await api
      .post('/api/events', {
        ...request
      })
      .then(({ data }: AxiosResponse<CreateEventResponse>) =>
        dispatch(createEventSuccessAction(data))
      )

    dispatch(clearLoading())
  } catch (err) {
    if (axios.isAxiosError(err)) {
      switch (err.response?.status) {
        case 409:
          dispatch(
            setAlertModalAction({
              buttonText: 'Fechar',
              open: true,
              title: 'Evento já registrado',
              variant: 'error'
            })
          )
          break
        case 400:
          switch (err.response.data.message) {
            case 'seat map is required':
              dispatch(
                setAlertModalAction({
                  buttonText: 'Fechar',
                  open: true,
                  title: 'Evento já registrado',
                  variant: 'error'
                })
              )
              break
            case 'seat map not allowed for tabulated events':
              dispatch(
                setAlertModalAction({
                  buttonText: 'Fechar',
                  open: true,
                  title: 'Eventos tabelados não possuem mapas de assento',
                  variant: 'error'
                })
              )
              break

            default:
              dispatch(
                setAlertModalAction({
                  buttonText: 'Fechar',
                  open: true,
                  title: 'Falha ao criar evento',
                  variant: 'error'
                })
              )
              break
          }
          break
        default:
          break
      }
    } else {
      dispatch(
        setAlertModalAction({
          buttonText: 'Tentar novamente',
          open: true,
          title: 'Erro interno',
          variant: 'error'
        })
      )
    }

    dispatch(clearLoading())
  }
}
