import styled, { css } from 'styled-components'

import { ReactComponent as BackFile } from 'assets/icons/backIcon.svg'

import { ReactComponent as FacebookFile } from 'assets/icons/facebookPurple.svg'

import { ReactComponent as InstagramFile } from 'assets/icons/instagramPurple.svg'

import { ReactComponent as YoutubeFile } from 'assets/icons/youtubePurple.svg'

import { ReactComponent as TiktokFile } from 'assets/icons/tiktokPurple.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`
export const FacebookIcon = styled(FacebookFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (max-width: 767px) {
      height: 32px;
      width: 32px;
      min-height: 32px;
      min-width: 32px;
      margin-right: 24px;
    }
    @media (min-width: 1366px) {
      min-width: 48px;
      min-height: 48px;
      margin-bottom: 24px;
      margin-right: 24px;
    }
  `}
`

export const InstagramIcon = styled(InstagramFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (max-width: 767px) {
      height: 32px;
      width: 32px;
      margin-right: 24px;
    }
    @media (min-width: 1366px) {
      min-width: 48px;
      min-height: 48px;
      margin-bottom: 24px;
      margin-right: 24px;
    }
  `}
`

export const YoutubeIcon = styled(YoutubeFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (max-width: 767px) {
      height: 32px;
      width: 32px;
      margin-right: 24px;
    }
    @media (min-width: 1366px) {
      min-width: 48px;
      min-height: 36px;
      margin-bottom: 24px;
      margin-right: 24px;
    }
  `}
`

export const TiktokIcon = styled(TiktokFile)`
  ${({ theme }) => css`
    cursor: pointer;
    @media (max-width: 767px) {
      height: 32px;
      width: 32px;
      margin-right: 24px;
    }
    @media (min-width: 1366px) {
      min-width: 48px;
      min-height: 48px;
      margin-bottom: 24px;
      margin-right: 24px;
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs};
    }
    @media (min-width: 1366px) {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
      width: 100%;
    }
  `}
`

export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
  `}
`

export const Subtitle = styled.p`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      margin: ${theme.spacing.stack.xs} 0;
    }

    @media (min-width: 1366px) {
      font-size: 27px;
      font-weight: 600;
      line-height: 41px;
      margin-left: 72px;
      margin-top: ${theme.spacing.stack.sm};
      margin-bottom: ${theme.spacing.stack.xs};
    }
  `}
`

export const Content = styled.form`
  ${({ theme }) => css`
    @media (min-width: 1366px) {
      display: flex;
      flex-direction: column;
      .pixel-input {
        margin-left: 72px;
        width: 420px;
      }
    }
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      > div {
        width: 100%;
      }
    }
    @media (min-width: 1366px) {
      display: flex;
      width: 100%;
      align-items: flex-end;
      > div {
        width: 420px;
      }
    }
  `}
`

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    button:first-of-type {
      background: ${theme.color.base.nth1};
    }
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin-top: ${theme.spacing.stack.lg};
      > button {
        width: 50%;
        padding: 10px 0;
        border-radius: 12px;
        font-weight: 600;
      }
    }

    @media (min-width: 1366px) {
      margin-top: ${theme.spacing.stack.xxl};
      display: flex;
      margin-left: auto;
      gap: 16px;
    }
  `}
`
