import * as S from './ImageSelector.styles'
import { Paragraph } from 'applaus-ui-kit'
import { Dispatch, SetStateAction } from 'react'

type Props = {
  name: string
  selectionText: string
  imagePreview: string
  size: string
  setImage: Dispatch<SetStateAction<string>>
  handleSetField: (name: string, file: File | '') => void
  previewSize: { height: number; width: number }
  error?: { error: boolean; message: string }
  setError: Dispatch<SetStateAction<{ error: boolean; message: string }>>
}

export const ImageSelector = ({
  name,
  selectionText,
  imagePreview,
  size,
  setImage,
  handleSetField,
  previewSize,
  setError,
  error
}: Props) => {
  return (
    <S.Container>
      <div>
        <div>
          <S.ImageLabel htmlFor={name}>
            <Paragraph variant="large" type="semiBold">
              Escolher imagem
            </Paragraph>
          </S.ImageLabel>
          <S.Input
            id={name}
            type="file"
            name={name}
            onChange={(event) => {
              if (event.target.files?.length === 0) {
                setError({
                  error: true,
                  message: '*Escolha um arquivo'
                })
                setImage('')
                handleSetField(name, '')
              } else {
                if (
                  (event.target.files &&
                    event.target.files.length > 0 &&
                    event.target.files[0].type === 'image/png') ||
                  (event.target.files &&
                    event.target.files[0].type === 'image/jpeg')
                ) {
                  setError({ error: false, message: '' })
                  handleSetField(name, event.target.files[0])
                  const reader = new FileReader()
                  reader.readAsDataURL(event.target.files[0])
                  reader.addEventListener('load', () => {
                    if (typeof reader.result === 'string') {
                      setImage(reader.result)
                    }
                  })
                } else {
                  setError({
                    error: true,
                    message: '*O arquivo não atende aos requisitos'
                  })
                  setImage('')
                  handleSetField(name, '')
                }
              }
            }}
          />
          <Paragraph variant="large" type="bold">
            {selectionText}
          </Paragraph>
        </div>

        <Paragraph variant="regular">{size}</Paragraph>

        {error ? <S.Error>{error.message}</S.Error> : null}
      </div>
      {imagePreview !== '' && imagePreview !== undefined ? (
        <S.PreviewSize size={previewSize} src={imagePreview} />
      ) : null}
    </S.Container>
  )
}
