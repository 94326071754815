/* eslint-disable no-unused-vars */
export enum AlertModalActions {
  SET_ALERT_MODAL = '@alertModal/SET_ALERT_MODAL',
  CLEAR_MODAL = '@alertModal/CLEAR_MODAL'
}

export type AlertModal = {
  open: boolean
  title: string
  description?: string
  buttonText: string
  variant: 'error' | 'success'
  click?: () => void
}
