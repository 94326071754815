import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    > div:first-of-type {
      display: flex;
      flex-direction: column;
      margin-right: ${theme.spacing.inline.xxxs};
      > div {
        display: flex;
        flex-direction: column;
      }
    }
    @media (min-width: 1171px) {
      flex-direction: row;
    }
  `}
`

export const ImageLabel = styled.label`
  ${({ theme }) => css`
    cursor: pointer;
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxs};
    background: #dfdfdf;
    width: auto;
    border-radius: ${theme.border.radius.medium};
    text-align: center;
    > p {
      color: #808080;
    }
    @media (max-width: 1170px) {
      margin-bottom: ${theme.spacing.stack.small};
    }
  `}
`

export const Input = styled.input`
  ${({ theme }) => css`
    display: none;
  `}
`

export const Error = styled.p`
  ${({ theme }) => css`
    color: #ff6565;
    font-size: ${theme.font.size.xxs};
  `}
`

export const PreviewSize = styled.img<{
  size: { height: number; width: number }
}>`
  ${({ theme, size }) => css`
    height: ${`${size.height}px`};
    width: ${`${size.width}px`};
    border-radius: ${theme.border.radius.medium};
    @media (max-width: 1170px) {
      margin-top: ${theme.spacing.stack.small};
    }
  `}
`
