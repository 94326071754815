import * as S from './Publishing.styles'
import { stepBack } from '../../../../redux/Events/actions'
import {
  Heading,
  Paragraph,
  RadioButton,
  CheckBox,
  Button
} from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { updateEventItemStatusService } from '../../../../api/events/updateEventStatusService'
import { ReduxState } from '../../../../redux/rootReducer'
import { history } from '../../../../routes/services/history'
import { setAlertModalAction } from '../../../../redux/AlertModal/actions'

export const Publishing = () => {
  const { manageEvent } = useSelector((state: ReduxState) => state.EventReducer)
  const dispatch = useDispatch()
  const [isPublic, setIsPublic] = useState(false)
  const [status, setStatus] = useState<'OPEN' | 'CLOSED'>('OPEN')
  const [terms, setTerms] = useState(false)

  const handlePublish = async () => {
    try {
      if (!terms) {
        dispatch(
          setAlertModalAction({
            title: 'É preciso aceitar os termos de uso para publicar o evento.',
            buttonText: 'Ok',
            open: true,
            variant: 'error',
            description: 'Aceite os termos de uso.'
          })
        )
        return
      }

      await updateEventItemStatusService(manageEvent!.id!, isPublic, status)

      history.push('/events')
    } catch (e: any) {
      if (e.response.data.message === 'account not verified') {
        dispatch(
          setAlertModalAction({
            title: 'Você precisa verificar sua conta bancária',
            buttonText: 'Ok',
            open: true,
            variant: 'error'
          })
        )
      } else if (
        e.response.data.message === 'banners are required for published event'
      ) {
        dispatch(
          setAlertModalAction({
            title: 'O evento precisa ter todos os banners para ser publicado.',
            buttonText: 'Ok',
            open: true,
            variant: 'error'
          })
        )
      } else if (
        e.response.data.message ===
        'description is required for published event'
      ) {
        dispatch(
          setAlertModalAction({
            title: 'O evento precisa ter descrição para ser publicado.',
            buttonText: 'Ok',
            open: true,
            variant: 'error'
          })
        )
      } else if (
        e.response.data.message === 'address are required for published event'
      ) {
        dispatch(
          setAlertModalAction({
            title: 'O evento precisa ter endereço para ser publicado.',
            buttonText: 'Ok',
            open: true,
            variant: 'error'
          })
        )
      }
    }
  }

  return (
    <S.Container>
      <S.TitleBox>
        <S.BackIcon onClick={() => dispatch(stepBack())} />
        <Heading variant="h3">Publicar</Heading>
      </S.TitleBox>
      <Paragraph variant="large" type="semiBold">
        Quem pode ver seu evento?
      </Paragraph>
      <S.PrivacySelection>
        <div className="radioButtonBox">
          <RadioButton
            checked={isPublic}
            readOnly
            onClick={() => setIsPublic(true)}
            className="radioButton"
          />
          <div>
            <Paragraph variant="large" type="semiBold">
              Público
            </Paragraph>
            <Paragraph variant="regular">
              Seu evento ficará publico na plataforma da Applaus, todos usuários
              poderão visualizar seu evento
            </Paragraph>
          </div>
        </div>
        <div className="radioButtonBox">
          <RadioButton
            checked={!isPublic}
            readOnly
            onClick={() => setIsPublic(false)}
            className="radioButton"
          />
          <div>
            <Paragraph variant="large" type="semiBold">
              Privado
            </Paragraph>
            <Paragraph variant="regular">
              Evento para uma audiência exclusiva
            </Paragraph>
          </div>
        </div>
      </S.PrivacySelection>

      <S.ChooseEventStatus>
        <Paragraph variant="large" type="bold">
          Status do evento
        </Paragraph>
        <div className="radioButtonWrapper">
          <RadioButton
            checked={status === 'OPEN'}
            onChange={() => setStatus('OPEN')}
            readOnly
          />
          <div className="options-text">
            <Paragraph variant="large" type="semiBold">
              Aberto
            </Paragraph>
            <Paragraph variant="regular">
              O evento ficará aberto para todos os usuários da plataforma
              Applaus
            </Paragraph>
          </div>
        </div>
        <div className="radioButtonWrapper">
          <RadioButton
            checked={status === 'CLOSED'}
            onChange={() => setStatus('CLOSED')}
            readOnly
          />
          <div className="options-text">
            <Paragraph variant="large" type="semiBold">
              Fechado
            </Paragraph>
            <Paragraph variant="regular">
              O Evento ficará restrito para venda, mas poderá ser exibido caso o
              evento esteja público
            </Paragraph>
          </div>
        </div>
      </S.ChooseEventStatus>

      <Button
        className="show-event"
        variant="text"
        color="primary"
        size="medium"
        onClick={() => window.open(`/eventPreview/${manageEvent!.id}`)}
        fullWidth={false}>
        Pré-visualização <S.LoginIcon />
      </Button>

      <Paragraph variant="large" type="bold">
        RESPONSABILIDADES
      </Paragraph>
      <S.TermsSection>
        <CheckBox
          name="terms"
          checked={terms}
          onClick={() => setTerms((prev) => !prev)}
          readOnly
        />
        <Paragraph variant="small">
          Estou de acordo com os Termos de uso, bem como declaro estar ciente da
          Política de Privacidade, da Política de Cookies e das Obrigatoriedades
          Legais ao publicar este evento.
        </Paragraph>
      </S.TermsSection>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => handlePublish()}
        fullWidth={false}>
        Publicar
      </Button>
    </S.Container>
  )
}
