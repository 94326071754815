import * as Yup from 'yup'
import { messageHelper } from '../../../../../utils/messageHelper'

export const createTicketValidation = Yup.object({
  name: Yup.string()
    .min(1, 'Titulo do ingresso deve ter mínimo 2 caracteres.')
    .max(255, 'Titulo do ingresso deve ter menos do que 128 caracteres.')
    .required(messageHelper.yup.creator.error.nameIsMandatory),
  priceCents: Yup.string()
    .required('Preço é obrigatório')
    .test('priceTest', 'Preço deve ser maior do que 0', (value) => {
      if (value) {
        return Number(value.replace(/[^0-9]/g, '')) >= 0
      }
      return false
    }),
  type: Yup.string()
    .required('Tipo do ingresso é obrigatório.')
    .test('typeVerify', 'Minimo de 3 caracters', (values) => {
      if (values && values.length >= 3) {
        return true
      } else return false
    }),
  rule: Yup.string()
})
