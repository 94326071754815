import { LoadingActions, LoadingState } from './types'
import { Reducer } from 'redux'
import produce from 'immer'

const INITIAL_STATE: LoadingState = {
  isLoading: false
}

export const LoadingReducer: Reducer<LoadingState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case LoadingActions.SET_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = action.payload
      })
    default:
      return state
  }
}
