import { EventProfile } from '../../EventProfile/Mobile/EventProfile'
import { useEffect, useState } from 'react'
import produce from 'immer'
import { ChooseEventItem } from '../../EventProfile/Mobile/ChooseEventItem/ChooseEventItem'
import { MappedEvent } from '../../EventProfile/Mobile/MappedEvent/MappedEvent'
import { MobileTabulatedEvent } from '../../EventProfile/Mobile/TabulatedEvent/MobileTabulatedEvent'

type Props = {
  event: any
}

export type StepState = {
  show: 'showEvent' | 'chooseEventItem' | 'chooseTicket'
  data?: any
  selectedEventItem?: any
}

export const Event = ({ event }: Props) => {
  const [step, setStep] = useState<StepState>({
    show: 'showEvent'
  })

  useEffect(() => {
    setStep(
      produce((draft) => {
        draft.data = {
          ...event,
          ticketPrices: event!.eventItems!.flatMap(
            ({ tickets }: { tickets: any }) =>
              tickets.flatMap(
                ({
                  priceCents,
                  discountCents
                }: {
                  priceCents: number
                  discountCents: number
                }) => (priceCents - discountCents) / 100
              )
          )
        }
      })
    )
  }, [])

  switch (step.show) {
    case 'showEvent':
      return <EventProfile data={step.data} setStep={setStep} />
    case 'chooseEventItem':
      return <ChooseEventItem data={step.data} setStep={setStep} />
    case 'chooseTicket':
      return step.data.type === 'MAPPED' ? (
        <MappedEvent selectedEvent={step.selectedEventItem} setStep={setStep} />
      ) : (
        <MobileTabulatedEvent
          eventItem={step.selectedEventItem}
          setStep={setStep}
        />
      )
    default:
      return <EventProfile data={step.data} setStep={setStep} />
  }
}
