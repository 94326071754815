import * as S from './Session.styles'
import { Heading, Paragraph, Button, AlertModal } from 'applaus-ui-kit'
import { useState } from 'react'
import { CreateSession } from './components/CreateSession/CreateSession'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/rootReducer'
import { removeEventItemRequest } from '../../../../redux/Events/actions'
import {
  editNextStep,
  editStepBack
} from '../../../../redux/Events/editActions'
import { EventItems } from '../../../../redux/Events/types'
import { EditSession } from './components/EditSession/EditSession'

type AlertModalType = {
  open: boolean
  title: string
  description?: string
  variant: 'error' | 'success'
  eventItemId?: string
}

export const Session = () => {
  const [createSession, setCreateSession] = useState(false)
  const [editSession, setEditSession] = useState<{
    open: boolean
    eventItem?: EventItems
  }>({
    open: false
  })
  const [alertModal, setAlertModal] = useState<AlertModalType>({
    open: false,
    title: '',
    variant: 'error'
  })
  const dispatch = useDispatch()
  const { editEvent } = useSelector((state: ReduxState) => state.EventReducer)

  const handleRemoveEventItem = (id: string) => {
    setAlertModal({
      title: 'Deseja deletar a sessão ?',
      open: true,
      eventItemId: id,
      variant: 'error'
    })
  }

  const closeAlertModal = () => {
    setAlertModal({
      open: false,
      title: '',
      variant: 'error'
    })
  }

  return (
    <>
      <S.Container>
        <S.Wrapper>
          {!createSession && !editSession.open ? (
            <>
              <S.TitleBox>
                <S.BackIcon onClick={() => dispatch(editStepBack())} />
                <Heading variant="h3">Sessões</Heading>
              </S.TitleBox>
              <Paragraph variant="large">
                Crie as sessões com as datas e horários do seu evento
              </Paragraph>
              <S.CreateSessionBox>
                <Paragraph variant="large" type="bold">
                  Sessões
                </Paragraph>
                <Button
                  className="add-section"
                  onClick={() => setCreateSession(true)}
                  type="button"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  fullWidth={false}>
                  + Adicionar seção
                </Button>
              </S.CreateSessionBox>
              <S.EventItemsBox>
                <S.EventItemsHeader>
                  <Paragraph variant="regular" type="semiBold">
                    Nome
                  </Paragraph>
                </S.EventItemsHeader>
                {editEvent &&
                  editEvent.eventItems?.map((eventItem) => (
                    <S.EventItemsContent key={eventItem.id}>
                      <Paragraph variant="regular">{eventItem.title}</Paragraph>
                      <S.EditIcon
                        onClick={() =>
                          setEditSession({
                            open: true,
                            eventItem: eventItem
                          })
                        }
                      />
                      <S.TrashIcon
                        onClick={() =>
                          handleRemoveEventItem(eventItem.id ?? '')
                        }
                      />
                    </S.EventItemsContent>
                  ))}
              </S.EventItemsBox>
              <Button
                className="continue-button"
                variant="contained"
                color="primary"
                size="medium"
                fullWidth={false}
                onClick={() => dispatch(editNextStep())}>
                Continuar
              </Button>
            </>
          ) : editSession.open ? (
            <EditSession
              setEditSession={setEditSession}
              eventItem={editSession.eventItem!}
            />
          ) : (
            <CreateSession setCreateSession={setCreateSession} />
          )}
        </S.Wrapper>
      </S.Container>
      <AlertModal
        message={alertModal.title}
        buttonText="Confirmar"
        variant="error"
        click={() => {
          dispatch(removeEventItemRequest(alertModal.eventItemId ?? ''))
          closeAlertModal()
        }}
        cancelClick={closeAlertModal}
        open={alertModal.open}
      />
    </>
  )
}
