import { AxiosResponse } from 'axios'
import { api } from '../config'

export type Ticket = {
  ticketId: string
  eventItemId: string
  seatKey?: string
  holdToken?: string
  ticketInfo?: {
    value: string
    name: string
  }[]
}

export type CourtesyRequest = {
  customerEmail: string
  tickets: Ticket[]
}

export type CourtesyResponse = {
  customerEmail: string
  tickets: Ticket[]
}

export const courtesyService = async (dataRequest: CourtesyRequest) => {
  dataRequest.tickets = dataRequest.tickets.map((ticket) => ({
    ...ticket,
    ticketInfo:
      ticket.ticketInfo?.length === 0
        ? undefined
        : ticket.ticketInfo?.reduce((p, c) => ({ ...p, [c.name]: c.value }), {})
  })) as Ticket[]

  const { data }: AxiosResponse = await api.post(
    '/api/creators/tickets/courtesy',
    dataRequest
  )

  return data
}
