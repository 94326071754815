import * as S from './TabulatedEvent.styles'
import { Heading, Paragraph } from 'applaus-ui-kit'
import { formatPrice } from '../../../utils/formatPrice'
import { useWindowSize } from '../../../hooks/useWindowSize'

type Props = {
  eventItem: any
  setState: () => void
}
export const TabulatedEvent = ({ eventItem, setState }: Props) => {
  const { isMobile } = useWindowSize()

  return (
    <S.Container>
      {isMobile && (
        <div>
          <S.BackIcon onClick={() => setState()} />
          <Paragraph variant="large" type="bold">
            Ingressos
          </Paragraph>
        </div>
      )}
      <Heading variant="h4" className="title">
        Ingressos
      </Heading>
      <S.TicketSection>
        {eventItem &&
          eventItem.tickets.map((ticket: any) => (
            <S.TicketCard key={ticket.id}>
              <div>
                <Paragraph variant="large" type="semiBold">
                  {ticket.name} | {ticket.category} ({ticket.type})
                </Paragraph>
                <Paragraph variant="small" type="normal">
                  {formatPrice(
                    (ticket.priceCents - ticket.discountCents) / 100
                  )}
                </Paragraph>
              </div>
              <S.SelectTickets>
                <button disabled onClick={() => {}}>
                  -
                </button>
                <Paragraph variant="small" type="semiBold">
                  0
                </Paragraph>
                <button onClick={() => {}} disabled>
                  +
                </button>
              </S.SelectTickets>
            </S.TicketCard>
          ))}
      </S.TicketSection>
    </S.Container>
  )
}
