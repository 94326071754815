import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from '../../../../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 1366px;
    width: 100%;
    height: 100%;
    align-self: center;
    > button {
      width: max-content;
      margin-left: auto;
    }
    padding-bottom: ${theme.spacing.stack.sm};
    .dropdown {
      width: max-content;
    }
  `}
`

export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`
