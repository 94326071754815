import * as S from './MobileAppBar.styles'

import logo from '../../../assets/images/logo.png'
import { Paragraph } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../redux/rootReducer'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import useControlScroll from '../../../hooks/useControlScroll'
import { logoutAction } from '../../../redux/User/actions'

export const MobileAppBar = () => {
  const history = useHistory()
  const { name, email, accessToken } = useSelector(
    (state: ReduxState) => state.UserReducer.data
  )
  const [openDrawer, setOpenDrawer] = useState(false)
  const dispatch = useDispatch()

  const buttonClick = (
    route: 'profile' | 'events' | 'scan' | 'bankData' | 'courtesy' | 'partners'
  ) => {
    history.push(`/${route}`)
    setOpenDrawer(false)
  }

  const [blockScroll, allowScroll] = useControlScroll()

  useEffect(() => {
    if (openDrawer) {
      blockScroll()
    } else {
      allowScroll()
    }
    return () => allowScroll()
  }, [openDrawer])

  return (
    <>
      <S.Container>
        {accessToken !== '' ? (
          <S.MenuIcon
            onClick={() => {
              setOpenDrawer(true)
            }}
          />
        ) : (
          <S.UserIcon
            onClick={() => {
              window.location.href = process.env.REACT_APP_AUTH_WEB_URL ?? ''
            }}
          />
        )}

        <S.Logo src={logo} alt="applaus" />
      </S.Container>
      <AnimatePresence>
        {openDrawer && (
          <S.DrawerContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}>
            <S.DrawerContent>
              <S.IconBox
                animate={{ rotate: -90 }}
                transition={{ duration: 0.3 }}>
                <S.DrawerMenuIcon onClick={() => setOpenDrawer(false)} />
              </S.IconBox>

              <S.UserInfoBox>
                <S.FilledProfileIcon />
                <Paragraph variant="large" type="bold">
                  {name}
                </Paragraph>
                <Paragraph
                  variant="regular"
                  type="normal"
                  className="emailText">
                  {email}
                </Paragraph>
              </S.UserInfoBox>
              <S.ButtonBox>
                <S.Button onClick={() => buttonClick('events')}>
                  <S.TicketIcon />
                  <Paragraph variant="large" type="normal">
                    Meus eventos
                  </Paragraph>
                </S.Button>

                <S.Button onClick={() => buttonClick('profile')}>
                  <S.DataIcon />
                  <Paragraph variant="large" type="normal">
                    Meus dados
                  </Paragraph>
                </S.Button>

                <S.Button onClick={() => buttonClick('bankData')}>
                  <S.CreditCardIcon />
                  <Paragraph variant="large" type="normal">
                    Dados Bancários
                  </Paragraph>
                </S.Button>

                <S.Button onClick={() => buttonClick('partners')}>
                  <S.PartnerIcon />
                  <Paragraph variant="large" type="normal">
                    Parceiros
                  </Paragraph>
                </S.Button>

                <S.Button onClick={() => dispatch(logoutAction())}>
                  <S.LogoutIcon />
                  <Paragraph variant="large" type="normal">
                    Sair
                  </Paragraph>
                </S.Button>
              </S.ButtonBox>
            </S.DrawerContent>
          </S.DrawerContainer>
        )}
      </AnimatePresence>
    </>
  )
}
