import { setAlertModalAction } from '../../redux/AlertModal/actions'
import { deleteTicketSuccess } from '../../redux/Events/actions'
import { clearLoading, setLoading } from '../../redux/Loading/actions'
import store from '../../redux/store'
import { api } from '../config'

export const deleteTicketService = async (ticketId: string) => {
  const { dispatch } = store
  try {
    dispatch(setLoading())
    await api.delete(`api/events/eventItems/tickets/${ticketId}`).then(() => {
      dispatch(clearLoading())
      dispatch(deleteTicketSuccess(ticketId))
      dispatch(
        setAlertModalAction({
          open: true,
          variant: 'success',
          title: 'Ticket deletado com sucesso',
          buttonText: 'Ok'
        })
      )
    })
  } catch (err) {
    dispatch(clearLoading())
    dispatch(
      setAlertModalAction({
        open: true,
        variant: 'error',
        title: 'Não foi possível deletar o ticket',
        buttonText: 'Fechar'
      })
    )
  }
}
