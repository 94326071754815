import produce from 'immer'
import { Reducer } from 'redux'
import { UserActions, UserState } from './types'
import { history } from '../../routes/services/history'

const INITIAL_STATE: UserState = {
  data: {
    email: '',
    name: '',
    accessToken: '',
    refreshToken: ''
  }
}

export const UserReducer: Reducer<UserState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case UserActions.setUser:
      return produce(state, () => ({ ...action.payload }))
    case UserActions.refreshToken:
      return produce(state, (draft) => {
        draft.data.accessToken = action.payload.accessToken
        draft.data.refreshToken = action.payload.refreshToken
      })
    case UserActions.getUserInfo:
      return produce(state, (draft) => {
        draft.userInfo = { ...draft.userInfo, ...action.payload }
      })
    case UserActions.editCreatorPaymentSettingSuccess:
      return produce(state, (draft) => {
        draft.userInfo!.creatorPaymentSetting = action.payload
      })
    case UserActions.logout:
      document.cookie = `applaus-auth-creator=; path=/; domain=${
        process.env.REACT_APP_DOMAIN
      }; expires= 
        ${new Date(0).toUTCString()}`
      if (window.matchMedia('(display-mode: standalone)').matches) {
        history.push('/loginCreator')
      } else {
        window.location.href = process.env.REACT_APP_AUTH_URL ?? ''
      }
      return INITIAL_STATE
    default:
      return state
  }
}
