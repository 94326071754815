import { CustomerTicket } from '../../../../redux/Events/types'
import { formatPrice } from '../../../../utils/formatPrice'

import * as S from './TicketCard.styles'

export const TicketCard = ({
  totalPrice,
  labels,
  category,
  name
}: CustomerTicket) => {
  return (
    <S.Container>
      <div>
        <S.TicketCategory>{name}</S.TicketCategory>
        <S.TicketCategory>{category}</S.TicketCategory>
        <S.TicketValue>{formatPrice(totalPrice)}</S.TicketValue>
      </div>
      <div className="priceTotal">
        <S.TicketQuantity>ingresso</S.TicketQuantity>
        <S.PriceTotal>{formatPrice(totalPrice)}</S.PriceTotal>
      </div>
    </S.Container>
  )
}
