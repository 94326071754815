import styled, { css } from 'styled-components'
import { CKEditor } from '@ckeditor/ckeditor5-react'

export const Container = styled.div`
  min-height: 300px;
  > .ck {
    height: 300px;
  }
  > .ck > .ck-editor__main {
    height: 300px;
  }

  > .ck > .ck-editor__main > .ck-editor__editable {
    height: 300px;
  }
`

export const Editor = styled(CKEditor)`
  ${({ theme }) => css`
    :host ::ng-deep .ck-editor__editable_inline {
      min-height: 500px !important;
    }
  `}
`
