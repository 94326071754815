import styled, { css } from 'styled-components'
import { ReactComponent as ClockFile } from '../../../../assets/icons/clock.svg'

export const ClockIcon = styled(ClockFile)`
  ${({ theme }) => css`
    fill: #39383a;
    margin-right: ${theme.spacing.inline.xxxs};
    @media (max-width: 767px) {
      fill: white;
    }
    @media (min-width: 768px) {
      min-height: 40px;
      min-width: 40px;
    }
  `}
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    .textInfo {
      color: white;
    }
  }
`

export const SingleDateBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  `}
`
