import { api } from '../config'

export const updateTicketSectionsService = async (
  ticketId: string,
  requestBody: { eventItemId: string; ticketQuantity: number }[]
) => {
  await api.put(
    `/api/events/eventItems/tickets/${ticketId}/sections`,
    requestBody
  )
}
