import * as S from './MobileBar.styles'
import { Paragraph } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/rootReducer'
import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { goToStepAction } from '../../../../redux/Events/editActions'

export const MobileBar = () => {
  const { editSteps } = useSelector((state: ReduxState) => state.EventReducer)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <S.Container>
        <S.IconBox
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}>
          <S.ExpandIcon onClick={() => setIsOpen((prev) => !prev)} />
        </S.IconBox>
        <Paragraph variant="large" type="bold">
          {editSteps.find((step) => step.isActive)!.name}
        </Paragraph>
      </S.Container>
      <AnimatePresence>
        {isOpen && (
          <S.Steps
            initial={{ height: '0', opacity: 0 }}
            transition={{
              duration: 0.3
            }}
            animate={{ height: 'max-content', opacity: 1 }}
            exit={{ opacity: 0, height: 0 }}>
            {editSteps.map((step) => (
              <S.StepCard
                isActive={step.isActive}
                key={step.name}
                onClick={() => {
                  dispatch(goToStepAction(step.position))
                  setIsOpen(false)
                }}>
                <S.StepPosition isActive={step.isActive} variant="regular">
                  {step.position}
                </S.StepPosition>
                <Paragraph variant="large">{step.name}</Paragraph>
              </S.StepCard>
            ))}
          </S.Steps>
        )}
      </AnimatePresence>
    </>
  )
}
