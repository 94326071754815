import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { ReactComponent as ExpandFile } from '../../../../assets/icons/chevron-down.svg'
import { Paragraph } from 'applaus-ui-kit'

export const IconBox = styled(motion.div)`
  cursor: pointer;
  margin-right: 2rem;
`

export const ExpandIcon = styled(ExpandFile)`
  ${({ theme }) => css`
    fill: #39383a;
    cursor: pointer;
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    background: #e5e5e5;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 320px;
  `}
`

export const Steps = styled(motion.div)``

export const StepCard = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xs};
    background: ${isActive ? '#dfdfdf' : 'transparent'};
    display: flex;
    align-items: center;
    > p:last-of-type {
      color: ${isActive ? '#400058' : '#39383A'};
    }
  `}
`

export const StepPosition = styled(Paragraph)<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    width: ${theme.spacing.stack.xs};
    line-height: ${theme.spacing.stack.xs};
    text-align: center;
    color: white;
    margin-right: 1rem;
    background: ${isActive ? '#400058' : '#5C5C5C'};
    border-radius: ${theme.border.radius.circle};
  `}
`
