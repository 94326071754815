type AgeGroup =
  | 'CLASS_ER'
  | 'CLASS_L'
  | 'CLASS_10'
  | 'CLASS_12'
  | 'CLASS_14'
  | 'CLASS_16'
  | 'CLASS_18'

export const ageGroupHelper = (ageGroup: AgeGroup): string => {
  switch (ageGroup) {
    case 'CLASS_ER':
      return 'Extremamente Recomendado'
    case 'CLASS_L':
      return 'Livre'
    case 'CLASS_10':
      return 'Recomendado para menores de 10 anos'
    case 'CLASS_12':
      return 'Recomendado para menores de 12 anos'
    case 'CLASS_14':
      return 'Recomendado para menores de 14 anos'
    case 'CLASS_16':
      return 'Recomendado para menores de 16 anos'
    case 'CLASS_18':
      return 'Recomendado para menores de 18 anos'
  }
}
