import { api } from '../config'
import { AxiosResponse } from 'axios'
import { Coupon } from '../../redux/Events/types'

export const listCouponsByEventIdService = async (eventId: string) => {
  const { data }: AxiosResponse<Coupon> = await api.get(
    `api/coupons/events/${eventId}`
  )
  return data
}
