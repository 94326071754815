import styled, { css } from 'styled-components'

import { ReactComponent as LocationFile } from '../../../assets/icons/location.svg'
import { ReactComponent as Backfile } from '../../../assets/icons/backIcon.svg'

export const FilledBackIcon = styled(Backfile)`
  position: absolute;
  fill: black;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background: white;
  top: 24px;
  left: 24px;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`

export const LocationIcon = styled(LocationFile)`
  ${({ theme }) => css`
    fill: #39383a;
    margin-right: ${theme.spacing.inline.xxxs};
    @media (min-width: 768px) {
      height: 40px;
      width: 40px;
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    @media (min-width: 1171px) {
      align-items: center;
    }
    padding: 0 ${theme.spacing.stack.xxxl};
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: 0 ${theme.spacing.stack.md};
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    @media (min-width: 1171px) {
      max-width: 1070px;
    }
  `}
`

export const Spacing = styled.div`
  ${({ theme }) => css`
    @media (min-width: 1170px) {
      height: 80px;
    }
  `}
`

export const ImageBox = styled.div`
  display: flex;
  position: relative;
  margin: 3rem 0;
`

export const EventImage = styled.img`
  width: 100%;
  @media (min-width: 1366px) {
    max-height: 600px;
  }
  @media (min-width: 768px) and (max-width: 1170px) {
    align-self: center;
  }
`

export const EventInfo = styled.section`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xs} 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
  `}
`

export const EventData = styled.div`
  ${({ theme }) => css``}
`

export const LocationBox = styled.div`
  display: flex;
  align-items: center;
`

export const EventPricing = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing.stack.md} ${theme.spacing.stack.xxxl};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.border.radius.large};
    > button {
      margin-top: ${theme.spacing.stack.xxs};
      background: ${theme.color.alert.success.nth1};
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.md};
    }
  `}
`

export const EventDetails = styled.div`
  ${({ theme }) => css`
    > h5 {
      font-size: ${theme.font.size.md};
      color: #6c01a3;
    }
    margin-bottom: ${theme.spacing.stack.xl};
  `}
`

export const EventTickets = styled.div`
  ${({ theme }) => css`
    > h4 {
      font-size: ${theme.font.size.md};
      color: #6c01a3;
    }
    margin-bottom: ${theme.spacing.stack.xl};
  `}
`

export const EventLocation = styled.div`
  ${({ theme }) => css`
    > h5 {
      font-size: ${theme.font.size.md};
      color: #6c01a3;
    }
    margin-bottom: ${theme.spacing.stack.xl};
  `}
`

export const LocationIframe = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    border-radius: ${theme.border.radius.large};

    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  `}
`

export const Description = styled.div`
  > p {
    font-size: 2.5rem;
  }
  > h1,
  strong {
    font-size: 3rem;
  }
`

export const TicketBox = styled.section`
  ${({ theme }) => css`
    position: fixed;
    bottom: ${theme.spacing.stack.xxxs};
    left: ${theme.spacing.stack.xxxl};
    right: ${theme.spacing.stack.xxxl};
    border-radius: ${theme.border.radius.large};
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.sm};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    > h4 {
      font-size: ${theme.font.size.md};
      line-height: ${theme.font.size.md};
    }

    > button {
      background: ${theme.color.alert.success.nth1};
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      bottom: ${theme.spacing.stack.md};
      left: ${theme.spacing.stack.md};
      right: ${theme.spacing.stack.md};
    }
  `}
`

export const Dates = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 2rem;
    > h4 {
      font-size: ${theme.font.size.md};
      color: #6c01a3;
    }
    .eventItemsBox {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
      margin: ${theme.spacing.stack.xxs} 0;
    }
  `}
`

export const DatesInfo = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing.inline.nano} ${theme.spacing.inline.xs};
    border-radius: ${theme.border.radius.medium};
    border: 2px solid #8601c9;
    cursor: pointer;
    transition: background ease-in-out 0.5s;
    margin-right: ${theme.spacing.inline.xxxs};
    > p {
      white-space: nowrap;
    }
    ${isActive &&
    css`
      background: #8601c9;
      > p {
        color: white;
      }
    `}
    :hover {
      background: #8601c9;
      > p {
        color: white;
      }
    }
  `}
`

export const Paragraph = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.semiBold};
  `}
`
