import { theme } from 'applaus-ui-kit'
import styled, { css } from 'styled-components'

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    background: '#F4F4F4',
    fontSize: '2rem',
    color: 'gray',
    cursor: 'pointer',
    transition: 'hover 300ms',
    '&:hover': {
      color: 'black'
    }
  }),
  control: (provided: any) => ({
    ...provided,
    marginTop: theme.spacing.stack.xxxs,
    width: 272,
    background: '#F4F4F4',
    fontSize: '2rem',
    border: 0,
    boxShadow: 'none',
    padding: `2px ${theme.spacing.stack.xxxs}`,
    borderRadius: '6px',
    cursor: 'pointer'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '2rem',
    '& input': {
      font: 'inherit'
    }
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '2rem',
    '& input': {
      font: 'inherit',
      fontSize: '2rem'
    }
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '2rem'
  })
}

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    > button {
      margin-top: 1rem;
      margin-left: auto;
    }
    > div {
      width: 100%;
    }

    .radioButtonBox {
      display: flex;
      margin: ${theme.spacing.stack.xxs} 0;

      > div:first-child {
        margin-right: ${theme.spacing.inline.md};
      }
    }

    .inputType {
      span {
        display: none;
      }
    }

    .inputBox {
      display: flex;
      gap: ${theme.spacing.stack.xxxs};
      flex-wrap: wrap;

      @media (max-width: 583px) {
        gap: 0 16px;
      }
    }

    .priceBox {
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing.stack.xxxs};
      @media (max-width: 583px) {
        gap: 0 16px;
      }
    }
  `}
`

export const customStylesType = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Label = styled.p`
  ${({ theme }) => css`
    color: #39383a;
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 24px;
    margin-bottom: 2px;
  `}
`

export const Error = styled.p`
  color: #fc3e3e;
  font-size: 12px;
  min-height: 24px;
`
