import Select from 'react-select'

import * as S from './AgeGroupSelect.styles'

type Props = {
  value?: string
  onChange: (e: { value: string; label: string }) => void
}

const ageGroupOptions = [
  { value: 'CLASS_L', label: 'Livre' },
  { value: 'CLASS_10', label: '10 anos' },
  { value: 'CLASS_12', label: '12 anos' },
  { value: 'CLASS_14', label: '14 anos' },
  { value: 'CLASS_16', label: '16 anos' },
  { value: 'CLASS_18', label: '18 anos' }
]

const renderAgeGroupIcon = (value: string) => {
  switch (value) {
    case 'CLASS_L':
      return <S.AgeFreeIcon />
    case 'CLASS_10':
      return <S.Age10Icon />
    case 'CLASS_12':
      return <S.Age12Icon />
    case 'CLASS_14':
      return <S.Age14Icon />
    case 'CLASS_16':
      return <S.Age16Icon />
    case 'CLASS_18':
      return <S.Age18Icon />
    default:
      return <S.AgeFreeIcon />
  }
}

const Option = (props: any) => {
  const { innerRef, innerProps, ...data } = props
  return (
    <S.Option ref={innerRef} {...innerProps}>
      <span>{data.label}</span>
      {renderAgeGroupIcon(data.value)}
    </S.Option>
  )
}

// eslint-disable-next-line no-unused-vars
const CustomInput = (props: any) => {
  const { selectProps } = props
  if (selectProps.value && selectProps.value[0]) {
    const { label, value } = selectProps.value[0]
    return (
      <S.Option>
        <span>{value}x de</span>
        <p>{label}</p>
      </S.Option>
    )
  } else {
    return <></>
  }
}

export const AgeGroupSelect = ({ value, onChange }: Props) => {
  return (
    <Select
      placeholder="Selecione uma opção"
      options={ageGroupOptions}
      onChange={(e) => (e ? onChange(e) : undefined)}
      styles={S.customStylesCategory}
      components={{ Option }}
      value={
        value
          ? ageGroupOptions.find((option) => option.value === value)
          : undefined
      }
    />
  )
}
