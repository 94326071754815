import { EventItems } from '../../redux/Events/types'
import { api } from '../config'
import * as _ from 'lodash'
import moment from 'moment'
import { AxiosResponse } from 'axios'

export const editEventItemService = async (
  eventItemId: string,
  editEventItem: any,
  eventITem: EventItems
) => {
  if (_.isEqual(eventITem.ticketInfo, editEventItem.ticketInfo)) {
    delete editEventItem.ticketInfo
  }
  if (
    _.isEqual(eventITem.maxCustomerTickets, editEventItem.maxCustomerTickets)
  ) {
    delete editEventItem.maxCustomerTickets
  }
  if (_.isEqual(eventITem.title, editEventItem.title)) {
    delete editEventItem.title
  }
  if (_.isEqual(eventITem.description, editEventItem.description)) {
    delete editEventItem.description
  }
  if (
    _.isEqual(
      moment(moment(eventITem.salesFrom).format('YYYY-MM-DD[T]HH:mm')).utc(),
      editEventItem.salesFrom
    )
  ) {
    delete editEventItem.salesFrom
  }
  if (
    _.isEqual(
      moment(moment(eventITem.finalSales).format('YYYY-MM-DD[T]HH:mm')).utc(),
      editEventItem.finalSales
    )
  ) {
    delete editEventItem.finalSales
  }
  if (
    _.isEqual(
      eventITem.dates.map((date) => moment(date).toDate()),
      editEventItem.dates
    )
  ) {
    delete editEventItem.dates
  }

  if (!_.isEmpty(editEventItem)) {
    const { data }: AxiosResponse<EventItems> = await api.put(
      `/api/events/eventItems/${eventItemId}`,
      editEventItem
    )

    return data
  }
}
