import * as S from './MobileBar.styles'
import { Paragraph } from 'applaus-ui-kit'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../../../redux/rootReducer'
import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

export const MobileBar = () => {
  const { steps } = useSelector((state: ReduxState) => state.EventReducer)
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <S.Container>
        <S.IconBox
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}>
          <S.ExpandIcon onClick={() => setIsOpen((prev) => !prev)} />
        </S.IconBox>
        <Paragraph variant="large" type="bold">
          {steps.find((step) => step.isActive)!.name}
        </Paragraph>
      </S.Container>
      <AnimatePresence>
        {isOpen && (
          <S.Steps
            initial={{ height: '0', opacity: 0 }}
            transition={{
              duration: 0.3
            }}
            animate={{ height: 'max-content', opacity: 1 }}
            exit={{ opacity: 0, height: 0 }}>
            {steps.map((step) => (
              <S.StepCard isActive={step.isActive} key={step.name}>
                <S.StepPosition isActive={step.isActive} variant="regular">
                  {step.position}
                </S.StepPosition>
                <Paragraph variant="large">{step.name}</Paragraph>
              </S.StepCard>
            ))}
          </S.Steps>
        )}
      </AnimatePresence>
    </>
  )
}
