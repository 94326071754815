import { Button, Input } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import * as yup from 'yup'
import TemplateImage from '../../assets/templateImage.png'
import * as S from './LoginCreatorPwa.styles'
import UnauthenticatedTemplate from '../../components/unauthenticatedTemplate/Unauthenticated'
import { authService } from '../../api/user/authService'
import { decode } from 'jsonwebtoken'

import logo from '../../assets/logo.png'
import { SocialLogin } from './components/socialLogin/SocialLogin'
import { sleep } from '../../utils/sleep'
import { useDispatch } from 'react-redux'
import { setModalState } from '../../redux/Modal/Actions/actionsModal'
import { clearLoading, setLoading } from '../../redux/Loading/actions'
import { getUserInfoRequest, setUserAction } from '../../redux/User/actions'
import { history } from '../../routes/services/history'
import { useEffect } from 'react'
import { clearModalAction } from '../../redux/AlertModal/actions'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Insira um email válido')
    .required('Email é obrigatório'),
  password: yup.string().required('Senha é obrigatória')
})

export const LoginCreatorPwa = () => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch(setLoading())
        const data = await authService({
          email: values.email,
          password: values.password
        })

        const decoded = decode(data.accessToken)

        if (decoded && typeof decoded !== 'string') {
          switch (decoded.type) {
            case 'CUSTOMER':
              dispatch(
                setModalState({
                  open: true,
                  message:
                    'Você tentou acessar o aplicativo do Produtor com uma conta de Cliente',
                  buttonText: 'Fechar',
                  variant: 'error'
                })
              )
              break

            case 'CREATOR':
              dispatch(
                setUserAction({
                  data: {
                    name: decoded.name,
                    email: decoded.email,
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                  }
                })
              )
              dispatch(getUserInfoRequest())
              history.push('/')
              break

            default:
              break
          }
        }
        await sleep(3000)
        dispatch(clearLoading())
      } catch (err) {
        await sleep(3000)
        dispatch(clearLoading())
        dispatch(
          setModalState({
            buttonText: 'Tentar novamente',
            message: 'Não foi possivel realizar o login',
            open: true,
            variant: 'error'
          })
        )
      }
    }
  })

  useEffect(() => {
    dispatch(clearModalAction())
  }, [])

  return (
    <UnauthenticatedTemplate image={TemplateImage}>
      <S.Container>
        <S.Form
          onSubmit={formik.handleSubmit}
          initial={{ opacity: 0, x: '-20%' }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1, x: 0 }}>
          <S.Logo src={logo} />
          <Input
            name="email"
            label="Email"
            placeholder="Digite seu email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            texterror={formik.errors.email}
            onChange={formik.handleChange}
            value={formik.values.email}
            fullWidth
          />
          <Input
            name="password"
            label="Senha"
            placeholder="Digite sua senha"
            type="password"
            error={formik.touched.password && Boolean(formik.errors.password)}
            texterror={formik.errors.password}
            onChange={formik.handleChange}
            value={formik.values.password}
            fullWidth
          />
          <Button
            size="small"
            color="primary"
            variant="contained"
            type="submit"
            fullWidth>
            Login
          </Button>
          <SocialLogin />
          <S.Wrapper>
            <S.Link
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AUTH_URL}/forgotpassword`,
                  '_blank'
                )
              }>
              Esqueci minha senha
            </S.Link>
            <S.SignupText>
              Não tem conta ainda?
              <S.Link
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_AUTH_URL}/register`,
                    '_blank'
                  )
                }>
                {'  '}Inscreva-se
              </S.Link>
            </S.SignupText>
          </S.Wrapper>
        </S.Form>
      </S.Container>
    </UnauthenticatedTemplate>
  )
}
