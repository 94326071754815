import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { ReactComponent as ChevronDownFile } from '../../../../assets/icons/chevron-down.svg'

export const ChevronIcon = styled(ChevronDownFile)`
  ${({ theme }) => css`
    fill: gray;
    transition: 0.3s ease;
    width: 34px;
    height: 34px;
    padding: ${theme.spacing.stack.small};
    &:hover {
      fill: black;
      transform: translateY(10%) scale(1.2);
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing.stack.small} 0;
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};

    @media (min-width: 768px) {
      padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    }
    .module-title {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      margin: 0;
    }
  `}
`

export const IconBox = styled(motion.div)`
  cursor: pointer;
`

export const AccordionInfo = styled(motion.div)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxs};
  `}
`
