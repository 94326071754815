import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'redux/rootReducer'
import { AppBar } from 'components/AppBar/AppBar'

import * as S from './CreateEvent.styles'
import { Paragraph } from 'applaus-ui-kit'
import { useEffect, useMemo } from 'react'
import { RegisterEvent } from './Components/RegisterEvent/RegisterEvent'
import { EventDescription } from './Components/EventDescription/EventDescription'
import { EventAddress } from './Components/EventAddress/EventAddress'
import { Session } from './Components/Session/Session'
import { Tickets } from './Components/Tickets/Tickets'
import { useWindowSize } from 'hooks/useWindowSize'
import { MobileBar } from './Components/Mobile/MobileBar'
import { Publishing } from './Components/Publishing/Publishing'
import { useParams } from 'react-router-dom'
import { listOneEventService } from 'api/events/listOneEventService'
import {
  editNextStep,
  editStepBack,
  goToStepAction,
  loadEventSuccesstAction
} from '../../redux/Events/editActions'
import { clearLoading, setLoading } from '../../redux/Loading/actions'
import { setAlertModalAction } from '../../redux/AlertModal/actions'
import { history } from '../../routes/services/history'
import { EventPreview } from '../EventPreview/EventPreview'
import { EventMarketing } from '../../components/templates/EventMarketing/EventMarketing'
import {
  createCouponRequestAction,
  editCouponRequestAction,
  editPixelsRequestAction,
  removeCouponRequestAction
} from '../../redux/Events/actions'
import { EventCoupons } from 'components/templates/EventCoupons/EventCoupons'
import { EditEvent as EditEventType } from '../../redux/Events/types'

type FormValues = {
  name: string
  description: string
  amountOfUse: number
  value: number | string
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
  type: 'limited' | 'unlimited'
}

type Links = {
  facebookLink: string
  youtubeLink: string
  instagramLink: string
  tiktokLink: string
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export const EditEvent = () => {
  const { editSteps, editEvent } = useSelector(
    (state: ReduxState) => state.EventReducer
  )
  const dispatch = useDispatch()
  const { isDesktop, isMobile } = useWindowSize()
  const { id }: { id: string } = useParams()

  const handleStepBack = () => {
    dispatch(editStepBack())
  }

  const handleNextStep = () => {
    dispatch(editNextStep())
  }

  const handleMarketingSubmit = (values: Links) => {
    dispatch(editPixelsRequestAction(values))
  }

  const handleCreateCoupon = (values: FormValues) => {
    dispatch(createCouponRequestAction(values))
  }

  const handleDeleteCoupon = (couponId: string) => {
    dispatch(removeCouponRequestAction(couponId))
  }

  const handleEditCoupon = (values: {
    id: string
    name: string
    description: string
    amountOfUse: number
    value: string
    valueType: 'PERCENT' | 'CENTS'
    quantity?: number
    startValidateDate?: string
    finalValidateDate?: string
  }) => {
    dispatch(editCouponRequestAction(values))
  }

  const renderPages = useMemo(() => {
    const activeStep = editSteps.findIndex((step) => step.isActive)
    switch (activeStep) {
      case 0:
        return <RegisterEvent />
      case 1:
        return <EventDescription />
      case 2:
        return <EventAddress />
      case 3:
        return <Session />
      case 4:
        return <Tickets />
      case 5:
        return (
          <EventCoupons
            handleClickBack={handleStepBack}
            handleClickNextStep={handleNextStep}
            handleCreateCoupon={handleCreateCoupon}
            handleEditCoupon={handleEditCoupon}
            handleDeleteCoupon={handleDeleteCoupon}
            coupons={editEvent?.coupons ?? []}
          />
        )
      case 6:
        return (
          <EventMarketing
            handleNextStep={handleNextStep}
            handleClickBack={handleStepBack}
            handleSubmit={handleMarketingSubmit}
            initialValues={{
              facebookLink: editEvent?.socialLinks?.facebookLink ?? '',
              instagramLink: editEvent?.socialLinks?.instagramLink ?? '',
              tiktokLink: editEvent?.socialLinks?.tiktokLink ?? '',
              youtubeLink: editEvent?.socialLinks?.youtubeLink ?? '',
              facebookpixel: editEvent?.pixels?.facebookpixel ?? '',
              googletagmanagerpixel:
                editEvent?.pixels?.googletagmanagerpixel ?? '',
              googleanalyticspixel:
                editEvent?.pixels?.googleanalyticspixel ?? ''
            }}
          />
        )
      case 7:
        return <Publishing />

      case 8:
        return <EventPreview />
      default:
        return <RegisterEvent />
    }
  }, [editSteps, isMobile, editEvent])

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        dispatch(setLoading())
        const data = await listOneEventService(id)
        dispatch(loadEventSuccesstAction(data as EditEventType))
        dispatch(clearLoading())
      } catch (err) {
        dispatch(clearLoading())
        dispatch(
          setAlertModalAction({
            open: true,
            title: 'Evento não encontrado',
            buttonText: 'Fechar',
            variant: 'error'
          })
        )
        history.push('/events')
      }
    }
    fetchEvent()
  }, [])

  return (
    <S.Container>
      <AppBar />
      {editSteps.findIndex((step) => step.isActive) !== 8 ? (
        <>
          {editEvent ? (
            <S.Wrapper>
              {!isDesktop ? (
                <MobileBar />
              ) : (
                <S.Steps>
                  {editSteps.map(({ name, position, completed, isActive }) => (
                    <S.StepContent
                      key={name}
                      isActive={isActive}
                      onClick={() => dispatch(goToStepAction(position))}>
                      <S.StepPosition
                        isActive={isActive}
                        variant="regular"
                        type="semiBold">
                        {position}
                      </S.StepPosition>
                      <Paragraph variant="regular">{name}</Paragraph>
                    </S.StepContent>
                  ))}
                </S.Steps>
              )}
              {renderPages}
            </S.Wrapper>
          ) : undefined}
        </>
      ) : (
        renderPages
      )}
    </S.Container>
  )
}
