import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from '../../../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`

export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`
export const Container = styled.div`
  width: 100%;
  overflow: auto;
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-width: 320px;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    > button {
      align-self: end;
    }
    .continueButton {
      margin-top: auto;
    }
    @media (max-width: 1170px) {
      padding: 3rem;
      height: max-content;
    }
  `}
`

export const CreateTicketBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${theme.spacing.stack.xxxs} 0;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `}
`
