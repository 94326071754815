import { AxiosResponse } from 'axios'
import { Address, EventItems } from '../../redux/Events/types'
import { api } from '../config'
import { listCouponsByEventIdService } from './listCouponsByEventIdService'
import { SocialLinks } from './updateSocialLinks'

export type Ticket = {
  id: string
  name: string
  description: string
  priceCents: number
  discountCents: number
  maxCustomerTickets: number
  type: string
  category: string
  rule: string
  eventItems: EventItems[]
  createdAt: string
  ticketsSold: number
}

export type EventItem = {
  id: string
  title: string
  description: string
  dates: string[]
  maxCustomerTickets: string
  salesFrom?: string
  finalSales?: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
  tickets: Ticket[]
  eventKey?: string
}

export type BalanceWithdrawals = {
  comments: null
  createdAt: string
  creator: null
  creatorId: null
  eventId: string
  eventPartner: {
    bank: string
    bankAccountType: string
    bankAg: string
    bankCc: string
    createdAt: string
    creatorId: string
    document: string
    documentType: string
    email: string
    id: string
    liveApiToken: string
    name: string
    phoneNumber: string
    status: string
    subAccountId: string
    testApiToken: string
    updatedAt: string
    userToken: string
  }
  eventPartnerId: string
  id: string
  status: string
  updatedAt: null
  valueCents: number
}

export type Partners = {
  eventId: string
  eventPartner: {
    bank: string
    bankAccountType: string
    bankAg: string
    bankCc: string
    createdAt: string
    creatorId: string
    document: string
    documentType: string
    email: string
    id: string
    liveApiToken: string
    name: string
    phoneNumber: string
    status: string
    subAccountId: string
    testApiToken: string
    updatedAt: string
    userToken: string
  }
  eventPartnerId: string
  revenuePercent: number
}

export type EventResponse = {
  id: string
  name: string
  ageGroup:
    | 'CLASS_ER'
    | 'CLASS_L'
    | 'CLASS_10'
    | 'CLASS_12'
    | 'CLASS_14'
    | 'CLASS_16'
    | 'CLASS_18'
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  dates: string[]
  description: string
  status: 'OPEN' | 'CLOSED'
  banners: {
    desktopUrl: string | File
    tabletUrl: string | File
    mobileUrl: string | File
    cardUrl: string | File
  }[]
  address: Address
  createdAt: string
  updatedAt: string | null
  creatorId: string
  eventItems: EventItem[]
  balanceWithdrawals: BalanceWithdrawals[]
  socialLinks: SocialLinks | null
}

export const listEventService = async (eventId: string) => {
  const couponData = await listCouponsByEventIdService(eventId)
  const { data }: AxiosResponse<EventResponse> = await api.get(
    `/api/creators/events/${eventId}`
  )

  return { ...data, coupons: couponData }
}
