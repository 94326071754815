import { AxiosResponse } from 'axios'
import { api } from '../config'

export const deletePartnerService = async (partnerId: string) => {
  const { status }: AxiosResponse = await api.delete(
    `/api/creators/partners/${partnerId}`
  )

  return status
}
