import * as S from './Profile.styles'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editUserRequest } from '../../redux/User/actions'
import { Heading, Button, Input, Paragraph, InputMask } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { ReduxState } from '../../redux/rootReducer'
import {
  validationSchemaCompany,
  validationSchemaPersonal
} from './validationSchema'
import jsonStates from '../../utils/estados-cidades.json'
import { viaCepService } from '../../api/viaCep/viaCepService'
import { UpdatepasswordModal } from './UpdatePasswordModal/UpdatePasswordModal'
import ReactSelect from 'react-select'

export const Profile = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(
    (state: ReduxState) => state.UserReducer.userInfo
  )
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: userInfo?.firstName ?? '',
      lastName: userInfo?.lastName ?? '',
      email: userInfo?.email ?? '',
      address: {
        city: userInfo?.address?.city ?? '',
        complement: userInfo?.address?.complement ?? '',
        country: userInfo?.address?.country ?? '',
        number:
          userInfo?.address?.number !== null
            ? String(userInfo?.address?.number)
            : '',
        state: userInfo?.address?.state ?? '',
        street: userInfo?.address?.street ?? '',
        zipCode: userInfo?.address?.zipCode ?? '',

        district: userInfo?.address?.district ?? ''
      },
      corporateName: userInfo?.corporateName ?? '',
      document: userInfo?.document ?? '',
      phone: userInfo?.phone ?? ''
    },
    validationSchema:
      userInfo?.documentType === 'CNPJ'
        ? validationSchemaCompany
        : validationSchemaPersonal,
    onSubmit: (values) => {
      const phoneFormatted = values.phone.replace(/[^0-9]/g, '')
      const documentFormatted = values.document.replace(/[^0-9]/g, '')
      dispatch(
        editUserRequest({
          ...values,
          phone: phoneFormatted,
          document: documentFormatted
        })
      )
    }
  })

  const [state] = useState(
    jsonStates.estados.map((state) => ({
      value: state.sigla,
      label: state.nome
    }))
  )

  const [cities, setCities] = useState<{ value: string; label: string }[]>(
    jsonStates.estados
      .find(({ sigla }) => sigla === formik.values.address.state)
      ?.cidades.map((city) => ({ value: city, label: city })) ?? []
  )

  useEffect(() => {
    if (userInfo) {
      formik.setValues({
        email: userInfo.email,
        phone: userInfo.phone,
        lastName: userInfo.lastName,
        corporateName: userInfo.corporateName ?? '',
        firstName: userInfo.firstName,
        document: userInfo.document,
        address: {
          district: userInfo.address?.district ?? '',
          city: userInfo.address?.city ?? '',
          zipCode: userInfo.address?.zipCode ?? '',
          complement: userInfo.address?.complement ?? '',
          country: userInfo.address?.country ?? 'BR',
          number:
            userInfo.address?.number !== null
              ? String(userInfo.address?.number)
              : '',
          street: userInfo.address?.street ?? '',
          state: userInfo.address?.state ?? ''
        }
      })
    }
  }, [userInfo])

  const handleCep = async (e: ChangeEvent<HTMLInputElement>) => {
    const cep = e.target.value.replace(/[^0-9]/g, '')
    try {
      if (cep.length === 8) {
        const { complemento, uf, logradouro, localidade, erro } =
          await viaCepService(e.target.value)
        if (!erro) {
          await formik.setFieldValue('address.state', uf)
          await formik.setFieldValue('address.city', localidade)
          await formik.setFieldValue('address.complement', complemento)
          await formik.setFieldValue('address.city', localidade)
          await formik.setFieldValue('address.street', logradouro)
          await formik.setFieldValue('address.country', 'BR')
        }
      }
    } catch (e) {}
  }

  const handleOpenUpdatePasswordModal = () => {
    setUpdatePasswordModal(true)
  }

  const handleCloseUpdatePasswordModal = () => {
    setUpdatePasswordModal(false)
  }

  useEffect(() => {
    if (formik.values.address.state !== '') {
      const stateIndex = jsonStates.estados.findIndex(
        (state) => state.sigla === formik.values.address.state
      )
      if (stateIndex !== -1) {
        setCities(
          jsonStates.estados[stateIndex].cidades.map((city) => ({
            value: city,
            label: city
          }))
        )
      }
    }
  }, [formik.values.address.state])

  return (
    <AuthenticatedTemplate isActive={'profile'}>
      <S.Container>
        <S.Wrapper onSubmit={formik.handleSubmit}>
          <Heading variant="h3">Meus dados</Heading>
          <div className="splitInput">
            <Input
              name="firstName"
              label="Nome"
              value={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              texterror={formik.errors.firstName}
              onChange={formik.handleChange}
              fullWidth
            />
            <Input
              name="lastName"
              label="Sobrenome"
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              texterror={formik.errors.lastName}
              onChange={formik.handleChange}
              fullWidth
            />
          </div>
          <div className="splitInput">
            <Input
              name="email"
              label="Email"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              texterror={formik.errors.email}
              onChange={formik.handleChange}
              fullWidth
            />
            <InputMask
              label="Telefone"
              mask="(99) 9 9999-9999"
              name="phone"
              value={formik.values.phone}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              texterror={formik.errors.phone}
              onChange={formik.handleChange}
              fullWidth
            />
          </div>
          <div>
            {userInfo?.documentType === 'CNPJ' ? (
              <Input
                name="corporateName"
                value={formik.values.corporateName}
                error={
                  formik.touched.corporateName &&
                  Boolean(formik.errors.corporateName)
                }
                texterror={formik.errors.corporateName}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : undefined}
            <InputMask
              name="document"
              mask={
                userInfo?.documentType === 'CNPJ'
                  ? '99.999.999/9999-99'
                  : '999.999.999-99'
              }
              label={userInfo?.documentType === 'CNPJ' ? 'CNPJ' : 'CPF'}
              value={formik.values.document}
              error={formik.touched.document && Boolean(formik.errors.document)}
              texterror={formik.errors.document}
              onChange={formik.handleChange}
              fullWidth
            />
            <S.UpdatePasswordBox onClick={handleOpenUpdatePasswordModal}>
              <S.LockIcon />
              <Paragraph variant="regular" type="semiBold">
                Alterar senha
              </Paragraph>
            </S.UpdatePasswordBox>
          </div>
          <Heading variant="h3">MEU ENDEREÇO</Heading>
          <div className="twoInputs">
            <InputMask
              name="address.zipCode"
              mask={'99999-999'}
              label="CEP"
              value={formik.values.address.zipCode}
              error={
                formik.touched.address?.zipCode &&
                Boolean(formik.errors.address?.zipCode)
              }
              texterror={formik.errors.address?.zipCode}
              onChange={(e) => {
                handleCep(e)
                formik.setFieldValue('address.zipCode', e.target.value)
              }}
              fullWidth
            />
            <Input
              name="address.street"
              label="Endereço"
              value={formik.values.address.street}
              error={
                formik.touched.address?.street &&
                Boolean(formik.errors.address?.street)
              }
              texterror={formik.errors.address?.street}
              onChange={(e) =>
                formik.setFieldValue('address.street', e.target.value)
              }
              fullWidth
            />
          </div>
          <div className="splitInput">
            <div>
              <S.Label>*Estado</S.Label>
              <ReactSelect
                onChange={(selectValue) => {
                  if (selectValue && selectValue.value) {
                    formik.setFieldValue('address.state', selectValue.value)
                    formik.setFieldValue('address.city', '')
                  }
                }}
                value={
                  state.find(
                    (option) => option.value === formik.values.address.state
                  ) ?? {
                    value: '',
                    label: ''
                  }
                }
                placeholder="Estado"
                styles={S.customStyles}
                options={state}
                noOptionsMessage={() => 'Cidade não encontrada'}
              />
            </div>
            <div>
              <S.Label>*Cidade</S.Label>
              <ReactSelect
                value={
                  cities.find(
                    (option) => option.value === formik.values.address.city
                  ) ?? {
                    value: '',
                    label: ''
                  }
                }
                onChange={(selectValue) => {
                  if (selectValue && selectValue.value) {
                    formik.setFieldValue('address.city', selectValue.value)
                  }
                }}
                placeholder="Cidade"
                styles={S.customStyles}
                options={cities}
                noOptionsMessage={() => 'Cidade não encontrada'}
              />
              <S.Error>
                {formik.touched.address?.city &&
                Boolean(formik.errors.address?.city)
                  ? formik.errors.address?.city
                  : null}
              </S.Error>
            </div>
          </div>
          <div className="splitInput">
            <Input
              name="address.number"
              label="Número"
              type="number"
              value={formik.values.address.number}
              error={
                formik.touched.address?.number &&
                Boolean(formik.errors.address?.number)
              }
              texterror={formik.errors.address?.number}
              onChange={(e) =>
                formik.setFieldValue('address.number', e.target.value)
              }
              fullWidth
            />
            <Input
              className="district"
              name="address.district"
              label="Bairro"
              value={formik.values.address.district}
              error={
                formik.touched.address?.district &&
                Boolean(formik.errors.address?.district)
              }
              fullWidth
              texterror={formik.errors.address?.district}
              onChange={(e) =>
                formik.setFieldValue('address.district', e.target.value)
              }
            />
          </div>
          <div className="twoInputs">
            <Input
              className="complement"
              name="address.complement"
              label="Complemento"
              value={formik.values.address.complement}
              error={
                formik.touched.address?.complement &&
                Boolean(formik.errors.address?.complement)
              }
              texterror={formik.errors.address?.complement}
              onChange={(e) =>
                formik.setFieldValue('address.complement', e.target.value)
              }
              fullWidth
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth={false}
            type="submit">
            Alterar Dados
          </Button>
        </S.Wrapper>
        <UpdatepasswordModal
          isOpen={updatePasswordModal}
          closeModal={handleCloseUpdatePasswordModal}
        />
      </S.Container>
    </AuthenticatedTemplate>
  )
}
