import styled, { css } from 'styled-components'

import { ReactComponent as CloseFile } from '../../../../assets/icons/times-square.svg'

export const CloseIcon = styled(CloseFile)`
  ${({ theme }) => css`
    cursor: pointer;
    position: relative;
    bottom: 20px;
    left: 95%;
    width: 32px;
    @media (max-width: 767px) {
      top: 0;
      left: 90%;
    }
  `}
`

export const Container = styled.div<{ open: boolean }>`
  ${({ theme, open }) => css`
    min-width: 320px;
    min-height: 100%;
    width: 100%;
    height: 100%;
    display: ${open ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    background: ${theme.color.modal.nth1};
    position: fixed;
    top: 0;
    left: 0;

    .title {
      width: 418px;
      text-align: center;
      margin-bottom: ${theme.spacing.stack.xxs};
      margin-top: -50px;
    }

    h5 {
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.sm};
      font-weight: ${theme.font.weight.semiBold};
    }

    .purpleColor {
      color: ${theme.color.brand.primary.nth2};
    }

    .button {
      display: flex;
      justify-content: flex-end;
      margin-top: ${theme.spacing.stack.xl};
    }

    @media (min-width: 767px) {
      .title {
        width: 300px;
        text-align: center;
        margin-bottom: ${theme.spacing.stack.xxs};
      }
    }

    @media (max-width: 767px) {
      .title {
        width: 100%;
        text-align: center;
        margin-bottom: ${theme.spacing.stack.xxs};
        margin-top: 0;
      }
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    background: ${theme.color.neutral.nth4};
    padding: ${theme.spacing.stack.xxs};
    border-radius: 12px;

    .totalBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid ${theme.color.brand.primary.nth2};
      padding: 0 0 ${theme.spacing.stack.xxxs} 0;
    }

    .partner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: ${theme.spacing.stack.xxxs};
      .regular {
        font-weight: ${theme.font.weight.regular};
        width: 250px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .regularPercent {
        font-weight: ${theme.font.weight.regular};
        width: 50px;
      }
      .regularPrice {
        font-weight: ${theme.font.weight.regular};
        align-self: end;
      }

      > h5:nth-child(1),
      h5:nth-child(2) {
        margin-right: ${theme.spacing.stack.xxxs};
      }
    }

    @media (max-width: 767px) {
      margin: 0;
      width: 100%;
      height: 100%;
      border-radius: 0px;
      padding: ${theme.spacing.stack.xxs};

      .totalBox {
        padding: 0;
      }
    }
  `}
`
