import CurrencyInput from 'react-currency-input-field'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  .heightInput {
    > input {
      width: 244px;
    }
  }

  @media (max-width: 767px) {
    .heightInput {
      > input {
        width: 100%;
      }
    }
  }
`

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.color.base.nth1};
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 21px;
    margin-bottom: 4px;
  `}
`

export const Input = styled(CurrencyInput)<{ error?: boolean }>`
  ${({ theme, error }) => css`
    width: max-content;
    padding: 8px ${theme.spacing.stack.xxs};
    color: ${theme.color.base.nth1};
    background: ${error ? theme.color.alert.error.nth4 : theme.color.base.nth4};
    border: ${error
      ? `1px solid ${theme.color.alert.error.nth1}`
      : '1px solid transparent'};
    border-radius: 6px;
    font-size: ${theme.font.size.sm};
    padding-left: ${theme.spacing.stack.xxxs};
    &:focus {
      ${!error
        ? css`
            border: ${theme.border.width.line} solid
              ${theme.color.brand.primary.nth2};
          `
        : null}
      background: ${theme.color.neutral.nth4};
    }
    &::placeholder {
      color: ${theme.color.base.nth1};
    }
    @media (max-width: 767px) {
      padding: 8px ${theme.spacing.stack.xxs};
    }
  `}
`

export const Error = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.alert.error.nth1};
    font-size: ${theme.font.size.xxs};
    min-height: ${theme.spacing.stack.xxs};
    width: 244px;
  `}
`

export const IconSvg = styled.svg<{ error?: boolean }>`
  ${({ theme, error }) =>
    css`
      fill: ${error ? theme.color.alert.error.nth1 : theme.color.base.nth1};
      height: 24px;
      width: 24px;
      margin-left: -40px;
      margin-bottom: -8px;

      @media (max-width: 1400px) {
        left: 94%;
      }

      @media (max-width: 750px) {
        left: 93%;
      }

      @media (max-width: 600px) {
        left: 92%;
      }

      @media (max-width: 450px) {
        left: 90%;
      }
    `}
`
