import styled, { css } from 'styled-components'
import { Paragraph } from 'applaus-ui-kit'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  .pages {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1171px) {
    overflow: hidden;
    flex-direction: row;
  }
`

export const Steps = styled.section`
  ${({ theme }) => css`
    background: #f4f4f4;
    display: flex;
    overflow: auto;
    flex-direction: column;
    min-width: 330px;
    > div:first-of-type {
      margin-top: ${theme.spacing.stack.xxl};
    }
  `}
`

export const StepContent = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    display: flex;
    align-items: center;
    background: ${isActive ? '#dfdfdf' : 'transparent'};
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xs};
    > p:last-child {
      color: ${isActive ? '#400058' : '#5C5C5C'};
    }
    transition: 0.3s ease;
    &:hover {
      cursor: pointer;
      background: #dfdfdf;
    }
  `}
`

export const StepPosition = styled(Paragraph)<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    min-width: ${theme.spacing.stack.sm};
    min-height: ${theme.spacing.stack.sm};
    line-height: ${theme.spacing.stack.sm};
    text-align: center;
    color: white;
    margin-right: 1rem;
    background: ${isActive ? '#400058' : '#5C5C5C'};
    border-radius: ${theme.border.radius.circle};
  `}
`
