import { AxiosResponse } from 'axios'
import { uploadImagesService } from '../files/uploadImageService'
import { CourseResponse } from './listCoursesService'
import { api } from '../config'

export type CourseCreationRequest = {
  name: string
  description: string
  priceCents: number
  discountCents: number
  coverUrl: File | string
}

export const createCourseService = async (value: CourseCreationRequest) => {
  let coverUrl = ''
  if (typeof value.coverUrl !== 'string') {
    coverUrl = await uploadImagesService(value.coverUrl)
  }
  value.coverUrl = coverUrl
  const { data }: AxiosResponse<CourseResponse> = await api.post(
    '/api/courses',
    value
  )

  return data
}
