import styled, { css } from 'styled-components'
import { theme } from 'applaus-ui-kit'

import { ReactComponent as BackFile } from '../../../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`

export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.semiBold};
    margin-bottom: ${theme.spacing.stack.nano};
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs};
    max-width: 1366px;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    > button {
      align-self: end;
    }
    @media (min-width: 1171px) {
      align-self: center;
      margin: 0 auto;
      padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
    }
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xs} 0;

    .categoryBox {
      margin-bottom: ${theme.spacing.stack.xxs};
    }
  `}
`

export const ChooseSectionType = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    .radioButtonWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }
    .options-text {
      margin-left: 2rem;
    }
  `}
`

export const OptionsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    .radioButtonWrapper {
      display: flex;
      div:first-of-type {
        padding-top: ${theme.spacing.stack.small};
        margin-right: ${theme.spacing.stack.small};
      }
    }
    @media (min-width: 1171px) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: ${theme.spacing.stack.small};
    }
  `}
`

export const ImageText = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.md} 0;
    > p:last-child {
      text-align: left;
    }
  `}
`

export const ImageSelectSection = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    > div {
      margin: 3rem 0;
    }
  `}
`

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    background: '#F4F4F4',
    fontSize: '2rem',
    color: 'gray',
    cursor: 'pointer',
    transition: 'hover 300ms',
    '&:hover': {
      color: 'black'
    }
  }),
  control: (provided: any) => ({
    ...provided,
    marginTop: theme.spacing.stack.xxxs,
    width: 272,
    height: '43px',
    background: '#F4F4F4',
    fontSize: '2rem',
    border: 0,
    boxShadow: 'none',
    padding: `2px ${theme.spacing.stack.xxxs}`,
    borderRadius: '6px',
    cursor: 'pointer'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '2rem',
    '& input': {
      font: 'inherit'
    }
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '2rem',
    '& input': {
      font: 'inherit',
      fontSize: '2rem'
    }
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '2rem'
  })
}

export const LabelCategory = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.bold};
    margin-bottom: ${theme.spacing.stack.nano};
  `}
`

export const customStylesCategory = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    display: 'flex',
    height: '43px',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '100%',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const CashbackBox = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxl};

    > div:first-child {
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.xxs};
      > h5 {
        margin-left: ${theme.spacing.stack.xxs};
      }
    }

    div:nth-child(2) {
      > label {
        font-weight: ${theme.font.weight.semiBold};
      }
    }
  `}
`

export const SelectWrapper = styled.div`
  @media (min-width: 1171px) {
    max-width: 35%;
  }
`

export const Error = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    min-height: 24px;
    color: ${theme.color.alert.error.nth1};
  `}
`
