import * as S from './Scan.styles'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'
import { Hidden, Input } from 'applaus-ui-kit'
import { history } from '../../routes/services/history'
import { BackButton } from '../../components/BackButton/BackButton'
import { useDispatch } from 'react-redux'
import { clearLoading, setLoading } from '../../redux/Loading/actions'
import { ticketDetachService } from '../../api/tickets/ticketDetach'
import { sleep } from '../../utils/sleep'
import { setAlertModalAction } from '../../redux/AlertModal/actions'
import axios from 'axios'
import { loadDetachedEvent } from '../../redux/ManageEvent/actions'
import { KeyboardEvent, useState } from 'react'

export const Scan = () => {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')

  const handleScan = async (customerTicketId: string) => {
    try {
      dispatch(setLoading())
      const data = await ticketDetachService(customerTicketId)
      dispatch(loadDetachedEvent(data))
      history.push('/detachedTicket')
      await sleep(300)
      dispatch(clearLoading())
    } catch (err) {
      dispatch(clearLoading())
      if (axios.isAxiosError(err)) {
        if (
          err.response?.status === 422 &&
          err.response?.data.message === 'customer ticket already detached'
        ) {
          dispatch(
            setAlertModalAction({
              title: 'Ticket já destacado',
              buttonText: 'Fechar',
              open: true,
              variant: 'error'
            })
          )
        } else if (
          err.response?.status === 422 &&
          err.response?.data.message !== 'customer ticket already detached'
        ) {
          dispatch(
            setAlertModalAction({
              title: 'Ingresso não pertence ao produtor',
              buttonText: 'Fechar',
              open: true,
              variant: 'error'
            })
          )
        }
      }
    }
  }
  const handleError = (data: any) => {}

  const handleKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e && e.key === 'Enter') {
      await handleScan(inputValue)
    }
  }

  const handleClickBack = () => {
    const eventId = localStorage.getItem('eventId')
    if (eventId) {
      history.push(`/manageEvent/${eventId}`)
    } else {
      history.push('/events')
    }
  }

  return (
    <AuthenticatedTemplate isActive="events">
      <S.Container>
        <S.ContentScan>
          <div className="backButton">
            <BackButton onClick={handleClickBack} />
          </div>
          <Hidden mobile>
            <p className="scan-text">Ler Código QR</p>
          </Hidden>
          <S.QrReaderStyled
            onError={(err) => handleError(err)}
            onScan={(result) => result !== null && handleScan(result)}
          />
        </S.ContentScan>
        <div className="inputBox">
          <Input
            label="Digite ID do ingresso"
            placeholder="Digite ID do ingresso"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value.toUpperCase())}
            onKeyPress={handleKeyPress}
            className="input"
          />
        </div>
      </S.Container>
    </AuthenticatedTemplate>
  )
}
