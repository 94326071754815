import { action } from 'typesafe-actions'
import { EventData, ManageEventsActions } from './types'
import { DetachResponse } from '../../api/tickets/ticketDetach'
import { EventCustomerTickets } from '../../api/events/listEventCustomerTickets'
import { EventPurchaseOrder } from '../../api/events/listEventPurchasesOrder'

export const loadDetachedEvent = (data: DetachResponse) =>
  action(ManageEventsActions.SET_CUSTOMER_TICKET, data)

export const loadEventDataRequestAction = (eventId: string) =>
  action(ManageEventsActions.LOAD_EVENT_DATA_REQUEST, eventId)

export const loadEventDataSuccessAction = (data: EventData) =>
  action(ManageEventsActions.LOAD_EVENT_DATA_SUCCESS, data)

export const customerTicketsPaginationChangeRequest = (step: number) =>
  action(
    ManageEventsActions.CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_REQUEST,
    step
  )

export type ChangeCustomerPagination = {
  items: EventCustomerTickets[]
  skip: number
}

export const customerTicketsPaginationChangeSuccess = (
  data: ChangeCustomerPagination
) =>
  action(
    ManageEventsActions.CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_SUCCESS,
    data
  )

export const customerTicketsNextStepRequest = () =>
  action(ManageEventsActions.CUSTOMER_TICKET_NEXT_STEP_REQUEST)

export const customerTicketsStepBackRequest = () =>
  action(ManageEventsActions.CUSTOMER_TICKET_STEP_BACK_REQUEST)

export const purchasesPaginationChangeRequest = (step: number) =>
  action(ManageEventsActions.CHANGE_PURCHASES_PAGINATION_STEP_REQUEST, step)

export type ChangePurchasePagination = {
  items: EventPurchaseOrder[]
  skip: number
}

export const purchasePaginationChangeSuccess = (
  data: ChangePurchasePagination
) => action(ManageEventsActions.CHANGE_PURCHASES_PAGINATION_STEP_SUCCESS, data)

export const purchaseNextStepRequest = () =>
  action(ManageEventsActions.PURCHASE_NEXT_STEP_REQUEST)

export const purchaseStepBackRequest = () =>
  action(ManageEventsActions.PURCHASE_STEP_BACK_REQUEST)

export const loadCustomerTicket = (data: EventCustomerTickets) =>
  action(ManageEventsActions.LOAD_CUSTOMER_TICKET, data)

export const loadPurchaseOrder = (data: EventPurchaseOrder) =>
  action(ManageEventsActions.LOAD_PURCHASE_ORDER, data)
