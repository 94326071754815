/* eslint-disable no-unused-vars */
import * as S from './CourtesyEvent.styles'
import { Button, Heading, Hidden, Input, Paragraph } from 'applaus-ui-kit'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'
import { loadEventDataRequestAction } from '../../redux/ManageEvent/actions'
import { ReduxState } from '../../redux/rootReducer'
import { formatPrice } from '../../utils/formatPrice'
import { EventMapped } from './Components/EventMapped/EventMapped'
import { CustomerTicket, EventItems } from '../../redux/Events/types'
import { PurchaseSummary } from './Components/PurchaseSummary/PurchaseSummary'
import { DescriptionCard } from './Components/DescriptionCard/DescriptionCard'
import { TicketCard } from './Components/TicketCard/TicketCard'
import produce from 'immer'
import { setModalState } from '../../redux/Modal/Actions/actionsModal'
import { setAlertModalAction } from '../../redux/AlertModal/actions'
import { courtesyService } from '../../api/courtesy/courtesyService'
import { clearLoading, setLoading } from '../../redux/Loading/actions'
import { sleep } from '../../utils/sleep'
import { history } from '../../routes/services/history'
import { EventResponse } from '../../api/events/listEventService'
import { EventTabulated } from './Components/EventTabulated/EventTabulated'
import { Column, Row } from '../../components/grid/GridSystem'
import { useWindowSize } from '../../hooks/useWindowSize'
import { BackButton } from '../../components/BackButton/BackButton'

type TicketInfo = {
  name: string
  value: string
  error: boolean
}

type CourtesyProps = {
  eventId: string
}

export const CourtesyEvent = () => {
  const param = useParams<{ eventId: string }>()
  const { isDesktop, isMobile, isTablet } = useWindowSize()
  const dispatch = useDispatch()
  const { eventData } = useSelector(
    (state: ReduxState) => state.ManageEventReducer
  )
  const [selectedItem, setSelectedItem] = useState<EventItems | undefined>()
  const [selectedTickets, setSelectedTickets] = useState<
    CustomerTicket[] | undefined
  >([])
  const [giveCourtesy, setGiveCourtesy] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [customerEmail, setCustomerEmail] = useState('')
  const [showEventItems, setShowEventItems] = useState(true)

  useEffect(() => {
    dispatch(loadEventDataRequestAction(param.eventId))

    localStorage.removeItem('eventId')
  }, [])

  const eventDates =
    eventData?.eventItems.flatMap((item) =>
      item.dates.flatMap((date) => moment(date))
    ) ?? []

  const eventPrice =
    eventData?.eventItems.flatMap((item) =>
      item.tickets.flatMap((ticket) => ticket.priceCents / 100)
    ) ?? []

  const handleSetFieldValue = (
    value: string,
    index: number,
    indexInfo: number,
    name: string
  ) => {
    const findIndex = selectedTickets?.findIndex((ticket) =>
      ticket.ticketInfo?.flatMap((info) => info.name === name)
    )

    if (value.length) {
      setSelectedTickets(
        produce((draft) => {
          draft![index].ticketInfo![indexInfo].value = value
        })
      )
    } else if (!value.length) {
      setSelectedTickets(
        produce((draft) => {
          draft![index].ticketInfo![indexInfo].value = value
        })
      )
    }
  }

  const handleCourtesy = async () => {
    setSubmit(true)
    const errors = selectedTickets?.flatMap((ticket) =>
      ticket.ticketInfo?.filter((info) => info.value === '' && info.isRequired)
    )

    if (errors && errors.length > 0) {
      dispatch(
        setAlertModalAction({
          buttonText: 'Ok',
          open: true,
          title: 'Campos obrigatórios não preenchidos.',
          variant: 'error'
        })
      )
    } else {
      if (
        customerEmail.length === 0 ||
        /\S+@\S+\.\S+/.test(customerEmail) === false
      ) {
        return
      }
      try {
        dispatch(setLoading())
        await sleep(1500)
        await courtesyService({
          customerEmail: customerEmail,
          tickets:
            selectedTickets?.map((ticket, index) => ({
              seatKey: ticket.seatKey,
              eventItemId: ticket.eventItemId,
              ticketId: ticket.id,
              ticketInfo: selectedTickets[index].ticketInfo
                ?.filter((info) => info.value !== '')
                .flatMap((ticket) => ({
                  name: ticket.name,
                  value: ticket.value
                }))
            })) ?? []
        })
        dispatch(clearLoading())
        dispatch(
          setAlertModalAction({
            buttonText: 'Ok',
            open: true,
            title: 'Cortesia ocorrida com sucesso.',
            variant: 'success',
            click: () => {
              setGiveCourtesy(false)
              dispatch(
                setAlertModalAction({
                  buttonText: '',
                  open: false,
                  title: '',
                  variant: 'error'
                })
              )
            }
          })
        )
      } catch (e) {
        dispatch(clearLoading())
        dispatch(
          setAlertModalAction({
            buttonText: 'Ok',
            open: true,
            title: 'Email não encontrado.',
            variant: 'error'
          })
        )
      }
    }
  }

  const filteredEvents = eventData?.eventItems
    .filter((eventItem) => eventItem && eventItem.tickets.length > 0)
    .filter(
      (item) =>
        moment().isAfter(item.salesFrom) && moment().isBefore(item.finalSales)
    )

  return (
    <AuthenticatedTemplate isActive="courtesy">
      <S.Container>
        <Heading variant="h5">Cortesia</Heading>

        {giveCourtesy ? (
          <Row className="row">
            <Hidden tablet mobile>
              <Column desktop={7}>
                <PurchaseSummary
                  eventAddress={eventData?.address.name ?? ''}
                  eventDate={
                    moment().locale('pt-br').format('ll [ás] HH[h]mm') ?? ''
                  }
                  tickets={selectedTickets ?? []}
                  titleTicket={eventData?.name ?? ''}
                />
              </Column>
            </Hidden>

            <Column tablet={8} desktop={5} mobile={4} spacing={4}>
              <Input
                fullWidth
                name="email"
                label="Email para cortesia"
                placeholder="Digite o email"
                onChange={(e) => {
                  if (e.target.value) {
                    setCustomerEmail(e.target.value)
                  }
                }}
                error={
                  (customerEmail.length === 0 && submit) ||
                  (/\S+@\S+\.\S+/.test(customerEmail) === false && submit)
                }
                texterror={
                  /\S+@\S+\.\S+/.test(customerEmail) === false &&
                  customerEmail.length > 0
                    ? 'Email inválido'
                    : 'Email é obrigatório'
                }
              />
              {selectedTickets?.map((ticket, index) => (
                <div key={ticket.id}>
                  <DescriptionCard title="Dados do destinatário" />

                  {ticket?.ticketInfo!.map((info, indexInfo) => (
                    <Input
                      key={indexInfo + 1}
                      label={`${info.isRequired ? '*' : ''}${info.name}`}
                      onChange={(e) =>
                        handleSetFieldValue(
                          e.target.value,
                          index,
                          indexInfo,
                          info.value
                        )
                      }
                      error={info.isRequired && info.value.length < 1 && submit}
                      texterror={`${info.name} é obrigatório`}
                      fullWidth
                    />
                  ))}
                </div>
              ))}

              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={handleCourtesy}>
                Dar cortesia
              </Button>
            </Column>
          </Row>
        ) : (
          <>
            <Hidden mobile>
              <Row style={{ maxWidth: '700px' }}>
                <Column desktop={4} tablet={3}>
                  <S.EventData>
                    <Heading variant="h5">{eventData?.name}</Heading>
                    <div>
                      <S.ClockIcon />
                      <Paragraph variant="large">
                        {moment.min(eventDates).format('DD/MM')}
                        {eventData && eventData?.eventItems.length > 1
                          ? ' - ' + moment.max(eventDates).format('DD/MM')
                          : null}
                      </Paragraph>
                    </div>
                    <div>
                      <S.MapIcon />
                      <Paragraph variant="large">
                        {eventData?.address.name}
                      </Paragraph>
                    </div>
                  </S.EventData>
                </Column>

                <Column desktop={8} tablet={5} spacing={4}>
                  <S.EventPrice>
                    <Paragraph variant="large">Ingressos entre</Paragraph>
                    <Heading variant="h5">
                      {eventPrice.length > 0
                        ? formatPrice(_.min(eventPrice) ?? 0) +
                          ' e ' +
                          formatPrice(_.max(eventPrice) ?? 0)
                        : null}
                    </Heading>
                    <div>
                      <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        fullWidth
                        onClick={() =>
                          selectedTickets && selectedTickets?.length > 0
                            ? setGiveCourtesy(true)
                            : null
                        }>
                        Dar cortesia
                      </Button>
                    </div>
                  </S.EventPrice>
                </Column>
              </Row>
            </Hidden>

            <S.ChooseSeats>
              {filteredEvents && filteredEvents?.length > 0 ? (
                <Paragraph variant="large">
                  {showEventItems && isMobile
                    ? 'Escolha o setor'
                    : !showEventItems && isMobile
                    ? null
                    : 'Escolha o setor'}
                </Paragraph>
              ) : (
                <Paragraph variant="large">
                  Não há nenhum setor disponível
                </Paragraph>
              )}

              {showEventItems ? (
                <S.EventItemDates>
                  {filteredEvents?.map((item) =>
                    item.dates.map((date, index) => (
                      <S.Date
                        key={index}
                        isActive={item.id === selectedItem?.id}
                        onClick={() => {
                          setSelectedItem(item)
                          if (isMobile) {
                            setShowEventItems(false)
                          }
                        }}>
                        <Paragraph variant="small">
                          {moment(date).format('dddd').split('-', 1)}
                        </Paragraph>
                        <div>
                          <Paragraph variant="large">
                            {moment(date).format('DD/MM')}
                          </Paragraph>
                          <Paragraph variant="large">
                            {' - ' + moment(date).format('HH:mm')}
                          </Paragraph>
                        </div>
                      </S.Date>
                    ))
                  )}
                </S.EventItemDates>
              ) : null}

              {isMobile && !showEventItems ? (
                <BackButton onClick={() => setShowEventItems(true)} />
              ) : null}
              {selectedItem && eventData?.type === 'MAPPED' ? (
                <EventMapped
                  selectedTickets={selectedTickets}
                  setSelectedTickets={setSelectedTickets}
                  selectedEvent={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              ) : selectedItem && eventData?.type === 'TABULATED' ? (
                <EventTabulated
                  selectedTickets={selectedTickets}
                  setSelectedTickets={setSelectedTickets}
                  selectedEvent={selectedItem}
                />
              ) : null}
            </S.ChooseSeats>
          </>
        )}
      </S.Container>
    </AuthenticatedTemplate>
  )
}
