import styled, { css } from 'styled-components'

export const Container = styled.div<{ isOpen: boolean }>`
  ${({ isOpen, theme }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'all' : 'none'};
    background: ${theme.color.modal.nth1};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease;
  `}
`

export const Content = styled.form`
  ${({ theme }) => css`
    max-height: 80vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xs} ${theme.spacing.inline.md};
    background: ${theme.color.neutral.nth4};
    border-radius: ${theme.border.radius.medium};
    @media (min-width: 767px) {
      min-width: 510px;
    }
    .subtitle {
      font-size: ${theme.font.size.md};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.font.size.xl};
      margin-bottom: ${theme.spacing.stack.xs};
    }
    > button {
      margin: ${theme.spacing.stack.xs} 0 0 auto;
    }
  `}
`

export const PasswordRules = styled.div`
  ${({ theme }) => css`
    > p {
      color: ${theme.color.brand.primary.nth1};
    }
  `}
`

export const ButtonWrappers = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.stack.xxxs};
    margin-top: ${theme.spacing.stack.sm};
  `}
`
