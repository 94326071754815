import styled, { css } from 'styled-components'

import { ReactComponent as CouponFile } from '../../../assets/icons/coupon.svg'
import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg'
import { ReactComponent as Logoutfile } from '../../../assets/icons/log-out.svg'
import { ReactComponent as Userfile } from '../../../assets/icons/user.svg'
import { ReactComponent as ScanFile } from '../../../assets/icons/scan-qrcode.svg'
import { ReactComponent as FluentFile } from '../../../assets/icons/fluent.svg'

import { ReactComponent as HomeFile } from '../../../assets/icons/home.svg'
import { ReactComponent as CreditCardFile } from '../../../assets/icons/credit-card.svg'
import { ReactComponent as PartnetFile } from '../../../assets/icons/partner.svg'

export const HomeIcon = styled(HomeFile)`
  fill: #39383a;
`

export const FluentIcon = styled(FluentFile)`
  fill: #39383a;
`

export const PartnerIcon = styled(PartnetFile)`
  fill: #39383a;
`

export const CreditCardIcon = styled(CreditCardFile)`
  fill: #39383a;
`

export const UserIcon = styled(Userfile)`
  fill: #39383a;
`

export const CouponIcon = styled(CouponFile)`
  fill: #39383a;
`
export const DataIcon = styled(FileIcon)`
  fill: #39383a;
`
export const LogoutIcon = styled(Logoutfile)`
  fill: #39383a;
`

export const ScanIcon = styled(ScanFile)`
  fill: #39383a;
`

export const Container = styled.div`
  @media (min-width: 1169px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 1170px) {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100vh;
    overflow-y: hidden;
  }
`

export const Menu = styled.section`
  ${({ theme }) => css`
    background: #f4f4f4;
    display: flex;
    height: 100%;
    flex-direction: column;
    > img {
      margin: ${theme.spacing.stack.md} 0;
      align-self: center;
    }
  `}
`

export const Item = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    display: flex;
    align-items: center;
    cursos: pointer;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xs};
    background: ${isActive ? '#DFDFDF' : 'transparent'};
    > p {
      margin-left: ${theme.spacing.stack.xs};
      white-space: nowrap;
    }
    cursor: pointer;
    transition: background ease-in-out 0.5s;
    &:hover {
      background: #dfdfdf;
    }
  `}
`
