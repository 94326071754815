import styled, { css } from 'styled-components'
import { ReactComponent as ChevronDownFile } from '../../../assets/icons/chevron-down.svg'

import { ReactComponent as LogoutFile } from '../../../assets/icons/log-out.svg'

export const ChevronDownIcon = styled(ChevronDownFile)`
  position: absolute;
  fill: #8601c9;
  top: 40%;
  left: 100%;
  transform: translate(-100%, -40%);
  transition: 0.3s ease;
  &:hover {
    top: 50%;
  }
`

export const LogoutIcon = styled(LogoutFile)`
  fill: #8601c9;
`

export const DropDown = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    .user-name {
      color: white;
      margin-right: ${theme.spacing.inline.xxs};
    }
    .chevronIcon {
      cursor: pointer;
    }
  `}
`

export const Content = styled.nav`
  ${({ theme }) => css`
    > img {
      cursor: pointer;
    }
    background: #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 1171px) {
      justify-content: space-between;
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xxs};
    }
  `}
`
