import * as S from './AddCoupons.styles'
import { Button, Heading, Input, RadioButton } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { Coupon } from 'redux/Events/types'
import { ChangeEvent, useEffect } from 'react'
import PriceInput from 'components/PriceInput/PriceInput'
import { addZeroes } from 'utils/addZeroes'
import { formatPrice } from 'utils/formatPrice'
import * as Yup from 'yup'

type InitialValues = {
  name: string
  description: string
  amountOfUse: number
  value: string
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
  type: 'limited' | 'unlimited'
}

type Props = {
  handleClickBack: () => void
  handleSubmit: (value: InitialValues) => void
  handleEditCoupon: (value: {
    id: string
    name: string
    description: string
    amountOfUse: number
    value: string
    valueType: 'PERCENT' | 'CENTS'
    quantity?: number
    startValidateDate?: string
    finalValidateDate?: string
  }) => void
  coupon?: Coupon
}

export const AddCoupons = ({
  handleClickBack,
  handleSubmit,
  handleEditCoupon,
  coupon
}: Props) => {
  const initialValues: InitialValues = {
    name: '',
    description: 'cupom',
    amountOfUse: 0,
    value: '',
    valueType: 'PERCENT',
    type: 'limited'
  }

  const validationSchemaCoupon = Yup.object({
    name: Yup.string().required('Nome é obrigatório'),
    amountOfUse: Yup.string().required('Quantidade por usuario é obrigatório'),
    valueType: Yup.string().required('Tipo do cupom é obrigatório'),
    value: Yup.string()
      .test({
        name: 'Validate field',
        message: 'Complete o campo de preço',
        test: (value, ctx) => {
          if (value !== undefined) {
            if (
              ctx.parent.valueType === 'CENTS' &&
              value.includes(',') &&
              value.split(',')[1].length < 2
            ) {
              return false
            } else return true
          } else {
            return false
          }
        }
      })
      .test({
        name: 'Validate field',
        message: 'Valor deve ser maior que 0',
        test: (value, ctx) => {
          if (value !== undefined) {
            if (Number(value.replace(/[^\d]/g, '')) === 0) {
              return false
            } else return true
          } else {
            return false
          }
        }
      })
      .test({
        name: 'Validate field',
        message: 'Valor deve ser menor que 100',
        test: (value, ctx) => {
          return !(
            value !== undefined &&
            ctx.parent.valueType === 'PERCENT' &&
            Number(value) > 100
          )
        }
      })
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaCoupon,
    onSubmit: (values) => {
      if (coupon) {
        handleEditCoupon({
          ...values,
          id: coupon.id
        })
      } else handleSubmit(values)
    }
  })

  useEffect(() => {
    if (coupon) {
      formik.setValues({
        ...coupon,
        type: coupon.quantity ? 'limited' : 'unlimited',
        value:
          coupon.valueType === 'CENTS'
            ? formatPrice(Number(addZeroes(coupon.value)))
            : coupon.value.toString()
      })
    }
  }, [coupon])

  const handlePercentValueClick = () => {
    formik.setFieldValue('valueType', 'PERCENT')
    if (Number(formik.values.value) > 100) {
      formik.setFieldValue('value', 100)
    }
  }

  const handlePriceInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('value', e.target.value)
  }

  return (
    <S.Container>
      <S.TitleBox>
        <S.BackIcon onClick={handleClickBack} />
        <Heading variant="h3">{`${
          coupon ? 'Editar' : 'Adicionar'
        } cupom`}</Heading>
      </S.TitleBox>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.Subtitle>Tipo do cupom</S.Subtitle>
        <S.RadioButtonWrapper>
          <S.RadioButtonContainer>
            <RadioButton
              checked={formik.values.type === 'limited'}
              readOnly
              onClick={() => formik.setFieldValue('type', 'limited')}
            />
            <S.Label>Limitado</S.Label>
          </S.RadioButtonContainer>
          <S.RadioButtonContainer>
            <RadioButton
              checked={formik.values.type === 'unlimited'}
              readOnly
              onClick={() => formik.setFieldValue('type', 'unlimited')}
            />
            <S.Label>Ilimitado</S.Label>
          </S.RadioButtonContainer>
        </S.RadioButtonWrapper>
        <S.InputWrapper>
          {formik.values.type === 'limited' && (
            <Input
              label="Quantidade Disponível"
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              type="number"
              min={1}
              fullWidth
            />
          )}
          <Input
            label="Quantidade  por usuário"
            name="amountOfUse"
            value={formik.values.amountOfUse}
            onChange={formik.handleChange}
            type="number"
            fullWidth
          />
        </S.InputWrapper>

        <S.Subtitle>Tipo do Desconto</S.Subtitle>
        <S.RadioButtonWrapper>
          <S.RadioButtonContainer>
            <RadioButton
              checked={formik.values.valueType === 'PERCENT'}
              readOnly
              onClick={handlePercentValueClick}
            />
            <S.Label>Porcentagem %</S.Label>
          </S.RadioButtonContainer>
          <S.RadioButtonContainer>
            <RadioButton
              checked={formik.values.valueType === 'CENTS'}
              readOnly
              onClick={() => formik.setFieldValue('valueType', 'CENTS')}
            />
            <S.Label>Valor R$</S.Label>
          </S.RadioButtonContainer>
        </S.RadioButtonWrapper>
        {formik.values.valueType === 'CENTS' ? (
          <PriceInput
            label="Quantidade de desconto R$"
            name="value"
            value={formik.values.value}
            onChange={handlePriceInputChange}
          />
        ) : (
          <Input
            label="Quantidade de desconto %"
            name="value"
            value={formik.values.value}
            onChange={formik.handleChange}
            type="number"
            min={0.1}
            step=".1"
            max={100}
            fullWidth
          />
        )}
        <Input
          label="Código do cupom"
          fullWidth
          name="name"
          onChange={(e) =>
            formik.setFieldValue('name', e.target.value.toUpperCase())
          }
          value={formik.values.name}
        />
      </S.Form>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        fullWidth={false}
        type="submit"
        onClick={formik.submitForm}>
        {`${coupon ? 'Editar' : 'Criar'} cupom`}
      </Button>
    </S.Container>
  )
}
