import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs} 0;
    background: #540074;
    border-radius: ${theme.border.radius.medium};
    text-align: center;
    margin-bottom: ${theme.spacing.stack.small};

    @media (min-width: 768px) and (max-width: 1170px) {
      ${({ theme }) => css`
        padding: ${theme.spacing.stack.xxxs} 0;
        text-align: center;
      `}
    }

    @media (min-width: 1171px) {
      ${({ theme }) => css`
        padding: ${theme.spacing.stack.xxxs} 0;
        text-align: center;
      `}
    }
  `}
`

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    color: #ffffff;
    font-weight: ${theme.font.weight.bold};

    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: ${theme.font.size.md};
      line-height: ${theme.spacing.stack.sm};
    }
  `}
`
