export const BankOptions = {
  '001': 'Banco do Brasil',
  '033': 'Santander',
  '104': 'Caixa Econômica',
  '237': 'Bradesco',
  '341': 'Itaú',
  '041': 'Banrisul',
  '748': 'Sicredi',
  '756': 'Sicoob',
  '077': 'Inter',
  '070': 'BRB',
  '085': 'Via Credi',
  '655': 'Neon/Votorantim',
  '260': 'Nubank',
  '290': 'Pagseguro',
  '212': 'Banco Original',
  '422': 'Safra',
  '746': 'Modal',
  '021': 'Banestes',
  '136': 'Unicred',
  '274': 'Money Plus',
  '389': 'Mercantil do Brasil',
  '376': 'JP Morgan',
  '364': 'Gerencianet Pagamentos do Brasil',
  '336': 'Banco C6',
  '218': 'BS2',
  '082': 'Banco Topazio',
  '099': 'Uniprime',
  '197': 'Stone',
  '707': 'Banco Daycoval',
  '633': 'Rendimento',
  '004': 'Banco do Nordeste',
  '745': 'Citibank',
  '301': 'PJBank',
  '97': 'Cooperativa Central de Credito Noroeste Brasileiro',
  '084': 'Uniprime Norte do Paraná',
  '384': 'Global SCM',
  '403': 'Cora',
  '323': 'Mercado Pago',
  '003': 'Banco da Amazonia',
  '752': 'BNP Paribas Brasil',
  '383': 'Juno',
  '133': 'Cresol',
  '173': 'BRL Trust DTVM',
  '047': 'Banco Banese',
  '208': 'Banco BTG Pactual',
  '613': 'Banco Omni',
  '332': 'Acesso Soluções de Pagamento',
  '273': ' CCR de São Miguel do Oeste',
  '093': 'Polocred',
  '355': 'Ótimo',
  '121': 'Agibank',
  '037': 'Banpará',
  '380': 'Picpay',
  '125': 'Banco Genial',
  '412-x': 'Banco Capital S.A',
  '741': 'Banco Ribeirão Preto',
  '623': 'Banco Pan'
}
export const bankInfos = [
  {
    bank: 'Banco do Brasil',
    agency: '9999-9',
    account: '99999999-9',
    bankNumber: '001'
  },
  {
    bank: 'Santander',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '033'
  },
  {
    bank: 'Caixa Econômica',
    agency: '9999',
    account: '99999999999-9',
    bankNumber: '104'
  },
  {
    bank: 'Bradesco',
    agency: '9999-9',
    account: '9999999-9',
    bankNumber: '237'
  },
  { bank: 'Itaú', agency: '9999', account: '99999-9', bankNumber: '341' },
  {
    bank: 'Agibank',
    agency: '9999',
    account: '9999999999',
    bankNumber: '121'
  },
  {
    bank: 'Banpará',
    agency: '9999',
    account: '999999999-9',
    bankNumber: '037'
  },
  {
    bank: 'Banrisul',
    agency: '9999',
    account: '999999999-9',
    bankNumber: '041'
  },
  {
    bank: 'Sicoob',
    agency: '9999',
    account: '999999999-9',
    bankNumber: '756'
  },
  { bank: 'Inter', agency: '9999', account: '999999999-9', bankNumber: '077' },
  { bank: 'BRB', agency: '9999', account: '999999999-9', bankNumber: '070' },
  {
    bank: 'Via Credi',
    agency: '9999',
    account: '99999999999-9',
    bankNumber: '085'
  },
  {
    bank: 'Neon/Votorantim',
    agency: '9999',
    account: '9999999999-9',
    bankNumber: '655'
  },
  {
    bank: 'Nubank',
    agency: '9999',
    account: '9999999999-9',
    bankNumber: '260'
  },
  {
    bank: 'Pagseguro',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '290'
  },
  {
    bank: 'Banco Original',
    agency: '9999',
    account: '9999999-9',
    bankNumber: '212'
  },
  { bank: 'Safra', agency: '9999', account: '99999999-9', bankNumber: '422' },
  { bank: 'Modal', agency: '9999', account: '999999999-9', bankNumber: '746' },
  {
    bank: 'Banestes',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '021'
  },
  {
    bank: 'Unicred',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '136'
  },
  {
    bank: 'Money Plus',
    agency: '9',
    account: '99999999-9',
    bankNumber: '274'
  },
  {
    bank: 'Mercantil do Brasil',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '389'
  },
  {
    bank: 'JP Morgan',
    agency: '9999',
    account: '99999999999-9',
    bankNumber: '376'
  },
  {
    bank: 'Gerencianet Pagamentos do Brasil',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '163'
  },
  {
    bank: 'Banco C6',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '336'
  },
  { bank: 'BS2', agency: '9999', account: '9999999-9', bankNumber: '218' },
  {
    bank: 'Banco Topazio',
    agency: '9999',
    account: '9999999-9',
    bankNumber: '082'
  },
  { bank: 'Uniprime', agency: '9999', account: '999999-9', bankNumber: '091' },
  { bank: 'Stone', agency: '9999', account: '9999999-9', bankNumber: '197' },
  {
    bank: 'Rendimento',
    agency: '9999-9',
    account: '9999999999',
    bankNumber: '633'
  },
  {
    bank: 'Banco Daycoval',
    agency: '9999',
    account: '9999999-9',
    bankNumber: '707'
  },
  {
    bank: 'Banco do Nordeste',
    agency: '999',
    account: '999999-9',
    bankNumber: '004'
  },
  { bank: 'Citibank', agency: '9999', account: '99999999', bankNumber: '745' },
  {
    bank: 'PJBank',
    agency: '9999',
    account: '9999999999-9',
    bankNumber: '746'
  },
  {
    bank: 'Cooperativa Central de Credito Noroeste Brasileiro',
    agency: '9999',
    account: '9999999-9',
    bankNumber: '085'
  },
  {
    bank: 'Uniprime Norte do Paraná',
    agency: '9999',
    account: '999999-9',
    bankNumber: '109'
  },
  {
    bank: 'Global SCM',
    agency: '9999',
    account: '99999999999',
    bankNumber: '125'
  },
  { bank: 'Next', agency: '9999-9', account: '9999999-9', bankNumber: '237' },
  { bank: 'Cora', agency: '9999', account: '9999999-9', bankNumber: '233' },
  {
    bank: 'Mercado Pago',
    agency: '9999',
    account: '9999999999-9',
    bankNumber: '290'
  },
  {
    bank: 'Banco da Amazonia',
    agency: '9999',
    account: '999999-9',
    bankNumber: '003'
  },
  {
    bank: 'BNP Paribas Brasil',
    agency: '999',
    account: '999999-9DD',
    bankNumber: '752'
  },
  { bank: 'Juno', agency: '9999', account: '9999999999-9', bankNumber: '376' },
  { bank: 'Cresol', agency: '9999', account: '99999999-9', bankNumber: '133' },
  {
    bank: 'BRL Trust DTVM',
    agency: '999',
    account: '999999-9',
    bankNumber: '173'
  },
  {
    bank: 'Banco Banese',
    agency: '999',
    account: '99999999-9',
    bankNumber: '047'
  },
  {
    bank: 'Banco BTG Pactual',
    agency: '9999',
    account: '9999999-9',
    bankNumber: '208'
  },
  {
    bank: 'Banco Omni',
    agency: '9999',
    account: '999999-9',
    bankNumber: '613'
  },
  {
    bank: 'Acesso Soluções de pagamento',
    agency: '9999',
    account: '999999999-9',
    bankNumber: '332'
  },
  {
    bank: 'CCR de São Miguel do Oeste',
    agency: '9999',
    account: '99999',
    bankNumber: '273'
  },
  { bank: 'Polocred', agency: '9999', account: '999999-9', bankNumber: '093' },
  { bank: 'Ótimo', agency: '9999', account: '99999-9', bankNumber: '355' },
  { bank: 'Picpay', agency: '9999', account: '99999999-9', bankNumber: '380' },
  {
    bank: 'Banco Genial',
    agency: '9999',
    account: '9999-9',
    bankNumber: '125'
  },
  {
    bank: 'Banco Capital S.A',
    agency: '9999',
    account: '999999999',
    bankNumber: '412-x'
  },
  { bank: 'Sicredi', agency: '9999', account: '99999999D', bankNumber: '748' },
  {
    bank: 'Banco Ribeirão Preto',
    agency: '99999',
    account: '9999999999',
    bankNumber: '741'
  },
  {
    bank: 'ASAAS IP',
    agency: '9999',
    account: '9999999-9',
    bankNumber: '461'
  },
  {
    bank: 'Banco Pan',
    agency: '9999',
    account: '999999999-9',
    bankNumber: '623'
  },
  {
    bank: 'VORTX DTVM LTDA',
    agency: '9999-9',
    account: '999-9',
    bankNumber: '310'
  },
  {
    bank: 'Banco BMG',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '318'
  },
  {
    bank: 'Fitbank',
    agency: '9999',
    account: '99999999-9',
    bankNumber: '450'
  },
  { bank: 'Pefisa', agency: '9999', account: '9999999-9', bankNumber: '174' },
  {
    bank: 'Credisan',
    agency: '9999',
    account: '999999999-9',
    bankNumber: '89'
  },
  {
    bank: 'XP Investimentos',
    agency: '9999',
    account: '999999-9',
    bankNumber: '102'
  },
  {
    bank: 'Crefisa',
    agency: '9999',
    account: '999999999-9',
    bankNumber: '69'
  }
]
