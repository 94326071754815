import { EventItems } from '../../redux/Events/types'
import { api } from '../config'
import { AxiosResponse } from 'axios'

export const updateEventItemStatusService = async (
  eventId: string,
  isPublic: boolean,
  status: 'OPEN' | 'CLOSED'
) => {
  const { data }: AxiosResponse<EventItems> = await api.put(
    `/api/events/${eventId}`,
    {
      isPublic,
      status
    }
  )

  return data
}
