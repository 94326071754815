import styled, { css } from 'styled-components'

import { ReactComponent as ClockFile } from '../../assets/icons/clock.svg'
import { ReactComponent as MapFile } from '../../assets/icons/map-marker.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxl};
    display: flex;
    flex-direction: column;
    overflow: auto;
    > h5 {
      font-weight: ${theme.font.weight.semiBold};
      margin-bottom: ${theme.spacing.stack.lg};
    }

    .row {
      max-width: 1200px;
    }

    @media (max-width: 1170px) {
      padding: ${theme.spacing.stack.lg};
    }

    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs};
    }
  `}
`

export const EventData = styled.div`
  ${({ theme }) => css`
    > h5 {
      max-width: 302px;
    }

    > div:nth-child(2),
    div:nth-child(3) {
      margin-top: ${theme.spacing.stack.xxxs};
      display: flex;
      align-items: center;
    }

    p {
      font-weight: ${theme.font.weight.semiBold};
      margin-left: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const ClockIcon = styled(ClockFile)`
  ${({ theme }) => css`
    fill: #39383a;
  `}
`

export const MapIcon = styled(MapFile)`
  ${({ theme }) => css`
    fill: #39383a;
  `}
`

export const EventPrice = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.sm};
    box-shadow: 0px 3.17268px 8.72486px rgba(0, 0, 0, 0.25);

    > p {
      margin-bottom: ${theme.spacing.stack.xxxs};
    }

    button {
      max-width: 245px;
      margin-top: ${theme.spacing.stack.xxs};
      background: ${theme.color.alert.success.nth1};
    }
  `}
`

export const ChooseSeats = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xs};

    > p {
      color: ${theme.color.brand.primary.nth2};
      font-weight: ${theme.font.weight.bold};
    }

    @media (max-width: 767px) {
      margin-top: 0;
    }
  `}
`

export const EventItemDates = styled.div`
  ${({ theme }) =>
    css`
      display: flex;
      flex-wrap: wrap;
      margin-top: ${theme.spacing.stack.xxs};
    `}
`

export const Date = styled.div<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    min-width: 155px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxs}
      ${theme.spacing.stack.nano};
    background: ${isActive ? theme.color.brand.primary.nth1 : '#ffffff'};
    border: 2px solid ${theme.color.brand.primary.nth1};
    margin: 0 ${theme.spacing.stack.small} ${theme.spacing.stack.small} 0;

    > p {
      color: ${isActive ? '#ffffff' : theme.color.base.nth1};
    }

    > div {
      display: flex;
      > p {
        color: ${isActive ? '#ffffff' : theme.color.base.nth1};
      }
    }
  `}
`

export const CourtesySummary = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 410px 300px;
    gap: ${theme.spacing.stack.xxxl};
  `}
`

export const DataParticipant = styled.div`
  ${({ theme }) => css``}
`
