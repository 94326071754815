import * as S from './BackButton.styles'

type Props = {
  onClick: () => void
}

export const BackButton = ({ onClick }: Props) => {
  return (
    <S.BackButton onClick={onClick}>
      <S.BackIcon />
      <p className="back-text">Voltar</p>
    </S.BackButton>
  )
}
