import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { ReactComponent as MenuFile } from '../../../assets/icons/menu.svg'
import { ReactComponent as SearchFile } from '../../../assets/icons/search.svg'
import { ReactComponent as FilledProfile } from '../../../assets/icons/filledProfile.svg'
import { ReactComponent as LockFile } from '../../../assets/icons/unlock.svg'
import { ReactComponent as LikeFile } from '../../../assets/icons/heart.svg'
import { ReactComponent as TicketFile } from '../../../assets/icons/coupon.svg'
import { ReactComponent as FileFile } from '../../../assets/icons/file.svg'
import { ReactComponent as LogoutFile } from '../../../assets/icons/log-out.svg'
import { ReactComponent as LocationFile } from '../../../assets/icons/location.svg'
import { ReactComponent as UserFile } from '../../../assets/icons/userIcon.svg'

import { ReactComponent as CreditCardFile } from '../../../assets/icons/credit-card.svg'
import { ReactComponent as PartnetFile } from '../../../assets/icons/partner.svg'

export const IconBox = styled(motion.div)`
  width: max-content;
`
export const CreditCardIcon = styled(CreditCardFile)`
  cursor: pointer;
`

export const PartnerIcon = styled(PartnetFile)`
  fill: #39383a;
  width: 24px;
  height: 24px;
`

export const DrawerMenuIcon = styled(MenuFile)`
  fill: #8601c9;
  cursor: pointer;
`

export const UserIcon = styled(UserFile)`
  fill: #39383a;
`

export const LocationIcon = styled(LocationFile)`
  fill: #39383a;
`

export const DataIcon = styled(FileFile)`
  fill: #39383a;
`

export const LogoutIcon = styled(LogoutFile)`
  fill: #39383a;
`

export const LockIcon = styled(LockFile)`
  fill: #39383a;
`

export const LikeIcon = styled(LikeFile)`
  fill: transparent;
`

export const TicketIcon = styled(TicketFile)`
  fill: #39383a;
`

export const MenuIcon = styled(MenuFile)`
  fill: #39383a;
`

export const SearchIcon = styled(SearchFile)`
  fill: #39383a;
  width: 48px;
`

export const Container = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${theme.spacing.inline.xxs};
  `}
`

export const Logo = styled.img`
  max-height: 58px;
`
export const DrawerContainer = styled(motion.div)`
  ${() => css`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  `}
`

export const DrawerContent = styled(motion.div)`
  ${({ theme }) => css`
    background: #f4f4f4;
    width: max-content;
    height: 100%;
    overflow: auto;
    z-index: 1000;
    padding: ${theme.spacing.inline.xxs};
  `}
`

export const UserInfoBox = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 3px solid #dfdfdf;
    margin-top: ${theme.spacing.stack.xs};
    .emailText {
      margin-bottom: ${theme.spacing.stack.xxs};
      word-break: break-all;
      white-space: normal;
      text-align: center;
    }
  `}
`

export const FilledProfileIcon = styled(FilledProfile)`
  ${({ theme }) => css`
    fill: #39383a;
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`
export const ButtonBox = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing.stack.small} 0;
    white-space: nowrap;
  `}
`

export const Button = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.stack.xxxs} 0 ${theme.spacing.stack.xxxs}
      ${theme.spacing.inline.xs};
    > p {
      margin: 0 ${theme.spacing.stack.xs};
    }
  `}
`
