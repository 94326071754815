import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (min-width: 1170px) {
    justify-content: center;
  }
`

export const Logo = styled.img`
  align-self: center;
`

export const Form = styled(motion.form)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 1rem 0;
    padding: ${theme.spacing.stack.xxs};

    button:first-of-type {
      margin-top: auto;
    }
    @media (min-width: 1170px) {
      margin: 0 auto;
      max-width: 417px;
      justify-content: center;
      button:first-of-type {
        margin: 2rem 0;
      }
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    @media (max-width: 768px) {
      margin-top: auto;
    }
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 5rem;
  `}
`

export const Link = styled.a`
  ${({ theme }) => css`
    color: #8601c9;
    font-size: 12px;
    line-height: ${theme.spacing.stack.xxs};
    text-decoration: underline;
    cursor: pointer;
    align-self: center;
    @media (min-width: 1170px) {
      font-size: 16px;
    }
  `}
`
export const SignupText = styled.p`
  ${({ theme }) => css`
    text-align: center;
    font-size: 12px;
    margin-bottom: ${theme.spacing.stack.xxs};
    @media (min-width: 1170px) {
      font-size: 16px;
    }
    line-height: ${theme.spacing.stack.xxs};
  `}
`
