import * as S from './EventMarketing.styles'
import { Heading, Input, Button } from 'applaus-ui-kit'
import { useFormik } from 'formik'

type Links = {
  facebookLink: string
  youtubeLink: string
  instagramLink: string
  tiktokLink: string
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

type Props = {
  handleClickBack: () => void
  handleNextStep: () => void
  handleSubmit: (values: Links) => void
  initialValues: Links | null
}

export const EventMarketing = ({
  handleClickBack,
  handleNextStep,
  handleSubmit,
  initialValues
}: Props) => {
  const formik = useFormik({
    initialValues: {
      facebookLink: initialValues?.facebookLink ?? '',
      youtubeLink: initialValues?.youtubeLink ?? '',
      instagramLink: initialValues?.instagramLink ?? '',
      tiktokLink: initialValues?.tiktokLink ?? '',
      facebookpixel: initialValues?.facebookpixel ?? '',
      googleanalyticspixel: initialValues?.googleanalyticspixel ?? '',
      googletagmanagerpixel: initialValues?.googletagmanagerpixel ?? ''
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values)
      setSubmitting(false)
    }
  })

  return (
    <S.Container>
      <S.TitleBox>
        <S.BackIcon onClick={handleClickBack} />
        <Heading variant="h3">Marketing</Heading>
      </S.TitleBox>
      <S.Content onSubmit={formik.handleSubmit}>
        <S.Subtitle>Redes Sociais</S.Subtitle>
        <S.InputWrapper>
          <S.FacebookIcon />
          <div>
            <Input
              label="Facebook"
              fullWidth
              placeholder="Link"
              value={formik.values.facebookLink}
              name="facebookLink"
              onChange={formik.handleChange}
              texterror={formik.errors.facebookLink}
              error={
                formik.touched.facebookLink &&
                Boolean(formik.errors.facebookLink)
              }
            />
          </div>
        </S.InputWrapper>
        <S.InputWrapper>
          <S.InstagramIcon />
          <div>
            <Input
              label="Instagram"
              fullWidth
              placeholder="Link"
              name="instagramLink"
              value={formik.values.instagramLink}
              onChange={formik.handleChange}
              texterror={formik.errors.instagramLink}
              error={
                formik.touched.instagramLink &&
                Boolean(formik.errors.instagramLink)
              }
            />
          </div>
        </S.InputWrapper>
        <S.InputWrapper>
          <S.YoutubeIcon />
          <div>
            <Input
              label="Youtube"
              fullWidth
              placeholder="Link"
              value={formik.values.youtubeLink}
              name="youtubeLink"
              onChange={formik.handleChange}
              texterror={formik.errors.youtubeLink}
              error={
                formik.touched.youtubeLink && Boolean(formik.errors.youtubeLink)
              }
            />
          </div>
        </S.InputWrapper>
        <S.InputWrapper>
          <S.TiktokIcon />
          <div>
            <Input
              label="TikTok"
              fullWidth
              placeholder="Link"
              value={formik.values.tiktokLink}
              name="tiktokLink"
              onChange={formik.handleChange}
              texterror={formik.errors.tiktokLink}
              error={
                formik.touched.tiktokLink && Boolean(formik.errors.tiktokLink)
              }
            />
          </div>
        </S.InputWrapper>
        <S.Subtitle>Pixels</S.Subtitle>
        <div className="pixel-input">
          <Input
            label="Google Ads"
            fullWidth
            placeholder="Link"
            name="googleanalyticspixel"
            value={formik.values.googleanalyticspixel}
            onChange={formik.handleChange}
            texterror={formik.errors.googleanalyticspixel}
            error={
              formik.touched.googleanalyticspixel &&
              Boolean(formik.errors.googleanalyticspixel)
            }
          />
        </div>
        <div className="pixel-input">
          <Input
            label="Facebook Ads"
            fullWidth
            placeholder="Link"
            name="facebookpixel"
            value={formik.values.facebookpixel}
            onChange={formik.handleChange}
            texterror={formik.errors.facebookpixel}
            error={
              formik.touched.facebookpixel &&
              Boolean(formik.errors.facebookpixel)
            }
          />
        </div>
        <div className="pixel-input">
          <Input
            label="Google Tag manager"
            fullWidth
            placeholder="Link"
            name="googletagmanagerpixel"
            value={formik.values.googletagmanagerpixel}
            onChange={formik.handleChange}
            texterror={formik.errors.googletagmanagerpixel}
            error={
              formik.touched.googletagmanagerpixel &&
              Boolean(formik.errors.googletagmanagerpixel)
            }
          />
        </div>
        <S.ButtonWrapper>
          <Button
            onClick={handleNextStep}
            type="button"
            fullWidth={false}
            size="medium"
            variant="contained"
            color="primary">
            Pular etapa
          </Button>
          <Button
            disabled={formik.isSubmitting}
            fullWidth={false}
            size="medium"
            variant="contained"
            color="primary"
            type="submit">
            Salvar
          </Button>
        </S.ButtonWrapper>
      </S.Content>
    </S.Container>
  )
}
