import styled from 'styled-components'

import { ReactComponent as AgeFree } from '../../assets/icons/ageGroup/ageFree.svg'
import { ReactComponent as Age10 } from '../../assets/icons/ageGroup/age10.svg'
import { ReactComponent as Age12 } from '../../assets/icons/ageGroup/age12.svg'
import { ReactComponent as Age16 } from '../../assets/icons/ageGroup/age16.svg'
import { ReactComponent as Age14 } from '../../assets/icons/ageGroup/age14.svg'

import { ReactComponent as Age18 } from '../../assets/icons/ageGroup/age18.svg'

export const AgeFreeIcon = styled(AgeFree)``
export const Age10Icon = styled(Age10)``
export const Age12Icon = styled(Age12)``
export const Age14Icon = styled(Age14)``
export const Age16Icon = styled(Age16)``
export const Age18Icon = styled(Age18)``

export const customStylesCategory = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '8px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '100%',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  > span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  > p {
    margin-left: 8px;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    text-align: center;
  }
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    background: lightgrey;
  }

  @media (max-width: 767px) {
    > span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    > p {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
    }
  }
`
