import * as S from './RegisterEvent.styles'
import {
  Heading,
  Paragraph,
  Input,
  Button,
  AlertModal,
  RadioButton
} from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createEventSchema,
  createEventSchemaWithCashback
} from './validationSchema'
import { ReduxState } from '../../../../redux/rootReducer'
import { ImageSelector } from './Components/ImageSelector'
import Select, { components, GroupBase, SingleValueProps } from 'react-select'
import { listSeatMapService } from '../../../../api/seatMap/listSeatMapServices'
import { history } from '../../../../routes/services/history'
import { editBasicInformationRequestAction } from '../../../../redux/Events/editActions'
import { CurrencyInput } from '../../../../components/CurrencyInput/CurrencyInput'
import { AgeGroupSelect } from '../../../../components/AgeGroupSelect/AgeGroupSelect'
import _ from 'lodash'

type AlertModalTypes = {
  message: string
  description?: string
  open: boolean
  variant: 'error' | 'success'
}
type OptionType = {
  value: string
  label: string
}

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line no-unused-vars
    Group extends GroupBase<Option>
  > {}
}

function SingleValue({
  children,
  ...props
}: SingleValueProps<OptionType, false>) {
  return <components.SingleValue {...props}>{children}</components.SingleValue>
}

type ErrorState = {
  error: boolean
  message: string
}

export const RegisterEvent = () => {
  const { editEvent } = useSelector((state: ReduxState) => state.EventReducer)

  const [options, setOptions] = useState<OptionType[]>([])
  const [mappedEvent] = useState(editEvent?.type === 'MAPPED')
  const [mobilePreview, setMobilePreview] = useState('')
  const [tabletPreview, setTabletPreview] = useState('')
  const [cardPreview, setCardPreview] = useState('')
  const [desktopPreview, setDesktopPreview] = useState<string>('')
  const [cashback, setCashback] = useState(false)
  const [alertModal, setAlertModal] = useState<AlertModalTypes>({
    open: false,
    message: '',
    variant: 'error'
  })
  const [errorCard, setErrorCard] = useState<ErrorState>({
    error: false,
    message: ''
  })
  const [errorMobile, setErrorMobile] = useState<ErrorState>({
    error: false,
    message: ''
  })
  const [errorTablet, setErrorTablet] = useState<ErrorState>({
    error: false,
    message: ''
  })
  const [errorDesktop, setErrorDesktop] = useState<ErrorState>({
    error: false,
    message: ''
  })
  const categoryOptions = [
    {
      label: 'Shows',
      value: 'Shows'
    },
    {
      label: 'Eventos online',
      value: 'Eventos online'
    },
    {
      label: 'Esportes',
      value: 'Esportes'
    },
    {
      label: 'Palestras',
      value: 'Palestras'
    },
    {
      label: 'Teatro',
      value: 'Teatro'
    }
  ]
  const filteredCategory = categoryOptions.findIndex(
    (option) => option.value === editEvent?.category
  )

  const [selectedCategory, setSelectedCategory] = useState(
    categoryOptions[filteredCategory]?.value ?? 'Shows'
  )
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      name: '',
      ageGroup: editEvent?.ageGroup ?? '',
      cashback: '',
      desktopUrl: '',
      tabletUrl: '',
      mobileUrl: '',
      cardUrl: '',
      seatMapId: editEvent?.seatMapId ? editEvent.seatMapId : ''
    },
    validationSchema: cashback
      ? createEventSchemaWithCashback
      : createEventSchema,
    onSubmit: (values) => {
      const banners = [
        {
          desktopUrl: values.desktopUrl !== '' ? values.desktopUrl : undefined,
          tabletUrl: values.tabletUrl !== '' ? values.tabletUrl : undefined,
          mobileUrl: values.mobileUrl !== '' ? values.mobileUrl : undefined,
          cardUrl: values.cardUrl !== '' ? values.cardUrl : undefined
        }
      ]

      dispatch(
        editBasicInformationRequestAction({
          name: values.name,
          category: selectedCategory,
          ageGroup: formik.values.ageGroup,
          cashBackPercent:
            cashback === false
              ? null
              : values.cashback !== String(0) && values.cashback !== ''
              ? Number(values.cashback)
              : null,
          banners: _.isEmpty(
            Object.entries(banners[0]).filter((value) => value[1] !== undefined)
              .length > 0
          )
            ? banners
            : undefined,
          seatMapId: mappedEvent ? formik.values.seatMapId : undefined
        })
      )
    }
  })

  const fetchSeatMaps = async () => {
    try {
      const data = await listSeatMapService()
      setOptions(
        data.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    } catch (e) {}
  }

  const handleSetField = (name: string, file: File | '') => {
    formik.setFieldValue(name, file)
  }

  useEffect(() => {
    fetchSeatMaps()
    if (editEvent) {
      formik.setFieldValue('name', editEvent.name)

      formik.setFieldValue(
        'desktopUrl',
        editEvent.banners && editEvent.banners[0]?.desktopUrl
          ? editEvent.banners[0]?.desktopUrl
          : ''
      )
      formik.setFieldValue(
        'tabletUrl',
        editEvent.banners && editEvent.banners[0]?.tabletUrl
          ? editEvent.banners[0]?.tabletUrl
          : ''
      )
      formik.setFieldValue(
        'mobileUrl',
        editEvent.banners && editEvent.banners[0]?.mobileUrl
          ? editEvent.banners[0]?.mobileUrl
          : ''
      )
      formik.setFieldValue(
        'cardUrl',
        editEvent.banners && editEvent.banners[0]?.cardUrl
          ? editEvent.banners[0]?.cardUrl
          : ''
      )
      setDesktopPreview(
        editEvent.banners && editEvent.banners[0]?.desktopUrl
          ? (editEvent.banners[0]?.desktopUrl as string)
          : ''
      )

      setMobilePreview(
        editEvent.banners && editEvent.banners[0]?.mobileUrl
          ? (editEvent.banners[0]?.mobileUrl as string)
          : ''
      )
      setTabletPreview(
        editEvent.banners && editEvent.banners[0]?.tabletUrl
          ? (editEvent.banners[0]?.tabletUrl as string)
          : ''
      )
      setCardPreview(
        editEvent.banners && editEvent.banners[0]?.cardUrl
          ? (editEvent.banners[0]?.cardUrl as string)
          : ''
      )
      if (
        editEvent?.cashBackPercent !== null &&
        editEvent?.cashBackPercent !== 0
      ) {
        setCashback(true)
        formik.setFieldValue('cashback', editEvent.cashBackPercent)
      }
    }
  }, [])

  const handleAgeGroupChange = (e: { value: string; label: string }) => {
    formik.setFieldValue('ageGroup', e.value)
  }

  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleBox>
          <S.BackIcon onClick={() => history.push('/events')} />
          <Heading variant="h3">Informações básicas</Heading>
        </S.TitleBox>
        <Paragraph variant="large">
          Adicione as principais informações do evento
        </Paragraph>

        <S.Form onSubmit={formik.handleSubmit}>
          <Input
            placeholder="Nome do evento"
            label="*Nome do evento"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            className="eventNameInput"
            error={formik.touched.name && Boolean(formik.errors.name)}
            texterror={formik.errors.name}
            fullWidth
          />

          <S.ChooseSectionType>
            <Paragraph variant="large" type="bold">
              Tipo de evento
            </Paragraph>
            <div className="radioButtonWrapper">
              <RadioButton checked={mappedEvent} readOnly />
              <div className="options-text">
                <Paragraph variant="large" type="semiBold">
                  Mapeado
                </Paragraph>
                <Paragraph variant="regular">
                  O evento terá um mapa de assentos no qual o usuário escolherá
                  seu lugar
                </Paragraph>
              </div>
            </div>
            <div className="radioButtonWrapper">
              <RadioButton checked={!mappedEvent} readOnly />
              <div className="options-text">
                <Paragraph variant="large" type="semiBold">
                  Tabelado
                </Paragraph>
                <Paragraph variant="regular">
                  Evento terá uma tabela de preços
                </Paragraph>
              </div>
            </div>
          </S.ChooseSectionType>

          {mappedEvent ? (
            <div style={{ width: 'max-content' }}>
              <Paragraph variant="large" type="semiBold">
                *Selecione o Mapa
              </Paragraph>

              <Select<OptionType>
                value={options.find(
                  (option) => option.value === formik.values.seatMapId
                )}
                onChange={(selectValue) => {
                  if (selectValue && selectValue.value) {
                    formik.setFieldValue('seatMapId', selectValue.value)
                  }
                }}
                placeholder="Selecione o mapa"
                styles={S.customStyles}
                options={options}
                components={{ SingleValue }}
                noOptionsMessage={() =>
                  'Mapa não encontrado, contate o suporte'
                }
              />
            </div>
          ) : null}
          <S.ImageText>
            <Paragraph variant="large" type="semiBold">
              Imagem principal do evento
            </Paragraph>
            <Paragraph variant="regular">
              Esta é a primeira imagem que os participantes verão no
              <br /> início da sua página. Use uma imagem de alta qualidade
            </Paragraph>
          </S.ImageText>
          <S.ImageSelectSection>
            <ImageSelector
              name={'cardUrl'}
              selectionText={'*Card do evento'}
              imagePreview={cardPreview}
              size={'270x145px'}
              setImage={setCardPreview}
              handleSetField={handleSetField}
              previewSize={{ width: 270, height: 145 }}
              setError={setErrorCard}
              error={errorCard}
            />
            <ImageSelector
              name={'mobileUrl'}
              selectionText={'*Imagem para celular'}
              imagePreview={mobilePreview}
              size={'270x145px'}
              setImage={setMobilePreview}
              handleSetField={handleSetField}
              previewSize={{ width: 270, height: 145 }}
              setError={setErrorMobile}
              error={errorMobile}
            />
            <ImageSelector
              name={'tabletUrl'}
              selectionText={'*Imagem para Mobile'}
              imagePreview={tabletPreview}
              size={'340x283px'}
              setImage={setTabletPreview}
              handleSetField={handleSetField}
              previewSize={{ width: 340, height: 280 }}
              setError={setErrorTablet}
              error={errorTablet}
            />
            <ImageSelector
              name={'desktopUrl'}
              selectionText={'*Imagem para desktop'}
              imagePreview={desktopPreview}
              size={'418x283px'}
              setImage={setDesktopPreview}
              handleSetField={handleSetField}
              previewSize={{ width: 420, height: 280 }}
              setError={setErrorDesktop}
              error={errorDesktop}
            />
          </S.ImageSelectSection>

          <Heading variant="h3">Classifique seu evento</Heading>

          <S.SelectWrapper style={{ marginTop: '30px' }}>
            <S.Label>Classificação Etária</S.Label>
            <AgeGroupSelect
              onChange={handleAgeGroupChange}
              value={formik.values.ageGroup}
            />

            <S.Error>
              {formik.touched.ageGroup &&
                Boolean(formik.errors.ageGroup) &&
                formik.errors.ageGroup}
            </S.Error>
          </S.SelectWrapper>

          <S.SelectWrapper style={{ marginTop: '30px' }}>
            <S.Label>Categoria</S.Label>
            <Select
              placeholder="Selecione uma opção"
              options={categoryOptions}
              defaultValue={categoryOptions[filteredCategory]}
              onChange={(e) => setSelectedCategory(e?.value ?? 'Shows')}
              styles={S.customStylesCategory}
            />
          </S.SelectWrapper>

          <S.CashbackBox>
            <div>
              <RadioButton
                readOnly
                checked={cashback}
                onClick={() => setCashback(!cashback)}
              />
              <Heading variant="h5">Evento com Cashback</Heading>
            </div>
            {cashback ? (
              <CurrencyInput
                style={{ height: '43px' }}
                name="cashback"
                label="Porcentagem de cashback"
                value={formik.values.cashback}
                error={
                  formik.touched.cashback && Boolean(formik.errors.cashback)
                }
                texterror={formik.errors.cashback}
                onChange={(e) => {
                  if (
                    e.target.value !== undefined &&
                    e.target.value.replace(/[a-zA-Z, %]/g, '').length > 0
                  ) {
                    formik.setFieldValue(
                      'cashback',
                      e.target.value.replace(/[a-zA-Z, %]/g, '')
                    )
                  }

                  if (
                    e.target.value !== undefined &&
                    e.target.value.replace(/[a-zA-Z, %]/g, '').length === 0
                  ) {
                    formik.setFieldValue('cashback', '')
                  }
                }}
                suffix=" %"
                decimalSeparator="."
                groupSeparator=","
                placeholder="Digite a % do cashback"
              />
            ) : null}
          </S.CashbackBox>
        </S.Form>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          fullWidth={false}
          type="submit"
          onClick={() => formik.handleSubmit()}>
          Continuar
        </Button>
        <AlertModal
          {...alertModal}
          buttonText="Fechar"
          click={() =>
            setAlertModal({ open: false, message: '', variant: 'error' })
          }
        />
      </S.Wrapper>
    </S.Container>
  )
}
