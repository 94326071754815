import { AxiosResponse } from 'axios'
import { api } from '../config'
import { CourseResponse } from './listCoursesService'

export type CourseEditRequest = {
  id: string
  name?: string
  description?: string
  priceCents?: number
  discountCents?: number
  coverUrl?: string
}

export const editCourseService = async (value: CourseEditRequest) => {
  const { data }: AxiosResponse<CourseResponse> = await api.put(
    `/api/courses/${value.id}`,
    {
      name: value.name,
      description: value.description,
      priceCents: value.priceCents,
      discountCents: value.discountCents,
      coverUrl: value.coverUrl
    }
  )

  return data
}
