import { useEffect } from 'react'
import * as S from './Loading.styles'
import Lottie from 'react-lottie'
import loadingAnimation from '../../assets/animation/lottie/loading.json'
import useControlScroll from '../../hooks/useControlScroll'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export const Loading = ({
  isLoading,
  blur
}: {
  isLoading: boolean
  blur?: boolean
}) => {
  const [blockScroll, allowScroll] = useControlScroll()
  useEffect(() => {
    if (isLoading) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [isLoading])
  return (
    <S.Container blur={blur} isLoading={isLoading}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </S.Container>
  )
}
