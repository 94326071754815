import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { EventItems, Tickets } from '../../../../../../redux/Events/types'
import { DesktopTicketTable } from './desktop/DesktopTicketTable'
import { MobileTicketTable } from './mobile/MobileTicketTable'

type Props = {
  eventItems: EventItems[]
  handleRemoveTicket: (ticketId: string, ticket: Tickets) => void
  handleEditTicket: (ticket: Tickets) => void
}

export const TicketTable = (props: Props) => {
  const { isMobile } = useWindowSize()

  return isMobile ? (
    <MobileTicketTable {...props} />
  ) : (
    <DesktopTicketTable {...props} />
  )
}
