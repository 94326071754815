import { AxiosResponse } from 'axios'
import { api } from '../config'

export type DetachResponse = {
  id: string
  customerTicketId: string
  seatId: string
  qrCode: string
  ticketInfo: Record<string, string>[] | null
  detachedAt: string
  isValid: boolean
  customerId: string
  createdAt: string
  updatedAt: string
  eventItemId: string
  ticketId: string
  purchaseOrderId: string
  ticket: {
    id: string
    name: string
    description: string
    priceCents: number
    discountCents: number
    type: string
    category: string
    rule: string
    createdAt: string
    updatedAt: string | null
    customField: string | null
  }
  customer: {
    id: string
    firstName: string
    lastName: string
  }
  eventItem: {
    id: string
    dates: string[]
    description: string
    title: string
    maxCustomerTickets: number
    ticketInfo: []
    createdAt: string
    eventKey: null
    salesFrom: string
    finalSales: string
    updatedAt: null
    eventId: string
    event: {
      id: string
      name: string
      type: 'TABULATED' | 'MAPPED'
      status: string
      isPublic: boolean
      description: string
      createdAt: string
      updatedAt: string
      creatorId: string
      seatMapId: string | null
      address: {
        city: string
        complement: string
        country: string
        createdAt: string
        creatorId: string
        customerId: string
        district: string
        eventId: string
        id: string
        name: string
        number: number
        seatMapId: string
        state: string
        street: string
        updatedAt: string
        zipCode: string
      }
    }
  }
  purchaseOrder: {
    id: string
    orderId: string
    status: 'SUCCESS' | 'WAITING' | 'FAILED'
    invoiceId: string
    pixQrCode: string
    pixQrCodeText: string
    customerId: string
    creatorId: string | null
    createdAt: string
    updatedAt: string
    paymentHistory: {
      id: string
      status: string
      totalPrice: number
      description: string
      lastCardNumbers: string | null
      brand: string | null
      paymentMethod: 'CREDIT_CARD' | 'PIX' | 'FREE'
      createdAt: string
      updatedAt: string
      customerId: string
      purchaseOrderId: string
      installments: number
    }
  }
}

export const ticketDetachService = async (customerTicketId: string) => {
  const { data }: AxiosResponse<DetachResponse> = await api.patch(
    `/api/creators/customerTickets/detach/${customerTicketId}`
  )

  return data
}
