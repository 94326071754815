import { Switch } from 'react-router'
import { Events } from '../pages/Events/Events'
import { PrivateRoute } from './PrivateRoute'
import { CreateEvent } from '../pages/CreateEvent/CreateEvent'
import { Profile } from '../pages/Profile/Profile'
import { EditEvent } from '../pages/EditEvent/EditEvent'
import { Scan } from '../pages/Scan/Scan'
import { ManageEvents } from '../pages/ManageEvents/ManageEvents'
import { DetachedTicket } from '../pages/DetachedTicket/DetachedTicket'
import { CustomerTicket } from '../pages/CustomerTicket/CustomerTicket'
import { EventPreview } from '../pages/EventPreview/EventPreview'
import { PaymentTicket } from '../pages/PaymentTicket/PaymentTicket'
import { Home } from '../pages/Home/Home'
import { BankData } from '../pages/BankData/BankData'
import { useSelector } from 'react-redux'
import { ReduxState } from '../redux/rootReducer'
import { Loading } from '../components/loading/Loading'
import { Route } from 'react-router-dom'
import { LoginCreatorPwa } from '../pages/loginCreatorPwa/LoginCreatorPwa'
import { Courtesy } from '../pages/Courtesy/Courtesy'
import { CourtesyEvent } from '../pages/CourtesyEvent/CourtesyEvent'
import { Partner } from '../pages/Partner/Partner'

export const Routes = () => {
  const { userInfo } = useSelector((state: ReduxState) => state.UserReducer)
  return (
    <Switch>
      <Route path="/loginCreator" component={LoginCreatorPwa} />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute path="/events" component={Events} />
      <PrivateRoute path="/createEvent" component={CreateEvent} />
      <PrivateRoute path="/editEvent/:id" component={EditEvent} />
      <PrivateRoute path="/detachedTicket" component={DetachedTicket} />
      <PrivateRoute
        path="/bankData"
        component={userInfo ? BankData : Loading}
      />
      <PrivateRoute path="/customerTicket" component={CustomerTicket} />
      <PrivateRoute path="/pucharseOrder" component={PaymentTicket} />
      <PrivateRoute path="/manageEvent/:id/:tab?" component={ManageEvents} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/scan" component={Scan} />
      <PrivateRoute path="/eventPreview/:id" component={EventPreview} />
      <PrivateRoute path="/courtesy" component={Courtesy} />
      <PrivateRoute path="/courtesyEvent/:eventId" component={CourtesyEvent} />
      <PrivateRoute path="/partners" component={Partner} />
    </Switch>
  )
}
