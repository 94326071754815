import { api } from '../config'
import { AxiosResponse } from 'axios'

export const downloadCsvService = async (eventId: string) => {
  const { data }: AxiosResponse<any> = await api.get(
    `/api/purchase/orders/events/${eventId}/csv`
  )
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'Planilha Ingressos.csv') //  or any other extension
  document.body.appendChild(link)
  link.click()
}
