import { Paragraph } from 'applaus-ui-kit'

import { EventItems, Tickets } from '../../../../../../../redux/Events/types'

import * as S from './MobileTicketTable.styles'

type Props = {
  eventItems: EventItems[]
  handleRemoveTicket: (ticketId: string, ticket: Tickets) => void
  handleEditTicket: (ticket: Tickets) => void
}

export const MobileTicketTable = ({
  eventItems,
  handleRemoveTicket,
  handleEditTicket
}: Props) => {
  return (
    <S.TableContainer>
      <S.TableHeader>
        <Paragraph type="semiBold" variant="large">
          Nome
        </Paragraph>
        <Paragraph type="semiBold" variant="large">
          Setor
        </Paragraph>
      </S.TableHeader>
      <S.TableBody>
        {eventItems
          .filter((eventItems) => eventItems.tickets.length > 0)
          .map((eventItem) =>
            eventItem.tickets.map((ticket) => (
              <S.TicketCard key={ticket.id}>
                <Paragraph type="normal" variant="regular">
                  {ticket.name}
                </Paragraph>
                <Paragraph type="normal" variant="regular">
                  {ticket.eventItems[0].title}
                </Paragraph>
                <div className="actions">
                  <S.EditIcon onClick={() => handleEditTicket(ticket)} />
                  <S.TrashIcon
                    onClick={() => handleRemoveTicket(ticket.id, ticket)}
                  />
                </div>
              </S.TicketCard>
            ))
          )}
      </S.TableBody>
    </S.TableContainer>
  )
}
