import { api } from '../config'

type Request = {
  currentPassword: string
  password: string
}

export const updatePasswordService = async (data: Request) => {
  await api.patch('/api/creators/credentials', data)
}
