import { AxiosResponse } from 'axios'
import { api } from '../config'

export type EventPurchaseOrder = {
  id: string
  orderId: string
  status: 'SUCCESS' | 'WAITING' | 'FAILED'
  invoiceId: string
  pixQrCode: string | null
  pixQrCodeText: string | null
  failedCode: string | null
  customField: string | null
  customerId: string
  creatorId: string | null
  createdAt: string
  updatedAt: string | null
  customerTickets: {
    id: string
    customerTicketId: string
    seatId: string
    qrCode: string
    ticketInfo: Record<string, string>[] | null
    detachedAt: string | null
    isValid: boolean
    customerId: string
    createdAt: string
    updatedAt: string | null
    eventItemId: string
    ticketId: string
    purchaseOrderId: string
    eventItem: {
      id: string
      dates: string[]
      description: string
      title: string
      maxCustomerTickets: number
      ticketInfo: {
        value: string
        isRequired: boolean
      }[]
      createdAt: string
      eventKey: string
      salesFrom: string
      finalSales: string
      updatedAt: string | null
      eventId: string
      event: {
        Creator: {
          id: string
        }
        address: {
          id: string
          name: string | null
          country: string
          state: string
          city: string
          street: string
          number: number
          zipCode: string
          latitude: string | null
          longitude: string | null
          complement: string | null
          district: string | null
          createdAt: string
          updatedAt: string | null
          creatorId: string | null
          customerId: string | null
          eventId: string
          seatMapId: string | null
        }
      }
    }
    ticket: {
      id: string
      name: string
      description: string
      priceCents: number
      discountCents: number
      type: string
      category: string
      rule: string | null
      createdAt: string
      updatedAt: string | null
      customField: string | null
    }
  }[]
  paymentHistory?: {
    id: string
    status: 'APPROVED' | 'PROCESSING' | 'DECLINED'
    totalPrice: number
    description: string
    lastCardNumbers: number
    brand: string
    paymentMethod: 'CREDIT_CARD' | 'PIX' | 'FREE' | 'CASH_BACK'
    installments: number | null
    createdAt: string
    updatedAt: string
    customerId: string
    purchaseOrderId: string
  }
  customer: {
    id: string
    firstName: string
    lastName: string
    email: string
  }
}

export type PurchaseOrdersByEventResponse = {
  items: EventPurchaseOrder[]
  total: number
}

export const listEventPurchaseOrder = async (
  eventId: string,
  skip?: number
) => {
  const { data }: AxiosResponse<PurchaseOrdersByEventResponse> = await api.get(
    `/api/creators/events/${eventId}/orders?take=10&skip=${
      skip ? skip * 10 : 0
    }`
  )

  return data
}
