import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  min-width: 320px;
  ${media.greaterThan('large')`
    grid-template-columns: repeat(2, 1fr);
`}
`

export const TemplateImage = styled.div`
  ${() => css`
    position: fixed;
    left: 0;
    top: 0;
    right: 50vw;
  `}
`

export const Image = styled.img`
  ${() => css`
    height: 100vh;
    width: 100%;
  `}
`

export const TemplatePage = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`
