import { Paragraph } from 'applaus-ui-kit'
import produce from 'immer'
import { Dispatch, SetStateAction } from 'react'
import {
  CustomerTicket,
  EventItems,
  Tickets
} from '../../../../redux/Events/types'
import { formatPrice } from '../../../../utils/formatPrice'
import { DescriptionCard } from '../DescriptionCard/DescriptionCard'
import * as S from './EventTabulated.styles'

type EventTabulatedProps = {
  selectedTickets: CustomerTicket[] | undefined
  setSelectedTickets: Dispatch<SetStateAction<CustomerTicket[] | undefined>>
  selectedEvent: EventItems | undefined
}

export const EventTabulated = ({
  selectedTickets,
  setSelectedTickets,
  selectedEvent
}: EventTabulatedProps) => {
  const setTicket = (ticket: Tickets) => {
    setSelectedTickets(
      produce((draft) => {
        draft?.push({
          category: ticket.category!,
          eventItemId: selectedEvent?.id!,
          id: ticket.id,
          name: ticket.name,
          totalPrice: ticket.priceCents! / 100,
          ticketInfo: selectedEvent?.ticketInfo!.map((info) => ({
            value: '',
            isRequired: info.isRequired,
            name: info.value
          }))
        })
      })
    )
  }

  const removeTicket = (name: string) => {
    const findIndex = selectedTickets?.findIndex(
      (ticket) => ticket.name === name
    )

    if (findIndex! > -1) {
      setSelectedTickets(
        produce((draft) => {
          draft?.splice(findIndex!, 1)
        })
      )
    }
  }

  const lenghtTickets = (ticketId: string) => {
    return selectedTickets?.filter(
      (selectedTicket) => selectedTicket.id === ticketId
    ).length
  }

  return (
    <S.Container>
      <div className="descriptionCard">
        <DescriptionCard title="Ingressos" />
      </div>

      <S.Tickets>
        {selectedEvent?.tickets.map((ticket) => (
          <S.TicketCard key={ticket.id}>
            <S.DescriptionTicket>
              <S.TicketName>{ticket.name}</S.TicketName>
              <S.TicketPrice>
                {formatPrice(ticket.priceCents! / 100)}
              </S.TicketPrice>
            </S.DescriptionTicket>
            <S.ActionsTicket>
              <S.Circle
                isActive={lenghtTickets(ticket.id) !== 0}
                onClick={() => {
                  removeTicket(ticket.name)
                }}>
                <div className="trace"></div>
              </S.Circle>
              <Paragraph variant="small">{lenghtTickets(ticket.id)}</Paragraph>
              <S.Circle
                isActive={true}
                onClick={() => {
                  setTicket(ticket)
                }}>
                <div className="trace"></div>
              </S.Circle>
            </S.ActionsTicket>
          </S.TicketCard>
        ))}
      </S.Tickets>
    </S.Container>
  )
}
