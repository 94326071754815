import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    max-width: 740px;
    .descriptionCard {
      margin-top: ${theme.spacing.stack.md};
    }
  `}
`

export const Tickets = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.spacing.stack.xxxs};

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  `}
`

export const TicketCard = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: ${theme.color.base.nth4};
    padding: 12px ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.light};
  `}
`

export const DescriptionTicket = styled.div`
  ${({ theme }) => css``}
`

export const TicketName = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.xxs};
    color: ${theme.color.base.nth1};
    line-height: 18px;
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const TicketPrice = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.xxs};
    color: ${theme.color.base.nth1};
    line-height: 12px;
  `}
`

export const ActionsTicket = styled.div`
  ${({ theme }) => css`
    display: flex;

    > div {
      margin: auto 0 auto;
    }
    > p {
      margin: auto ${theme.spacing.stack.xxxs};
      width: 10px;
    }
  `}
`

export const Circle = styled.div<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    cursor: pointer;
    width: 25px;
    height: 28px;
    border: ${isActive
      ? '1px solid' + theme.color.brand.primary.nth1
      : '1px solid' + theme.color.base.nth3};
    border-radius: 50%;

    .trace {
      width: 6px;
      position: relative;
      left: 9px;
      top: 12px;
      border: ${isActive
        ? '1px solid' + theme.color.base.nth1
        : '1px solid' + theme.color.base.nth3};
    }
  `}
`
