import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { listOneEventService } from '../../api/events/listOneEventService'
import { useWindowSize } from '../../hooks/useWindowSize'
import { DesktopEventProfile } from './desktop/EventProfile'
import { Event } from './mobile/Event'
import { EditEvent } from '../../redux/Events/types'

export const EventPreview = () => {
  const { isMobile } = useWindowSize()
  const { id } = useParams<{ id: string }>()
  const [eventState, setEventState] = useState<EditEvent | undefined>()

  useEffect(() => {
    const fetchEventData = async () => {
      const dataState = await listOneEventService(id)
      setEventState(dataState as EditEvent)
    }

    fetchEventData()
  }, [])

  if (eventState) {
    if (isMobile) {
      return <Event event={eventState} />
    } else return <DesktopEventProfile event={eventState} />
  } else return <></>
}
