import * as S from './BankData.styles'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'

import {
  Container as GridContainer,
  Row,
  Column
} from '../../components/grid/GridSystem'
import { Heading, Paragraph, Button, InputMask } from 'applaus-ui-kit'
import ReactSelect from 'react-select'

import { BankOptions, bankInfos } from './banks'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../redux/rootReducer'
import { motion } from 'framer-motion'
import { bankAccountVerificationService } from '../../api/user/bankAccountVerificationService'
import axios from 'axios'
import { setAlertModalAction } from '../../redux/AlertModal/actions'
import { editCreatorPaymentSettingSuccess } from '../../redux/User/actions'
import { editAccountAddressService } from '../../api/user/editAccountAddress'
import { useWindowSize } from '../../hooks/useWindowSize'
import { bankValidation } from './validationSchema'

const mappedBanks = Object.entries(BankOptions).map((values) => ({
  value: values[0],
  label: values[1]
}))

const accountOptions = [
  { value: 'Corrente', label: 'Conta Corrente' },
  { value: 'Poupança', label: 'Conta Poupança' }
]

export const BankData = () => {
  const { userInfo } = useSelector((state: ReduxState) => state.UserReducer)
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch()

  if (userInfo !== undefined) {
    const { bank, bankCc, bankAg, bankAccountType, status } =
      userInfo.creatorPaymentSetting

    const formik = useFormik({
      initialValues: {
        bank: bank ?? '',
        bankAg: bankAg ?? '',
        bankCc: bankCc ?? '',
        bankAccountType: bankAccountType ?? ''
      },
      onSubmit: async (values) => {
        try {
          if (status !== 'ACCEPTED') {
            if (
              status === 'WAITING' &&
              bank !== null &&
              bankAg !== null &&
              bankCc !== null &&
              bankAccountType !== null
            ) {
              const data = await editAccountAddressService(values)
              dispatch(editCreatorPaymentSettingSuccess(data))
              dispatch(
                setAlertModalAction({
                  open: true,
                  variant: 'success',
                  title:
                    'Conta bancária alterada, aguarde o processo de análise.',
                  buttonText: 'Ok'
                })
              )
            } else {
              const data = await bankAccountVerificationService(values)
              dispatch(editCreatorPaymentSettingSuccess(data))
              dispatch(
                setAlertModalAction({
                  open: true,
                  variant: 'success',
                  title: 'Sua conta bancária foi enviada para análise.',
                  buttonText: 'Ok'
                })
              )
            }
          } else {
            const data = await editAccountAddressService(values)
            dispatch(editCreatorPaymentSettingSuccess(data))
            dispatch(
              setAlertModalAction({
                open: true,
                variant: 'success',
                title:
                  'Conta bancária alterada, aguarde o processo de análise.',
                buttonText: 'Ok'
              })
            )
          }
        } catch (err) {
          if (
            axios.isAxiosError(err) &&
            err.response &&
            err.response.data.message
          ) {
            const { message } = err.response.data
            dispatch(
              setAlertModalAction({
                open: true,
                variant: 'error',
                title:
                  message === 'Address is incomplete'
                    ? 'Endereço está incompleto'
                    : 'Algo deu errado tente novamente mais tarde',
                buttonText: 'Fechar'
              })
            )
          } else {
            dispatch(
              setAlertModalAction({
                open: true,
                variant: 'error',
                title: 'Algo deu errado tente novamente mais tarde',
                buttonText: 'Fechar'
              })
            )
          }
        }
      },
      validationSchema: bankValidation
    })
    const currentBank = bankInfos.find(
      (item) => item.bankNumber === formik.values.bank
    )

    return (
      <AuthenticatedTemplate isActive="bankData">
        <S.Container onSubmit={formik.handleSubmit}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>
            <GridContainer>
              <Row
                marginTop={isMobile ? 'sm' : 'xxxl'}
                marginBottom={isMobile ? 'xxs' : 'sm'}>
                <Column tablet={8} desktop={12}>
                  <Heading variant="h5" className="title">
                    Dados Bancários
                  </Heading>
                </Column>
              </Row>
              {(userInfo.creatorPaymentSetting.status === 'WAITING' &&
                userInfo.creatorPaymentSetting.bank !== '') ||
              userInfo.creatorPaymentSetting.status === 'REJECTED' ? (
                <Row>
                  <Column mobile={4} tablet={8} desktop={12}>
                    <S.RequiredBankData>
                      <S.RequiredIcon />
                      <p>
                        Para criar um evento é necessário preencher seus dados
                        bancários
                      </p>
                    </S.RequiredBankData>
                  </Column>
                </Row>
              ) : null}
              <Row>
                <Column tablet={4} desktop={4}>
                  <Paragraph variant="regular" className="status-title">
                    Status
                  </Paragraph>
                </Column>
              </Row>
              <Row marginBottom="small">
                <Column tablet={4} desktop={6}>
                  <Paragraph
                    variant="regular"
                    className={`status ${status.toLowerCase()}`}>
                    {status === 'WAITING'
                      ? 'Pendente'
                      : status === 'ACCEPTED'
                      ? 'Aprovada'
                      : 'Rejeitada'}
                  </Paragraph>
                </Column>
              </Row>
              {status === 'WAITING' ? (
                bank === undefined ? (
                  <Row marginBottom="sm">
                    <Paragraph variant="regular" className="status-info">
                      Aguardando a validação dos seus dados bancários
                    </Paragraph>
                  </Row>
                ) : (
                  <Row marginBottom="sm">
                    <Paragraph variant="regular" className="status-info">
                      Envie seus dados bancários para verificação
                    </Paragraph>
                  </Row>
                )
              ) : undefined}
              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={3} desktop={4}>
                  <S.InputWrapper>
                    <S.Label htmlFor="banks">Banco</S.Label>
                    <ReactSelect
                      id="banks"
                      placeholder="Banco"
                      options={mappedBanks}
                      onChange={(e) => formik.setFieldValue('bank', e?.value)}
                      // @ts-ignore
                      styles={S.customStyles}
                      value={mappedBanks.find(
                        (option) =>
                          option.label === formik.values.bank ||
                          option.value === formik.values.bank
                      )}
                    />
                    <S.Error>
                      {formik.touched.bank &&
                        Boolean(formik.errors.bank) &&
                        formik.errors.bank}
                    </S.Error>
                  </S.InputWrapper>
                </Column>
                <Column mobile={4} tablet={3} desktop={8}>
                  <InputMask
                    mask={currentBank ? currentBank.agency : ''}
                    value={formik.values.bankAg}
                    label="Agência"
                    name="bankAg"
                    fullWidth
                    texterror={formik.errors.bankAg}
                    error={
                      formik.touched.bankAg && Boolean(formik.errors.bankAg)
                    }
                    onChange={formik.handleChange}
                  />
                </Column>
              </Row>
              <Row gutter={isMobile ? 0 : 2}>
                <Column mobile={4} tablet={3} desktop={4}>
                  <InputMask
                    mask={currentBank ? currentBank.account : ''}
                    value={formik.values.bankCc}
                    label="Conta"
                    name="bankCc"
                    fullWidth
                    error={
                      formik.touched.bankCc && Boolean(formik.errors.bankCc)
                    }
                    texterror={formik.errors.bankCc}
                    onChange={formik.handleChange}
                  />
                </Column>
                <Column mobile={4} tablet={3} desktop={8}>
                  <S.InputWrapper>
                    <S.Label htmlFor="accountType">Tipo de Conta</S.Label>
                    <ReactSelect
                      id="accountType"
                      value={accountOptions.find(
                        (option) =>
                          option.value === formik.values.bankAccountType
                      )}
                      placeholder="Tipo de conta"
                      options={accountOptions}
                      onChange={(e) =>
                        formik.setFieldValue('bankAccountType', e?.value)
                      }
                      // @ts-ignore
                      styles={S.customStyles}
                    />

                    <S.Error>
                      {formik.touched.bankAccountType &&
                        Boolean(formik.errors.bankAccountType) &&
                        formik.errors.bankAccountType}
                    </S.Error>
                  </S.InputWrapper>
                </Column>
              </Row>
              <Row marginTop="sm" marginBottom={isMobile ? 'xxs' : undefined}>
                <Column mobile={4} tablet={1} desktop={12}>
                  <S.ButtonWrapper>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth={isMobile}
                      type="submit">
                      {status !== 'ACCEPTED' ? 'Salvar' : 'Editar'}
                    </Button>
                  </S.ButtonWrapper>
                </Column>
              </Row>
            </GridContainer>
          </motion.div>
        </S.Container>
      </AuthenticatedTemplate>
    )
  } else return <></>
}
