import { ReactNode } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'

import { Route, RouteProps } from 'react-router-dom'
import { ReduxState } from '../redux/rootReducer'
import { history } from './services/history'

const authUrl = process.env.REACT_APP_AUTH_URL

export type PrivateRouteProps = {
  component: ReactNode
} & RouteProps

export const PrivateRoute = ({ component, ...rest }: PrivateRouteProps) => {
  const { accessToken } = useSelector(
    (state: ReduxState) => state.UserReducer.data
  )
  const getCookie = useCookies(['applaus-auth-creator'])[0]

  if (accessToken === '' && !getCookie['applaus-auth-creator']) {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      history.push('/loginCreator')
    } else {
      window.location.href = authUrl ?? ''
    }
  }

  return <Route {...rest} component={component} />
}
