import styled, { css } from 'styled-components'

import { ReactComponent as AlertFile } from '../../assets/icons/alert-circle-sharp.svg'

export const Container = styled.form`
  ${({ theme }) => css`
    .title {
      font-size: 27px;
      font-style: normal;
      font-weight: 600;
      line-height: 41px;
      text-align: left;
    }
    .status-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
    }
    .status {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
    }
    .status-info {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      text-align: left;
    }

    .accepted {
      color: ${theme.color.alert.success.nth1};
    }
  `}
`

export const RequiredBankData = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xs};
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    background: #f4f4f4;
    margin-bottom: ${theme.spacing.stack.sm};
    > p {
      margin-left: ${theme.spacing.stack.xxs};
      color: ${theme.color.base.nth1};
      font-size: 16px;
      font-weight: 400;
      line-height: 12px;
    }

    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};

      > p {
        font-size: 9px;
        align-items: normal;
      }
    }
  `}
`

export const RequiredIcon = styled(AlertFile)`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      width: 50px;
    }
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
     {
      color: ${theme.color.base.nth1};
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 4px;
    }
  `}
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  > button {
    margin-left: auto;
  }
`

export const Error = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.alert.error.nth1};
    font-size: ${theme.font.size.xxs};
    height: ${theme.spacing.stack.xxs};
  `}
`
