export const messageHelper = {
  yup: {
    creator: {
      success: {},
      error: {
        corporateNameIsMandatory: 'Razão social é obrigatória',
        shortName: 'Nome deve ter mínimo 2 caracteres',
        longName: 'Nome deve ter menos do que 55 caracteres',
        nameIsMandatory: 'Nome é obrigatório.',
        ageGroupIsMandatory: 'Classificação etária é obrigatória.',
        shortLastName: 'Sobrenome deve ter mínimo 2 caracteres',
        longLastName: 'Sobrenome deve ter menos do que 55 caracteres',
        lastNameIsMandatory: 'Sobrenome é obrigatório.',
        cnpjIsMandatory: 'CNPJ é obrigatório.',
        cnpjIsInvalid: 'CNPJ inválido.',
        documentIsMandatory: 'CPF é obrigatório.',
        documentIsInvalid: 'CPF é inválido.',
        emailIsMandatory: 'Email é obrigatório.',
        emailIsInvalid: 'Digite um email válido.',
        phoneIsMandatory: 'Telefone é obrigatório.',
        phoneIsInvalid: 'Telefone inválido.',
        cepIsMandatory: 'CEP é obrigatório.',
        cepIsInvalid: 'CEP inválido.',
        streetIsMandatory: 'Rua é obrigatória.',
        districtIsMandatory: 'Bairro é obrigatório.',
        cityIsMandatory: 'Cidade é obrigatória.',
        cityIsInvalid: 'Digite uma cidade válida.',
        stateIsMandatory: 'Estado é obrigatório.',
        stateIsInvalid: 'Digite um estado válido.',
        shortAddress: 'Endereço deve ter mínimo 5 caracteres',
        longAddress: 'Endereço deve ter menos do que 255 caracteres',
        shortComplement: 'Complemento deve ter mínimo 5 caracteres',
        longComplement: 'Complemento deve ter menos do que 255 caracteres',
        passwordConfirmation: 'Senhas não coincidem',
        courseShortName: 'Nome deve ter mínimo 2 caracteres.',
        courseLongName: 'Nome deve ter menos do que 128 caracteres.',
        courseShortDescription: 'Descrição do deve ter mínimo 3 caracteres.',
        courseLongDescription:
          'Descrição deve ter menos do que 255 caracteres.',
        descriptionIsMandatory: 'Descrição é obrigatória.',
        imageIsMandatory: 'Imagem é obrigatória.',
        priceIsMandatory: 'Preço é obrigatório.',
        discountIsMandatory: 'Desconto é obrigatório.',
        maxTicketsNoZero:
          'Quantidade máxima de tickets não pode ser igual a zero'
      }
    }
  },
  axios: {
    createCourseTitle400: 'Não foi possivel criar o curso',
    createCourseDescription400: 'Verifique os dados inseridos',
    editCourseTitle400: 'Não foi possivel editar o curso',
    editCourseDescription400: 'Verifique os dados inseridos',
    internalErrorTitle: 'Erro interno',
    internalErrorDescription: 'Tente novamente mais tarde'
  }
}
