import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Editor, Container } from './RichText.styles'

type Props = {
  data: string
  onChange: (text: string) => void
  onReady: () => void
}

export const RichText = ({ data, onChange, onReady }: Props) => {
  const handleChange = (event: Event, editor: any) => {
    const onChangeData = editor.getData()
    onChange(onChangeData)
  }
  return (
    <Container>
      <Editor
        editor={ClassicEditor}
        data={data}
        onChange={handleChange}
        onReady={onReady}
      />
    </Container>
  )
}
