import styled, { css } from 'styled-components'

import { ReactComponent as RemoveFile } from '../../../../../../../assets/icons/trash-alt.svg'
import { ReactComponent as EditFile } from '../../../../../../../assets/icons/edit.svg'

export const TrashIcon = styled(RemoveFile)`
  fill: #808080;
  margin-left: 1rem;
  cursor: pointer;
  &:hover {
    fill: black;
  }
  transition: 0.3s ease;
`
export const EditIcon = styled(EditFile)`
  ${({ theme }) => css`
    fill: #8601c9;
    cursor: pointer;
  `}
`

export const TableContainer = styled.section`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const TableHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #f4f4f4;
    margin-bottom: ${theme.spacing.stack.xxs};
    padding: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
    > p {
      color: #808080;
    }
  `}
`
export const TableBody = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    > div {
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const TicketCard = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    padding: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);

    > p:nth-child(2) {
      overflow: auto;
      white-space: nowrap;
      ::-webkit-scrollbar {
        display: none;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
    }
  `}
`
