import { Paragraph } from 'applaus-ui-kit'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Select, { MultiValue } from 'react-select'
// @ts-ignore
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'

import { ReduxState } from '../../../../../../../redux/rootReducer'
import * as S from './MappedTicket.styles'
import { TicketAccordion } from './components/TicketAccordion/TicketAccordion'

type Props = {
  setCreateTicket: () => void
}

type ChartCategories = {
  label: string
}

export const MappedTicket = ({ setCreateTicket }: Props) => {
  const { manageEvent } = useSelector((state: ReduxState) => state.EventReducer)

  const [selectedValue, setSelectedValue] = useState<string[]>([])

  const [categories, setCategories] = useState<string[]>([])

  const handleChange = (e: MultiValue<{ value: string; label: string }>) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])
  }

  const removeSelected = (value: string) => {
    setSelectedValue((prev) => prev.filter((select) => select !== value))
  }

  const options = manageEvent?.eventItems
    ? manageEvent!.eventItems!.map(({ id, title }) => ({
        value: id ?? '',
        label: title
      }))
    : []

  return (
    <>
      <Paragraph variant="regular" type="semiBold">
        *Selecione sessão
      </Paragraph>
      <Select
        className="dropdown"
        placeholder="Selecione a sessão "
        value={options.filter((obj) => selectedValue.includes(obj.value))}
        options={options}
        onChange={(e) => handleChange(e)}
        styles={S.CustomStyles}
        controlShouldRenderValue={false}
        isMulti
        isClearable
      />
      <S.SelectedSections>
        {options
          .filter((obj) => selectedValue.includes(obj.value))
          .map((selected) => (
            <S.SelectedSectionCard key={selected.value}>
              <Paragraph variant="regular" type="semiBold">
                {selected.label}
              </Paragraph>
              <S.RemoveIcon onClick={() => removeSelected(selected.value)} />
            </S.SelectedSectionCard>
          ))}
      </S.SelectedSections>
      {selectedValue.length !== 0 ? (
        <>
          <SeatsioSeatingChart
            mode="static"
            workspaceKey={process.env.REACT_APP_PUBLIC_KEY_WORKSPACE}
            event={
              manageEvent?.eventItems?.find(
                (eventItem) => eventItem.id === selectedValue[0]
              )!.eventKey
            }
            onChartRendered={async (createdChart: any) => {
              const chartCategories: ChartCategories[] =
                await createdChart.listCategories()

              setCategories(chartCategories.map(({ label }) => label))
            }}
            onChartRenderingFailed={() => setCreateTicket()}
            region="sa"
            language="pt"
            session="continue"
            multiSelectEnabled={true}
            showSectionPricingOverlay={true}
          />
          <S.CategoriesSection>
            <Paragraph type="semiBold" variant="large">
              Escolha a seção do ingresso
            </Paragraph>

            <Paragraph type="semiBold" variant="large" className="categories">
              Nome
            </Paragraph>
            {categories.map((categorie, index) => (
              <TicketAccordion
                key={categorie}
                category={categorie}
                eventItems={selectedValue}
              />
            ))}
          </S.CategoriesSection>
        </>
      ) : undefined}
    </>
  )
}
