import { api } from '../config'
import { AxiosResponse } from 'axios'
import { EditEvent } from '../../redux/Events/types'
import { isEmpty } from 'lodash'

export type Pixels = {
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export const updateEventPixelsService = async (
  eventId: string,
  origin: Pixels | null,
  requestData: Pixels
) => {
  console.log('ON SVC', origin, requestData)
  const changes: any = {}
  for (const prop in origin) {
    const propName = prop as
      | 'facebookpixel'
      | 'googleanalyticspixel'
      | 'googletagmanagerpixel'
    if (
      propName.indexOf('$') !== 0 &&
      origin[propName] !== requestData[propName]
    ) {
      changes[propName] = requestData[propName]
    }
  }
  console.log('ON SVC 2', changes)
  if (!isEmpty(changes) || origin === null) {
    const { data }: AxiosResponse<EditEvent> = await api.patch(
      `/api/events/${eventId}/pixels`,
      {
        pixels: {
          ...requestData
        }
      }
    )
    return data.pixels
  }
}
