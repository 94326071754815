import styled, { css } from 'styled-components'

import { ReactComponent as BackFile } from '../../../../../assets/icons/backIcon.svg'
import { ReactComponent as TrashFile } from '../../../../../assets/icons/trash.svg'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 1366px;
    width: 100%;
    align-self: center;
    @media (max-width: 768px) {
      .maxTickets {
        width: 100%;
      }
    }
    @media (min-width: 1171px) {
      .maxTickets {
        width: max-content;
      }
    }

    .inputHeight {
      height: 43px;
    }
  `}
`

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`
export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const EventInfoForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: ${theme.spacing.stack.md};
    > button {
      align-self: end;
    }
    @media (max-width: 767px) {
      > div:first-child {
        width: 100%;
      }
    }
  `}
`

export const DateSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0;
  > div {
    margin: 2rem 0;
  }
  > button {
    align-self: start;
  }
  @media (min-width: 768px) {
    > div {
      width: max-content;
    }
  }

  @media (max-width: 380px) {
    label {
      max-width: 275px;
    }
  }
`

export const SalesPeriod = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    div:first-child {
      margin-right: 2rem;
    }
  }
`

export const DateContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    > p {
      color: #6c01a3;
    }
    @media (min-width: 1171px) {
      width: max-content;
    }
  `}
`

export const InputBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 768px) {
      flex-direction: row;
      > div:nth-child(2) {
        margin-left: ${theme.spacing.stack.xxs};
      }
    }
  `}
`

export const TrashIcon = styled(TrashFile)`
  ${({ theme }) => css`
    width: 50px;
    height: 50px;
    margin-left: 1rem;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 32px;
      height: 32px;
      align-self: center;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      width: 40px;
      height: 40px;
      align-self: center;
    }
  `}
`

export const Input = styled.div`
  ${({ theme }) => css`
    width: 100%;
    input {
      border: 0;
      padding: ${theme.spacing.stack.xxxs};
      background: #f4f4f4;
      border-radius: ${theme.border.radius.medium};
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.semiBold};
      color: #808080;
      width: 100%;
    }
    > div {
      display: flex;
    }
  `}
`

export const RequiredUserInformationBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    > button {
      margin: ${theme.spacing.stack.xs} 0;
      width: max-content;
    }
  `}
`

export const RequiredInfoHeader = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    margin-top: ${theme.spacing.stack.xs};
    border-radius: ${theme.border.radius.medium};
    column-gap: 1rem;
    @media (min-width: 1171px) {
      grid-template-columns: 6fr 4fr 1fr;
      max-width: 550px;
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      max-width: 550px;
    }
  `}
`

export const TrashIconData = styled(TrashFile)`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    margin-left: 1rem;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      align-self: center;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      width: 30px;
      height: 30px;
      align-self: center;
    }
  `}
`

export const RequiredInfoData = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    column-gap: 1rem;
    margin-top: ${theme.spacing.stack.xxs};

    .trashOption {
      display: none;
    }

    > div:nth-child(2) {
      margin-top: ${theme.spacing.stack.nano};
    }

    > div:nth-child(3) {
      display: flex;
      align-items: center;
    }

    @media (max-width: 767px) {
      > div:nth-child(2) {
        min-width: 95px;
      }
    }

    @media (max-width: 650px) {
      > div:nth-child(2) {
        > div:first-child {
          min-width: 250px;
          max-width: 275px;
        }
      }
    }

    @media (min-width: 1171px) {
      grid-template-columns: 6fr 4fr 1fr;
      max-width: 550px;
    }

    @media (min-width: 768px) and (max-width: 1170px) {
      max-width: 550px;
    }

    @media (max-width: 650px) {
      display: flex;
      flex-direction: column;

      .trash {
        display: none !important;
      }

      .trashOption {
        display: flex;
        margin-top: 20px;
      }

      .obrigatoryBox {
        display: flex;
        > div {
          width: 100%;
        }
      }
    }
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '7px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  indicatorsContainer: (provided: any) => ({
    div: {
      padding: '0'
    }
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}
