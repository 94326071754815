import { theme, AlertModal } from 'applaus-ui-kit'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Router } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { Routes } from './routes/routes'
import { history } from './routes/services/history'
import GlobalStyles from './styles/GlobalStyles'
import { decode } from 'jsonwebtoken'
import { getUserInfoRequest, setUserAction } from './redux/User/actions'
import { ReduxState } from './redux/rootReducer'
import { clearModalAction } from './redux/AlertModal/actions'
import { Loading } from './components/loading/Loading'

function App() {
  // eslint-disable-next-line no-unused-vars
  const [getCookie, _, removeCookie] = useCookies(['applaus-auth-creator'])
  const dispatch = useDispatch()
  const { AlertModalReducer, LoadingReducer } = useSelector(
    (state: ReduxState) => state
  )

  useEffect(() => {
    if (getCookie['applaus-auth-creator']) {
      const decoded = decode(getCookie['applaus-auth-creator'].accessToken)

      if (decoded && typeof decoded !== 'string') {
        dispatch(
          setUserAction({
            data: {
              name: decoded.name,
              email: decoded.email,
              accessToken: getCookie['applaus-auth-creator'].accessToken,
              refreshToken: getCookie['applaus-auth-creator'].refreshToken
            }
          })
        )
      }
    }
    dispatch(getUserInfoRequest())
  }, [])

  const { open, title, description, buttonText, variant, click } =
    AlertModalReducer

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Routes />
        <GlobalStyles />
      </Router>
      <AlertModal
        open={open}
        message={title}
        description={description}
        variant={variant}
        buttonText={buttonText}
        click={click || (() => dispatch(clearModalAction()))}
      />
      <Loading isLoading={LoadingReducer.isLoading} />
    </ThemeProvider>
  )
}

export default App
