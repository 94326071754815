import { Button, Input, Paragraph, Subtitle } from 'applaus-ui-kit'
import axios from 'axios'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { updatePasswordService } from '../../../api/profile/updatePasswordService'
import { clearLoading, setLoading } from '../../../redux/Loading/actions'
import { setModalState } from '../../../redux/Modal/Actions/actionsModal'
import { ReduxState } from '../../../redux/rootReducer'
import { sleep } from '../../../utils/sleep'
import { UpdatePasswordValidateSchema } from '../validation/UpdatePasswordSchema'
import * as S from './UpdatePasswordModal.styles'

type Props = {
  isOpen: boolean
  closeModal: () => void
}

export const UpdatepasswordModal = ({ isOpen, closeModal }: Props) => {
  const { open } = useSelector((state: ReduxState) => state.ModalReducer)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: UpdatePasswordValidateSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        dispatch(setLoading())
        await updatePasswordService({
          currentPassword: values.currentPassword,
          password: values.newPassword
        })

        dispatch(
          setModalState({
            message: 'Senha alterada com sucesso',
            open: true,
            variant: 'success',
            buttonText: 'Ok'
          })
        )
        sleep(3000)
        closeModal()
        dispatch(clearLoading())
      } catch (e) {
        dispatch(clearLoading())
        if (axios.isAxiosError(e) && e.response?.data) {
          const { message } = e.response?.data as { message: string }
          switch (message) {
            case 'password verification failed':
              dispatch(
                setModalState({
                  message: 'Senha atual inválida',
                  description:
                    'Tente novamente, caso não lembre sua senha atual selecione a opção esqueci minha senha',
                  open: true,
                  variant: 'error',
                  buttonText: 'Fechar'
                })
              )
              break
            default:
              dispatch(
                setModalState({
                  message: 'Ocorreu um erro interno',
                  description: 'Tente novamente mais tarde',
                  open: true,
                  variant: 'error',
                  buttonText: 'Fechar'
                })
              )
              break
          }
        }
      }
      resetForm()
    }
  })

  const handleCloseModal = () => {
    formik.resetForm()

    closeModal()
  }

  return (
    <>
      <S.Container isOpen={isOpen && !open}>
        <S.Content onSubmit={formik.handleSubmit}>
          <Subtitle variant="regular" className="subtitle">
            Alterar senha
          </Subtitle>
          <Input
            name="currentPassword"
            type="password"
            label="Digite a sua senha atual"
            onChange={formik.handleChange}
            value={formik.values.currentPassword || ''}
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
            autoComplete="off"
            texterror={formik.errors.currentPassword}
            fullWidth
          />
          <Input
            name="newPassword"
            type="password"
            label="Digite nova senha"
            onChange={formik.handleChange}
            value={formik.values.newPassword || ''}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            texterror={formik.errors.newPassword}
            fullWidth
          />
          <Input
            name="confirmNewPassword"
            type="password"
            label="Confirme a nova senha"
            onChange={formik.handleChange}
            value={formik.values.confirmNewPassword || ''}
            error={
              formik.touched.confirmNewPassword &&
              Boolean(formik.errors.confirmNewPassword)
            }
            texterror={formik.errors.confirmNewPassword}
            fullWidth
          />
          <S.PasswordRules>
            <Paragraph variant="small">*Pelo menos 8 Caracteres</Paragraph>
            <Paragraph variant="small">*No mínimo 1 letra e 1 número</Paragraph>
          </S.PasswordRules>
          <S.ButtonWrappers>
            <Button
              color="primary"
              variant="text"
              size="large"
              fullWidth={true}
              type="button"
              onClick={handleCloseModal}>
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth={true}
              type="submit">
              Alterar
            </Button>
          </S.ButtonWrappers>
        </S.Content>
      </S.Container>
    </>
  )
}
