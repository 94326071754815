import * as S from './EventMapped.styles'
import produce from 'immer'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { CustomerTicket, EventItems } from '../../../../redux/Events/types'
import { formatPrice } from '../../../../utils/formatPrice'

type ChartCategories = {
  label: string
}

type ChartSeats = {
  seatId: string
  category: {
    label: string
  }
  selectedTicketType: string
  pricing: {
    ticketTypes: [
      {
        price: number
        ticketType: string
      }
    ]
  }
  labels: {
    own: string
    parent: string
    section: string
    displayedLabel: string
  }
}

type EventMappedProps = {
  setSelectedItem: Dispatch<SetStateAction<EventItems | undefined>>
  selectedEvent: EventItems | undefined
  selectedTickets: CustomerTicket[] | undefined
  setSelectedTickets: Dispatch<SetStateAction<CustomerTicket[] | undefined>>
}

type Chart = {
  listCategories: () => Promise<ChartCategories[]>
  listSelectedObjects: () => Promise<ChartSeats[]>
  destroy: () => void
}

export const EventMapped = ({
  setSelectedItem,
  selectedEvent,
  selectedTickets,
  setSelectedTickets
}: EventMappedProps) => {
  let chart: Chart | null = null
  const [categories, setCategories] = useState<string[]>([])
  const [unavailableCategories, setUnavailableCategories] = useState<string[]>(
    []
  )

  const generatePrice = useMemo(() => {
    setUnavailableCategories([])
    const groupedTickets = _.groupBy(
      selectedEvent?.tickets,
      (ticket) => ticket.category
    )

    const ticketTypes = _.mapValues(groupedTickets, function (value) {
      return value.flatMap((ticket) => ({
        ticketType: ticket.name,
        price: formatPrice(ticket.priceCents / 100)
      }))
    })

    for (const category of categories) {
      if (ticketTypes[category] === undefined) {
        ticketTypes[category] = [
          { ticketType: 'Adulto', price: formatPrice(0) }
        ]

        setUnavailableCategories(
          produce((draft) => {
            draft.push(category)
          })
        )
      }
    }

    return categories.map((category) => ({
      category: category,
      ticketTypes: _.values(_.pick(ticketTypes, category))[0]
    }))
  }, [selectedEvent, categories])

  useEffect(() => {
    if (chart) {
      // @ts-ignore
      chart.changeConfig({
        unavailableCategories: unavailableCategories
      })
    }
  }, [chart, unavailableCategories])

  const handleListSeats = async () => {
    if (chart !== null && selectedEvent) {
      try {
        const selectedObjects = await chart.listSelectedObjects()

        setSelectedTickets(
          selectedObjects.map(
            ({ category, labels, seatId, selectedTicketType }) => {
              return {
                id:
                  selectedEvent.tickets.find(
                    (ticket) =>
                      ticket.category === category.label &&
                      ticket.name === selectedTicketType
                  )?.id ?? '',
                category: labels.section,
                name: labels.displayedLabel,
                totalPrice:
                  selectedEvent?.tickets.find(
                    (ticket) =>
                      ticket.category === category.label &&
                      ticket.name === selectedTicketType
                  )?.priceCents! / 100 ?? 0,
                labels: labels,
                eventItemId: selectedEvent.id!,
                seatKey: seatId,
                ticketInfo: selectedEvent?.ticketInfo?.map((info) => ({
                  value: '',
                  isRequired: info.isRequired,
                  name: info.value
                }))
              }
            }
          )
        )
      } catch (e) {}
    }
  }

  const handleChartFailed = () => {}

  return (
    <S.SeatsioChart
      workspaceKey={process.env.REACT_APP_PUBLIC_KEY_WORKSPACE}
      event={selectedEvent?.eventKey}
      onRenderStarted={async (createdChart: any) => {
        chart = createdChart
        if (chart !== null) {
          const getCategories = await chart.listCategories()

          setCategories(getCategories.map(({ label }) => label))
        }
      }}
      onChartRenderingFailed={handleChartFailed}
      pricing={[...generatePrice]}
      onSelectionValid={() => handleListSeats()}
      region="sa"
      language="pt"
    />
  )
}
