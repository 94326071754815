import { useParams } from 'react-router-dom'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'
import * as S from './ManageEvents.styles'
import { Column, Container, Row } from '../../components/grid/GridSystem'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../redux/rootReducer'
import moment from 'moment'
import { formatPrice } from '../../utils/formatPrice'
import { motion } from 'framer-motion'
import { history } from '../../routes/services/history'
import { Button, Hidden, Paragraph } from 'applaus-ui-kit'
import { CustomAccordion } from './Components/Accordion/Accordion'

import {
  customerTicketsNextStepRequest,
  customerTicketsPaginationChangeRequest,
  customerTicketsStepBackRequest,
  loadCustomerTicket,
  loadEventDataRequestAction,
  loadPurchaseOrder,
  purchaseNextStepRequest,
  purchasesPaginationChangeRequest,
  purchaseStepBackRequest
} from '../../redux/ManageEvent/actions'
import { useWindowSize } from '../../hooks/useWindowSize'
import { AddPartner } from './Components/AddPartner/AddPartner'
import {
  listPartners,
  ListPartnersResponse
} from '../../api/partner/listPartners'
import { addPartnerInEvent } from '../../api/partner/addPartnerInEvent'
import {
  clearModalAction,
  setAlertModalAction
} from '../../redux/AlertModal/actions'
import { sendBordero } from '../../api/events/sendBordero'
import { BalanceWithdrawal } from './Components/BalanceWithdrawal/BalanceWithdrawal'
import { ageGroupHelper } from '../../utils/ageGroupHelper'
import { downloadCsvService } from '../../api/events/downloadCsvService'

export const ManageEvents = () => {
  const param = useParams<{
    id: string
    tab?: 'dashboard' | 'tickets' | 'payments' | 'eventData'
  }>()
  const dispatch = useDispatch()
  const { ManageEventReducer } = useSelector((state: ReduxState) => state)
  const { isMobile } = useWindowSize()
  const [open, setOpen] = useState(false)
  const [openBalance, setOpenBalance] = useState(false)
  const { eventData, summaryData, customerTickets, purchaseOrders } =
    ManageEventReducer
  const [partners, setPartners] = useState<ListPartnersResponse[]>()
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [partnersNoEvent, setPartnersNoEvent] =
    useState<ListPartnersResponse[]>()
  const [activeBar, setActiveBar] = useState<
    'dashboard' | 'tickets' | 'payments' | 'eventData'
  >(
    param.tab !== undefined
      ? param.tab === 'tickets'
        ? param.tab
        : param.tab === 'dashboard'
        ? param.tab
        : param.tab === 'payments'
        ? param.tab
        : param.tab === 'eventData'
        ? param.tab
        : 'dashboard'
      : 'dashboard'
  )

  useEffect(() => {
    if (eventData || open === false || openBalance === false) {
      const getPartners = async () => {
        const response: ListPartnersResponse[] = await listPartners()

        setPartners(
          response.filter((partner) => {
            const findIndex = partner.events.findIndex(
              (event) => event.event.id === eventData?.id
            )

            if (findIndex > -1) {
              return true
            } else return false
          })
        )

        setPartnersNoEvent(
          response.filter((partner) => {
            const findIndex = partner.events.findIndex(
              (event) => event.event.id === eventData?.id
            )

            if (findIndex > -1) {
              return false
            } else return true
          })
        )
      }
      getPartners()
      setDeleteSuccess(false)
    }
  }, [open, eventData, deleteSuccess === true, openBalance])

  useEffect(() => {
    if (!eventData) {
      dispatch(loadEventDataRequestAction(param.id))
    } else if (eventData.id !== param.id) {
      dispatch(loadEventDataRequestAction(param.id))
    }
    localStorage.removeItem('eventId')
  }, [])

  const renderCustomerPagination = useMemo(() => {
    const steps = []
    for (let i = 0; i < customerTickets.total / customerTickets.take; i++) {
      steps.push(
        <S.Steps
          key={i}
          activeIndex={i === customerTickets.skip}
          onClick={() => {
            i !== customerTickets.skip &&
              dispatch(customerTicketsPaginationChangeRequest(i))
          }}>
          {i + 1}
        </S.Steps>
      )
    }
    return steps
  }, [customerTickets])

  const renderPurchasesPagination = useMemo(() => {
    const steps = []
    for (let i = 0; i < purchaseOrders.total / purchaseOrders.take; i++) {
      steps.push(
        <S.Steps
          key={i}
          activeIndex={i === purchaseOrders.skip}
          onClick={() =>
            i !== purchaseOrders.skip &&
            dispatch(purchasesPaginationChangeRequest(i))
          }>
          {i + 1}
        </S.Steps>
      )
    }
    return steps
  }, [purchaseOrders])

  const handleClickScan = () => {
    localStorage.setItem('eventId', eventData?.id ?? '')
    history.push('/scan')
  }

  const deletePartner = async (partner: ListPartnersResponse) => {
    try {
      const status = await addPartnerInEvent({
        email: partner.email,
        events: partner.events
          .filter((event) => event.event.id === eventData?.id)
          .flatMap((event) => ({
            eventId: event.event.id ?? '',
            revenuePercent: event.revenuePercent
          }))
      })

      if (status === 200) {
        dispatch(
          setAlertModalAction({
            buttonText: 'Ok',
            open: true,
            title: 'Parceiro removido com sucesso.',
            variant: 'success',
            click: () => {
              dispatch(clearModalAction())
              setDeleteSuccess(true)
            }
          })
        )
      }
    } catch (e) {
      dispatch(
        setAlertModalAction({
          buttonText: 'Ok',
          open: true,
          title: 'Ocorreu um erro ao remover o parceiro.',
          description: 'Tente novamente.',
          variant: 'error'
        })
      )
    }
  }

  const generateBordero = async () => {
    try {
      const status = await sendBordero(eventData!.id)
      if (status === 200) {
        dispatch(
          setAlertModalAction({
            buttonText: 'Ok',
            open: true,
            title:
              'Borderô gerado com sucesso, chegará em seu email em alguns instantes.',
            variant: 'success'
          })
        )
      }
    } catch (e) {
      dispatch(
        setAlertModalAction({
          buttonText: 'Ok',
          open: true,
          title: 'Ocorreu um erro ao gerar o borderô.',
          description: 'Tente novamente.',
          variant: 'error'
        })
      )
    }
  }

  const filteredBalanceWithdrawal = (partner: ListPartnersResponse) => {
    const status =
      partner.balanceWithdrawal.filter(
        (balance) => balance.eventId === eventData!.id
      )[0]?.status === 'WAITING'
        ? 'Pendente'
        : partner.balanceWithdrawal.filter(
            (balance) => balance.eventId === eventData!.id
          )[0]?.status === 'REJECTED'
        ? 'Rejeitado'
        : partner.balanceWithdrawal.filter(
            (balance) => balance.eventId === eventData!.id
          )[0]?.status === 'APPROVED'
        ? 'Confirmado'
        : null

    return status
  }

  const percentagePartner = (partner: ListPartnersResponse) => {
    const eventsLength = partner.events.filter(
      (event) => event.event.id === eventData!.id
    )
    const percentage =
      eventsLength.length === 1 ? eventsLength[0].revenuePercent + '%' : '0%'

    return percentage
  }

  const handleDownloadTickets = async () => {
    await downloadCsvService(eventData!.id)
  }

  return (
    <>
      <AuthenticatedTemplate isActive="events">
        {eventData && summaryData && partners && partnersNoEvent ? (
          <S.Container>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              <Container>
                <Row>
                  <Column mobile={4} tablet={8} desktop={12}>
                    <S.EventDataBox>
                      <div>
                        <S.Title>{eventData.name}</S.Title>
                        <S.EventCreationDate>
                          {moment(eventData.createdAt).format('lll')}
                        </S.EventCreationDate>
                      </div>

                      {location.pathname ===
                      `/manageEvent/${eventData.id}/eventData` ? (
                        <div className="editButton">
                          <Button
                            className="buttonEdit"
                            color="primary"
                            variant="contained"
                            fullWidth={false}
                            size="large"
                            onClick={() =>
                              history.push(`/editEvent/${eventData.id!}`)
                            }>
                            Editar
                            <S.PenIcon />
                          </Button>
                        </div>
                      ) : (
                        <div className="buttonsBox">
                          {activeBar === 'tickets' && (
                            <S.DownloadButton onClick={handleDownloadTickets}>
                              <Hidden tablet>Exportar</Hidden>
                              <S.DownloadIcon />
                            </S.DownloadButton>
                          )}
                          <S.CheckinButton onClick={handleClickScan}>
                            <S.QrCodeIcon />$
                            <S.CheckinButtonText>Check-in</S.CheckinButtonText>
                          </S.CheckinButton>
                          {activeBar !== 'tickets' && (
                            <Button
                              className="courtesy"
                              color="primary"
                              size="medium"
                              variant="contained"
                              fullWidth={false}
                              onClick={() =>
                                history.push(`/courtesyEvent/${eventData.id}`)
                              }>
                              Cortesia
                            </Button>
                          )}
                        </div>
                      )}
                    </S.EventDataBox>
                  </Column>
                </Row>
                <Row>
                  <Column mobile={4} tablet={8} desktop={12}>
                    <S.Tab>
                      <S.MenuTab>
                        <S.TabText
                          activeTab={activeBar === 'dashboard'}
                          onClick={() => {
                            setActiveBar('dashboard')
                            history.push(
                              `/manageEvent/${eventData.id}/dashboard`
                            )
                          }}>
                          DashBoard
                        </S.TabText>
                        <S.TabText
                          activeTab={activeBar === 'tickets'}
                          onClick={() => {
                            setActiveBar('tickets')
                            history.push(`/manageEvent/${eventData.id}/tickets`)
                          }}>
                          Ingressos
                        </S.TabText>
                        <S.TabText
                          activeTab={activeBar === 'payments'}
                          onClick={() => {
                            setActiveBar('payments')
                            history.push(
                              `/manageEvent/${eventData.id}/payments`
                            )
                          }}>
                          Pagamentos
                        </S.TabText>
                        <S.TabText
                          activeTab={activeBar === 'eventData'}
                          onClick={() => {
                            setActiveBar('eventData')
                            history.push(
                              `/manageEvent/${eventData.id}/eventData`
                            )
                          }}>
                          Dados evento
                        </S.TabText>
                      </S.MenuTab>
                    </S.Tab>
                  </Column>
                </Row>
                {activeBar === 'dashboard' ? (
                  <>
                    <Row gutter={isMobile ? 0 : 2}>
                      <Column mobile={4} tablet={4} desktop={6}>
                        <S.EventDetails>
                          <p className="details-title">Detalhes do evento</p>
                          <p className="subtitle">Status</p>
                          <p
                            className={`text ${eventData.status.toLowerCase()} />`}>
                            {eventData.status === 'OPEN' ? 'Aberto' : 'Fechado'}
                          </p>
                          <p className="subtitle">Visibilidade</p>
                          <p className="event-type-text">
                            {eventData.type === 'MAPPED'
                              ? 'Mapeado'
                              : 'Tabelado'}
                          </p>
                        </S.EventDetails>
                      </Column>
                      <Column mobile={4} tablet={4} desktop={6}>
                        <S.PublicDetails>
                          <p className="public-title">Publico</p>
                          <div className="public-total-box">
                            <div>
                              <p className="subtitle">Total</p>
                              <p className="text">
                                {summaryData.customerStatics.total}
                              </p>
                            </div>
                            <div>
                              <p className="subtitle">Presentes</p>
                              <p className="text">
                                {summaryData.customerStatics.totalAttendence}
                              </p>
                            </div>
                          </div>

                          <div className="payers-content">
                            <div>
                              <p className="subtitle">Pagantes</p>
                              <p className="event-payers">
                                {summaryData.customerStatics.totalPayers}
                              </p>
                            </div>
                          </div>
                        </S.PublicDetails>
                      </Column>
                    </Row>

                    <Row>
                      <Column mobile={4} tablet={8} desktop={12}>
                        <S.PriceDetails>
                          <p className="title">Vendas</p>
                          <div className="content">
                            <div>
                              <p className="subtitle">Total</p>
                              <p className="total-price">
                                {formatPrice(
                                  summaryData.summary.creditSales +
                                    summaryData.summary.pendingPayments +
                                    summaryData.summary.pixSales
                                )}
                              </p>
                            </div>
                            <div>
                              <div>
                                <p className="subtitle">Pagamento confirmado</p>
                                <p className="success-payment">
                                  {formatPrice(
                                    summaryData.summary.confirmedPayments
                                  )}
                                </p>
                              </div>
                              <div>
                                <p className="subtitle">Vendas no crédito</p>
                                <p className="credit-payment">
                                  {formatPrice(summaryData.summary.creditSales)}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <p className="subtitle">Pagamento pendente</p>
                                <p className="pending-payment">
                                  {formatPrice(
                                    summaryData.summary.pendingPayments
                                  )}
                                </p>
                              </div>
                              <div>
                                <p className="subtitle">Vendas no pix</p>
                                <p className="credit-payment">
                                  {formatPrice(summaryData.summary.pixSales)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </S.PriceDetails>
                      </Column>
                    </Row>
                    <Row>
                      <Column mobile={4} tablet={8} desktop={12}>
                        <S.ViewsDetails>
                          <p className="title">Vendas</p>
                          <div className="content">
                            <div>
                              <p className="subtitle">Total de visualizações</p>
                              <p className="value">
                                {summaryData.customerStatics.views}
                              </p>
                            </div>
                            <div>
                              <div>
                                <p className="subtitle">
                                  Visualizações sem compras
                                </p>
                                <p className="value">
                                  {
                                    summaryData.customerStatics
                                      .viewsWithoutPayment
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </S.ViewsDetails>
                      </Column>
                    </Row>

                    <Row>
                      <Column mobile={4} tablet={8} desktop={12}>
                        <S.Partners>
                          <p className="title">Parceiros</p>
                          <div className="header">
                            <p className="subTitle">Nome</p>
                            <p className="subTitle email">Email</p>
                            <p className="subTitle percent">%</p>
                            <p className="subTitle actionsTitle">Ações</p>
                          </div>
                          {partners &&
                            partners.length > 0 &&
                            partners!.map((partner) => (
                              <div
                                key={partner.id}
                                className="partnersInformation">
                                <p className="partnerName">{partner.name}</p>
                                <p className="partnerEmail">{partner.email}</p>
                                <p className="percentage percent">
                                  {percentagePartner(partner) ?? '0'}
                                </p>
                                <div className="actions">
                                  {filteredBalanceWithdrawal(partner) ? (
                                    <p
                                      className={`statusBalance ${filteredBalanceWithdrawal(
                                        partner
                                      )}`}>
                                      {filteredBalanceWithdrawal(partner)}
                                    </p>
                                  ) : (
                                    <>
                                      <S.EditIcon
                                        onClick={() =>
                                          history.push('/partners')
                                        }
                                      />
                                      <S.TrashIcon
                                        onClick={() => deletePartner(partner)}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            ))}
                          {partners &&
                          partners?.length > 0 &&
                          partners![0].balanceWithdrawal.filter(
                            (balance) => balance.eventId === eventData.id
                          )[0] ? null : (
                            <p
                              className="addPartner"
                              onClick={() => setOpen(true)}>
                              + Adicionar parceiro
                            </p>
                          )}
                        </S.Partners>
                      </Column>
                    </Row>
                    <Row>
                      <S.ButtonActions>
                        {partners &&
                        partners?.length > 0 &&
                        partners![0].balanceWithdrawal.filter(
                          (balance) => balance.eventId === eventData.id
                        )[0] ? null : (
                          <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth={false}
                            onClick={() => setOpenBalance(true)}>
                            Distribuir
                          </Button>
                        )}

                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth={false}
                          onClick={generateBordero}>
                          Gerar borderô
                        </Button>
                      </S.ButtonActions>
                    </Row>
                  </>
                ) : activeBar === 'tickets' ? (
                  <>
                    <Row>
                      <Column mobile={4} tablet={8} desktop={12}>
                        <S.InputWrapper>
                          <S.SearchIcon />
                          <S.Input
                            placeholder="Buscar pelo nome do evento"
                            // onChange={(e) => handleInputChange(e)}
                          />
                        </S.InputWrapper>
                      </Column>
                    </Row>
                    <Row marginTop="xs" marginBottom="xxxs">
                      <S.TableHeader>
                        <p>ID</p>
                        <p>Cliente</p>
                        <p>Ingresso</p>
                      </S.TableHeader>
                    </Row>
                    <Row>
                      {customerTickets.items.flatMap((customerTicket) => (
                        <S.TableBody
                          key={customerTicket.id}
                          onClick={
                            isMobile
                              ? () => {
                                  localStorage.setItem('eventId', eventData.id)
                                  dispatch(loadCustomerTicket(customerTicket))
                                  history.push(`/customerTicket`)
                                }
                              : () => {}
                          }>
                          <p>{customerTicket.customerTicketId.toUpperCase()}</p>
                          <p>{customerTicket.customer.firstName}</p>
                          <S.ButtonWrapper>
                            <p>{customerTicket.ticket.category}</p>
                            <Hidden mobile>
                              <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                fullWidth={false}
                                onClick={() => {
                                  localStorage.setItem('eventId', eventData.id)
                                  dispatch(loadCustomerTicket(customerTicket))
                                  history.push(`/customerTicket`)
                                }}>
                                Ver ingresso
                              </Button>
                            </Hidden>
                          </S.ButtonWrapper>
                        </S.TableBody>
                      ))}
                    </Row>
                    <Row>
                      <Column mobile={4} tablet={8} desktop={12}>
                        <S.Pagination>
                          <S.StepBack
                            disabled={customerTickets.skip === 0}
                            onClick={() => {
                              window.scrollTo({ top: 0, behavior: 'smooth' })
                              dispatch(customerTicketsStepBackRequest())
                            }}>{`<`}</S.StepBack>
                          {renderCustomerPagination}
                          <S.StepBack
                            disabled={
                              customerTickets.skip + 1 ===
                              renderCustomerPagination.length
                            }
                            onClick={() => {
                              window.scrollTo({ top: 0, behavior: 'smooth' })
                              dispatch(customerTicketsNextStepRequest())
                            }}>
                            {`>`}
                          </S.StepBack>
                          <Paragraph variant="large">
                            {customerTickets.total} items
                          </Paragraph>
                        </S.Pagination>
                      </Column>
                    </Row>
                  </>
                ) : activeBar === 'payments' ? (
                  <>
                    <Row>
                      <Column mobile={4} tablet={8} desktop={12}>
                        <S.InputWrapper>
                          <S.SearchIcon />
                          <S.Input
                            placeholder="Buscar pelo nome do evento"
                            // onChange={(e) => handleInputChange(e)}
                          />
                        </S.InputWrapper>
                      </Column>
                    </Row>
                    <Row marginTop="xs" marginBottom="xxxs">
                      <S.PurchaseTableHeader>
                        <p>ID</p>
                        <p>Cliente</p>
                        <Hidden mobile>
                          <p>Status pagamento</p>
                        </Hidden>
                        <p>Ingressos</p>
                      </S.PurchaseTableHeader>
                    </Row>
                    <Row>
                      {purchaseOrders.items.flatMap((purchaseOrder) => (
                        <S.PurchaseTableBody
                          key={purchaseOrder.id}
                          onClick={
                            isMobile
                              ? () => {
                                  dispatch(loadPurchaseOrder(purchaseOrder))
                                  localStorage.setItem('eventId', eventData.id)
                                  history.push(`/pucharseOrder`)
                                }
                              : () => {}
                          }>
                          <p>{purchaseOrder.orderId.toUpperCase()}</p>
                          <p>{purchaseOrder.customer.firstName}</p>

                          <Hidden mobile>
                            <p className={purchaseOrder.status}>
                              {purchaseOrder.status === 'SUCCESS'
                                ? 'Confirmado'
                                : purchaseOrder.status === 'WAITING'
                                ? 'Pendente'
                                : 'Recusado'}
                            </p>
                          </Hidden>
                          <S.ButtonWrapper>
                            <p>{purchaseOrder.customerTickets.length}</p>
                            <Hidden mobile>
                              <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                fullWidth={false}
                                onClick={() => {
                                  dispatch(loadPurchaseOrder(purchaseOrder))
                                  localStorage.setItem('eventId', eventData.id)
                                  history.push(`/pucharseOrder`)
                                }}
                                disabled={!purchaseOrder.paymentHistory}>
                                Ver mais
                              </Button>
                            </Hidden>
                          </S.ButtonWrapper>
                        </S.PurchaseTableBody>
                      ))}
                    </Row>
                    <Row>
                      <Column mobile={4} tablet={8} desktop={12}>
                        <S.Pagination>
                          <S.StepBack
                            disabled={purchaseOrders.skip === 0}
                            onClick={() =>
                              dispatch(purchaseStepBackRequest())
                            }>{`<`}</S.StepBack>
                          {renderPurchasesPagination}
                          <S.StepBack
                            disabled={
                              purchaseOrders.skip + 1 ===
                              renderPurchasesPagination.length
                            }
                            onClick={() => {
                              dispatch(purchaseNextStepRequest())
                            }}>{`>`}</S.StepBack>
                          <Paragraph variant="large">
                            {purchaseOrders.total} items
                          </Paragraph>
                        </S.Pagination>
                      </Column>
                    </Row>
                  </>
                ) : activeBar === 'eventData' ? (
                  <Row>
                    <Column mobile={4} tablet={8} desktop={12}>
                      <CustomAccordion title="Informações Básicas">
                        <S.BasicInformation>
                          <div>
                            <S.EventInfoTitle>Nome do Evento</S.EventInfoTitle>
                            <S.EventInfoText>{eventData.name}</S.EventInfoText>
                          </div>
                          <div>
                            <S.EventInfoTitle>Classificação</S.EventInfoTitle>
                            <S.EventInfoText>
                              {ageGroupHelper(eventData.ageGroup)}
                            </S.EventInfoText>
                          </div>
                        </S.BasicInformation>
                      </CustomAccordion>
                    </Column>
                    <Column mobile={4} tablet={8} desktop={12}>
                      <CustomAccordion title="Localização">
                        <S.AddresInformation>
                          <div>
                            <div>
                              <S.EventInfoTitle>Nome do Local</S.EventInfoTitle>
                              <S.EventInfoText>
                                {eventData.address?.name ?? '--'}
                              </S.EventInfoText>
                            </div>
                            <div>
                              <S.EventInfoTitle>Cidade</S.EventInfoTitle>
                              <S.EventInfoText>
                                {eventData.address?.city ?? '--'}
                              </S.EventInfoText>
                            </div>
                          </div>
                          <div>
                            <div>
                              <S.EventInfoTitle>CEP</S.EventInfoTitle>
                              <S.EventInfoText>
                                {eventData.address?.zipCode ?? '--'}
                              </S.EventInfoText>
                            </div>
                            <div>
                              <S.EventInfoTitle>Estado</S.EventInfoTitle>
                              <S.EventInfoText>
                                {eventData.address?.state ?? '--'}
                              </S.EventInfoText>
                            </div>
                          </div>
                          <div>
                            <div>
                              <S.EventInfoTitle>Endereço</S.EventInfoTitle>
                              <S.EventInfoText>
                                {eventData.address?.street ?? '--'}
                              </S.EventInfoText>
                            </div>
                            <div>
                              <S.EventInfoTitle>Complemento</S.EventInfoTitle>
                              <S.EventInfoText>
                                {eventData.address?.complement ?? '---'}
                              </S.EventInfoText>
                            </div>
                          </div>
                          <div>
                            <div>
                              <S.EventInfoTitle>Número</S.EventInfoTitle>
                              <S.EventInfoText>
                                {eventData.address?.number ?? '--'}
                              </S.EventInfoText>
                            </div>
                          </div>
                        </S.AddresInformation>
                      </CustomAccordion>
                    </Column>
                    <Column mobile={4} tablet={8} desktop={12}>
                      <CustomAccordion title="Sessões">
                        <S.SectionsInformation>
                          {eventData.eventItems.map((eventItem) => (
                            <div key={eventItem.id}>
                              <Hidden tablet desktop>
                                <div className="circle" />
                              </Hidden>
                              <div>
                                <div className="dividerBox">
                                  <S.EventInfoTitle>Nome</S.EventInfoTitle>
                                  <S.EventInfoText>
                                    {eventItem.title}
                                  </S.EventInfoText>
                                </div>
                                <div className="dividerBox">
                                  <S.EventInfoTitle>Datas</S.EventInfoTitle>
                                  <S.EventInfoText>
                                    {moment
                                      .min(
                                        eventItem.dates.map((date) =>
                                          moment(date)
                                        )
                                      )
                                      .format('L')}{' '}
                                    -{' '}
                                    {moment
                                      .max(
                                        eventItem.dates.map((date) =>
                                          moment(date)
                                        )
                                      )
                                      .format('L')}
                                  </S.EventInfoText>
                                </div>

                                <div className="dividerBox">
                                  <S.EventInfoTitle>Hora</S.EventInfoTitle>
                                  <S.EventInfoText>
                                    {moment
                                      .min(
                                        eventItem.dates.map((date) =>
                                          moment(date)
                                        )
                                      )
                                      .format('hh:mm')}{' '}
                                    -{' '}
                                    {moment
                                      .max(
                                        eventItem.dates.map((date) =>
                                          moment(date)
                                        )
                                      )
                                      .format('hh:mm')}
                                  </S.EventInfoText>
                                </div>
                                <div className="dividerBox">
                                  <S.EventInfoTitle>Perguntas</S.EventInfoTitle>
                                  {eventItem.ticketInfo?.map(({ value }) => (
                                    <S.EventInfoText key={'value'}>
                                      {value}
                                    </S.EventInfoText>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </S.SectionsInformation>
                      </CustomAccordion>
                    </Column>

                    <Column mobile={4} tablet={8} desktop={12}>
                      <CustomAccordion title="Marketing">
                        <S.MarketingInformation>
                          <div>
                            <S.EventInfoTitle>Facebook</S.EventInfoTitle>
                            <S.EventInfoText>
                              {eventData.socialLinks?.facebookLink ?? 'Link'}
                            </S.EventInfoText>
                          </div>
                          <div>
                            <S.EventInfoTitle>Instagram</S.EventInfoTitle>
                            <S.EventInfoText>
                              {eventData.socialLinks?.instagramLink ?? 'Link'}
                            </S.EventInfoText>
                          </div>

                          <div>
                            <S.EventInfoTitle>Youtube</S.EventInfoTitle>
                            <S.EventInfoText>
                              {eventData.socialLinks?.youtubeLink ?? 'Link'}
                            </S.EventInfoText>
                          </div>
                          <div>
                            <S.EventInfoTitle>Tiktok</S.EventInfoTitle>
                            <S.EventInfoText>
                              {eventData.socialLinks?.tiktokLink ?? 'Link'}
                            </S.EventInfoText>
                          </div>
                        </S.MarketingInformation>
                      </CustomAccordion>
                    </Column>

                    <Column mobile={4} tablet={8} desktop={12}>
                      <CustomAccordion title="Ingressos">
                        <S.TicketInformation>
                          {eventData.eventItems.flatMap(({ tickets }) =>
                            tickets.flatMap((ticket) => (
                              <div key={ticket.id}>
                                <Hidden tablet desktop>
                                  <div className="circle" />
                                </Hidden>
                                <div className="ticketDescription">
                                  <div className="dividerBox">
                                    <div className="dividerName">
                                      <S.EventInfoTitle>Nome</S.EventInfoTitle>
                                      <S.EventInfoText>
                                        {ticket.name}
                                      </S.EventInfoText>
                                    </div>

                                    <div>
                                      <S.EventInfoTitle>
                                        Categoria
                                      </S.EventInfoTitle>
                                      <S.EventInfoText>
                                        {ticket.category}
                                      </S.EventInfoText>
                                    </div>
                                  </div>

                                  <div className="dividerBox">
                                    <div>
                                      <S.EventInfoTitle>Preço</S.EventInfoTitle>
                                      <S.EventInfoText>
                                        {formatPrice(ticket.priceCents / 100)}
                                      </S.EventInfoText>
                                    </div>
                                    <div>
                                      <S.EventInfoTitle>Tipo</S.EventInfoTitle>
                                      <S.EventInfoText>
                                        {ticket.type}
                                      </S.EventInfoText>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </S.TicketInformation>
                      </CustomAccordion>
                    </Column>
                    <Column mobile={4} tablet={8} desktop={12}>
                      <CustomAccordion title="Detalhes">
                        <div
                          className="details"
                          dangerouslySetInnerHTML={{
                            __html: eventData.description
                          }}
                        />
                      </CustomAccordion>
                    </Column>
                  </Row>
                ) : undefined}
              </Container>
            </motion.div>
          </S.Container>
        ) : undefined}
      </AuthenticatedTemplate>
      <AddPartner
        open={open}
        setOpen={setOpen}
        eventPartners={partnersNoEvent}
      />
      <BalanceWithdrawal
        open={openBalance}
        setOpen={setOpenBalance}
        eventPartners={partners}
      />
    </>
  )
}
