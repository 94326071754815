import * as S from './DetachedTicket.styles'

import {
  Container as GridContainer,
  Row,
  Column
} from '../../components/grid/GridSystem'

import { BackButton } from '../../components/BackButton/BackButton'
import { history } from '../../routes/services/history'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'
import { motion } from 'framer-motion'
import { TicketComponent } from '../../components/Ticket/Ticket'
import moment from 'moment'
import { formatPrice } from '../../utils/formatPrice'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../redux/rootReducer'
import { Redirect } from 'react-router-dom'
import { useWindowSize } from '../../hooks/useWindowSize'
import DinersFile from '../../assets/icons/diners.svg'
import MastercardFile from '../../assets/icons/mastercard.svg'
import EloFile from '../../assets/icons/elo.svg'
import AmexFile from '../../assets/icons/amex.svg'
import VisaFile from '../../assets/icons/visa.svg'
import PixFile from '../../assets/icons/pix.svg'
import { Hidden } from 'applaus-ui-kit'

export const DetachedTicket = () => {
  const { data } = useSelector((state: ReduxState) => state.ManageEventReducer)
  const { isDesktop, isTablet } = useWindowSize()
  const handleClickScan = () => {
    history.push('/scan')
  }

  const iconBrand =
    data?.purchaseOrder?.paymentHistory?.brand === 'Visa'
      ? VisaFile
      : data?.purchaseOrder?.paymentHistory?.brand === 'Master'
      ? MastercardFile
      : data?.purchaseOrder?.paymentHistory?.brand === 'Elo'
      ? EloFile
      : data?.purchaseOrder?.paymentHistory?.brand === 'Diners'
      ? DinersFile
      : data?.purchaseOrder?.paymentHistory?.brand === 'Amex'
      ? AmexFile
      : data?.purchaseOrder?.paymentHistory?.brand === 'PIX'
      ? PixFile
      : PixFile

  return (
    <AuthenticatedTemplate isActive={'events'}>
      {data ? (
        <S.Container>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>
            <GridContainer>
              <Row marginTop={isDesktop ? 'xxxl' : isTablet ? 'lg' : 'xs'}>
                <Column desktop={12}>
                  <BackButton onClick={() => history.push('/scan')} />
                </Column>
              </Row>
              <Row>
                <Column tablet={8} desktop={12}>
                  <S.TitleBox>
                    <Hidden mobile>
                      <p className="title">Ingresso</p>
                    </Hidden>
                    <S.CheckinButton onClick={handleClickScan}>
                      <S.QrCodeIcon />
                      <S.CheckinButtonText>Check-in</S.CheckinButtonText>
                    </S.CheckinButton>
                  </S.TitleBox>
                  <Hidden tablet desktop>
                    <p className="title">Ingresso</p>
                  </Hidden>
                </Column>
              </Row>
              <Row>
                <Column tablet={8} desktop={12}>
                  <TicketComponent
                    eventName={data.eventItem.event.name}
                    address={{
                      street: data.eventItem.event.address.street,
                      city: data.eventItem.event.address.city
                    }}
                    date={data.eventItem.dates[0]}
                    ticketName={data.ticket.name}
                    ticketCategory={data.ticket.category}
                    eventType={data.eventItem.event.type}
                    seatId={data.ticket.id}
                    purchaseDate={
                      data.purchaseOrder.updatedAt
                        ? data.purchaseOrder.updatedAt
                        : data.purchaseOrder.createdAt
                    }
                    ticketLocale={data.ticket.type}
                    ticketPrice={
                      (data.ticket.priceCents - data.ticket.discountCents) / 100
                    }
                    ticketInfo={data.ticketInfo}
                    customerTicketId={data.customerTicketId}
                  />
                </Column>
              </Row>
              <Row marginBottom="md">
                <p className="title">Pagamento</p>
                <Column mobile={4} tablet={4} desktop={6}>
                  <S.PaymentInfo>
                    <div>
                      <p className="payment">Pagamento:</p>
                      <p className="payment status">
                        {data.purchaseOrder.status === 'SUCCESS'
                          ? 'Aprovado'
                          : data.purchaseOrder.status === 'WAITING'
                          ? 'Em análise'
                          : 'Recusado'}
                      </p>
                    </div>
                    <span>
                      Realizado em{' '}
                      {moment(
                        data.purchaseOrder.paymentHistory.updatedAt
                      ).format('ll')}
                    </span>
                  </S.PaymentInfo>
                </Column>
                <Column mobile={4} tablet={4} desktop={6}>
                  <S.PaymentPricing>
                    <p>
                      {formatPrice(
                        data.purchaseOrder.paymentHistory.totalPrice / 100
                      )}{' '}
                      {data.purchaseOrder.paymentHistory?.installments === null
                        ? ' à vista'
                        : ` em ${data.purchaseOrder.paymentHistory?.installments}x`}
                    </p>
                    <span>
                      <img src={iconBrand} alt="Bandeira do cartão" />
                      {data.purchaseOrder.paymentHistory.paymentMethod ===
                      'FREE'
                        ? 'Gratuito'
                        : data.purchaseOrder.paymentHistory.paymentMethod ===
                          'PIX'
                        ? 'PIX'
                        : `${data.purchaseOrder.paymentHistory.brand} - final ${data.purchaseOrder.paymentHistory.lastCardNumbers}`}
                    </span>
                  </S.PaymentPricing>
                </Column>
              </Row>
              <Row marginBottom="xxxl">
                <p className="title">Usuário</p>
                <Column tablet={2} desktop={4}>
                  <S.UserInfo>
                    <p className="user"> Nome</p>
                    <p className="user-info-text">{data.customer.firstName}</p>
                  </S.UserInfo>
                </Column>
                <Column tablet={2} desktop={4}>
                  <S.UserInfo>
                    <p className="user">ID</p>
                    <p className="user-info-text">{data.customer.id}</p>
                  </S.UserInfo>
                </Column>
              </Row>
            </GridContainer>
          </motion.div>
        </S.Container>
      ) : (
        <Redirect to="/scan" />
      )}
    </AuthenticatedTemplate>
  )
}
