import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from '../../../assets/icons/backIcon.svg'

export const Container = styled.div`
  position: relative;
`
export const EventDataBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: max-content;
`

export const FilledBackIcon = styled(BackFile)`
  position: absolute;
  fill: black;
  height: 40px;
  width: 40px;
  background: white;
  padding: 1rem 0.8rem;
  border-radius: 100%;
  top: 24px;
  left: 24px;
`

export const Description = styled.div`
  ${({ theme }) => css`
    margin-bottom: 2rem;
    > * {
      font-size: 2rem;
    }
  `}
`

export const EventData = styled.div`
  ${({ theme }) => css`
    background: black;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.xxs};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 49.29%);
    .textInfo {
      color: white;
    }
  `}
`

export const CoverUrl = styled.img`
  width: 100%;
  min-height: 488px;
`

export const EventInfo = styled.section`
  ${({ theme }) => css`
    position: absolute;
    top: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    border-top-left-radius: ${theme.border.radius.big};
    border-top-right-radius: ${theme.border.radius.big};
    background: white;
    .heading {
      color: #6c01a3;
    }
    > p {
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const LocationIframe = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    border-radius: ${theme.border.radius.large};

    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  `}
`

export const TicketBox = styled.section`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.2));
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    align-items: center;
    > button {
      font-size: 9px;
      padding: 10px 26px;
      background: ${theme.color.alert.success.nth1};
    }
  `}
`

export const PricingInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p:last-of-type {
    color: rebeccapurple;
  }
`
