import * as Yup from 'yup'
import { bankInfos } from './banks'

export const bankValidation = Yup.object({
  bankAg: Yup.string()
    .required('Agência é obrigatória')
    .test('countVerify', 'Agência esta incorreta', function (values) {
      return (
        bankInfos.find((item) => item.bankNumber === this.parent.bank)?.agency
          .length === values?.replace(/\s/g, '').length
      )
    }),
  bankCc: Yup.string()
    .required('Conta é obrigatória')
    .test('countVerify', 'Conta esta incorreta', function (values) {
      return (
        bankInfos.find((item) => item.bankNumber === this.parent.bank)?.account
          .length === values?.replace(/\s/g, '').length
      )
    })
})
