import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from 'assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`

export const Container = styled.div`
  ${({ theme }) => css`
    > button {
      margin-left: auto;
    }
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs};
    }
    @media (min-width: 1366px) {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
      width: 100%;
      button:first-of-type {
        margin-left: auto;
        margin-bottom: ${theme.spacing.stack.xxxs};
      }
      button:last-of-type {
        margin-top: auto;
        margin-left: auto;
        margin-bottom: ${theme.spacing.stack.xxxs};
      }
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.xs};
    }

    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxs};
    }
  `}
`

export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
    margin-bottom: ${theme.spacing.stack.sm};
    @media (min-width: 768px) and (max-width: 1170px) {
    }

    @media (max-width: 767px) {
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
  `}
`
export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  `}
`

export const RadioButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.sm};
    gap: ${theme.spacing.stack.xxxl};

    @media (min-width: 768px) and (max-width: 1170px) {
    }

    @media (max-width: 767px) {
      gap: ${theme.spacing.stack.xxxs};
      align-items: unset;
      flex-direction: column;
      margin-top: ${theme.spacing.stack.small};
      margin-bottom: ${theme.spacing.stack.xs};
    }
  `}
`

export const RadioButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.inline.xs};

    @media (max-width: 767px) {
      gap: 0;
      flex-direction: column;
    }
  `}
`

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-left: ${theme.spacing.stack.small};
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    .currency-input {
      width: 100%;
      .heightInput > input {
        width: 100%;
      }
    }
    @media (min-width: 1366px) {
      max-width: 530px;
    }
  `}
`
