import styled, { css } from 'styled-components'
import { theme } from 'applaus-ui-kit'

import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg'

export const ChevronDownIcon = styled(ChevronDown)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth2};
  `}
`

export const Container = styled.div`
  overflow: auto;
  min-width: 320px;

  .spacingTop {
    margin-top: ${theme.spacing.stack.xxs};
  }
  .spacing {
    margin: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxs} 0
      ${theme.spacing.stack.xxs};
  }

  @media (max-width: 767px) {
    .spacing {
      margin: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxs} 0 0;
    }
  }
`

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
  `}
`

export const ChartWrapper = styled.div`
  box-shadow: ${theme.shadow.level2} 0px rgba(0, 0, 0, 0.25);
  padding: ${theme.spacing.stack.xxs} 0;
  border-radius: 6px;
`

export const BalanceWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 ${theme.spacing.inline.xl};

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 ${theme.spacing.inline.xxxs};
  }
`

export const AvailableForWithdrawal = styled.div`
  ${({ theme }) => css`
    > p {
      font-size: ${theme.font.size.sm};
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
    > span {
      margin-top: ${theme.spacing.stack.xxxs};
      font-size: ${theme.font.size.sm};
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
      color: ${theme.color.alert.success.nth1};
    }
  `}
`

export const BalanceReceivable = styled.div`
  ${({ theme }) => css`
    > p {
      font-size: ${theme.font.size.sm};
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
    > span {
      margin-top: ${theme.spacing.stack.xxxs};
      font-size: ${theme.font.size.sm};
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
      color: ${theme.color.alert.warning.nth1};
    }

    @media (max-width: 767px) {
      margin-top: ${theme.spacing.stack.xxs};
    }
  `}
`

export const MonthlyBilling = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.medium};
    margin-right: ${theme.spacing.stack.xxxs};
    box-shadow: ${theme.shadow.level2} 0px rgba(0, 0, 0, 0.25);
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxs};
    > p {
      font-size: ${theme.spacing.stack.xxxs};
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    > span {
      font-size: ${theme.spacing.stack.xxxs};
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: ${theme.color.brand.primary.nth1};
    }

    @media (max-width: 767px) {
      margin-right: 0;
    }
  `}
`

export const PendingBilling = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.medium};
    box-shadow: ${theme.shadow.level2} 0px rgba(0, 0, 0, 0.25);
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxs};
    > p {
      font-size: ${theme.spacing.stack.xxxs};
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    > span {
      font-size: ${theme.spacing.stack.xxxs};
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: ${theme.color.brand.primary.nth1};
    }
  `}
`
export const PaymentInfo = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.medium};
    box-shadow: ${theme.shadow.level2} 0px rgba(0, 0, 0, 0.25);
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxs};
    > p {
      font-size: ${theme.spacing.stack.xxxs};
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    > span {
      font-size: ${theme.spacing.stack.xxxs};
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }

    margin: 0;
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}
