import { action } from 'typesafe-actions'
import { Address, EventActions, Event, Tickets, Coupon } from './types'

import { CreateEventItemResponse } from '../../api/eventItem/createEventItemService'
import { Pixels } from '../../api/events/updateEventPixelsService'
import { SocialLinks } from '../../api/events/updateSocialLinks'

type Links = {
  facebookLink: string
  youtubeLink: string
  instagramLink: string
  tiktokLink: string
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export const nextStep = () => action(EventActions.nextStep)

export const stepBack = () => action(EventActions.stepBack)

export const listEventsRequestAction = () =>
  action(EventActions.listEventsRequest)

export const listEventsSuccessAction = (data: Event[]) =>
  action(EventActions.listEventsSuccess, data)

export const registerEvent = (data: {
  name: string
  category: string
  cashBackPercent?: number
  ageGroup: string
  banners: {
    mobileUrl: File | string
    desktopUrl: File | string
    tabletUrl: File | string
    cardUrl: File | string
  }[]
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
}) => action(EventActions.registerEventRequest, data)

export const registerEventSuccess = (data: {
  name: string
  category: string
  cashBackPercent?: number
  ageGroup: string
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  banners?: {
    mobileUrl: File | string
    desktopUrl: File | string
    tabletUrl: File | string
    cardUrl: File | string
  }[]
}) => action(EventActions.registerEventSuccess, data)

export const addDescription = (description: string) =>
  action(EventActions.addDescription, description)

export const addDescriptionSuccess = (description: string) =>
  action(EventActions.addDescriptionSuccess, description)

export const addLocation = (data: Address) =>
  action(EventActions.addLocation, data)

export const addLocationSuccess = (data: Address) =>
  action(EventActions.addLocationSuccess, data)

export const removeEventSuccessAction = (eventId: string) =>
  action(EventActions.removeEventSuccess, eventId)

export const createEventSuccessAction = (data: Event) =>
  action(EventActions.createEventSuccess, data)

export const createEventItemRequest = (data: {
  title: string
  description: string
  dates: string[]
  maxCustomerTickets: number
  salesFrom: string
  finalSales: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
}) => action(EventActions.createEventItemRequest, data)

export const createEventItemSuccess = (data: CreateEventItemResponse) =>
  action(EventActions.createEventItemSuccess, data)

export const removeEventItemRequest = (eventIemId: string) =>
  action(EventActions.removeEventItemRequest, eventIemId)

export const removeEventItemSuccess = (eventIemId: string) =>
  action(EventActions.removeEventItemSuccess, eventIemId)

export const addTicketEventItem = (
  ticket: Tickets,
  actionTicket: 'edit' | 'create',
  eventItemId?: string
) => action(EventActions.addTicket, { ticket, eventItemId, actionTicket })

export const deleteTicket = (ticket: Tickets) =>
  action(EventActions.deleteTicket, ticket)

export const publishEventAction = (status: 'OPEN' | 'CLOSED') =>
  action(EventActions.publishEvent, status)

export const deleteTicketRequest = (ticketId: string) =>
  action(EventActions.deleteTicketRequest, ticketId)

export const deleteTicketSuccess = (ticketId: string) =>
  action(EventActions.deleteTicketSuccess, ticketId)

export const clearStateAction = () => action(EventActions.clearState)

export const editTicketSuccess = (ticket: Tickets) =>
  action(EventActions.editTicketSuccess, ticket)

export const editPixelsRequestAction = (links: Links) =>
  action(EventActions.editPixelsRequest, links)

export const editPixelsSuccessAction = (pixels: Pixels) =>
  action(EventActions.editPixelsSuccess, pixels)

export const createCouponRequestAction = (coupon: {
  name: string
  description: string
  amountOfUse: number
  value: number | string
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
  type: 'limited' | 'unlimited'
}) => action(EventActions.createCouponRequest, coupon)

export const createCouponSuccessAction = (coupon: Coupon) =>
  action(EventActions.createCouponSuccess, coupon)

export const editCouponRequestAction = (coupon: {
  id: string
  name: string
  description: string
  amountOfUse: number
  value: string
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
}) => action(EventActions.editCouponRequest, coupon)

export const editCouponSuccessAction = (coupon: Coupon) =>
  action(EventActions.editCouponSuccess, coupon)

export const removeCouponRequestAction = (couponId: string) =>
  action(EventActions.removeCouponRequest, couponId)

export const removeCouponSuccessAction = (couponId: string) =>
  action(EventActions.removeCouponSuccess, couponId)

export const updateSocialLinksSuccessAction = (socialLinks: SocialLinks) =>
  action(EventActions.editSocialLinksSuccess, socialLinks)
