import axios, { AxiosResponse } from 'axios'

export type ViaCepResponse = {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
  erro: boolean | undefined
}

export const viaCepService = async (zipCode: string) => {
  const { data }: AxiosResponse<ViaCepResponse> = await axios.get(
    `https://viacep.com.br/ws/${zipCode}/json/`
  )

  return data
}
