import { api } from '../config'
import * as _ from 'lodash'
import { EventItem } from './listEventService'
import { listCouponsByEventIdService } from './listCouponsByEventIdService'
import { Event } from 'redux/Events/types'

export const listOneEventService = async (eventId: string): Promise<Event> => {
  const couponData = await listCouponsByEventIdService(eventId)
  const { data } = await api.get(`/api/creators/events/${eventId}`)

  const tickets = data.eventItems.flatMap(
    (eventItem: { tickets: any[]; id: any }) =>
      eventItem.tickets.flatMap((ticket) => ({
        ...ticket,
        eventItems: eventItem
      }))
  )

  const eventItems = data.eventItems.flatMap((eventItem: EventItem) => ({
    ...eventItem,
    tickets: eventItem.tickets.flatMap((ticket) => ({
      ...ticket,
      eventItems: [{ ...eventItem, tickets: undefined }]
    }))
  }))

  return {
    ...data,
    coupons: couponData,
    eventItems: eventItems,
    tickets: _.uniqBy(tickets, 'id'),
    address: _.omitBy(data.address, _.isNil)
  }
}
