import { all, delay } from 'redux-saga/effects'
import { call, put, takeLatest, select } from '@redux-saga/core/effects'
import { Address, EventActions, EventState, Event, Coupon } from './types'
import { PayloadAction } from 'typesafe-actions'
import { createEventService } from 'api/events/createEvent'
import {
  addDescriptionSuccess,
  createCouponSuccessAction,
  editCouponSuccessAction,
  editPixelsSuccessAction,
  listEventsSuccessAction,
  nextStep,
  removeCouponSuccessAction,
  removeEventItemSuccess,
  updateSocialLinksSuccessAction
} from './actions'
import { ReduxState } from '../rootReducer'
import { clearLoading, setLoading } from '../Loading/actions'
import { listEventsService } from 'api/events/listEventsService'
import { uploadImagesService } from 'api/files/uploadImageService'
import { setAlertModalAction } from '../AlertModal/actions'
import { updateEventAddress } from 'api/events/updateEventAddress'
import { removeEventIemService } from 'api/eventItem/removeEventIemService'
import { deleteTicketService } from 'api/tickets/deleteTicketService'
import {
  EditBasicInformationRequest,
  editBasicInformationSuccessAction,
  editDescriptionSuccessAction,
  editNextStep
} from './editActions'

import { updateEventService } from 'api/events/updateEventService'

import * as _ from 'lodash'
import {
  Pixels,
  updateEventPixelsService
} from '../../api/events/updateEventPixelsService'
import { createCouponService } from 'api/events/createCouponService'
import { editCouponService } from 'api/events/editCouponService'
import { removeCouponService } from 'api/events/removeCouponService'
import { updateSocialLinks } from '../../api/events/updateSocialLinks'

type Links = {
  facebookLink: string
  youtubeLink: string
  instagramLink: string
  tiktokLink: string
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export function* registerEvent(
  action: PayloadAction<
    EventActions.registerEventRequest,
    {
      name: string
      category: string
      ageGroup: string
      cashBackPercent?: number
      type: 'MAPPED' | 'TABULATED'
      seatMapId?: string
      banners: [
        {
          mobileUrl: File | string
          desktopUrl: File | string
          tabletUrl: File | string
          cardUrl: File | string
        }
      ]
    }
  >
) {
  try {
    yield put(setLoading())
    const getState = (state: ReduxState) => state.EventReducer
    const { manageEvent }: EventState = yield select(getState)
    const {
      banners,
      name,
      type,
      seatMapId,
      category,
      cashBackPercent,
      ageGroup
    } = action.payload
    let cardUrl =
      banners[0].cardUrl === ''
        ? manageEvent?.banners[0].cardUrl
        : banners[0].cardUrl
    let mobileUrl =
      banners[0].mobileUrl === ''
        ? manageEvent?.banners[0].mobileUrl
        : banners[0].mobileUrl
    let tabletUrl =
      banners[0].tabletUrl === ''
        ? manageEvent?.banners[0].tabletUrl
        : banners[0].tabletUrl
    let desktopUrl =
      banners[0].desktopUrl === ''
        ? manageEvent?.banners[0].desktopUrl
        : banners[0].desktopUrl

    if (typeof cardUrl !== 'string' && cardUrl !== undefined) {
      cardUrl = yield call(uploadImagesService, cardUrl as File)
    }
    if (typeof mobileUrl !== 'string' && mobileUrl !== undefined) {
      mobileUrl = yield call(uploadImagesService, mobileUrl as File)
    }
    if (typeof tabletUrl !== 'string' && tabletUrl !== undefined) {
      tabletUrl = yield call(uploadImagesService, tabletUrl as File)
    }
    if (typeof desktopUrl !== 'string' && desktopUrl !== undefined) {
      desktopUrl = yield call(uploadImagesService, desktopUrl as File)
    }

    const createBanners = [
      {
        desktopUrl: desktopUrl as string,
        tabletUrl: tabletUrl as string,
        mobileUrl: mobileUrl as string,
        cardUrl: cardUrl as string
      }
    ]

    const emptyBanners = Object.entries(createBanners[0]).filter(
      (value) => value[1] !== undefined
    )

    yield call(createEventService, {
      cashBackPercent: cashBackPercent,
      ageGroup: ageGroup !== '' ? ageGroup : undefined,
      banners:
        !_.isEmpty(emptyBanners) && emptyBanners.length === 4
          ? createBanners
          : undefined,
      category: category,
      name: name,
      seatMapId: seatMapId,
      type: type
    })

    yield put(clearLoading())
    yield put(nextStep())
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setAlertModalAction({
        open: true,
        title: 'Não foi possível fazer o upload das imagens',
        variant: 'error',
        buttonText: 'Fechar'
      })
    )
  }
}

export function* createLocation(
  action: PayloadAction<EventActions.addLocation, Address>
) {
  const getState = (state: ReduxState) => state.EventReducer
  const { manageEvent }: EventState = yield select(getState)

  try {
    if (
      !_.isEqual(
        _.omitBy(action.payload, _.isNil),
        _.omitBy(manageEvent?.address, _.isNil || _.isUndefined)
      )
    ) {
      yield call(updateEventAddress, action.payload, manageEvent!.id)
    }
    yield put(nextStep())
  } catch {}
}

export function* listEvents() {
  try {
    yield put(setLoading())
    const data: Event[] = yield call(listEventsService)
    yield put(listEventsSuccessAction(data))
    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
  }
}

export function* removeEventItem(
  action: PayloadAction<EventActions.removeEventItemRequest, string>
) {
  try {
    yield call(removeEventIemService, action.payload)
    yield put(removeEventItemSuccess(action.payload))
    yield put(
      setAlertModalAction({
        open: true,
        title: 'Sessão removida com sucesso',
        variant: 'success',
        buttonText: 'Ok'
      })
    )
  } catch (e) {}
}

function* deleteTicket(
  action: PayloadAction<EventActions.deleteTicketRequest, string>
) {
  yield call(deleteTicketService, action.payload)
}

function* editBasicInformation(
  action: PayloadAction<
    EventActions.editBasicInformationRequest,
    EditBasicInformationRequest
  >
) {
  try {
    const { name, seatMapId, banners, category, cashBackPercent, ageGroup } =
      action.payload
    const getState = (state: ReduxState) => state.EventReducer
    const { editEvent }: EventState = yield select(getState)

    if (editEvent) {
      yield put(setLoading())
      let cardUrl =
        banners && banners[0].cardUrl === ''
          ? editEvent?.banners && editEvent?.banners[0].cardUrl
            ? editEvent?.banners[0].cardUrl
            : banners[0].cardUrl
          : banners && banners[0].cardUrl
          ? banners[0].cardUrl
          : undefined
      let mobileUrl =
        banners && banners[0].mobileUrl === ''
          ? editEvent?.banners && editEvent?.banners[0].mobileUrl
            ? editEvent?.banners[0].mobileUrl
            : banners[0].mobileUrl
          : banners && banners[0].mobileUrl
          ? banners[0].mobileUrl
          : undefined
      let tabletUrl =
        banners && banners[0].tabletUrl === ''
          ? editEvent?.banners && editEvent?.banners[0].tabletUrl
            ? editEvent?.banners[0].tabletUrl
            : banners[0].tabletUrl
          : banners && banners[0].tabletUrl
          ? banners[0].tabletUrl
          : undefined
      let desktopUrl =
        banners && banners[0].desktopUrl === ''
          ? editEvent?.banners && editEvent?.banners[0].desktopUrl
            ? editEvent?.banners[0].desktopUrl
            : banners[0].desktopUrl
          : banners && banners[0].desktopUrl
          ? banners[0].desktopUrl
          : undefined

      if (typeof cardUrl !== 'string' && cardUrl !== undefined) {
        cardUrl = yield call(uploadImagesService, cardUrl as File)
      }
      if (typeof mobileUrl !== 'string' && mobileUrl !== undefined) {
        mobileUrl = yield call(uploadImagesService, mobileUrl as File)
      }
      if (typeof tabletUrl !== 'string' && tabletUrl !== undefined) {
        tabletUrl = yield call(uploadImagesService, tabletUrl as File)
      }
      if (typeof desktopUrl !== 'string' && desktopUrl !== undefined) {
        desktopUrl = yield call(uploadImagesService, desktopUrl as File)
      }

      const editBanners = [
        {
          desktopUrl: desktopUrl as string,
          tabletUrl: tabletUrl as string,
          mobileUrl: mobileUrl as string,
          cardUrl: cardUrl as string
        }
      ]

      const emptyBanners = Object.entries(editBanners[0]).filter(
        (value) => value[1] !== undefined
      )

      if (
        name !== editEvent?.name ||
        seatMapId !== editEvent?.seatMapId ||
        cashBackPercent !== editEvent?.cashBackPercent ||
        !_.isEmpty(emptyBanners)
      ) {
        const body = {
          name: name !== editEvent.name ? name : undefined,
          ageGroup: ageGroup !== editEvent.ageGroup ? ageGroup : undefined,
          category: category !== editEvent.category ? category : undefined,
          cashBackPercent: 0,
          seatMapId: seatMapId !== editEvent.seatMapId ? seatMapId : undefined,
          banners:
            !_.isEmpty(emptyBanners) &&
            emptyBanners.length === 4 &&
            !_.isEqual(editBanners[0], editEvent?.banners![0])
              ? editBanners
              : undefined
        }

        const request = _.omitBy(body, _.isUndefined)

        if (!_.isEmpty(request)) {
          const data: {
            name: string
            category: string
            ageGroup: string
            cashBackPercent: number
            seatMapId: string
            banners: {
              id: string
              desktopUrl: string
              tabletUrl: string
              mobileUrl: string
              cardUrl: string
            }[]
          } = yield call(updateEventService, { body, eventId: editEvent.id })
          yield put(
            editBasicInformationSuccessAction({
              name: data.name,
              category: data.category,
              cashBackPercent: data.cashBackPercent,
              seatMapId: data.seatMapId,
              banners: data.banners,
              ageGroup: data.ageGroup
            })
          )
        }
      }
      yield put(clearLoading())
      yield put(editNextStep())
    }
  } catch (error: any) {
    yield put(clearLoading())
  }
}

function* addDescription(
  action: PayloadAction<EventActions.addDescription, string>
) {
  try {
    const descriptionPayload = action.payload
    const getState = (state: ReduxState) => state.EventReducer
    const { manageEvent }: EventState = yield select(getState)
    if (descriptionPayload !== manageEvent?.description) {
      yield put(setLoading())
      yield call(updateEventService, {
        body: {
          description: descriptionPayload
        },
        eventId: manageEvent!.id
      })
      yield put(addDescriptionSuccess(descriptionPayload))
    }
    yield put(clearLoading())
    yield put(editNextStep())
  } catch (error) {
    yield put(clearLoading())
  }
}

function* editDescription(
  action: PayloadAction<EventActions.editDescriptionRequest, string>
) {
  try {
    const descriptionPayload = action.payload
    const getState = (state: ReduxState) => state.EventReducer
    const { editEvent }: EventState = yield select(getState)
    if (descriptionPayload !== editEvent?.description) {
      yield put(setLoading())
      yield call(updateEventService, {
        body: {
          description: descriptionPayload
        },
        eventId: editEvent!.id
      })
      yield put(editDescriptionSuccessAction(descriptionPayload))
    }
    yield put(clearLoading())
    yield put(editNextStep())
  } catch (error) {
    yield put(clearLoading())
  }
}

function* editAddress(
  action: PayloadAction<EventActions.editAddressRequest, Address>
) {
  try {
    const addressPayload = action.payload
    const getState = (state: ReduxState) => state.EventReducer
    const { editEvent }: EventState = yield select(getState)

    if (editEvent) {
      const {
        city,
        complement,
        country,
        number,
        state,
        street,
        zipCode,
        name
      } = editEvent.address

      if (
        !_.isEqual(
          _.omitBy(addressPayload, _.isNil),
          _.omitBy(
            { city, complement, country, number, state, street, zipCode, name },
            _.isNil || _.isUndefined
          )
        )
      ) {
        yield put(setLoading())
        yield call(updateEventAddress, addressPayload, editEvent.id)
      }
      yield put(clearLoading())
      yield put(editNextStep())
    }
  } catch (error) {
    yield put(clearLoading())
  }
}

function* editPixels(
  action: PayloadAction<EventActions.editPixelsRequest, Links>
) {
  console.log('ON SAGA 1', action)
  const getState = (state: ReduxState) => state.EventReducer
  const { editEvent, manageEvent }: EventState = yield select(getState)
  const eventId = editEvent?.id ?? manageEvent?.id ?? ''
  const pixels = manageEvent?.pixels ?? editEvent?.pixels ?? null
  console.log('ON SAGA PIXELS 2')
  console.log(pixels)
  console.log(manageEvent)
  console.log(editEvent)
  console.log(manageEvent?.pixels)
  console.log(editEvent?.pixels)
  const existingSocialLinks =
    manageEvent?.socialLinks ?? editEvent?.socialLinks ?? null

  const socialLink = {
    facebookLink: action.payload.facebookLink,
    youtubeLink: action.payload.youtubeLink,
    instagramLink: action.payload.instagramLink,
    tiktokLink: action.payload.tiktokLink
  }

  const data: Pixels | null = yield call(
    updateEventPixelsService,
    eventId,
    pixels,
    {
      facebookpixel: action.payload.facebookpixel,
      googleanalyticspixel: action.payload.googleanalyticspixel,
      googletagmanagerpixel: action.payload.googletagmanagerpixel
    }
  )

  const links: {
    facebookLink: string
    youtubeLink: string
    instagramLink: string
    tiktokLink: string
  } | null = yield call(
    updateSocialLinks,
    eventId,
    existingSocialLinks,
    socialLink
  )
  yield put(updateSocialLinksSuccessAction(links!))
  yield put(editPixelsSuccessAction(data!))
  if (editEvent) yield put(editNextStep())
  else yield put(nextStep())
}

function* createCoupon(
  action: PayloadAction<
    EventActions.createCouponRequest,
    {
      name: string
      description: string
      amountOfUse: number
      value: string
      valueType: 'PERCENT' | 'CENTS'
      quantity?: number
      startValidateDate?: string
      finalValidateDate?: string
      type: 'limited' | 'unlimited'
    }
  >
) {
  try {
    const getState = (state: ReduxState) => state.EventReducer
    const { editEvent, manageEvent }: EventState = yield select(getState)
    const eventId = editEvent?.id ?? manageEvent?.id ?? ''
    yield put(setLoading())
    const data: Coupon = yield call(
      createCouponService,
      eventId,
      action.payload
    )
    yield put(createCouponSuccessAction(data))
    yield put(clearLoading())
    yield put(
      setAlertModalAction({
        open: true,
        title: 'Cupom criado com sucesso',
        buttonText: 'Fechar',
        variant: 'success'
      })
    )
  } catch (error) {
    yield put(clearLoading())
    yield put(
      setAlertModalAction({
        open: true,
        title: 'Não foi possível criar o cupom',
        buttonText: 'Fechar',
        variant: 'error'
      })
    )
    console.error(error)
  }
}

function* editCoupon(
  action: PayloadAction<
    EventActions.editCouponRequest,
    {
      id: string
      name: string
      description: string
      amountOfUse: number
      value: string | number
      valueType: 'PERCENT' | 'CENTS'
      quantity?: number
      startValidateDate?: string
      finalValidateDate?: string
    }
  >
) {
  try {
    yield put(setLoading())
    const getState = (state: ReduxState) => state.EventReducer
    const { editEvent }: EventState = yield select(getState)
    if (editEvent) {
      const couponIndex = editEvent.coupons?.findIndex(
        ({ id }) => id === action.payload.id
      )
      const data: Coupon = yield call(
        editCouponService,
        action.payload.id,
        editEvent.coupons![couponIndex!],
        {
          ...action.payload,
          value:
            typeof action.payload.value === 'number'
              ? action.payload.value
              : Number(action.payload.value.replace(/[^\d]/g, ''))
        }
      )
      yield put(editCouponSuccessAction(data))
      yield delay(1000)
      yield put(clearLoading())
      yield put(
        setAlertModalAction({
          open: true,
          title: 'Cupom editado com sucesso',
          buttonText: 'Fechar',
          variant: 'success'
        })
      )
    }
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setAlertModalAction({
        open: true,
        title: 'Não foi possivel editar o cupom',
        buttonText: 'Fechar',
        variant: 'error'
      })
    )
    console.error(e)
  }
}

function* removeCoupon(
  action: PayloadAction<EventActions.removeCouponRequest, string>
) {
  try {
    yield put(setLoading())
    yield call(removeCouponService, action.payload)
    yield put(removeCouponSuccessAction(action.payload))
    yield delay(1000)
    yield put(clearLoading())
    yield put(
      setAlertModalAction({
        open: true,
        title: 'Cupom deletado com sucesso',
        buttonText: 'Fechar',
        variant: 'success'
      })
    )
  } catch (e) {
    yield put(
      setAlertModalAction({
        open: true,
        title: 'Não foi possivel deletar o cupom',
        buttonText: 'Fechar',
        variant: 'error'
      })
    )
    console.error(e)
  }
}

export const EventSaga = all([
  takeLatest(EventActions.registerEventRequest, registerEvent),
  takeLatest(EventActions.addLocation, createLocation),
  takeLatest(EventActions.listEventsRequest, listEvents),
  takeLatest(EventActions.removeEventItemRequest, removeEventItem),
  takeLatest(EventActions.deleteTicketRequest, deleteTicket),
  takeLatest(EventActions.editBasicInformationRequest, editBasicInformation),
  takeLatest(EventActions.editDescriptionRequest, editDescription),
  takeLatest(EventActions.addDescription, addDescription),
  takeLatest(EventActions.editAddressRequest, editAddress),
  takeLatest(EventActions.editPixelsRequest, editPixels),
  takeLatest(EventActions.createCouponRequest, createCoupon),
  takeLatest(EventActions.editCouponRequest, editCoupon),
  takeLatest(EventActions.removeCouponRequest, removeCoupon)
])
