import {
  Paragraph,
  Input,
  RadioButton,
  Button,
  AlertModal
} from 'applaus-ui-kit'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { CurrencyInput } from '../../../../../../components/CurrencyInput/CurrencyInput'
import { useDispatch, useSelector } from 'react-redux'
import Select, { MultiValue } from 'react-select'
import {
  clearLoading,
  setLoading
} from '../../../../../../redux/Loading/actions'
import { ReduxState } from '../../../../../../redux/rootReducer'
import { createTicketValidation } from '../validationSchema'
import * as S from './TabulatedTicket.styles'
import { Tickets } from '../../../../../../redux/Events/types'
import {
  GetOneTicketResponse,
  getOneTicketService
} from '../../../../../../api/tickets/getOneTicketService'
import { updateTicketService } from '../../../../../../api/tickets/updateTicketService'
import { addTicketEventItem } from '../../../../../../redux/Events/actions'

type Props = {
  editTicket: Tickets
  setEditTicket: Dispatch<
    SetStateAction<{
      open: boolean
      ticket?: Tickets | undefined
    }>
  >
}

type SessionTickets = {
  value: string
  tickets?: number
}

type AlertModalState = {
  message: string
  buttonText: string
  variant: 'error' | 'success'
  click: () => void
  open: boolean
}

export const TabulatedTicket = ({ editTicket, setEditTicket }: Props) => {
  const { editEvent } = useSelector((state: ReduxState) => state.EventReducer)
  const dispatch = useDispatch()

  const [dataTicket, setDataTicket] = useState<
    GetOneTicketResponse | undefined
  >(undefined)
  const [selectedValue, setSelectedValue] = useState<string[]>([])
  const [ticketInfo, setTicketInfo] = useState<SessionTickets[]>([])
  const [typeTicket, setTypeTicket] = useState('')
  const [isFree, setIsFree] = useState(editTicket.type === 'Gratuito')

  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    open: false,
    buttonText: '',
    variant: 'error',
    click: () => {},
    message: ''
  })

  const handleChange = (e: MultiValue<{ value: string; label: string }>) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])

    setTicketInfo(
      Array.isArray(e) ? e.map((x) => ({ value: x.value, tickets: 1 })) : []
    )
  }

  const removeSelected = (value: string) => {
    setSelectedValue((prev) => prev.filter((select) => select !== value))
    setTicketInfo((prev) =>
      _.filter(prev, function (o) {
        return o.value !== value
      })
    )
  }

  const options = editEvent!.eventItems!.map(({ id, title }) => ({
    value: id ?? '',
    label: title
  }))

  useEffect(() => {
    const fetchTicket = async () => {
      dispatch(setLoading())
      const data = await getOneTicketService(editTicket.id)
      setDataTicket(data)
      setTypeTicket(editTicket.type)
      setSelectedValue(data.eventItems.map(({ eventItem }) => eventItem.id!))
      setTicketInfo(
        data.eventItems.map(({ eventItem, ticketQuantity }) => ({
          value: eventItem.id!,
          tickets: ticketQuantity
        }))
      )
      dispatch(clearLoading())
    }
    fetchTicket()
  }, [])

  const clearModal = () => {
    setAlertModalState({
      open: false,
      buttonText: '',
      variant: 'error',
      click: () => {},
      message: ''
    })
  }

  const formik = useFormik({
    initialValues: {
      name: editTicket.name,
      priceCents: String(editTicket.priceCents / 100),
      type: editTicket.type,
      discountCents: '0',
      rule: editTicket.rule,
      ticketInfo: ticketInfo.map(({ tickets, value }) => ({ [value]: tickets }))
    },
    validationSchema: createTicketValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const eventItemsTicket = values.ticketInfo.map((info) => ({
          eventItemId: Object.keys(info)[0],
          ticketQuantity: Object.values(info)[0]
            ? Number(Object.values(info)[0]) > 0
              ? Number(Object.values(info)[0])
              : 1
            : 1
        }))

        if (eventItemsTicket.length < 1) {
          setAlertModalState({
            open: true,
            click: clearModal,
            variant: 'error',
            message: 'Ticket deve ter pelo menos uma sessão',
            buttonText: 'Fechar'
          })
        } else {
          dispatch(setLoading())
          const responseData = await updateTicketService(
            editTicket.id,
            {
              category: values.name,
              name: values.name,
              type:
                editTicket.type !== typeTicket
                  ? typeTicket === 'Outros'
                    ? values.type
                    : typeTicket
                  : undefined,
              rule: values.rule !== '' ? values.rule : undefined,
              description: isFree ? 'Gratuito' : 'Pago',
              ticketPrice: {
                priceCents:
                  editTicket.priceCents.toString() !== values.priceCents
                    ? !isFree
                      ? values.priceCents.includes(',')
                        ? values.priceCents.includes('.')
                          ? Number(
                              values.priceCents
                                .replace(/[^0-9]/g, '')
                                .replace(',', '')
                            )
                          : Number(
                              values.priceCents
                                .replace(/[^0-9]/g, '')
                                .replace(',', '')
                            )
                        : Number(values.priceCents.replace(/[^0-9]/g, '')) * 100
                      : undefined
                    : Number(values.priceCents.replace(/[^0-9]/g, '')),
                discountCents: isFree ? undefined : 0,
                isFree: isFree
              },
              eventItemsTicket: eventItemsTicket
            },
            {
              ...dataTicket,
              eventItemsTicket: dataTicket?.eventItems.map(
                ({ eventItem, ticketQuantity }) => ({
                  eventItemId: eventItem.id!,
                  ticketQuantity
                })
              )
            }
          )
          responseData &&
            dispatch(
              addTicketEventItem(
                responseData,
                'edit',
                editTicket.eventItems[0].id!
              )
            )

          dispatch(clearLoading())
          setEditTicket({
            open: false
          })
        }
      } catch (e) {
        dispatch(clearLoading())
      }
    }
  })

  const optionsType = [
    {
      value: 'Inteira',
      label: 'Inteira'
    },
    {
      value: 'Meia',
      label: 'Meia'
    },
    {
      value: 'Outros',
      label: 'Outros'
    }
  ]

  return (
    <>
      {dataTicket ? (
        <S.Container>
          <Paragraph variant="regular" type="semiBold">
            *Selecione sessão
          </Paragraph>
          <Select
            className="dropdown"
            placeholder="Selecione a sessão "
            value={options.filter((obj) => selectedValue.includes(obj.value))}
            options={options}
            onChange={(e) => handleChange(e)}
            styles={S.CustomStyles}
            components={{ ClearIndicator: () => null }}
            controlShouldRenderValue={false}
            isMulti
          />
          <S.SelectedSections>
            {options
              .filter((obj) => selectedValue.includes(obj.value))
              .map((selected) => (
                <S.SelectedSectionCard key={selected.value}>
                  <Paragraph variant="regular" type="semiBold">
                    {selected.label}
                  </Paragraph>
                  <S.RemoveIcon
                    onClick={() => removeSelected(selected.value)}
                  />
                </S.SelectedSectionCard>
              ))}
          </S.SelectedSections>

          <S.TicketPerSession>
            <Paragraph variant="regular" type="semiBold">
              Quantidade de ingressos disponíveis
            </Paragraph>
            <div className="sessionInputs">
              {formik.values.ticketInfo.map((info, index) => {
                return (
                  <Input
                    key={index}
                    label={
                      _.find(options, function (e) {
                        return e.value === Object.keys(info)[0]
                      })?.label
                    }
                    value={Object.values(info)[0]}
                    name={`ticketInfo.${index}.${Object.keys(info)}`}
                    onChange={formik.handleChange}
                    type="number"
                    min="1"
                  />
                )
              })}
            </div>
          </S.TicketPerSession>
          <S.TicketTypeSelection>
            <Paragraph variant="regular" type="semiBold">
              Tipo do ingresso
            </Paragraph>
            <div className="optionsWrapper">
              <S.RadioButtonBox>
                <RadioButton
                  checked={!isFree}
                  readOnly
                  onClick={() => {
                    formik.setFieldValue('priceCents', '')
                    formik.setFieldValue('discountCents', '')
                    setIsFree(false)
                  }}
                />
                <Paragraph variant="regular">Ingresso Pago</Paragraph>
              </S.RadioButtonBox>
              <S.RadioButtonBox>
                <RadioButton
                  checked={isFree}
                  readOnly
                  onClick={() => {
                    formik.setFieldValue('priceCents', '100')
                    formik.setFieldValue('discountCents', '100')
                    setIsFree(true)
                  }}
                />
                <Paragraph variant="regular">Ingresso Gratuito</Paragraph>
              </S.RadioButtonBox>
            </div>
          </S.TicketTypeSelection>
          <S.TicketInfoBox>
            <div className="inputType">
              <Input
                fullWidth={false}
                name="name"
                label="*Título do ingresso"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                texterror={formik.errors.name}
              />
              <div
                style={{
                  width: '244px'
                }}>
                <div>
                  <S.Label>Tipo do ingresso</S.Label>
                  <Select
                    placeholder="Tipo do ingresso"
                    options={optionsType}
                    onChange={(e: any) => {
                      if (e && e?.value !== 'Outros') {
                        formik.setFieldValue('type', e?.value)
                        setTypeTicket(e?.value)
                      } else if (e && e?.value === 'Outros') {
                        setTypeTicket(e?.value)
                        formik.setFieldValue('type', '')
                      }
                    }}
                    value={
                      typeTicket !== 'Inteira' && typeTicket !== 'Meia'
                        ? [
                            {
                              label: 'Outros',
                              value: 'Outros'
                            }
                          ]
                        : formik.values.type
                        ? [
                            {
                              label: formik.values.type,
                              value: formik.values.type
                            }
                          ]
                        : null
                    }
                    styles={S.customStylesType}
                  />
                </div>
                {typeTicket === 'Outros' ? (
                  <div style={{ marginTop: '24px' }} className="inputType">
                    <Input
                      name="type"
                      label="Tipo do ingresso"
                      onChange={formik.handleChange}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                    />
                  </div>
                ) : null}
                {formik.touched.type && Boolean(formik.errors.type) ? (
                  <S.Error>{formik.errors.type}</S.Error>
                ) : (
                  <S.Error></S.Error>
                )}
              </div>
            </div>
            {!isFree && (
              <S.PriceBox>
                <CurrencyInput
                  className="input"
                  label="*Preço"
                  decimalsLimit={2}
                  intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
                  value={formik.values.priceCents}
                  onValueChange={(value) => {
                    formik.setFieldValue('priceCents', value)
                  }}
                  texterror={formik.errors.priceCents}
                  error={
                    formik.touched.priceCents &&
                    Boolean(formik.errors.priceCents)
                  }
                  min="1"
                />
                {/* <CurrencyInput
                  className="input"
                  label="*Preço com desconto"
                  decimalsLimit={2}
                  intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
                  value={formik.values.discountCents}
                  onValueChange={(value) =>
                    formik.setFieldValue('discountCents', value)
                  }
                  texterror={formik.errors.discountCents}
                  error={
                    formik.touched.discountCents &&
                    Boolean(formik.errors.discountCents)
                  }
                  min="1"
                /> */}
              </S.PriceBox>
            )}
          </S.TicketInfoBox>
          <Input
            name="rule"
            label="*Em caso de meia-entrada ou descontos explique regras para obter o ingresso:"
            value={formik.values.rule}
            onChange={formik.handleChange}
            error={formik.touched.rule && Boolean(formik.errors.rule)}
            texterror={formik.errors.rule}
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth={false}
            onClick={formik.submitForm}>
            Salvar Ingresso
          </Button>
        </S.Container>
      ) : undefined}
      <AlertModal {...alertModalState} />
    </>
  )
}
