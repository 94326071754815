import styled, { css } from 'styled-components'

export const ContentTicket = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const TicketBox = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.medium};
    border: 2px solid #dfdfdf;
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 570px;
    max-width: 570px;
    @media (min-width: 1171px) {
      min-width: 560px;
      max-width: 600px;
    }

    .circleLeft {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;

      border-top: 2px solid #dfdfdf;
      border-left: 2px solid white;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid white;
      transform: rotate(40deg);
      margin: auto ${theme.spacing.stack.xxxs} auto -20px;
    }
    .circleRight {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;

      border-top: 2px solid white;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid white;
      border-bottom: 2px solid #dfdfdf;
      transform: rotate(40deg);
      margin: auto -20px auto ${theme.spacing.stack.xxxs};
    }

    .titleEventBox {
      display: flex;
    }

    .ticketDetached {
      color: #ff6565;
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.bold};
      margin-left: ${theme.spacing.inline.nano};
    }
  `}
`

export const Ticket = styled.div`
  ${({ theme }) => css`
    border-left: 1px dashed #dfdfdf;
    border-right: 1px dashed #dfdfdf;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    width: 100%;
  `}
`

export const TitleEvent = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const AddressEvent = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxxs};
  `}
`

export const Date = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
  `}
`

export const TicketInfoBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing.stack.small};

    .contentTicketInfo {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }

      p {
        font-size: ${theme.font.size.xxs};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 45px;
      }
    }
  `}
`

export const TicketInfo = styled.div`
  ${({ theme }) => css`
    .contentInfoBox {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }

      p {
        font-size: ${theme.font.size.xxs};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
      }
    }
  `}
`

export const PurchaseData = styled.div`
  ${({ theme }) => css`
    h1,
    p {
      font-size: ${theme.font.size.xxs};
    }

    .contentPurchaseData {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.small};
      }
    }
  `}
`

export const QrCode = styled.div`
  ${({ theme }) => css`
    padding: 12px ${theme.spacing.stack.xxxs};
    border: 2px solid #dfdfdf;
    border-radius: ${theme.border.radius.light};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${theme.spacing.stack.xxs};
    min-width: 125px;
    max-width: 125px;

    .contentTicket {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 8px;
        font-weight: ${theme.font.weight.semiBold};
      }
    }

    .idQrCode {
      font-size: ${theme.font.size.xxs};
      line-height: ${theme.spacing.stack.xxs};
      color: #000000;
      font-weight: ${theme.font.weight.semiBold};
    }

    .circleLeft {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;

      border-top: 2px solid #dfdfdf;
      border-left: 2px solid white;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid white;
      transform: rotate(225deg);
      margin: auto -30px auto auto;
    }
    .circleRight {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;

      border-top: 2px solid white;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid white;
      border-bottom: 2px solid #dfdfdf;
      transform: rotate(225deg);
      margin: auto auto auto -30px;
    }

    .qrCodeImage {
      width: 90px;
      height: 90px;
    }

    @media (min-width: 1171px) {
      min-width: 135px;
      max-width: 135px;
      .qrCodeImage {
        width: 90px;
        height: 90px;
      }
    }
  `}
`

export const TicketBoxMobile = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.medium};
    border: 2px solid #dfdfdf;
    margin-top: ${theme.spacing.stack.xxxs};
    .circleTop {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      margin: -22px auto 10px auto;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid #dfdfdf;
      transform: rotate(45deg);
    }
    .circleBottom {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      margin: 10px auto -22px auto;
      border-top: 2px solid #dfdfdf;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
    .titleEventBox {
      display: flex;
    }
    .ticketDetached {
      color: #ff6565;
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.bold};
      margin-left: ${theme.spacing.inline.nano};
    }
  `}
`

export const TicketMobile = styled.div`
  ${({ theme }) => css`
    border-top: 1px dashed #dfdfdf;
    border-bottom: 1px dashed #dfdfdf;
    padding: ${theme.spacing.stack.xxxs};
  `}
`

export const TitleEventMobile = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.spacing.stack.xxs};
    margin-bottom: ${theme.spacing.stack.small};
    word-break: break-all;
  `}
`

export const AddressEventMobile = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const DateMobile = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const TicketInfoBoxMobile = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacing.stack.small};
    > div:nth-child(1) {
      margin-right: auto;
    }
  `}
`

export const TicketInfoMobile = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxs};
    .contentInfoBox {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }
      p {
        font-size: ${theme.font.size.xxs};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 70px;
      }
    }
  `}
`

export const PurchaseDataMobile = styled.div`
  ${({ theme }) => css`
    margin-left: 10px;
    .purchaseDate {
      h1 {
        white-space: nowrap;
        font-size: ${theme.font.size.xxs};
      }
      p {
        font-size: ${theme.font.size.xxs};
      }
    }
    .contentPurchaseData {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }
      p {
        font-size: ${theme.font.size.xxs};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
      }
    }
  `}
`

export const InfoCustomer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.small};
    .contentInfoCustomer {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }
      p {
        font-size: ${theme.font.size.xxs};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
      }
    }
  `}
`

export const QrCodeMobile = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs};
    border: 2px solid #dfdfdf;
    border-radius: ${theme.border.radius.light};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.nano};
    .contentTicket {
      font-size: ${theme.font.size.xxs};
      font-weight: ${theme.font.weight.semiBold};
    }
    .idQrCode {
      font-size: ${theme.font.size.xxs};
      line-height: ${theme.spacing.stack.xxs};
      color: #000000;
      font-weight: ${theme.font.weight.semiBold};
    }
    .circleTop {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      margin: -38px auto 10px auto;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid #dfdfdf;
      transform: rotate(45deg);
    }
    .circleBottom {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      margin: 10px auto -38px auto;
      border-top: 2px solid #dfdfdf;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
  `}
`
