import { api } from '../config'
import { AxiosResponse } from 'axios'
import { UserInfo } from '../../redux/User/types'

export type EditUserRequest = {
  firstName?: string
  lastName?: string
  document?: string
  documentType?: 'CNPJ' | 'CPF'
  email?: string
  phone?: string
  corporateName?: string
  address?: {
    city?: string
    complement?: string
    country?: string
    createdAt?: string
    creatorId?: string
    customerId?: string
    eventId?: string
    number?: number | undefined
    state?: string
    street?: string
    zipCode?: string
  }
}

export const editUserService = async (request: EditUserRequest) => {
  if (request.address) {
    if (request.address.number) {
      request.address.number = Number(request.address.number)
    }
    if (request.address.complement === '') {
      delete request.address.complement
    }
  }

  if (request.phone) {
    request.phone = request.phone.replace(/[^0-9]/g, '').trim()
  }
  const { data }: AxiosResponse<UserInfo> = await api.put(
    '/api/creators',
    request
  )
  return data
}
