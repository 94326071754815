/* eslint-disable no-unused-vars */
export enum CourseActions {
  LOAD_COURSES_REQUEST = '@course/LOAD_COURSES_REQUEST',
  LOAD_COURSES_SUCCESS = '@course/LOAD_COURSES_SUCCESS',
  ADD_COURSE_REQUEST = '@course/ADD_COURSE_REQUEST',
  ADD_COURSE_SUCCESS = '@course/ADD_COURSE_SUCCESS',
  REMOVE_COURSE = '@course/REMOVE_COURSE',
  EDIT_COURSE_REQUEST = '@course/EDIT_COURSE_REQUEST',
  EDIT_COURSE_SUCCESS = '@course/EDIT_COURSE_SUCCESS'
}

export type Course = {
  id: string
  name: string
  description: string
  priceCents: number
  discountCents: number
  coverUrl: string
}

export type CourseState = {
  data: Course[]
}
