import { BankAccount } from './bankAccountVerificationService'
import { api } from '../config'
import { AxiosResponse } from 'axios'
import { CreatorPaymentSetting } from '../../redux/User/types'

export const editAccountAddressService = async (request: BankAccount) => {
  const { data }: AxiosResponse<CreatorPaymentSetting> = await api.put(
    '/api/creators/accounts/bank/address',
    request
  )

  return data
}
