import styled, { css } from 'styled-components'

import { ReactComponent as RemoveFile } from '../../../../../../../assets/icons/trash-alt.svg'
import { ReactComponent as EditFile } from '../../../../../../../assets/icons/edit.svg'

export const TrashIcon = styled(RemoveFile)`
  fill: #808080;
  margin-left: 2rem;
  padding: 1rem;
  min-height: 40px;
  min-width: 40px;
  cursor: pointer;
  &:hover {
    fill: black;
    transform: scale(1.2);
  }
  transition: 0.3s ease;
`
export const EditIcon = styled(EditFile)`
  ${({ theme }) => css`
    fill: #b88bcf;
    padding: 1rem;
    min-height: 40px;
    min-width: 40px;
    cursor: pointer;
    &:hover {
      fill: #8601c9;
      transform: scale(1.2);
    }
    transition: 0.3s ease;
  `}
`

export const Table = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    margin: ${theme.spacing.stack.xs} 0;
    display: flex;
    flex-direction: column;
  `}
`

export const TableHead = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    margin-bottom: ${theme.spacing.stack.xs};
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    border-radius: ${theme.border.radius.medium};
    > p {
      color: #808080;
    }
  `}
`

export const TableBody = styled.div`
  ${({ theme }) => css`
    > div {
      margin-bottom: ${theme.spacing.stack.xxs};
    }
    div:last-child {
      margin-bottom: 0;
    }
  `}
`

export const TableData = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxs};
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.border.radius.medium};
    > p {
      align-self: center;
    }
    > progress {
      background: red;
    }
    p:last-child {
      margin-left: auto;
    }
  `}
`

export const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: end;
    align-items: center;
  `}
`
