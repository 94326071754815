import { AxiosResponse } from 'axios'
import { api } from '../config'

export type CreatePartnerRequest = {
  name: string
  document: string
  documentType: string
  email: string
  phoneNumber: string
  address: {
    zipCode: string
    complement?: string
    country: string
    state: string
    city: string
    street: string
    district: string
    number: number
  }
}

export type CreateBankAccountPartner = {
  bank: string
  bankAg: string
  bankCc: string
  bankAccountType: string
}

export type CreatePartnerResponse = {
  id: string
  name: string
  document: string
  documentType: 'CPF' | 'CNPJ'
  email: string
  phoneNumber: string
  address: {
    country: string
    state: string
    city: string
    street: string
    district: string
    number: number
    zipCode: string
    complement?: string
  }
  createdAt: string
  updatedAt: string | null
  bank: string
  bankAg: string
  bankCc: string
  bankAccountType: string
}

export const createPartnerService = async (
  request: CreatePartnerRequest,
  requestBank: CreateBankAccountPartner
) => {
  const { data }: AxiosResponse<CreatePartnerResponse> = await api.post(
    '/api/creators/partners',
    request
  )

  const response: AxiosResponse<CreatePartnerResponse> = await api.post(
    `/api/creators/partners/${data.id}/verification`,
    requestBank
  )

  return response.data
}
