import * as S from './MobileTabulatedEvent.styles'
import { TabulatedEvent } from '../../TabulatedEvent/TabulatedEvent'
import { Button, Heading, Paragraph } from 'applaus-ui-kit'
import { formatPrice } from '../../../../utils/formatPrice'
import { Dispatch, SetStateAction } from 'react'
import { StepState } from '../Event'
import produce from 'immer'

type Props = {
  eventItem: any
  setStep: Dispatch<SetStateAction<StepState>>
}

export const MobileTabulatedEvent = ({ eventItem, setStep }: Props) => {
  const setState = () => {
    setStep(
      produce((draft) => {
        draft.show = 'chooseEventItem'
      })
    )
  }
  return (
    <S.Container>
      <TabulatedEvent eventItem={eventItem} setState={setState} />
      <div style={{ height: '74px' }} />

      <S.TicketBox>
        <S.TotalPrice>
          <Paragraph variant="small" type="semiBold">
            Total
          </Paragraph>
          <Heading variant="h5">{formatPrice(120)}</Heading>
        </S.TotalPrice>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="button"
          size="small"
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {}}>
          Comprar
        </Button>
      </S.TicketBox>
    </S.Container>
  )
}
