import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'redux/rootReducer'
import { AppBar } from 'components/AppBar/AppBar'

import * as S from './CreateEvent.styles'
import { Paragraph } from 'applaus-ui-kit'
import { useMemo } from 'react'
import { RegisterEvent } from './Components/RegisterEvent/RegisterEvent'
import { EventDescription } from './Components/EventDescription/EventDescription'
import { EventAddress } from './Components/EventAddress/EventAddress'
import { Session } from './Components/Session/Session'
import { Tickets } from './Components/Tickets/Tickets'
import { useWindowSize } from 'hooks/useWindowSize'
import { MobileBar } from './Components/Mobile/MobileBar'
import { Publishing } from './Components/Publishing/Publishing'
import { EventPreview } from '../EventPreview/EventPreview'
import { goToStepAction } from 'redux/Events/editActions'
import { EventMarketing } from 'components/templates/EventMarketing/EventMarketing'
import {
  createCouponRequestAction,
  editCouponRequestAction,
  editPixelsRequestAction,
  nextStep,
  removeCouponRequestAction,
  stepBack
} from 'redux/Events/actions'
import { EventCoupons } from 'components/templates/EventCoupons/EventCoupons'

type FormValues = {
  name: string
  description: string
  amountOfUse: number
  value: number | string
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
  type: 'limited' | 'unlimited'
}

type Links = {
  facebookLink: string
  youtubeLink: string
  instagramLink: string
  tiktokLink: string
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export const CreateEvent = () => {
  const { steps, manageEvent } = useSelector(
    (state: ReduxState) => state.EventReducer
  )
  const { isDesktop, isMobile } = useWindowSize()
  const dispatch = useDispatch()

  const handleStepBack = () => {
    dispatch(stepBack())
  }

  const handleNextStep = () => {
    dispatch(nextStep())
  }

  const handleMarketingSubmit = (values: Links) => {
    console.log('ON CREATE EVENT:', values)
    dispatch(editPixelsRequestAction(values))
  }

  const handleCreateCoupon = (values: FormValues) => {
    dispatch(createCouponRequestAction(values))
  }

  const handleDeleteCoupon = (couponId: string) => {
    dispatch(removeCouponRequestAction(couponId))
  }

  const handleEditCoupon = (values: {
    id: string
    name: string
    description: string
    amountOfUse: number
    value: string
    valueType: 'PERCENT' | 'CENTS'
    quantity?: number
    startValidateDate?: string
    finalValidateDate?: string
  }) => {
    dispatch(editCouponRequestAction(values))
  }

  const renderPages = useMemo(() => {
    const activeStep = steps.findIndex((step) => step.isActive)
    switch (activeStep) {
      case 0:
        return <RegisterEvent />
      case 1:
        return <EventDescription />
      case 2:
        return <EventAddress />
      case 3:
        return <Session />
      case 4:
        return <Tickets />
      case 5:
        return (
          <EventCoupons
            handleClickBack={handleStepBack}
            handleClickNextStep={handleNextStep}
            handleCreateCoupon={handleCreateCoupon}
            handleEditCoupon={handleEditCoupon}
            handleDeleteCoupon={handleDeleteCoupon}
            coupons={manageEvent?.coupons ?? []}
          />
        )
      case 6:
        return (
          <EventMarketing
            initialValues={{
              facebookLink: manageEvent?.socialLinks?.facebookLink ?? '',
              instagramLink: manageEvent?.socialLinks?.instagramLink ?? '',
              tiktokLink: manageEvent?.socialLinks?.tiktokLink ?? '',
              youtubeLink: manageEvent?.socialLinks?.youtubeLink ?? '',
              facebookpixel: manageEvent?.pixels?.facebookpixel ?? '',
              googletagmanagerpixel:
                manageEvent?.pixels?.googletagmanagerpixel ?? '',
              googleanalyticspixel:
                manageEvent?.pixels?.googleanalyticspixel ?? ''
            }}
            handleClickBack={handleStepBack}
            handleSubmit={handleMarketingSubmit}
            handleNextStep={handleNextStep}
          />
        )
      case 7:
        return <Publishing />
      case 8:
        return <EventPreview />
      default:
        return <RegisterEvent />
    }
  }, [steps, isMobile])

  return (
    <S.Container>
      <AppBar />
      {steps.findIndex((step) => step.isActive) !== 7 ? (
        <S.Wrapper>
          {!isDesktop ? (
            <MobileBar />
          ) : (
            <S.Steps>
              {steps.map(({ name, position, completed, isActive }) => (
                <S.StepContent
                  key={name}
                  isActive={isActive}
                  onClick={() => dispatch(goToStepAction(position))}>
                  <S.StepPosition
                    isActive={isActive}
                    variant="regular"
                    type="semiBold">
                    {position}
                  </S.StepPosition>
                  <Paragraph variant="regular">{name}</Paragraph>
                </S.StepContent>
              ))}
            </S.Steps>
          )}
          {renderPages}
        </S.Wrapper>
      ) : (
        renderPages
      )}
    </S.Container>
  )
}
