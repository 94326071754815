import styled, { css, DefaultTheme } from 'styled-components'

const DirectionModifiers = {
  row: (theme: DefaultTheme) => css`
    flex-direction: row;
  `,
  rowReverse: (theme: DefaultTheme) => css`
    flex-direction: row-reverse;
  `,
  column: (theme: DefaultTheme) => css`
    flex-direction: column;
  `,
  columnReverse: (theme: DefaultTheme) => css`
    flex-direction: column-reverse;
  `
}

const AlignContentModifiers = {
  stretch: (theme: DefaultTheme) => css`
    align-content: stretch;
  `,
  center: (theme: DefaultTheme) => css`
    align-content: center;
  `,
  flexStart: (theme: DefaultTheme) => css`
    align-content: flex-start;
  `,
  flexEnd: (theme: DefaultTheme) => css`
    align-content: flex-end;
  `,
  spaceBetween: (theme: DefaultTheme) => css`
    align-content: space-between;
  `,
  spaceAround: (theme: DefaultTheme) => css`
    align-content: space-around;
  `
}

const AlignItemsModifiers = {
  flexStart: (theme: DefaultTheme) => css`
    align-items: flex-start;
  `,
  center: (theme: DefaultTheme) => css`
    align-items: center;
  `,
  flexEnd: (theme: DefaultTheme) => css`
    align-items: flex-end;
  `,
  stretch: (theme: DefaultTheme) => css`
    align-items: stretch;
  `,
  baseline: (theme: DefaultTheme) => css`
    align-items: baseline;
  `
}

const justifyModifiers = {
  flexStart: (theme: DefaultTheme) => css`
    justify-content: flex-start;
  `,
  center: (theme: DefaultTheme) => css`
    justify-content: center;
  `,
  flexEnd: (theme: DefaultTheme) => css`
    justify-content: flex-end;
  `,
  spaceBetween: (theme: DefaultTheme) => css`
    justify-content: space-between;
  `,
  spaceAround: (theme: DefaultTheme) => css`
    justify-content: space-around;
  `,
  spaceEvenly: (theme: DefaultTheme) => css`
    justify-content: space-evenly;
  `
}

type ContainerProps = {
  direction?: 'row' | 'rowReverse' | 'column' | 'columnReverse'
  alignContent?:
    | 'stretch'
    | 'center'
    | 'flexStart'
    | 'flexEnd'
    | 'spaceBetween'
    | 'spaceAround'
  alignItems?: 'flexStart' | 'center' | 'flexEnd' | 'stretch' | 'baseline'
  justify?:
    | 'flexStart'
    | 'center'
    | 'flexEnd'
    | 'spaceBetween'
    | 'spaceAround'
    | 'spaceEvenly'
}

export const Container = styled.div<ContainerProps>`
  ${({ direction, alignContent, alignItems, justify, theme }) => css`
    max-width: 1366px;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    ${direction && DirectionModifiers[direction](theme)}
    ${alignContent && AlignContentModifiers[alignContent](theme)}
    ${alignItems && AlignItemsModifiers[alignItems](theme)}
    ${justify && justifyModifiers[justify](theme)}
    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }
    padding: 0 ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: 0 ${theme.spacing.stack.xs};
    }
    @media (min-width: 1171px) {
      padding: 0 ${theme.spacing.stack.xxxl};
    }
  `}
`

type RowProps = {
  gutter?: number
  marginTop?:
    | 'nano'
    | 'small'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'xxxl'
    | 'bg'
    | 'giant'
  marginRight?:
    | 'nano'
    | 'small'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'xxxl'
    | 'bg'
    | 'giant'
  marginBottom?:
    | 'nano'
    | 'small'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'xxxl'
    | 'bg'
    | 'giant'
  marginLeft?:
    | 'nano'
    | 'small'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'xxxl'
    | 'bg'
    | 'giant'
}

export const Row = styled.div<RowProps>`
  ${({
    theme,
    gutter,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft
  }) => css`
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }
    > div {
      padding-left: ${`${gutter}rem`};
    }
    > div:first-child {
      padding-left: 0;
    }
    ${marginTop &&
    css`
      margin-top: ${theme.spacing.stack[marginTop]};
    `}
    ${marginRight &&
    css`
      margin-right: ${theme.spacing.stack[marginRight]};
    `}
    ${marginBottom &&
    css`
      margin-bottom: ${theme.spacing.stack[marginBottom]};
    `}
    ${marginLeft &&
    css`
      margin-left: ${theme.spacing.stack[marginLeft]};
    `}
  `}
`

type ColumnProps = {
  mobile?: number
  tablet?: number
  desktop?: number
  hugeDesktop?: number
  spacing?: number
  gutter?: number
}

const getWidthGridInline = (value: number, paddingValue: number) => {
  if (!value) return

  const width = (value / 12) * 100
  const padding = paddingValue / (12 / value)
  return `width: calc(${width}% - ${padding}px)`
}

export const ColumnInline = styled.div<ColumnProps>`
  ${({ mobile, tablet, desktop, hugeDesktop, spacing }) => css`
    display: inline-block;
    min-height: 1px;
    box-sizing: border-box;
    width: 100%;
    @media only screen and (max-width: 768px) {
      ${mobile && getWidthGridInline(mobile, 20)};
      padding: 0 ${`${spacing && spacing * 0.5}rem`};
    }
    @media only screen and (min-width: 768px) {
      ${tablet && getWidthGridInline(tablet, 44)};
      padding: 0 ${`${spacing && spacing * 0.5}rem`};
    }
    @media only screen and (min-width: 1171px) {
      ${desktop && getWidthGridInline(desktop, 50)};
      padding: 0 ${`${spacing && spacing * 1}rem`};
    }
    @media only screen and (min-width: 1440px) {
      ${hugeDesktop && getWidthGridInline(hugeDesktop, 50)};
      padding: 0 ${`${spacing && spacing * 1}rem`};
    }
  `}
`

const getWidthGrid = (value: number, columns: number) => {
  if (!value) return

  const width = (value / columns) * 100
  return `width: ${width}%`
}

export const Column = styled.div<ColumnProps>`
  ${({ mobile, tablet = 1, desktop, spacing, hugeDesktop }) => css`
    float: left;
    padding: 0.25rem;
    min-height: 1px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      ${mobile && getWidthGrid(mobile, 4)};
      padding: 0 ${`${spacing && spacing * 0.5}rem`};
    }
    @media (min-width: 768px) and (max-width: 1170px) {
      width: ${`${(tablet / 8) * 100}%`};
      padding: 0 ${`${spacing && spacing * 0.5}rem`};
    }
    @media (min-width: 1171px) {
      ${desktop && getWidthGrid(desktop, 12)};
      padding: 0 ${`${spacing && spacing * 1}rem`};
    }
  `}
`
