import { api } from '../config'
import { AxiosResponse } from 'axios'
import { Address, EventItems } from '../../redux/Events/types'

export type Event = {
  id: string
  name: string
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  dates: string[]
  description: string
  status: 'OPEN' | 'CLOSED'
  banners: {
    desktopUrl: string | File
    tabletUrl: string | File
    mobileUrl: string | File
    cardUrl: string | File
  }[]
  address: Address
  createdAt: string
  updatedAt: string | null
  creatorId: string
  eventItems: EventItems[]
}

type ListEventsResponse = {
  items: Event[]
  total: number
}

export const listEventsService = async () => {
  const { data }: AxiosResponse<ListEventsResponse> = await api.get(
    '/api/creators/events'
  )

  const eventsWithTickets = data.items.flatMap((event) => {
    const tickets = event.eventItems.flatMap(({ tickets }) => tickets)
    return { ...event, tickets: tickets }
  })

  return eventsWithTickets
}
