/* eslint-disable no-unused-vars */
import * as S from './Courtesy.styles'
import { history } from '../../routes/services/history'
import { AuthenticatedTemplate } from '../../components/templates/authenticatedTemplate/AuthenticatedTemplate'
import { Heading, Button, Paragraph } from 'applaus-ui-kit'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearStateAction,
  listEventsRequestAction
} from '../../redux/Events/actions'
import { ReduxState } from '../../redux/rootReducer'
import { useWindowSize } from '../../hooks/useWindowSize'
import moment from 'moment'
import 'moment/locale/pt-br'
import _ from 'lodash'

export const Courtesy = () => {
  const [searchInput, setSearchInput] = useState('')
  const dispatch = useDispatch()
  const { data } = useSelector((state: ReduxState) => state.EventReducer)
  const windowSize = useWindowSize()

  useEffect(() => {
    dispatch(clearStateAction())
    dispatch(listEventsRequestAction())
  }, [])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const renderEvents = useMemo(() => {
    return _.filter(data, function (event) {
      return event.name.toLowerCase().includes(searchInput.toLocaleLowerCase())
    })
  }, [searchInput, data])

  return (
    <AuthenticatedTemplate isActive={'courtesy'}>
      <S.Container>
        <S.Wrapper>
          <Heading variant="h3">Meus eventos</Heading>
          <S.CreateEventBox>
            <S.InputWrapper>
              <S.SearchIcon />
              <S.Input
                placeholder="Buscar pelo nome do evento"
                onChange={(e: any) => handleInputChange(e)}
              />
            </S.InputWrapper>
          </S.CreateEventBox>
          {windowSize.isMobile ? (
            data
              .filter(
                (event) =>
                  event.eventItems?.length !== 0 &&
                  event.eventItems?.filter(
                    (eventItem) => eventItem.tickets.length !== 0
                  ).length !== 0
              )
              .map((event) => (
                <S.EditEventCard
                  key={event.id}
                  onClick={() => history.push(`/courtesyEvent/${event.id}`)}>
                  <div className="titleBox">
                    <Paragraph
                      variant="large"
                      type="semiBold"
                      className="paragraph">
                      {event.name}
                    </Paragraph>

                    <Paragraph variant="regular" className="paragraph">
                      {event.eventItems && event.eventItems?.length > 0
                        ? moment(event.eventItems[0].dates[0]).format('lll')
                        : 'Não possui data definida'}
                    </Paragraph>
                  </div>
                  <div className="actions">
                    <S.EditIcon
                      onClick={() => history.push(`/courtesyEvent/${event.id}`)}
                    />
                  </div>
                </S.EditEventCard>
              ))
          ) : (
            <S.Table>
              <S.TableHead>
                <Paragraph variant="regular" type="semiBold">
                  Status
                </Paragraph>

                <Paragraph variant="regular" type="semiBold">
                  Evento
                </Paragraph>

                <Paragraph variant="regular" type="semiBold">
                  ingressos
                </Paragraph>
                <div />
              </S.TableHead>

              <S.TableBody>
                {data
                  .filter(
                    (event) =>
                      event.eventItems?.length !== 0 &&
                      event.eventItems?.filter(
                        (eventItem) => eventItem.tickets.length !== 0
                      ).length !== 0
                  )
                  .map((event) => (
                    <S.TableData key={event.id}>
                      <Paragraph
                        variant="regular"
                        type="semiBold"
                        className={
                          event.status === 'OPEN'
                            ? 'public-status'
                            : event.status === 'CANCELED'
                            ? 'closed-status'
                            : 'others-status'
                        }>
                        {event.status === 'OPEN'
                          ? 'Publicado'
                          : event.status === 'SOLD_OUT'
                          ? 'Esgotado'
                          : event.status === 'CANCELED'
                          ? 'Cancelado'
                          : event.status === 'CLOSED'
                          ? 'Fechado'
                          : event.status === 'DRAFT'
                          ? 'Rascunho'
                          : null}
                      </Paragraph>
                      <Paragraph
                        variant="regular"
                        type="semiBold"
                        className="paragraph">
                        {event.name}
                      </Paragraph>

                      {event.tickets && event.tickets?.length > 0 ? (
                        <div>
                          <Paragraph variant="regular" type="semiBold">
                            {`${event.tickets?.reduce(
                              (acc, { ticketsSold }) =>
                                acc + (ticketsSold || 0),
                              0
                            )}`}
                            {`/${event.tickets?.reduce(
                              (acc, { ticketQuantity }) =>
                                acc + (ticketQuantity || 0),
                              0
                            )}`}
                          </Paragraph>
                          <progress
                            value={event.tickets?.reduce(
                              (acc, { ticketsSold }) =>
                                acc + (ticketsSold || 0),
                              0
                            )}
                            max={event.tickets?.reduce(
                              (acc, { ticketQuantity }) =>
                                acc + (ticketQuantity || 0),
                              0
                            )}
                          />
                        </div>
                      ) : (
                        <Paragraph
                          variant="regular"
                          className="without-tickets-paragraph">
                          Sem ingressos
                        </Paragraph>
                      )}

                      <S.Actions>
                        <Button
                          color="primary"
                          size="medium"
                          variant="contained"
                          fullWidth={false}
                          onClick={() =>
                            history.push(`/courtesyEvent/${event.id}`)
                          }>
                          Cortesia
                        </Button>
                      </S.Actions>
                    </S.TableData>
                  ))}
              </S.TableBody>
            </S.Table>
          )}
        </S.Wrapper>
      </S.Container>
    </AuthenticatedTemplate>
  )
}
