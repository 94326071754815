import { AxiosResponse } from 'axios'
import { api } from '../config'

type Course = {
  id: string
  name: string
  description: string
  priceCents: number
  discountCents: number
  coverUrl: string
}

export type CourseResponse = {
  items: Course[]
}

export const listCoursesService = async () => {
  const { data }: AxiosResponse<CourseResponse> = await api.get(
    'api/creators/courses'
  )

  return data.items
}
