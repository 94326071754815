import moment from 'moment'
import { formatPrice } from '../../utils/formatPrice'
import * as S from './Ticket.styles'
import QRCode from 'qrcode'
import { useEffect, useState } from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'

type Props = {
  eventName: string
  address: {
    street: string
    city: string
  }
  date: string
  ticketName: string
  ticketCategory: string
  eventType: 'TABULATED' | 'MAPPED'
  seatId: string
  purchaseDate: string
  ticketPrice: number
  ticketInfo: Record<string, string>[] | null
  customerTicketId: string
  ticketLocale: string
}

export const TicketComponent = ({
  eventName,
  address,
  date,
  ticketName,
  eventType,
  ticketCategory,
  seatId,
  purchaseDate,
  ticketPrice,
  ticketInfo,
  customerTicketId,
  ticketLocale
}: Props) => {
  const [qrCodeImage, setQrCodeImage] = useState<string>('')
  const { isDesktop, isTablet } = useWindowSize()
  useEffect(() => {
    QRCode.toDataURL('applaus').then((response) => setQrCodeImage(response))
  }, [])

  return isDesktop || isTablet ? (
    <S.ContentTicket>
      <S.TicketBox>
        <div className="circleLeft" />
        <S.Ticket>
          <div className="titleEventBox">
            <S.TitleEvent>{eventName}</S.TitleEvent>
          </div>
          <S.AddressEvent>
            {address.street + ', ' + address.city}
          </S.AddressEvent>
          <S.Date>{moment(date).format('LLLL')}</S.Date>
          <S.TicketInfoBox>
            <S.TicketInfo>
              <div className="contentInfoBox">
                <h1>Ingresso:</h1> <p>{ticketName}</p>
              </div>
              {eventType === 'MAPPED' ? (
                <div className="contentInfoBox">
                  <h1>Setor:</h1> <p>{ticketCategory}</p>
                </div>
              ) : null}
              {eventType === 'MAPPED' ? (
                <div className="contentInfoBox">
                  <h1>Lugar:</h1> <p>{ticketLocale}</p>
                </div>
              ) : null}
            </S.TicketInfo>
            <S.PurchaseData>
              <h1>Data da compra:</h1>{' '}
              <p>
                {moment(purchaseDate).format('L') +
                  ' às ' +
                  moment(purchaseDate).format('LT')}
              </p>
              <div className="contentPurchaseData">
                <h1>Valor:</h1> <p>{formatPrice(ticketPrice)}</p>
              </div>
            </S.PurchaseData>
            {ticketInfo && ticketInfo.length > 0 ? (
              <div>
                {ticketInfo.map((ticket, index) =>
                  index < 3 ? (
                    <div key={index} className="contentTicketInfo">
                      <h1>{ticket.value}:</h1> <p>{ticket[ticket.value]}</p>
                    </div>
                  ) : null
                )}
              </div>
            ) : (
              <div></div>
            )}
          </S.TicketInfoBox>
        </S.Ticket>
        <div className="circleRight" />
      </S.TicketBox>
      <S.QrCode>
        <div className="contentTicket">
          <img src={qrCodeImage} alt="QRCode" className="qrCodeImage" />
          <p className="idQrCode">{customerTicketId}</p>
        </div>
      </S.QrCode>
    </S.ContentTicket>
  ) : (
    <S.ContentTicket>
      <S.TicketBoxMobile>
        <div className="circleTop" />
        <S.TicketMobile>
          <div className="titleEventBox">
            <S.TitleEventMobile>{eventName}</S.TitleEventMobile>
          </div>
          <S.AddressEventMobile>
            {address.street + ', ' + address.city}
          </S.AddressEventMobile>
          <S.DateMobile>{moment(date).format('LLLL')}</S.DateMobile>
          <S.TicketInfoBoxMobile>
            <S.TicketInfoMobile>
              <div className="contentInfoBox">
                <h1>Ingresso:</h1> <p>{ticketName}</p>
              </div>
              {eventType === 'MAPPED' ? (
                <div className="contentInfoBox">
                  <h1>Setor:</h1> <p>{ticketCategory}</p>
                </div>
              ) : null}
              {eventType === 'MAPPED' ? (
                <div className="contentInfoBox">
                  <h1>Lugar:</h1> <p>{ticketLocale}</p>
                </div>
              ) : null}
            </S.TicketInfoMobile>
            <S.PurchaseDataMobile>
              <div className="purchaseDate">
                <h1>Data da compra:</h1>{' '}
                <p>
                  {moment(purchaseDate).format('L') +
                    ' às ' +
                    moment(purchaseDate).format('LT')}
                </p>
              </div>
              <div className="contentPurchaseData">
                <h1>Valor:</h1> <p>{formatPrice(ticketPrice)}</p>
              </div>
            </S.PurchaseDataMobile>
          </S.TicketInfoBoxMobile>
          <S.InfoCustomer>
            {ticketInfo &&
              ticketInfo.length > 0 &&
              ticketInfo.map((ticket, index) =>
                index < 3 ? (
                  <div key={index} className="contentInfoCustomer">
                    <h1>{ticket.value}:</h1> <p>{ticket[ticket.value]}</p>
                  </div>
                ) : null
              )}
          </S.InfoCustomer>

          <S.QrCodeMobile>
            <div className="circleTop" />
            <img src={qrCodeImage} />
            <p className="idQrCode">{customerTicketId}</p>
            <div className="circleBottom" />
          </S.QrCodeMobile>
        </S.TicketMobile>
        <div className="circleBottom" />
      </S.TicketBoxMobile>
    </S.ContentTicket>
  )
}
