import * as Yup from 'yup'
import { AnyObject } from 'yup/es/types'

const requiredIfTyped = (message: string) => ({
  is: (value: string) => value?.length,
  then: (
    rule: Yup.StringSchema<
      string | null | undefined,
      AnyObject,
      string | null | undefined
    >
  ) => rule.min(3, message)
})

export const addressSchema = Yup.object().shape(
  {
    zipCode: Yup.string()
      .required('CEP é obrigatório.')
      .test('zipCode', 'Cep inválido', (value) =>
        value ? value.length === 8 : false
      ),
    name: Yup.string().required('Nome do local é obrigatório.'),
    street: Yup.string().required('Endereço é obrigatório.'),
    city: Yup.string().required('Cidade é obrigatório.'),
    number: Yup.string().required('Número é obrigatório.'),
    state: Yup.string().required('Estado é obrigatório.'),
    complement: Yup.string()
      .nullable()
      .notRequired()
      .when('complement', requiredIfTyped('Minímo de 3 caracteres'))
  },
  [['complement', 'complement']]
)
