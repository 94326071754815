import { all, call, put, takeLatest } from '@redux-saga/core/effects'
import { PayloadAction } from 'typesafe-actions'
import {
  CourseCreationRequest,
  createCourseService
} from '../../api/courses/createCourseService'
import { editCourseService } from '../../api/courses/editCourseService'
import { listCoursesService } from '../../api/courses/listCoursesService'
import {
  addCourseSuccessAction,
  editCourseSuccessAction,
  loadCoursesSuccessAction
} from './actions'
import { Course, CourseActions } from './types'
import { deleteCourseService } from '../../api/courses/deleteCourseService'
import { uploadImagesService } from '../../api/files/uploadImageService'
import axios from 'axios'
import { setAlertModalAction } from '../AlertModal/actions'
import { messageHelper } from '../../utils/messageHelper'

function* loadCreatorCourses() {
  try {
    const data: Course[] = yield call(listCoursesService)
    yield put(loadCoursesSuccessAction(data))
  } catch (error) {}
}

function* addCourse(
  action: PayloadAction<CourseActions.ADD_COURSE_REQUEST, CourseCreationRequest>
) {
  try {
    const data: Course = yield call(createCourseService, action.payload)
    yield put(addCourseSuccessAction(data))
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      if (err.response.status === 400) {
        yield put(
          setAlertModalAction({
            open: false,
            title: messageHelper.axios.createCourseTitle400,
            description: messageHelper.axios.createCourseDescription400,
            buttonText: 'Fechar',
            variant: 'error'
          })
        )
      } else {
        yield put(
          setAlertModalAction({
            open: false,
            title: messageHelper.axios.internalErrorTitle,
            description: messageHelper.axios.internalErrorDescription,
            buttonText: 'Fechar',
            variant: 'error'
          })
        )
      }
    }
  }
}

function* editCourse(
  action: PayloadAction<
    CourseActions.EDIT_COURSE_REQUEST,
    {
      id: string
      name?: string | undefined
      description?: string | undefined
      priceCents?: number | undefined
      discountCents?: number | undefined
      coverUrl?: File | string | undefined
    }
  >
) {
  try {
    const { id, name, description, priceCents, discountCents, coverUrl } =
      action.payload
    if (coverUrl && typeof coverUrl !== 'string') {
      const imageUrl: string = yield call(uploadImagesService, coverUrl)
      const data: Course = yield call(editCourseService, {
        id,
        name,
        description,
        priceCents,
        discountCents,
        coverUrl: imageUrl
      })
      yield put(editCourseSuccessAction(data))
    } else {
      const data: Course = yield call(editCourseService, {
        id,
        name,
        description,
        priceCents,
        discountCents,
        coverUrl
      })
      yield put(editCourseSuccessAction(data))
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      if (err.response.status === 400) {
        yield put(
          setAlertModalAction({
            open: false,
            title: messageHelper.axios.editCourseTitle400,
            description: messageHelper.axios.editCourseDescription400,
            buttonText: 'Fechar',
            variant: 'error'
          })
        )
      } else {
        yield put(
          setAlertModalAction({
            open: false,
            title: messageHelper.axios.internalErrorTitle,
            description: messageHelper.axios.internalErrorDescription,
            buttonText: 'Fechar',
            variant: 'error'
          })
        )
      }
    }
  }
}

function* deleteCourse(
  action: PayloadAction<CourseActions.REMOVE_COURSE, string>
) {
  try {
    yield call(deleteCourseService, action.payload)
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(
        setAlertModalAction({
          open: false,
          title: messageHelper.axios.internalErrorTitle,
          description: messageHelper.axios.internalErrorDescription,
          buttonText: 'Fechar',
          variant: 'error'
        })
      )
    }
  }
}

export const CourseSaga = all([
  takeLatest(CourseActions.LOAD_COURSES_REQUEST, loadCreatorCourses),
  takeLatest(CourseActions.ADD_COURSE_REQUEST, addCourse),
  takeLatest(CourseActions.EDIT_COURSE_REQUEST, editCourse),
  takeLatest(CourseActions.REMOVE_COURSE, deleteCourse)
])
