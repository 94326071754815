import styled, { css } from 'styled-components'
import { ReactComponent as BackFile } from '../../../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
`
export const TitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > svg {
      margin-right: ${theme.spacing.inline.xxs};
    }
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1366px;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    padding: ${theme.spacing.stack.xxs};
    > p {
      margin-bottom: ${theme.spacing.stack.xs};
    }
    > button {
      align-self: end;
      margin-top: ${theme.spacing.stack.md};
    }
    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.xxl} ${theme.spacing.stack.xxxl};
    }
  `}
`
