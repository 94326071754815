import * as S from './SocialLogin.styles'

export const SocialLogin = () => {
  return (
    <S.Container>
      <S.TitleDiv>
        <S.SemiDivider />
        <S.Text>ou</S.Text>
        <S.SemiDivider />
      </S.TitleDiv>
      <S.SocialLoginButton>
        <S.GoogleIcon />
        <p>Continuar com o Google</p>
      </S.SocialLoginButton>
      <S.SocialLoginButton>
        <S.FacebookIcon />
        <p>Continuar com o Facebook</p>
      </S.SocialLoginButton>
    </S.Container>
  )
}
