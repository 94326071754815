import * as S from './EventProfile.styles'

import 'moment/locale/pt-br'
import { Heading, Paragraph, Button } from 'applaus-ui-kit'
import { formatPrice } from '../../../utils/formatPrice'
import { min } from 'lodash'
import { DateInfo } from '../Components/DateInfo/DateInfo'
import { Dispatch, SetStateAction } from 'react'
import produce from 'immer'
import { StepState } from './Event'

type Props = {
  data: any
  setStep: Dispatch<SetStateAction<StepState>>
}

export const EventProfile = ({ data, setStep }: Props) => {
  return (
    <S.Container>
      {data ? (
        <>
          <S.EventDataBox>
            <S.CoverUrl src={data.banners[0].mobileUrl} alt={data.name} />
            <S.EventData>
              <Heading variant="h4" className="textInfo">
                {data.name}
              </Heading>
              <DateInfo
                dates={data.eventItems.flatMap(
                  ({ dates }: { dates: string[] }) => dates
                )}
              />

              <Heading variant="h5" className="textInfo">
                {data.address.street}
              </Heading>
              <Paragraph variant="small" type="normal" className="textInfo">
                {data.address.city}, {data.address.state}
                {data.address.zipCode}
              </Paragraph>
            </S.EventData>
          </S.EventDataBox>
          <S.EventInfo>
            <Heading className="heading" variant="h5">
              Detalhes
            </Heading>
            <S.Description
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
            <Heading className="heading" variant="h5">
              Localização
            </Heading>
            <S.LocationIframe>
              <iframe
                className="responsive-iframe"
                src={decodeURI(
                  `https://maps.google.com/maps?q=${data.address.zipCode}, ${data.address.street}, ${data.address.number}&t=&z=15&ie=UTF8&iwloc=&output=embed`
                )}
                frameBorder="0"
                scrolling="no"
              />
            </S.LocationIframe>
            <div style={{ height: '74px' }} />
            <S.TicketBox>
              <S.PricingInfo>
                <Paragraph variant="small" type="semiBold">
                  valores a partir de
                </Paragraph>
                <Paragraph variant="small" type="semiBold">
                  {formatPrice(
                    min(
                      data.eventItems.flatMap(({ tickets }: { tickets: any }) =>
                        tickets.map(
                          (ticket: any) =>
                            (ticket.priceCents - ticket.discountCents) / 100
                        )
                      )
                    ) ?? 0
                  )}
                </Paragraph>
              </S.PricingInfo>

              <Button
                color="primary"
                variant="contained"
                fullWidth={false}
                type="button"
                size="small"
                style={{ whiteSpace: 'nowrap' }}
                onClick={() =>
                  setStep(
                    produce((draft) => {
                      if (data.eventItems.length > 0) {
                        draft.show = 'chooseEventItem'
                      }
                    })
                  )
                }>
                Ver Ingressos
              </Button>
            </S.TicketBox>
          </S.EventInfo>{' '}
        </>
      ) : undefined}
    </S.Container>
  )
}
