/* eslint-disable no-unused-vars */
import { call, put, select, takeLatest } from '@redux-saga/core/effects'
import { all } from 'redux-saga/effects'
import { UserActions, UserInfo } from './types'
import { getUserInfoService } from '../../api/user/getUserInfoService'
import { EditUser, getUserInfoSuccess } from './actions'
import { setAlertModalAction } from '../AlertModal/actions'
import { clearLoading, setLoading } from '../Loading/actions'
import { PayloadAction } from 'typesafe-actions'
import { editUserService } from '../../api/user/editUserService'
import { ReduxState } from '../rootReducer'

export function* getUserInfo() {
  try {
    const data: UserInfo = yield call(getUserInfoService)
    yield put(getUserInfoSuccess(data))
  } catch (e) {}
}

export function* editUser(
  action: PayloadAction<UserActions.editUserRequest, EditUser>
) {
  try {
    yield put(setLoading())
    const {
      document,
      email,
      corporateName,
      firstName,
      lastName,
      phone,
      address
    } = action.payload
    const getState = (state: ReduxState) => state.UserReducer.userInfo
    const userState: UserInfo = yield select(getState)

    const newAddress = {
      city:
        (userState.address?.city !== address?.city && address?.city !== '') ||
        (userState.address?.complement !== address?.complement &&
          address?.complement !== '') ||
        (userState.address?.number !== Number(address?.number) &&
          Number(address?.number) !== 0) ||
        (userState.address?.state !== address?.state &&
          address?.state !== '') ||
        (userState.address?.street !== address?.street &&
          address?.street !== '') ||
        (userState.address?.zipCode !== address?.zipCode &&
          address?.zipCode !== '') ||
        (userState.address?.district !== address?.district &&
          address?.district !== '')
          ? address?.city
          : undefined,
      complement:
        userState.address?.complement !== address?.complement &&
        address?.complement !== ''
          ? address?.complement
          : undefined,
      country:
        (userState.address?.city !== address?.city && address?.city !== '') ||
        (userState.address?.complement !== address?.complement &&
          address?.complement !== '') ||
        (userState.address?.number !== Number(address?.number) &&
          Number(address?.number) !== 0) ||
        (userState.address?.state !== address?.state &&
          address?.state !== '') ||
        (userState.address?.street !== address?.street &&
          address?.street !== '') ||
        (userState.address?.zipCode !== address?.zipCode &&
          address?.zipCode !== '') ||
        (userState.address?.district !== address?.district &&
          address?.district !== '')
          ? 'BR'
          : undefined,
      number:
        userState.address?.number !== Number(address?.number) &&
        Number(address?.number) !== 0
          ? Number(address?.number)
          : undefined,
      state:
        (userState.address?.city !== address?.city && address?.city !== '') ||
        (userState.address?.complement !== address?.complement &&
          address?.complement !== '') ||
        (userState.address?.number !== Number(address?.number) &&
          Number(address?.number) !== 0) ||
        (userState.address?.state !== address?.state &&
          address?.state !== '') ||
        (userState.address?.street !== address?.street &&
          address?.street !== '') ||
        (userState.address?.zipCode !== address?.zipCode &&
          address?.zipCode !== '') ||
        (userState.address?.district !== address?.district &&
          address?.district !== '')
          ? address?.state
          : undefined,
      street:
        userState.address?.street !== address?.street && address?.street !== ''
          ? address?.street
          : undefined,
      zipCode:
        userState.address?.zipCode !== address?.zipCode &&
        address?.zipCode !== ''
          ? address?.zipCode
          : undefined,
      district:
        (userState.address?.city !== address?.city && address?.city !== '') ||
        (userState.address?.complement !== address?.complement &&
          address?.complement !== '') ||
        (userState.address?.number !== Number(address?.number) &&
          Number(address?.number) !== 0) ||
        (userState.address?.state !== address?.state &&
          address?.state !== '') ||
        (userState.address?.street !== address?.street &&
          address?.street !== '') ||
        (userState.address?.zipCode !== address?.zipCode &&
          address?.zipCode !== '') ||
        (userState.address?.district !== address?.district &&
          address?.district !== '')
          ? address?.district
          : undefined
    }

    const newUserInfo = {
      document: userState.document !== document ? document : undefined,
      email: userState.email !== email ? email : undefined,
      corporateName:
        corporateName !== '' && userState.corporateName !== corporateName
          ? corporateName
          : undefined,
      firstName: userState.firstName !== firstName ? firstName : undefined,
      lastName: userState.lastName !== lastName ? lastName : undefined,
      phone:
        userState.phone.replace(/[^0-9]/g, '') !==
        phone.replace(/[^0-9]/g, '').trim()
          ? phone
          : undefined,
      address:
        Object.values(newAddress).filter((i) => i !== undefined).length > 0
          ? newAddress
          : undefined
    }

    if (Object.values(newUserInfo).filter((i) => i !== undefined).length > 0) {
      const userInfo: UserInfo = yield call(editUserService, newUserInfo)

      yield put(
        setAlertModalAction({
          open: true,
          title: 'Dados alterados com sucesso!',
          variant: 'success',
          buttonText: 'Fechar'
        })
      )
      yield put(getUserInfoSuccess(userInfo))
    }

    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setAlertModalAction({
        open: true,
        title: 'Não foi possivel alterar seus dados',
        description: 'Tente novamente mais tarde',
        variant: 'error',
        buttonText: 'Fechar'
      })
    )
  }
}

export const UserSaga = all([
  takeLatest(UserActions.getUserInfoRequest, getUserInfo),
  takeLatest(UserActions.editUserRequest, editUser)
])
