import * as Yup from 'yup'
import { messageHelper } from '../../../../utils/messageHelper'

export const createEventSchema = Yup.object({
  name: Yup.string()
    .min(2, messageHelper.yup.creator.error.courseShortName)
    .max(128, messageHelper.yup.creator.error.courseLongName)
    .required(messageHelper.yup.creator.error.nameIsMandatory)
})

export const createEventSchemaWithCashback = Yup.object({
  name: Yup.string()
    .min(2, messageHelper.yup.creator.error.courseShortName)
    .max(128, messageHelper.yup.creator.error.courseLongName)
    .required(messageHelper.yup.creator.error.nameIsMandatory),
  cashback: Yup.string()
    .required('Porcetagem do cashback é obrigatória.')
    .test(
      'cashbackVerify',
      'Porcetagem do cashback é obrigatória.',
      (values) => {
        if (values && values.replace(/[a-zA-Z, %]/g, '').length > 0) {
          return true
        } else return false
      }
    )
})
