/* eslint-disable no-unused-vars */
export enum UserActions {
  setUser = '@user/SET_USER',
  refreshToken = '@user/REFRESH_TOKEN',
  getUserInfo = '@user/GET_USER_INFO',
  getUserInfoRequest = '@user/GET_USER_INFO_REQUEST',
  editUserRequest = '@user/EDIT_USER_REQUEST',
  editUserSuccess = '@user/EDIT_USER_SUCCESS',
  editCreatorPaymentSettingRequest = '@user/EDIT_CREATOR_PAYMENT_SETTINGS_REQUEST',
  editCreatorPaymentSettingSuccess = '@user/EDIT_CREATOR_PAYMENT_SETTINGS_SUCCESS',
  logout = '@user/LOGOUT'
}

export type User = {
  name: string
  email: string
  accessToken: string
  refreshToken: string
}

export type CreatorPaymentSetting = {
  id: string
  bank: string | null
  bankAg: string | null
  bankAccountType: string | null
  bankCc: string | null
  status: 'WAITING' | 'REJECTED' | 'ACCEPTED'
}

export type UserInfo = {
  id: string
  firstName: string
  lastName: string
  document: string
  documentType: 'CNPJ' | 'CPF'
  email: string
  phone: string
  userId: string
  createdAt: string
  updatedAt: string | null
  corporateName?: string
  address?: {
    district: string
    city: string
    complement: string
    country: string
    createdAt: string
    creatorId?: string
    customerId: string
    eventId?: string
    id: string
    latitude?: string
    longitude?: string
    number: number
    seatMapId: string
    state: string
    street: string
    updatedAt?: string
    zipCode: string
  }
  creatorPaymentSetting: CreatorPaymentSetting
}

export type UserState = {
  data: User
  userInfo?: UserInfo
}
